import React, { useContext, useEffect, useState } from "react";
import { TableHeader, TableRow, TableHeaderCell, TableBody } from "grabcad-ui-elements";
import { useDrop } from "react-dnd";
import { ApplicationContext } from "@/components/ApplicationProvider";
import {
  OrderListColumn,
  mandatoryColumns,
  allColumns,
  IShopPreferences,
  IShop,
} from "@/graphql/Fragments/Shop";
import { ShopColumnRow } from "./ShopColumnRow";
import { ApolloClient, ApolloError } from "@apollo/client";
import { UPDATE_SHOP_PREFERENCES } from "@/graphql/Mutations/Shop";
import { Notifier } from "@/utils/Notifier";
import { StyledPrefTable } from "../Page";

export const ItemTypes = {
  COLUMN_ROW: "column_row",
};

export interface IShopColumn {
  id: string;
  name: string;
  isVisible: boolean;
  isMandatory: boolean;
}

export const ShopColumnsTable = ({
  shopColumnsPreferences,
  client,
}: {
  shopColumnsPreferences: string[];
  client: ApolloClient<any>;
}): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const [shopColumns, setShopColumns] = useState([] as IShopColumn[]);
  const [initialShopColumns, setInitialShopColumns] = useState([] as IShopColumn[]);

  useEffect(() => {
    generateColumnsList(shopColumnsPreferences);
  }, [shopColumnsPreferences]);

  const generateColumnsList = (userColumns: string[]) => {
    const columnList: IShopColumn[] = [];
    const visibleColumns = userColumns.length ? (userColumns as OrderListColumn[]) : [];
    // Filtering out a rogue custom column key
    // https://github.com/GrabCAD/shop-client-frontoffice/pull/350
    const blackListColumns = ["orderItemsCount"];
    visibleColumns
      .filter(col => !blackListColumns.includes(col))
      .forEach(column => {
        if (mandatoryColumns.indexOf(column) < 0) {
          columnList.push({ name: column, isMandatory: false, isVisible: true, id: column });
        } else {
          columnList.push({ name: column, isMandatory: true, isVisible: true, id: column });
        }
      });
    allColumns
      .filter(column => visibleColumns.indexOf(column) < 0)
      .forEach(otherColumn =>
        columnList.push({
          name: otherColumn,
          isMandatory: false,
          isVisible: false,
          id: otherColumn,
        })
      );
    setShopColumns(columnList);
    setInitialShopColumns(columnList);
  };

  const moveRow = (id: string, atIndex: number) => {
    const { index } = findRow(id);
    const localShopColumns = [...shopColumns];
    localShopColumns.splice(index, 0, localShopColumns.splice(atIndex, 1)[0]);
    setShopColumns(localShopColumns);
  };

  const dropRow = () => {
    updateShopColumns(shopColumns);
    setInitialShopColumns(shopColumns);
  };

  const findRow = (id: string) => {
    const column = shopColumns.filter(c => `${c.id}` === id)[0];
    return {
      column,
      index: shopColumns.indexOf(column),
    };
  };

  const resetColumnsList = () => setShopColumns(initialShopColumns);

  const toggleColumnVisibility = (index: number) => {
    const localShopColumns = [...shopColumns];
    const columnToToggle = localShopColumns[index];
    columnToToggle.isVisible = !columnToToggle.isVisible;
    localShopColumns[index] = columnToToggle;
    setShopColumns(localShopColumns);
    updateShopColumns(localShopColumns);
  };

  const updateShopColumns = async (columns: IShopColumn[]) => {
    if (currentShop) {
      const columnsToUpdate = getVisibleColumnsArray([...columns]);
      try {
        await client.mutate<
          { updateShopPreferences: IShop },
          { shopId: number; prefs: Partial<IShopPreferences> }
        >({
          mutation: UPDATE_SHOP_PREFERENCES,
          variables: {
            shopId: currentShop.id,
            prefs: { columns: columnsToUpdate },
          },
        });

        Notifier.success(t("shop.preferences.columns.update.success"));
      } catch (error) {
        if (error instanceof ApolloError) {
          Notifier.error({ graphQLErrors: error.graphQLErrors });
        } else {
          throw error;
        }
      }
    }
  };

  const getVisibleColumnsArray = (columns: IShopColumn[]) =>
    columns.filter(column => column.isVisible).map(visibleColumn => visibleColumn.name);

  const [, drop] = useDrop({ accept: ItemTypes.COLUMN_ROW });

  return (
    <div ref={drop}>
      <StyledPrefTable className="unstackable">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("shop.preferences.table.title")}</TableHeaderCell>
            <TableHeaderCell className="short">{t("shop.preferences.visible")}</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {shopColumns.map((columnItem, index) => (
            <ShopColumnRow
              key={columnItem.name}
              id={columnItem.name}
              index={index}
              moveRow={moveRow}
              dropRow={dropRow}
              findRow={findRow}
              resetColumnsList={resetColumnsList}
              columnItem={columnItem}
              toggleColumnVisibility={toggleColumnVisibility}
            />
          ))}
        </TableBody>
      </StyledPrefTable>
    </div>
  );
};
ShopColumnsTable.displayName = "ShopColumnsTable";
