import { Mutation } from "@apollo/client/react/components";
import React, { useContext, useState } from "react";
import styled, { Segment, Input, StyleMixins } from "grabcad-ui-elements";
import { IOrder } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { UpdateOrderDetailsType, UPDATE_ORDER_DETAILS } from "@/graphql/Mutations/Order";
import { isEllipsisActive } from "@/utils/InputUtils";
import ReactGA from "react-ga";
import classnames from "classnames";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { useApolloClient } from "@apollo/client";

const Header = styled(Segment)`
  &.ui.segment {
    ${StyleMixins.roundAndShadow}
    width: 100%;
    background: #f9fafb;
    align-items: center;
    height: 69px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      font-weight: bold;
      min-width: 0;
      &.id {
        flex: 0 0 192px;
      }
      &.name {
        flex: 1 1 auto;
      }
    }
    .field {
      display: flex;
      align-items: center;
      .ui.input {
        margin-left: 5px;
        opacity: 1;
        > input {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            border-color: #003393 !important;
          }
        }
      }
    }
  }
`;

export const OrderHeader = ({ order }: { order: IOrder }): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { id } = order;
  const [projectName, setProjectName] = useState(order.name);
  const [projectCode, setProjectCode] = useState(order.projectCode);
  const client = useApolloClient();

  const blurOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const MAX_LENGTH = 100;
  const nameTooltip =
    projectName.length > MAX_LENGTH
      ? t("order.details.nameTooLong")
      : isEllipsisActive("qa-order-list-table-projectName")
      ? projectName
      : undefined;
  const codeTooltip =
    projectCode && projectCode.length > MAX_LENGTH
      ? t("order.details.codeTooLong")
      : isEllipsisActive("qa-order-list-table-projectCode")
      ? projectCode
      : undefined;

  return (
    <Mutation<
      { updateOrderDetails: UpdateOrderDetailsType },
      { input: UpdateOrderDetailsType & { id: number } }
    >
      mutation={UPDATE_ORDER_DETAILS}
      update={async cache => fetchNewOrderEvents(cache, client, id)}
    >
      {mutate => (
        <Header data-testid="orderHeader">
          <div className="id">{`${t("order.list.table.id")}: ${id}`}</div>
          <UiCan passThrough do={`${Permission.WRITE} name`} on={order}>
            {(writeable: boolean) => (
              <div className="field name">
                <label>{`${t("order.list.table.name")}:`}</label>
                <span
                  data-tooltip={nameTooltip}
                  data-position="bottom center"
                  data-visible={projectName.length > MAX_LENGTH ? true : undefined}
                >
                  <Input
                    className={classnames({ error: projectName.length > MAX_LENGTH })}
                    id="qa-order-list-table-projectName"
                    name="name"
                    value={projectName}
                    onChange={event => setProjectName(event.currentTarget.value)}
                    disabled={!writeable}
                    onKeyPress={blurOnEnter}
                    onBlur={async () => {
                      setProjectName(projectName.slice(0, MAX_LENGTH));
                      await mutate({
                        variables: {
                          input: { name: projectName.slice(0, MAX_LENGTH), id: order.id },
                        },
                      });
                      ReactGA.event({
                        category: "GcShop Order",
                        action: "Edit Order Project Name",
                        label: `Order ${order.id}`,
                      });
                    }}
                  />
                </span>
              </div>
            )}
          </UiCan>
          <UiCan passThrough do={`${Permission.WRITE} projectCode`} on={order}>
            {(writeable: boolean) => (
              <div className="field code">
                <label>{`${t("order.list.table.projectCode")}:`}</label>
                <span
                  data-tooltip={codeTooltip}
                  data-position="bottom right"
                  data-visible={projectCode && projectCode.length > MAX_LENGTH ? true : undefined}
                >
                  <Input
                    className={classnames({
                      error: projectCode && projectCode.length > MAX_LENGTH,
                    })}
                    id="qa-order-list-table-projectCode"
                    name="projectCode"
                    value={projectCode || ""} // project code can be null
                    onChange={event => setProjectCode(event.currentTarget.value)}
                    disabled={!writeable}
                    onKeyPress={blurOnEnter}
                    onBlur={async () => {
                      setProjectCode(projectCode?.slice(0, MAX_LENGTH));
                      await mutate({
                        variables: {
                          input: {
                            projectCode: projectCode?.slice(0, MAX_LENGTH),
                            id: order.id,
                          },
                        },
                      });
                      ReactGA.event({
                        category: "GcShop Order",
                        action: "Edit Order Order Code",
                        label: `Order ${order.id}`,
                      });
                    }}
                  />
                </span>
              </div>
            )}
          </UiCan>
        </Header>
      )}
    </Mutation>
  );
};
OrderHeader.displayName = "OrderHeader";
