import styled, { Button, Icon, Modal, Popup } from "grabcad-ui-elements";
import { IOrderItem } from "../../../../graphql/Fragments/Order";
import React, { useContext, useState } from "react";
import { ApplicationContext } from "../../../../components/ApplicationProvider";
import { PartPreview } from "./PartPreview";
import { Snapshot } from "../../../../components/Shared/Snapshot";
import {
  AutoEstimation,
  AutoEstimationAsset,
  AutoEstimationError,
  ORIENTATION,
  OrientationDetails,
} from "../../../../graphql/Fragments/AutoEstimation";
import {
  UpdateOrderItemData,
  UpdateOrderItemType,
  UpdateOrderItemVariables,
} from "../../../../graphql/Mutations/OrderItem";
import { MutationFunctionOptions } from "@apollo/client";

const StyledOrientationModal = styled(Modal)`
  &.ui.modal.orientation-modal {
    width: 1024px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
    > .close {
      color: black;
      margin-right: -7px;
    }
    .header {
      color: black;
    }
    .sub-header {
      padding: 20px;
      word-break: break-all;
    }
    > .content {
      color: black;
      padding: 0;
    }
    .inner-content {
      overflow: auto;
      max-height: 900px;
    }
    .actions {
      padding: 20px 20px;
      height: 78px;
      .cancel {
        float: left;
        margin: 0;
      }
      .submit {
        float: right;
      }
    }
    .clickable {
      color: #003393;
      cursor: pointer;
    }
    .hover-gray {
      &:hover {
        background-color: #ecf4ff;
      }
    }
  }
`;

const PreviewBand = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > span {
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    flex-basis: 250px;
    font-size: 36px;
  }
`;

const ButtonBand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 143px;
  margin-top: 5px;

  .button {
    padding: 2px;
    width: 99px;
  }
  .label {
    padding-top: 5px;
    text-align: center;
    height: auto;
    font-size: 12px;
  }
  .selected {
    background-color: #003393;
    color: white;
  }
`;

export const OrientationModal = ({
  open,
  orderItem,
  matchingAE,
  canEdit,
  updateOrderItem,
  onClose,
}: {
  open: boolean;
  orderItem: IOrderItem;
  matchingAE: AutoEstimation | undefined;
  canEdit: boolean;
  updateOrderItem(
    options?: MutationFunctionOptions<UpdateOrderItemData, UpdateOrderItemVariables>
  ): void;
  onClose(): void;
}): JSX.Element | null => {
  const { t, messageExists } = useContext(ApplicationContext);
  const itemToUpdate: UpdateOrderItemType = {};

  const orientationSnapshots: AutoEstimationAsset[] | undefined =
    matchingAE?.autoEstimationAssets?.filter(a => a.asset.assetType?.includes("ORIENTATION"));

  const orientationOptions: OrientationDetails[] | undefined =
    matchingAE?.resultData?.estimates[0]?.orientationOptions;

  const getAssetFromOrientation = (orientation: ORIENTATION): AutoEstimationAsset | undefined => {
    return orientationSnapshots?.find(a => a.asset.assetType === "ORIENTATION_" + orientation);
  };

  const [currentOrientation, setCurrentOrientation] = useState<OrientationDetails | undefined>(
    orientationOptions?.find(o => o.orientation === orderItem.orientation)
  );

  const next = (left: boolean) => {
    if (orientationOptions) {
      const iCurrent = orientationOptions.findIndex(a => a === currentOrientation);
      if (!left) {
        if (iCurrent < orientationOptions.length - 1) {
          setCurrentOrientation(orientationOptions[iCurrent + 1]);
        }
      } else if (iCurrent > 0) {
        setCurrentOrientation(orientationOptions[iCurrent - 1]);
      }
    }
  };

  const getErrorMessage = (error: AutoEstimationError) => {
    const message = `auto_estimation.error.${error?.name}`;
    return messageExists(message) ? t(message) : t("auto_estimation.error.OrientationError");
  };

  const OnCloseOrUpdate = () => {
    if (itemToUpdate.orientation && itemToUpdate.orientation !== orderItem.orientation) {
      updateOrderItem({
        variables: {
          id: orderItem.id,
          input: itemToUpdate,
        },
      });
    }
    onClose();
  };

  return (
    <StyledOrientationModal
      data-testid="orientationModal"
      basic
      size="small"
      open={open}
      className="orientation-modal"
      onClose={OnCloseOrUpdate}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{t("auto_estimation.orientation.popup.header")}</Modal.Header>
      <Modal.Content>
        <PreviewBand>
          {currentOrientation === orientationOptions?.[0] ? (
            <span />
          ) : (
            <span
              data-testid="previousOrientation"
              onClick={() => next(true)}
              className="clickable"
            >
              <Icon name="chevron left" />
            </span>
          )}
          <PartPreview
            snapshotAsset={
              currentOrientation?.orientation &&
              getAssetFromOrientation(currentOrientation?.orientation)
            }
          />
          {currentOrientation === orientationOptions?.[orientationOptions?.length - 1] ? (
            <span />
          ) : (
            <span data-testid="nextOrientation" onClick={() => next(false)} className="clickable">
              <Icon name="chevron right" />
            </span>
          )}
        </PreviewBand>
        {orientationOptions && orientationOptions.length > 0 ? (
          <ButtonBand data-testid="orientationOptions">
            {orientationOptions.map(orientationOption => (
              <div
                role="orientationOption"
                data-testid={orientationOption.orientation}
                key={orientationOption.orientation}
                className={
                  "button " +
                  (orientationOption === currentOrientation ? "selected" : "clickable hover-gray")
                }
                onClick={() => setCurrentOrientation(orientationOption)}
              >
                <Snapshot asset={getAssetFromOrientation(orientationOption.orientation)} />
                <div className="label">
                  {orientationOption.error && (
                    <Popup
                      trigger={
                        <span>
                          <Icon name="warning sign" color="red" />
                        </span>
                      }
                      content={getErrorMessage(orientationOption.error)}
                      position={"bottom center"}
                      inverted
                    />
                  )}
                  {t(
                    "auto_estimation.orientation.option." +
                      orientationOption.orientation.toLowerCase()
                  ) ?? orientationOption.orientation.toString()}
                </div>
              </div>
            ))}
          </ButtonBand>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        {canEdit && (
          <Button
            data-testid="updateOrientationButton"
            className="submit"
            id="qa-orderItemOrientation-update"
            disabled={
              orderItem.orientation === currentOrientation?.orientation ||
              !!currentOrientation?.error
            }
            onClick={async () => {
              const orientation = currentOrientation?.orientation;
              if (orientation) {
                itemToUpdate.orientation = orientation;
              }
              OnCloseOrUpdate();
            }}
          >
            {t("auto_estimation.orientation.popup.submit")}
          </Button>
        )}
      </Modal.Actions>
    </StyledOrientationModal>
  );
};
