import React, { useContext } from "react";
import { Image, Button } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import svg404 from "@/assets/404.svg";
import svgStratasys from "@/assets/stratasys.svg";
import svgGrabCAD from "@/assets/grabcad.svg";
import { StyledGeneralScreensPage } from "@/screens/ScreenStyleUtils/GeneralScreenStyle";

export const ScreensPageNotFound = (): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <StyledGeneralScreensPage>
      <div>
        <h1>{t("pageNotFound.header")}</h1>
        <p>{t("pageNotFound.detail")}</p>
        <Image src={svg404} />
        <a href="https://login.grabcad.com/">
          <Button>{t("pageNotFound.cta")}</Button>
        </a>
        <div className="logos">
          <a href="https://www.stratasys.com/">
            <Image src={svgStratasys} />
          </a>
          <a href="https://grabcad.com/">
            <Image src={svgGrabCAD} />
          </a>
        </div>
      </div>
    </StyledGeneralScreensPage>
  );
};
