import gql from "graphql-tag";

export interface IJobCreateInput {
  name: string;
  shopId: number;
  shopTechnologyId?: number;
  shopMachineId?: number;
  shopMaterialId?: number;
  shopMaterialColorId?: number;
  orderItemIds: number[];
}

export interface IUpdateJobInput {
  id: number;
  name?: string;
  isCompleted?: boolean;
}

export interface ItemQuantityToMove {
  id: number;
  quantity?: number;
  toStepId?: number;
  fromStepId?: number;
}
export interface IJobHistoriesInput {
  jobId: number;
  itemQuantitiesToMove: ItemQuantityToMove[];
}

export const CREATE_JOB = gql`
  mutation createJob($input: JobCreateInput!) {
    createJob(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($input: JobUpdateInput!) {
    updateJob(input: $input) {
      id
      name
      dateCompleted
    }
  }
`;

export const CREATE_ORDER_ITEM_JOB_ROUTE_STEP_HISTORIES = gql`
  mutation createOrderItemJobRouteStepHistories($input: JobHistoriesInput!) {
    createOrderItemJobRouteStepHistories(input: $input) {
      id
      orderItemId
      quantityMoved
      toShopRouteStepId
      fromShopRouteStepId
      dateCreated
      dateDeleted
    }
  }
`;

export const REMOVE_ORDER_ITEMS_FROM_JOB = gql`
  mutation removeOrderItemsFromJob($input: RemoveOrderItemsFromJobInput!) {
    removeOrderItemsFromJob(input: $input) {
      id
      dateDeleted
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: Int!) {
    deleteJob(id: $id) {
      id
      dateDeleted
    }
  }
`;
