import React, { useContext, useState, useEffect } from "react";
import classnames from "classnames";
import styled, {
  Popup,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  StyleMixins,
} from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { getLocalizedStatus, getStatusColor, getStatusName } from "../Status/Status";
import { GRAPHQL_ORDER_STATUS } from "@/screens/Shop/Order/New/New";
import { isNull } from "util";
import moment, { Moment } from "moment";
import { FormattedDateRangePicker } from "../DatePicker/FormattedDatePicker";
import { FocusedInputShape } from "react-dates";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import { useShopTechnologies } from "@/utils/queryHooks";

const PopupTrigger = styled.div`
  cursor: pointer;
  padding: 8px;
  margin: -8px;
`;
const StyledPopup = styled(Popup)`
  .popup-content {
    width: 384px;
    padding: 10px 10px 15px 10px;
    .header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 18px;
        margin: 0;
      }
      a.reset {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
      }
    }
    label {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin: 16px 0 5px;
      display: block;
    }
    .ui.dropdown {
      padding: 5px 10px;
      border: 1px solid #cccccc;
      border-radius: 4px;

      > .dropdown.icon {
        font-size: 16px;
        position: relative;
        top: 1px;
      }
      &.has-selection {
        color: #003393;
        background: #e0ecfc;
        border: 1px solid #e0ecfc;
        &:not(.button) > .default.text {
          color: #003393;
        }
      }
      .menu {
        width: 100%;
      }
      .item {
        border-left: 5px solid white;
      }
    }
    .flexRow {
      display: flex;
      &.padded {
        > :first-child {
          margin-right: 5px;
        }
        > :last-child {
          margin-left: 5px;
        }
      }
    }
    position: relative;
    .date-range-picker {
      ${StyleMixins.roundAndShadow}
      position: absolute;
      width: 330px;
      background: white;
    }
    .status-dropdown.menu {
      max-height: calc(100vh - 260px);
      overflow-y: auto;
    }
  }
`;

const ActiveFilter = styled.div`
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #003393;
  background: #e0ecfc;
  border-radius: 4px;
  padding: 3px 8px;
  i.icon {
    cursor: pointer;
    opacity: 0.5;
    &:before {
      padding: 5px 8px;
    }
    &:hover {
      opacity: 1;
    }
  }
  .infinity {
    font-size: 20px;
    line-height: 10px;
    font-family: Roboto, "San Francisco", "Segoe UI", sans-serif !important;
    position: relative;
    top: 1px;
  }
`;

const DateInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  height: 32px;
  border: 1px solid #ccc;
  margin-top: 8px;
  color: rgba(191, 191, 191, 0.87);
  .placeholder {
    word-break: keep-all;
    white-space: nowrap;
  }
  .ui.input > input {
    border: none !important;
    height: 30px;
    background: none;
    &:focus {
      border: none !important;
    }
  }
  position: relative;
  z-index: 1;
  &.focused {
    border: 1px solid #003393;
    z-index: 2; /* To create effect of shared border */
  }
  &.has-value {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
  }
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -2px;
  }
`;

export const activeAppStatuses = [
  "SUBMITTED",
  "IN_REVIEW",
  "APPROVED",
  "IN_PROGRESS",
  "HAS_ISSUES",
] as unknown as [GRAPHQL_ORDER_STATUS];

export const isStatusSet = (statuses: number[], set: number[]): boolean =>
  statuses.length === set.length && statuses.every(value => set.includes(value));

export const DATE_CREATED = "dateCreated";
export const NEED_BY_DATE = "needByDate";
export const DUE_DATE = "dueDate";
export type DateFilterType = "dateCreated" | "needByDate" | "dueDate";

export const LAST_24_HOURS = "last24hours";
export const LAST_7_DAYS = "last7days";
export const LAST_30_DAYS = "last30days";
export const CUSTOM_RANGE = "customRange";

export type DateFilterRangeType = "last24hours" | "last7days" | "last30days" | "customRange" | null;
export const allRanges: DateFilterRangeType[] = [
  LAST_24_HOURS,
  LAST_7_DAYS,
  LAST_30_DAYS,
  CUSTOM_RANGE,
];
export type CustomDateRange = { startDate: Moment | null; endDate: Moment | null };

export interface IFiltersPopupProps {
  selectedStatuses: number[];
  onStatusesSelected: (statuses: number[]) => void;
  onTechnologySelected: (shopTechnologyId: number | null) => void;
  selectedShopTechnologyId: number | null;

  dateFilterType: DateFilterType;
  onDateFilterTypeSelected: (type: DateFilterType) => void;
  dateFilterRange: DateFilterRangeType;
  onDateFilterRangeSelected: (range: DateFilterRangeType) => void;
  customDateRange: CustomDateRange;
  onCustomnDateRangeSelected: (range: CustomDateRange) => void;
}

export const getActiveStatusIds = (statuses: IShopStatus[]): number[] =>
  statuses
    .filter(s => s.isActive || (s.appStatus && activeAppStatuses.includes(s.appStatus)))
    .map(s => s.id);
export const getInactiveStatusIds = (statuses: IShopStatus[]): number[] =>
  statuses
    .filter(s => s.isActive === false || (s.appStatus && !activeAppStatuses.includes(s.appStatus)))
    .map(s => s.id);

export const FiltersPopup = ({
  selectedStatuses,
  onStatusesSelected,
  onTechnologySelected,
  selectedShopTechnologyId,

  dateFilterType,
  onDateFilterTypeSelected,
  dateFilterRange,
  onDateFilterRangeSelected,
  customDateRange,
  onCustomnDateRangeSelected,
}: IFiltersPopupProps): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return <div />;
  }
  const { shopTechnologies } = useShopTechnologies();

  const [dateInputFocused, setDateInputFocused] = useState(false);
  const [startDateInput, setStartDateInput] = useState(
    customDateRange.startDate ? customDateRange.startDate.format("l") : ""
  );
  const [endDateInput, setEndDateInput] = useState(
    customDateRange.endDate ? customDateRange.endDate.format("l") : ""
  );
  useEffect(() => {
    setStartDateInput(customDateRange.startDate ? customDateRange.startDate.format("l") : "");
    setEndDateInput(customDateRange.endDate ? customDateRange.endDate.format("l") : "");
  }, [customDateRange]);

  let statusDropdownText;
  const activeFilter = (text: string | JSX.Element, key: string, onClear: () => void) => (
    <ActiveFilter key={key} className="qa-active-filter" data-testid="activeFilter">
      {text}
      <Icon name="x" onClick={onClear} data-testid="activeFilterIcon" />
    </ActiveFilter>
  );

  const activeOrdersStatuses: number[] = getActiveStatusIds(currentShop.shopStatuses);
  const inactiveOrdersStatuses: number[] = getInactiveStatusIds(currentShop.shopStatuses);

  let resetButton;
  if (
    !isNull(selectedShopTechnologyId) ||
    !isStatusSet(selectedStatuses, activeOrdersStatuses) ||
    dateFilterRange
  ) {
    resetButton = (
      <a
        className="reset qa-reset-filters-btn"
        onClick={() => {
          onStatusesSelected(activeOrdersStatuses);
          onTechnologySelected(null);
          onDateFilterTypeSelected(DATE_CREATED);
          onDateFilterRangeSelected(null);
          onCustomnDateRangeSelected({ startDate: null, endDate: null });
        }}
      >
        {t("order.list.filters.reset")}
      </a>
    );
  }

  const activeFilters: JSX.Element[] = [];
  if (isStatusSet(selectedStatuses, activeOrdersStatuses)) {
    statusDropdownText = t("order.status.active");
    activeFilters.push(
      activeFilter(t("order.status.active"), "activeStatuses", () => onStatusesSelected([]))
    );
  } else if (isStatusSet(selectedStatuses, inactiveOrdersStatuses)) {
    statusDropdownText = t("order.status.inactive");
    activeFilters.push(
      activeFilter(t("order.status.inactive"), "inactiveStatuses", () => onStatusesSelected([]))
    );
  } else if (selectedStatuses.length && selectedStatuses[0]) {
    const status = currentShop.shopStatuses.find(s => s.id === selectedStatuses[0]);
    status &&
      (statusDropdownText =
        status.name || (status.appStatus && getLocalizedStatus(t, status.appStatus)));
    statusDropdownText &&
      activeFilters.push(activeFilter(statusDropdownText, "status", () => onStatusesSelected([])));
  }

  const selectedTechnology = !isNull(selectedShopTechnologyId)
    ? shopTechnologies.find(tech => tech.id === selectedShopTechnologyId)
    : null;
  if (selectedTechnology) {
    activeFilters.push(
      activeFilter(selectedTechnology.appTechnology.displayName, "tech", () =>
        onTechnologySelected(null)
      )
    );
  }

  const technologyOptions = [...shopTechnologies].map(shopTechnology => ({
    key: `shop-techonology-${shopTechnology.id}`,
    text: shopTechnology.appTechnology.displayName,
    onClick: () =>
      onTechnologySelected(
        shopTechnology.id !== selectedShopTechnologyId ? shopTechnology.id : null
      ),
    selected: selectedShopTechnologyId === shopTechnology.id,
  }));

  if (dateFilterRange) {
    const filterType = t(`order.list.filters.${dateFilterType}`);
    if (dateFilterRange === CUSTOM_RANGE) {
      if (customDateRange.startDate || customDateRange.endDate) {
        const infinity = <span className="infinity">∞</span>;
        const start = customDateRange.startDate ? customDateRange.startDate.format("l") : "";
        const end = customDateRange.endDate ? customDateRange.endDate.format("l") : "";
        activeFilters.push(
          activeFilter(
            <>
              {`${filterType}: `}
              {!start && infinity}
              {`${start} - ${end}`}
              {!end && infinity}
            </>,
            "customRange",
            () => {
              onDateFilterRangeSelected(null);
              onCustomnDateRangeSelected({ startDate: null, endDate: null });
            }
          )
        );
      }
    } else {
      activeFilters.push(
        activeFilter(
          filterType + ": " + t(`order.list.filters.dateRange.${dateFilterRange}`),
          "dateRange",
          () => onDateFilterRangeSelected(null)
        )
      );
    }
  }

  const START_DATE = "startDate";
  const END_DATE = "endDate";
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(START_DATE);
  const onFocusChange = (focused: FocusedInputShape | null) => {
    setFocusedInput(!focused ? START_DATE : focused);
  };

  const onBlurDateInput = () => {
    const newCustomDateRange = { ...customDateRange };
    if (moment(startDateInput).isValid()) {
      newCustomDateRange.startDate = moment(startDateInput);
    }
    if (moment(endDateInput).isValid()) {
      newCustomDateRange.endDate = moment(endDateInput);
    }
    onCustomnDateRangeSelected(newCustomDateRange);
    onDateFilterRangeSelected(CUSTOM_RANGE);
    setDateInputFocused(false);
  };

  const blurOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const dateRangePicker =
    dateFilterRange === CUSTOM_RANGE || dateInputFocused ? (
      <div className="date-range-picker">
        <FormattedDateRangePicker
          startDate={customDateRange.startDate}
          endDate={customDateRange.endDate}
          onDatesChange={dateRange => {
            setStartDateInput(dateRange.startDate ? dateRange.startDate.format("l") : "");
            setEndDateInput(dateRange.endDate ? dateRange.endDate.format("l") : "");
            onCustomnDateRangeSelected(dateRange);
          }}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
        />
      </div>
    ) : null;

  const filtersPopup = (
    <StyledPopup
      on="click"
      position="bottom left"
      trigger={
        <PopupTrigger className="qa-filters-popup-trigger">
          {t("order.list.filters.title")}
          <Icon name="dropdown" />
        </PopupTrigger>
      }
    >
      <div className="popup-content">
        <div className="header-row">
          <h2>{t("order.list.filters.header")}</h2>
          {resetButton}
        </div>

        <label>{t("order.list.filterByStatus")}</label>
        <Dropdown
          fluid
          text={statusDropdownText}
          placeholder={t("order.list.filters.selectStatus")}
          className={classnames("qa-status-filter-dropdown", {
            "has-selection": !!selectedStatuses.length,
          })}
        >
          <DropdownMenu className="status-dropdown">
            {currentShop.shopStatuses
              .filter(status => !status.isHidden && !status.dateDeleted && getStatusName(status, t))
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map(status => (
                <DropdownItem
                  key={status.id}
                  text={getStatusName(status, t)}
                  onClick={() => onStatusesSelected([status.id])}
                  selected={selectedStatuses.length === 1 && selectedStatuses[0] === status.id}
                  style={{ borderLeftColor: getStatusColor(status) }}
                />
              ))
              .concat([
                <DropdownItem
                  key="active"
                  text={t("order.status.active")}
                  onClick={() => onStatusesSelected(activeOrdersStatuses)}
                  selected={isStatusSet(selectedStatuses, activeOrdersStatuses)}
                />,
                <DropdownItem
                  key="inactive"
                  text={t("order.status.inactive")}
                  onClick={() => onStatusesSelected(inactiveOrdersStatuses)}
                  selected={isStatusSet(selectedStatuses, inactiveOrdersStatuses)}
                />,
              ])}
          </DropdownMenu>
        </Dropdown>
        <label>{t("order.list.filterByTechnology")}</label>

        <Dropdown
          fluid
          disabled={shopTechnologies.length <= 1}
          text={!!selectedTechnology ? selectedTechnology.appTechnology.displayName : undefined}
          placeholder={
            technologyOptions.length === 1
              ? technologyOptions[0].text
              : t("order.list.filters.tech")
          }
          className={classnames("qa-tech-filter-dropdown", {
            "has-selection": !!selectedShopTechnologyId,
          })}
        >
          <DropdownMenu>
            {technologyOptions.map(option => (
              <DropdownItem
                key={`shop-techonology-${option.key}`}
                text={option.text}
                onClick={() => option.onClick()}
                selected={option.selected}
              />
            ))}
          </DropdownMenu>
        </Dropdown>

        <label>{t("order.list.filters.dateRange")}</label>
        <div className="flexRow padded">
          <Dropdown
            fluid
            text={t(`order.list.filters.${dateFilterType}`)}
            className={classnames("qa-date-filter-type-dropdown", { "has-selection": true })}
          >
            <DropdownMenu>
              <DropdownItem
                key="submittedDate"
                text={t("order.list.filters.dateCreated")}
                onClick={() => onDateFilterTypeSelected(DATE_CREATED)}
                selected={dateFilterType === DATE_CREATED}
              />
              <DropdownItem
                key="needByDate"
                text={t("order.list.filters.needByDate")}
                onClick={() => onDateFilterTypeSelected(NEED_BY_DATE)}
                selected={dateFilterType === NEED_BY_DATE}
              />
              <DropdownItem
                key="dueDate"
                text={t("order.list.filters.dueDate")}
                onClick={() => onDateFilterTypeSelected(DUE_DATE)}
                selected={dateFilterType === DUE_DATE}
              />
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            fluid
            text={
              dateFilterRange ? t(`order.list.filters.dateRange.${dateFilterRange}`) : undefined
            }
            placeholder={t("order.list.filters.selectRange")}
            className={classnames("qa-date-filter-range-dropdown", {
              "has-selection": !!dateFilterRange,
            })}
          >
            <DropdownMenu>
              {allRanges.map(range => (
                <DropdownItem
                  key={range || "none"}
                  text={t(`order.list.filters.dateRange.${range}`)}
                  onClick={() => {
                    onDateFilterRangeSelected(range);
                    if (range !== CUSTOM_RANGE) {
                      onCustomnDateRangeSelected({ startDate: null, endDate: null });
                    }
                  }}
                  selected={dateFilterRange === range}
                />
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="flexRow">
          <DateInputWrapper
            className={classnames({
              focused:
                focusedInput === START_DATE &&
                (dateFilterRange === CUSTOM_RANGE || dateInputFocused),
              "has-value": !!startDateInput,
            })}
          >
            <div className="placeholder">{t("order.list.filters.dateRange.start")}</div>
            <Input
              className="qa-date-filter-start-input"
              value={startDateInput}
              onChange={({ target: { value } }: { target: { value: string } }) => {
                setStartDateInput(value);
              }}
              onFocus={() => {
                setFocusedInput(START_DATE);
                setDateInputFocused(true);
              }}
              onBlur={onBlurDateInput}
              onKeyDown={blurOnEnter}
            />
          </DateInputWrapper>
          <DateInputWrapper
            className={classnames({
              focused:
                focusedInput === END_DATE && (dateFilterRange === CUSTOM_RANGE || dateInputFocused),
              "has-value": !!endDateInput,
            })}
          >
            <div className="placeholder">{t("order.list.filters.dateRange.end")}</div>
            <Input
              className="qa-date-filter-end-input"
              value={endDateInput}
              onChange={({ target: { value } }: { target: { value: string } }) => {
                setEndDateInput(value);
              }}
              onFocus={() => {
                setFocusedInput(END_DATE);
                setDateInputFocused(true);
              }}
              onBlur={onBlurDateInput}
              onKeyDown={blurOnEnter}
            />
          </DateInputWrapper>
        </div>
        {dateRangePicker}
      </div>
    </StyledPopup>
  );

  return (
    <>
      {filtersPopup}
      {activeFilters}
    </>
  );
};

FiltersPopup.displayName = "FiltersPopup";
