import gql from "graphql-tag";
import { OrderFragments } from "@/graphql/Fragments";
import { IOrder } from "@/graphql/Fragments/Order";
import { Moment } from "moment";

export type OrderDetailsEditableFields = Pick<
  IOrder,
  "projectCode" | "dueDate" | "needByDate" | "name"
>;

export type UpdateOrderDetailsType = {
  [key in keyof Partial<OrderDetailsEditableFields>]: string | Date | undefined | Moment;
};

export interface IUpdateOrderDetailsInput extends UpdateOrderDetailsType {
  dueDate?: Moment;
  needByDate?: Moment;
}

export const CREATE_ORDER = gql`
  mutation createOrderMutation($input: OrderInput!) {
    createOrder(input: $input) {
      ...orderFields
    }
  }
  ${OrderFragments.order}
`;
export const CREATE_DUMMY_ORDERS = gql`
  mutation createDummyOrdersMutation($input: DummyOrdersInput!) {
    createDummyOrders(input: $input) {
      id
    }
  }
`;
export const DELETE_DUMMY_ORDERS = gql`
  mutation deleteDummyOrdersMutation($shopId: Int!) {
    deleteDummyOrders(shopId: $shopId)
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderShopStatus($id: Int!, $shopStatus: Int!) {
    updateOrderShopStatus(id: $id, shopStatus: $shopStatus) {
      id
      shopStatus
    }
  }
`;

export const UPDATE_ORDER_DETAILS = gql`
  mutation updateOrderDetailsMutation($input: OrderUpdateDetailsInput!) {
    updateOrderDetails(input: $input) {
      id
      name
      projectCode
      needByDate
      dueDate
    }
  }
`;

export const ADD_FILES_TO_ORDER = gql`
  mutation addModelsImagesFilesToOrderMutation($input: AddOrderPartsInput!) {
    addModelsImagesFilesToOrder(input: $input) {
      id
    }
  }
`;

export const DELETE_ORDER_ITEMS = gql`
  mutation deleteOrderItemsByIdsMutation($ids: OrderItemDeleteInput!, $orderId: Int!) {
    deleteModelsImagesFilesFromOrder(ids: $ids, orderId: $orderId) {
      id
    }
  }
`;

export const UPDATE_ORDER_OPERATOR = gql`
  mutation updateOrderOperator($id: Int!, $operatorId: Int) {
    updateOrderOperator(id: $id, operatorId: $operatorId) {
      id
      operator {
        id
        name
        email
      }
    }
  }
`;
