import classnames from "classnames";
import { DownloadButton } from "@/components/Shared/DownloadButton";
import { Checkbox, TableCell } from "grabcad-ui-elements";
import { OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import { IGenericFile } from "@/graphql/Mutations/Upload";
import React from "react";
import { OrderItemsTableRow } from "../Items/Items";

interface IOrderImageProps {
  item: IGenericFile;
  index: number;
  classNames?: string;
  selectedFileItems: number[];
  toggleSelection: (item: OrderItemOrImageOrFile) => void;
}

export const _OrderFileItem = ({
  item,
  index,
  classNames,
  selectedFileItems,
  toggleSelection,
}: IOrderImageProps): JSX.Element => {
  const { asset } = item;
  return (
    <OrderItemsTableRow key={index} className={classnames("qa-orderFileItemRow", classNames)}>
      <TableCell verticalAlign="top" className="num">
        <strong>{index + 1}</strong>
        <Checkbox
          checked={selectedFileItems.indexOf(item.id) > -1}
          onClick={() => toggleSelection(item)}
          className={"qa-orderItemRow-checkbox"}
        />
      </TableCell>
      <TableCell className="img">
        <DownloadButton items={[item]} />
      </TableCell>
      <TableCell className="model-settings" verticalAlign="middle">
        <strong className="file-name">{asset.originalName}</strong>
      </TableCell>

      {/* Cells below are just to maintan grid alignment */}
      <TableCell className="details" />
      <TableCell className="status" />
      <TableCell className="price" />
    </OrderItemsTableRow>
  );
};

// Normally, child React component will re-render if the parent re-renders.
// memo() changes child React component to be a "pure component" i.e., it will
// *not* re-render if the parent changes, but only when its props/states/context changes.
export const OrderFileItem = React.memo(_OrderFileItem);
