import Default_Machine_Image from "@/assets/default-image-500px-sq.png";
import FDM_250mc from "@/assets/Machines/FDM_250mc.png";
import FDM_Dimension_Elite from "@/assets/Machines/FDM_Dimension_Elite.png";
import FDM_Dimension_SST_1200 from "@/assets/Machines/FDM_Dimension_SST_1200.png";
import FDM_Dimension_SST_1200es from "@/assets/Machines/FDM_Dimension_SST_1200es.png";
import FDM_Dimension_SST_768 from "@/assets/Machines/FDM_Dimension_SST_768.png";
import FDM_F120 from "@/assets/Machines/FDM_F120.png";
import FDM_F170 from "@/assets/Machines/FDM_F170.png";
import FDM_F190_CR from "@/assets/Machines/FDM_F190_CR.png";
import FDM_F270 from "@/assets/Machines/FDM_F270.png";
import FDM_F370 from "@/assets/Machines/FDM_F370.png";
import FDM_F370_CR from "@/assets/Machines/FDM_F190_CR.png";
import FDM_F900 from "@/assets/Machines/FDM_F900.png";
import FDM_F3300 from "@/assets/Machines/F3300.jpg";
import FDM_Fortus_380mc from "@/assets/Machines/FDM_Fortus_380mc.png";
import FDM_Fortus_380_Carbon_Fiber_Edition from "@/assets/Machines/FDM_Fortus_380_Carbon_Fiber_Edition.png";
import FDM_Fortus_400mc from "@/assets/Machines/FDM_Fortus_400mc.png";
import FDM_Fortus_450mc from "@/assets/Machines/FDM_Fortus_450mc.png";
import FDM_Fortus_900mc from "@/assets/Machines/FDM_Fortus_900mc.png";
import FDM_uPrint from "@/assets/Machines/FDM_uPrint.png";
import FDM_uPrint_SE from "@/assets/Machines/FDM_uPrint_SE.png";
import Polyjet_Eden500V from "@/assets/Machines/Polyjet_Eden500V.png";
import Polyjet_J700_Dental from "@/assets/Machines/Polyjet_J700_Dental.png";
import Polyjet_J735 from "@/assets/Machines/Polyjet_J735.png";
import Polyjet_J750 from "@/assets/Machines/Polyjet_J750.png";
import Polyjet_Objet1000_Plus from "@/assets/Machines/Polyjet_Objet1000_Plus.png";
import Polyjet_Objet260_Connex3b from "@/assets/Machines/Polyjet_Objet260_Connex3b.png";
import Polyjet_Objet30_Orthodesk from "@/assets/Machines/Polyjet_Objet30_Orthodesk.png";
import Polyjet_Objet30_Pro from "@/assets/Machines/Polyjet_Objet30_Pro.png";
import Polyjet_Objet350_Connex3 from "@/assets/Machines/Polyjet_Objet350_Connex3.png";
import Polyjet_Objet500_Connex1 from "@/assets/Machines/Polyjet_Objet500_Connex1.png";
import Polyjet_Objet500_Connex3 from "@/assets/Machines/Polyjet_Objet500_Connex3.png";
import Polyjet_Objet_Eden260VS from "@/assets/Machines/Polyjet_Objet_Eden260VS.png";
import FDM_360mc_large from "@/assets/Machines/360mc_large.png";
import FDM_360mc_small from "@/assets/Machines/360mc_small.png";
import FDM_400mc_large from "@/assets/Machines/400mc_large.png";
import Connex_1_Objet260 from "@/assets/Machines/Connex_1_Objet260.png";
import Connex_1_Objet350 from "@/assets/Machines/Connex_1_Objet350.png";
import Connex_2_Objet260 from "@/assets/Machines/Connex_2_Objet260.png";
import Connex_2_Objet350 from "@/assets/Machines/Connex_2_Objet350.png";
import Connex_2_Objet500 from "@/assets/Machines/Connex_2_Objet500.png";
import Dimension_BST_1200 from "@/assets/Machines/Dimension_BST_1200.png";
import Dimension_BST_1200es from "@/assets/Machines/Dimension_BST_1200es.png";
import Dimension_BST_768 from "@/assets/Machines/Dimension_BST_768.png";
import Eden_260_dental from "@/assets/Machines/Eden_260_dental.png";
import Eden_350_series from "@/assets/Machines/Eden_350_series.png";
import Eden_500_dental from "@/assets/Machines/Eden_500_dental.png";
import J835 from "@/assets/Machines/J835.png";
import J850 from "@/assets/Machines/J850.png";
import MakerBot_Method_Series from "@/assets/Machines/MakerBot_Method_Series.png";
import MakerBot_Replicator_Series from "@/assets/Machines/MakerBot_Replicator_Series.png";
import Objet_24 from "@/assets/Machines/Objet_24.png";
import Objet_30_Dental from "@/assets/Machines/Objet_30_Dental.png";
import uPrint_Plus from "@/assets/Machines/uPrint_Plus.png";
import uPrint_SE_Plus from "@/assets/Machines/uPrint_SE_Plus.png";
import MakerBot_Sketch from "@/assets/Machines/MakerBot_Sketch.png";
import FDM_Continuous_Build from "@/assets/Machines/FDM_Continuous_Build.jpg";
import Stratasys_F770 from "@/assets/Machines/Stratasys_F770.png";
import Connex260 from "@/assets/Machines/Connex260.png";
import Connex350 from "@/assets/Machines/Connex350.png";
import Connex500 from "@/assets/Machines/Connex500.png";
import Objet30_V3_Prime from "@/assets/Machines/Objet30_V3_Prime.png";
import Objet30_V3_Pro from "@/assets/Machines/Objet30_V3_Pro.png";
import Objet30_V3_Standard from "@/assets/Machines/Objet30_V3_Standard.png";
import Objet30_V5_Prime from "@/assets/Machines/Objet30_V5_Prime.png";
import Objet30_V5_Pro from "@/assets/Machines/Objet30_V5_Pro.png";
import Stratasys_J3_DentaJet from "@/assets/Machines/Stratasys_J3_DentaJet.png";
import Stratasys_J5_DentaJet from "@/assets/Machines/Stratasys_J5_DentaJet.png";
import Stratasys_J5_MediJet from "@/assets/Machines/Stratasys_J5_MediJet.png";
import Stratasys_J35_Pro from "@/assets/Machines/Stratasys_J35_Pro.png";
import Stratasys_J55_Prime from "@/assets/Machines/Stratasys_J55_Prime.png";
import Stratasys_J55_Pro from "@/assets/Machines/Stratasys_J55_Pro.png";
import Stratasys_J720_Dental from "@/assets/Machines/Stratasys_J720_Dental.png";
import Stratasys_J750_Digital_Anatomy from "@/assets/Machines/Stratasys_J750_Digital_Anatomy.png";
import Stratasys_J850_Digital_Anatomy from "@/assets/Machines/Stratasys_J850_Digital_Anatomy.png";
import Stratasys_J850_Prime_FabriX from "@/assets/Machines/Stratasys_J850_Prime_FabriX.png";
import Stratasys_J850_Prime_TechStyle from "@/assets/Machines/Stratasys_J850_Prime_TechStyle.png";
import Stratasys_J826 from "@/assets/Machines/Stratasys_J826.png";
import Stratasys_J850_Pro from "@/assets/Machines/Stratasys_J850_Pro.png";
import Stratasys_J4100 from "@/assets/Machines/Stratasys_J4100.png";
import H350 from "@/assets/Machines/H350.png";
import Origin_One from "@/assets/Machines/Origin_One.png";
import Origin_One_Dental from "@/assets/Machines/Origin_One_Dental.png";
import Stratasys_Neo450e from "@/assets/Machines/Stratasys_Neo450e.png";
import Stratasys_Neo450s from "@/assets/Machines/Stratasys_Neo450s.png";
import Stratasys_Neo800 from "@/assets/Machines/Stratasys_Neo800.png";

import { IImage } from "@/graphql/Fragments/Image";
import { IAsset } from "@/graphql/Mutations/Upload";

export interface IMachineDefaultImage
  extends Omit<IImage, "id" | "__typename" | "small" | "medium" | "original"> {
  medium: Pick<IAsset, "location">;
}

export const MachinesDefaultImages: { [index: string]: IMachineDefaultImage } = {
  Fortus_250mc: { medium: { location: FDM_250mc } },
  Fortus_380mc: { medium: { location: FDM_Fortus_380mc } },
  Fortus_380mc_Carbon_Fiber_Edition: { medium: { location: FDM_Fortus_380_Carbon_Fiber_Edition } },
  Fortus_400mc_Small: { medium: { location: FDM_Fortus_400mc } },
  Fortus_450mc: { medium: { location: FDM_Fortus_450mc } },
  Fortus_900mc: { medium: { location: FDM_Fortus_900mc } },
  Objet500_Connex1: { medium: { location: Polyjet_Objet500_Connex1 } },
  Objet260_Connex3: { medium: { location: Polyjet_Objet260_Connex3b } },
  Objet350_Connex3: { medium: { location: Polyjet_Objet350_Connex3 } },
  Objet500_Connex3: { medium: { location: Polyjet_Objet500_Connex3 } },
  Dimension_Elite: { medium: { location: FDM_Dimension_Elite } },
  Dimension_SST_1200: { medium: { location: FDM_Dimension_SST_1200 } },
  Dimension_SST_1200es: { medium: { location: FDM_Dimension_SST_1200es } },
  "Dimension_SST/SST_768": { medium: { location: FDM_Dimension_SST_768 } },
  Eden_260_Series: { medium: { location: Polyjet_Objet_Eden260VS } },
  Eden_500_Series: { medium: { location: Polyjet_Eden500V } },
  Stratasys_F120: { medium: { location: FDM_F120 } },
  Stratasys_F170: { medium: { location: FDM_F170 } },
  Stratasys_F190_CR: { medium: { location: FDM_F190_CR } },
  Stratasys_F270: { medium: { location: FDM_F270 } },
  Stratasys_F370: { medium: { location: FDM_F370 } },
  Stratasys_F370_CR: { medium: { location: FDM_F370_CR } },
  Stratasys_F3300: { medium: { location: FDM_F3300 } },
  Stratasys_F900: { medium: { location: FDM_F900 } },
  Stratasys_J700_Dental: { medium: { location: Polyjet_J700_Dental } },
  Stratasys_J735: { medium: { location: Polyjet_J735 } },
  Stratasys_J750: { medium: { location: Polyjet_J750 } },
  Objet1000_Plus: { medium: { location: Polyjet_Objet1000_Plus } },
  Objet30_Orthodesk: { medium: { location: Polyjet_Objet30_Orthodesk } },
  Objet30: { medium: { location: Polyjet_Objet30_Pro } },
  uPrint: { medium: { location: FDM_uPrint } },
  uPrint_SE: { medium: { location: FDM_uPrint_SE } },
  Fortus_360mc_Large: { medium: { location: FDM_360mc_large } },
  Fortus_360mc_Small: { medium: { location: FDM_360mc_small } },
  Fortus_400mc_Large: { medium: { location: FDM_400mc_large } },
  Objet260_Connex1: { medium: { location: Connex_1_Objet260 } },
  Objet350_Connex1: { medium: { location: Connex_1_Objet350 } },
  Objet260_Connex2: { medium: { location: Connex_2_Objet260 } },
  Objet350_Connex2: { medium: { location: Connex_2_Objet350 } },
  Objet500_Connex2: { medium: { location: Connex_2_Objet500 } },
  Dimension_BST_1200: { medium: { location: Dimension_BST_1200 } },
  Dimension_BST_1200es: { medium: { location: Dimension_BST_1200es } },
  "Dimension_BST/BST_768": { medium: { location: Dimension_BST_768 } },
  Eden_260_Dental: { medium: { location: Eden_260_dental } },
  Eden_350_Series: { medium: { location: Eden_350_series } },
  Eden_500_Dental: { medium: { location: Eden_500_dental } },
  Stratasys_J835_Prime: { medium: { location: J835 } },
  Stratasys_J850_Prime: { medium: { location: J850 } },
  MakerBot_Method_Series: { medium: { location: MakerBot_Method_Series } },
  MakerBot_Replicator_Series: { medium: { location: MakerBot_Replicator_Series } },
  Objet24: { medium: { location: Objet_24 } },
  Objet30_Dental: { medium: { location: Objet_30_Dental } },
  uPrint_Plus: { medium: { location: uPrint_Plus } },
  uPrint_SE_Plus: { medium: { location: uPrint_SE_Plus } },
  MakerBot_Sketch: { medium: { location: MakerBot_Sketch } },
  Stratasys_Continuous_Build_3D_printer: { medium: { location: FDM_Continuous_Build } },
  Stratasys_F770: { medium: { location: Stratasys_F770 } },
  Connex260: { medium: { location: Connex260 } },
  Connex350: { medium: { location: Connex350 } },
  Connex500: { medium: { location: Connex500 } },
  Objet30_V3_Prime: { medium: { location: Objet30_V3_Prime } },
  Objet30_V3_Pro: { medium: { location: Objet30_V3_Pro } },
  Objet30_V3_Standard: { medium: { location: Objet30_V3_Standard } },
  Objet30_V5_Prime: { medium: { location: Objet30_V5_Prime } },
  Objet30_V5_Pro: { medium: { location: Objet30_V5_Pro } },
  Stratasys_J3_DentaJet: { medium: { location: Stratasys_J3_DentaJet } },
  Stratasys_J5_DentaJet: { medium: { location: Stratasys_J5_DentaJet } },
  Stratasys_J5_MediJet: { medium: { location: Stratasys_J5_MediJet } },
  Stratasys_J35_Pro: { medium: { location: Stratasys_J35_Pro } },
  Stratasys_J55_Prime: { medium: { location: Stratasys_J55_Prime } },
  Stratasys_J55: { medium: { location: Stratasys_J55_Pro } },
  Stratasys_J55_Pro: { medium: { location: Stratasys_J55_Pro } },
  Stratasys_J720_Dental: { medium: { location: Stratasys_J720_Dental } },
  Stratasys_J750_Digital_Anatomy: { medium: { location: Stratasys_J750_Digital_Anatomy } },
  Stratasys_J826_Prime: { medium: { location: Stratasys_J826 } },
  Stratasys_J850_Pro: { medium: { location: Stratasys_J850_Pro } },
  Stratasys_J850_Digital_Anatomy: { medium: { location: Stratasys_J850_Digital_Anatomy } },
  Stratasys_J850_Prime_FabriX: { medium: { location: Stratasys_J850_Prime_FabriX } },
  Stratasys_J850_Prime_TechStyle: { medium: { location: Stratasys_J850_Prime_TechStyle } },
  Stratasys_J4100: { medium: { location: Stratasys_J4100 } },
  Stratasys_H350: { medium: { location: H350 } },
  Origin_One: { medium: { location: Origin_One } },
  Origin_One_Dental: { medium: { location: Origin_One_Dental } },
  Stratasys_Neo450e: { medium: { location: Stratasys_Neo450e } },
  Stratasys_Neo450s: { medium: { location: Stratasys_Neo450s } },
  Stratasys_Neo800: { medium: { location: Stratasys_Neo800 } },
};

export const DefaultGenericMachineImage = { medium: { location: Default_Machine_Image } };
