import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Image } from "grabcad-ui-elements";
import { SHOP_IMAGE } from "@/graphql/Queries";
import React from "react";
import { IImage } from "@/graphql/Fragments/Image";
import defaultShopImage from "@/assets/default-image-500px-sq.png";

interface IShopImage {
  image?: Partial<IImage>;
  defaultImage: Partial<IImage>;
  style?: any;
  size?: ShopLogoSIZES;
  label?: any;
  rounded?: boolean;
  alt?: string;
  className?: string;
  centered?: boolean;
  onError?: any;
}
export type ShopLogoSIZES = "small" | "medium" | "original";

const getImageSrc = (images: Partial<IImage>, imageSize?: ShopLogoSIZES) => {
  let src: string;
  switch (imageSize) {
    case "small":
      src = images?.small ? images.small.location : defaultShopImage;
      break;

    case "medium":
      src = images?.medium ? images.medium.location : defaultShopImage;
      break;

    case "original":
    default:
      src = images?.original ? images.original.location : defaultShopImage;
  }
  return src;
};

export const ShopImage = ({
  image,
  defaultImage,
  style,
  size,
  label,
  rounded,
  alt,
  className,
  centered,
  onError,
}: IShopImage): JSX.Element => {
  let imgSrc: string = getImageSrc(image || defaultImage, size);
  const client = useApolloClient();
  const [fetchImage, { error, loading, data }] = useLazyQuery(SHOP_IMAGE);

  if (error || loading) {
    imgSrc = getImageSrc(defaultImage, size);
  }
  if (data) {
    imgSrc = getImageSrc(data.image, size);
  }

  const handleError = async (e: any) => {
    if (e?.target?.src) {
      e.target.src = getImageSrc(defaultImage, size);
    }
    const id = client.cache.identify({ __typename: image?.__typename, id: image?.id });
    client.cache.evict({ id });
    client.cache.gc();
    await fetchImage({ variables: { id: image?.id } });
    onError?.(e);
  };

  return (
    <Image
      style={style}
      className={className}
      src={imgSrc}
      size={size as any}
      label={label}
      alt={alt}
      rounded={rounded}
      onError={handleError}
      centered={centered}
    />
  );
};
