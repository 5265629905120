import React, { useContext } from "react";
import { Icon } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";
import { IOrderItemDependencyRemovedEvent } from "@/graphql/Fragments/Order";

interface IHistoryItemDependencyRemovedComponentProps {
  event: IOrderItemDependencyRemovedEvent;
}

export const HistoryItemDependencyRemovedComponent = ({
  event,
}: IHistoryItemDependencyRemovedComponentProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <>
      <b>{t("order.history.dependencyRemoved")}</b> <Icon name="long arrow alternate right" />
      <span>{event.metadata?.originalFilename}</span>
    </>
  );
};

HistoryItemDependencyRemovedComponent.displayName = "HistoryItemDependencyRemovedComponent";
