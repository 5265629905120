import React, { useContext } from "react";
import styled, {
  GridColumn,
  Grid,
  GridRow,
  StepGroup,
  Step,
  StepTitle,
  StepContent,
  Icon,
} from "grabcad-ui-elements";
import { ShopFormContext } from "@/screens/Shop/New/New";
import { SHOP, ROUTES } from "@/shopConstants";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@/components/ApplicationProvider";

interface IShopSetupFooterProps {
  section: string;
  subSection: string;
}

export const ShopSetupFooter = withRouter(
  ({ match: { params } }: RouteComponentProps<IShopSetupFooterProps>) => {
    const { generalCompleted, machinesCompleted, materialsCompleted, section } =
      useContext(ShopFormContext);
    const { currentShop, t } = useContext(ApplicationContext);
    const { subSection } = params;
    const shopRoot = currentShop && ROUTES.SHOP(currentShop.id).ORDERS;

    const isShopCreated = shopRoot && section !== SHOP.SECTIONS.SETUP.general;
    const isMachineSelectionActive = section === SHOP.SECTIONS.machines && !subSection;
    const isMaterialSelectionActive =
      section === SHOP.SECTIONS.machines && subSection === SHOP.SECTIONS.materials;

    // By Default <StepGroup> stacks vertically at < 767px - too wide with our sidebar.
    const ResponsiveStepGroup = styled(StepGroup)`
      @media only screen and (max-width: 1100px) {
        &.ui.steps {
          overflow: visible;
          flex-direction: column;
          .step {
            width: 100% !important;
            flex-direction: column;
            border-right: none;
            &:before {
              margin: 0 0 1rem 0;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    `;

    return (
      <Grid className="section-grid">
        <GridRow columns="equal">
          <GridColumn width="2" verticalAlign="middle">
            {isShopCreated && (
              <Link
                to={
                  isMachineSelectionActive
                    ? shopRoot || ""
                    : ROUTES.SHOP(currentShop?.id || "", true).MACHINES.SHOW({})
                }
              >
                <Icon name="angle left" />
                {t("navigation.back")}
              </Link>
            )}
          </GridColumn>
          <GridColumn width="16">
            <ResponsiveStepGroup ordered fluid size="tiny">
              <Step active={section === SHOP.SECTIONS.SETUP.general} completed={generalCompleted}>
                <StepContent>
                  <StepTitle>
                    {isShopCreated ? (
                      <Link to={shopRoot || ""}>{t("shop.setup.footer.completeInformation")}</Link>
                    ) : (
                      t("shop.setup.footer.completeInformation")
                    )}
                  </StepTitle>
                </StepContent>
              </Step>

              <Step
                active={section === SHOP.SECTIONS.machines && !subSection}
                completed={machinesCompleted}
              >
                <StepContent>
                  <StepTitle>
                    {isMaterialSelectionActive ? (
                      <Link to={ROUTES.SHOP(currentShop?.id || "", true).MACHINES.SHOW({})}>
                        {t("shop.setup.footer.addMachines")}
                      </Link>
                    ) : (
                      t("shop.setup.footer.addMachines")
                    )}
                  </StepTitle>
                </StepContent>
              </Step>
              <Step active={isMaterialSelectionActive} completed={materialsCompleted}>
                <StepContent>
                  <StepTitle>{t("shop.setup.footer.addMaterialsAndColors")}</StepTitle>
                </StepContent>
              </Step>
            </ResponsiveStepGroup>
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
);
