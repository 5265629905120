import { ApplicationContext, IUserContext } from "@/components/ApplicationProvider";
import { PROFILE } from "@/graphql/Queries/User";
import { unblockWebSocketReady } from "@/GraphQLClient";
import React, { ComponentClass, FunctionComponent, useContext, useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { ERRORS, ROUTES } from "@/shopConstants";
import { GraphQLError } from "graphql";
import { LicenseRole } from "@/graphql/Queries/UserLicense";
import { IProfileResponse } from "@/graphql/Fragments/User";

export interface IUserLoaderProps {
  userContext: Pick<IUserContext, "setAsLoggedIn" | "setAsLoggedOut" | "isLoggedIn">;
  setLicenseError: ({ code, role }: LicenseErrorType) => void;
}

export interface ILicenseError extends GraphQLError {
  extensions: LicenseErrorType;
}

export type LicenseErrorType = {
  code: keyof typeof ERRORS;
  role: LicenseRole;
};

function isLicenseError(err: GraphQLError | ILicenseError): err is ILicenseError {
  const licenseErrors = [ERRORS.LICENSE_EXPIRED, ERRORS.NO_LICENSE, ERRORS.NO_CUSTOMER_LICENSE];
  return !!licenseErrors.find(
    e => (err as ILicenseError).extensions && e === (err as ILicenseError).extensions.code
  );
}
/**
 * Trigger GQL query to check to see if cookie credentials are valid.
 * NB: This will have side effect that if GQL fails, GraphQLClient.ts will cause
 * client to redirect to company-server.
 */
const UserLoaderBase: ComponentClass<IUserLoaderProps> = graphql<
  IUserLoaderProps,
  IProfileResponse,
  Record<string, never>
>(PROFILE, {
  // Never catch the permissions
  options: {
    fetchPolicy: "network-only",
  },
})(
  ({
    data,
    children,
    userContext: { setAsLoggedIn, setAsLoggedOut, isLoggedIn },
    setLicenseError,
  }): JSX.Element | null => {
    useEffect(() => {
      if (!isLoggedIn && data && !data.error && !data.loading && data.userProfile) {
        // At least one authenticated call succeeded
        // -> normal HTTP POST/GET have cookies
        // -> unblock WebSocket traffic (since it too will have cookie)

        // Remember permissions for current user
        setAsLoggedIn(data.userProfile);
        unblockWebSocketReady();
      }
    });

    if (!data || data.loading) {
      return null;
    }
    if (data.error) {
      if (!isLicenseExpired(data.error) && !isNoLicense(data.error)) {
        // FIXME GC-55392 Calling setAsLoggedOut() should _not_ be necessary
        // The error should soon cause https://github.com/GrabCAD/shop-client-frontoffice/blob/35b189847d8cede9d34fbb4eb7c06812c73769b4/src/GraphQLClient.ts#L81
        // (i.e., redirect to differe site) to run, which will naturally clear the app state.
        if (isLoggedIn) {
          setAsLoggedOut();
        }
        throw data.error; // Throw unexpected error so it can be rendered by <ErrorBoundary>
      }
      const licenseError = isLicenseExpired(data.error) || isNoLicense(data.error);
      if (licenseError) {
        setLicenseError({
          code: licenseError.extensions.code,
          role: licenseError.extensions.role,
        });
      }
    } else {
      const winLoc = window.location.href;
      if (winLoc.includes(ROUTES.EXPIRED) || winLoc.includes(ROUTES.NO_LICENSE)) {
        // for logout from bad user, then log in with good user,
        // good user should *not* stay on /expired or /no_license pages
        window.location.replace(ROUTES.ROOT);
      }
    }

    return <>{children}</>;
  }
);

export const UserLoader: FunctionComponent = ({ children }) => {
  const { currentUser, setLicenseError: setLicenseError } = useContext(ApplicationContext);
  return (
    <UserLoaderBase userContext={currentUser} setLicenseError={setLicenseError}>
      {children}
    </UserLoaderBase>
  );
};
UserLoader.displayName = "UserLoader";

function isLicenseExpired(err: ApolloError): ILicenseError | undefined {
  const errors = err.graphQLErrors.filter(isLicenseError);
  return errors.find(
    err2 => !!(err2.extensions && err2.extensions.code === ERRORS.LICENSE_EXPIRED)
  );
}

function isNoLicense(err: ApolloError): ILicenseError | undefined {
  const errors = err.graphQLErrors.filter(isLicenseError);
  return errors.find(
    err2 =>
      !!(
        err2.extensions &&
        (err2.extensions.code === ERRORS.NO_LICENSE ||
          err2.extensions.code === ERRORS.NO_CUSTOMER_LICENSE)
      )
  );
}
