import { ApplicationContext } from "@/components/ApplicationProvider";
import React, { useContext } from "react";
import styled, { Dropdown, Icon } from "grabcad-ui-elements";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import classnames from "classnames";
import { DropdownProps } from "semantic-ui-react";
import { IAppMachineType } from "@/graphql/Fragments/AppMachineType";
import { sortByName } from "../../../utils/queryHooks";

export const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown .menu > .disabled.item {
    opacity: 1; /* Disabled Machine-Type-Options have full opacity ☑, so we have to override semantic-ui */
  }
  .content {
    display: flex;
    align-items: center;
    &.disabled .text {
      opacity: 0.45;
    }
    .machine-type-name {
      flex-grow: 1;
    }
    .already-added {
      flex-shrink: 0;
      padding-left: 30px;
      font-style: italic;
      i.icon {
        color: #003393;
        position: relative;
        left: 8px;
      }
    }
  }
`;

export interface IMachinesTypesDropdownProps {
  appMachineTypes: IAppMachineType[];
  selectedAppMachineTypeId: number | null;
  onMachineTypeSelect: (newAppMachineType: IAppMachineType) => void;
  shopMachines: IShopMachine[];
}

export const MachinesTypesDropdown = ({
  appMachineTypes,
  onMachineTypeSelect,
  selectedAppMachineTypeId,
  shopMachines,
}: IMachinesTypesDropdownProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const appMachineTypesById: { [id: string]: IAppMachineType } = {};
  const sortedMachines = [...appMachineTypes].sort(sortByName);
  const machineOptions = sortedMachines.map((appMachineType: IAppMachineType) => {
    appMachineTypesById[appMachineType.id] = appMachineType;
    const existingShopMachineType = shopMachines.find(
      machine => machine.appMachineType.id === appMachineType.id
    );
    return {
      key: appMachineType.id,
      value: appMachineType.id,
      text: appMachineType.displayName,
      active: appMachineType.id === selectedAppMachineTypeId,
      disabled: !!existingShopMachineType,
      content: (
        <div className={classnames("content", { disabled: !!existingShopMachineType })}>
          <span className="machine-type-name text">{appMachineType.displayName}</span>
          {existingShopMachineType ? (
            <span className="already-added">
              <span className="text">
                {t("machines.form.appTechnologies.dropdown.alreadyAdded")}
              </span>
              <Icon name="check circle" />
            </span>
          ) : null}
        </div>
      ),
    };
  });

  return (
    <StyledDropdown
      id="qa-machineTypeForm-typeDropdown"
      fluid
      placeholder={t("machines.form.appMachineTypes.dropdown.placeholder")}
      selection
      search
      disabled={machineOptions.length === 0}
      options={machineOptions}
      onChange={(_e: any, data: DropdownProps) =>
        onMachineTypeSelect(appMachineTypesById[data.value as string])
      }
      selectOnNavigation={false}
      value={selectedAppMachineTypeId ? selectedAppMachineTypeId : undefined}
    />
  );
};
