import React, { useContext } from "react";
import { IOrder } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "../../ApplicationProvider";
import styled, { Checkbox } from "grabcad-ui-elements";
import { UPDATE_ORDER_NOTIFICATION_PREFERENCE } from "@/graphql/Mutations";
import { ORDER_DETAILS } from "@/graphql/Queries";
import ReactGA from "react-ga";
import { ApolloError, MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Notifier } from "@/utils/Notifier";

export enum NOTIFICATION_TYPE {
  ORDER = "ORDER",
}
const NotificationSubscribe = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 13px 0 13px 13px;
  .ui.checkbox {
    margin: 0 0 0 1rem;
  }
`;
export const NotificationSubscription = ({ order }: { order: IOrder }): JSX.Element | null => {
  const { t, currentShop, currentUser } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const mutationCompletedFunc = () => {
    ReactGA.event({
      category: "GcShop Order",
      action: "Order Notification Preference Updated",
      label: `Shop ${currentShop?.id}`,
    });
  };
  return (
    <Mutation
      mutation={UPDATE_ORDER_NOTIFICATION_PREFERENCE}
      onError={(err: ApolloError) => Notifier.error(err)}
      onCompleted={() => {
        mutationCompletedFunc();
      }}
      refetchQueries={[{ query: ORDER_DETAILS, variables: { orderId: order.id } }]}
    >
      {(updateNotificationPreference: MutationFunction) => (
        <NotificationSubscribe>
          <span>{t("order.details.notification.updates")}</span>
          <Checkbox
            checked={order.notificationSubscribed}
            onClick={() => {
              if (order.notificationSubscribed) {
                updateNotificationPreference({
                  variables: {
                    input: {
                      userId: currentUser.userProfile?.id,
                      entityId: order.id,
                      type: NOTIFICATION_TYPE.ORDER,
                      unSubscribe: true,
                    },
                  },
                });
              } else {
                updateNotificationPreference({
                  variables: {
                    input: {
                      userId: currentUser.userProfile?.id,
                      entityId: order.id,
                      type: NOTIFICATION_TYPE.ORDER,
                    },
                  },
                });
              }
            }}
            className={"qa-subscribe-notification-checkbox"}
          />
        </NotificationSubscribe>
      )}
    </Mutation>
  );
};
