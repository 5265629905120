// TODO Merge this with server-side shop-server/src/permissions.ts (into single isomorphic shared src file)
export enum Permission {
  // subject = 'Shop' | 'Order' | 'ShopMachine'
  WRITE = "write",

  // subject = 'Order'
  COMMENT = "comment",
  ASSIGN_OPERATOR = "assign_operator",

  // subject = 'User'
  CREATE_SHOP = "create_shop",

  // subject = 'Shop'
  CREATE_MACHINE = "create_machine",
  CREATE_ORDER = "create_order",
  LIST = "list",

  SET_STATUS_WHEN_NOT_SUBMITTED = "can_set_status_when_not_submitted",
  SET_OPERATOR_STATUSES = "set_operator_statuses",
  SET_REQUESTER_STATUSES = "set_requester_statuses",

  WRITE_SHOP_RATES = "create_shop_rates",
  READ_SHOP_RATES = "read_shop_rates",

  // FIXME GC-56387 consolidate LIST approach
  LIST_MACHINES = "list_machines",
  READ = "read",
  SHOP_INFO_VIEW = "shop_info_view",
  WRITE_MACHINES = "write_machines",

  // subject = 'ShopMachine | Order'
  DELETE = "delete",

  // subject = 'Shop'
  VIEW_JOBS = "view_jobs",
  CREATE_JOBS = "create_jobs", // This is used as "write jobs"
}
