import React, { useContext } from "react";
import { IOrderItemDeletedEvent } from "@/graphql/Fragments/Order";
import { Icon } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";

interface IHistoryOrderItemDeletedComponentProps {
  event: IOrderItemDeletedEvent;
}

export const HistoryOrderItemDeletedComponent = ({
  event,
}: IHistoryOrderItemDeletedComponentProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <>
      {/* The check is necessary to show the correct content title for the event in case of
      non-grouped IOrderItemDeletedEvent/IOrderItemDependencyRemovedEvent events as
      they share the same metadata type OrderItemDeletedMetaData */}
      <b>{event.order ? t("order.history.item.deleted") : t("order.history.dependencyRemoved")}</b>{" "}
      <Icon name="long arrow alternate right" />
      <span>{event.metadata?.originalFilename}</span>
    </>
  );
};

HistoryOrderItemDeletedComponent.displayName = "HistoryOrderItemDeletedComponent";
