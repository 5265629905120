import gql from "graphql-tag";
import { IShop } from "./Shop";
import { GRAPHQL_ORDER_STATUS } from "@/screens/Shop/Order/New/New";
import { GRAPHQL_MODEL_UNITS } from "@/utils/DropdownUtils";
import { IUser } from "./User";
import { IImage } from "./Image";
import { IGenericFile } from "@/graphql/Mutations/Upload";
import { ITypeNamed } from "./base";
import { ISubject } from "@/GcAbility";
import { ICadModel } from "./CadModel";
import { IMachineRate, IMaterialRate, MachineRateFields, MaterialRateFields } from "./ShopRate";
import { AutoEstimation, AutoEstimationFields, ORIENTATION } from "./AutoEstimation";

export const OrderItemTypename = "OrderItem";

// TODO: 86 Unused estimatedUserId
export const UpdatableOrderItemFields = gql`
  fragment updatableOrderItemFields on OrderItem {
    price
    shopStatus
    units
    quantity
    estimatedUserId
    markupPrice
    extraCost
    overridePrice
    estimatedMaterialCubicMm
    estimatedSupportCubicMm
    estimatedMaterialGrams
    estimatedSupportGrams
    estimatedMaterialSquareMm
    estimatedMaterialMm
    estimatedMinutes
    machineRateId
    materialRateId
    shopMaterialId
    shopMaterialColorId
    shopTechnologyId
    autoEstimationId
    orientation
  }
`;

export const OrderItemFields = gql`
  fragment orderItemFields on OrderItem {
    id
    dateCreated
    units
    jobId
    ...updatableOrderItemFields
    order {
      id
    }
    parents {
      id
    }
    children {
      id
      cadModel {
        id
        unit
        conversionStatus
        size {
          x
          y
          z
        }
        original {
          asset {
            id
            originalName
            location
          }
        }
      }
    }
    childImages {
      id
      small {
        id
        originalName
        location
        mimeType
      }
      original {
        id
        originalName
        location
      }
    }
    childFiles {
      id
      asset {
        id
        location
        originalName
        mimeType
      }
    }
    permissionsV2 {
      default
      writableFields
    }
    cadModel {
      id
      unit
      conversionStatus
      size {
        x
        y
        z
      }
      original {
        asset {
          id
          originalName
          location
        }
      }
    }
    machineRate {
      ...machineRateFields
    }
    materialRate {
      ...materialRateFields
    }
    autoEstimations {
      ...autoEstimationFields
    }
    __typename
  }
  ${UpdatableOrderItemFields}
  ${MachineRateFields}
  ${MaterialRateFields}
  ${AutoEstimationFields}
`;

export const OrderFragments = {
  order: gql`
    fragment orderFields on Order {
      id
      name
      shopStatus
      needByDate
      dateCreated
      dueDate
      projectCode
      price
      permissions
      permissionsV2 {
        default
        writableFields
      }
      user {
        id
        name
      }
      operator {
        id
        name
        email
      }
      notificationSubscribed
    }
  `,
  orderList: gql`
    fragment orderListFields on Order {
      id
      name
      shopStatus
      needByDate
      dateCreated
      dueDate
      projectCode
      price
      totalPartsQuantity
      permissions
      permissionsV2 {
        default
        writableFields
      }
      user {
        id
        name
      }
      orderItemsCount
      shopTechnologyIds
      shopMaterialIds
      operator {
        id
        name
        email
      }
    }
  `,
  orderComments: gql`
    fragment orderCommentsFields on Order {
      comments {
        id
        text
        dateCreated
        user {
          id
          name
        }
      }
    }
  `,
  orderEvents: gql`
    fragment orderEventsFields on Event {
      id
      type
      dateCreated
      metadata {
        ... on StatusChangedEventMetadata {
          fromStatus
          toStatus
          fromShopStatus
          toShopStatus
          __typename
        }
        ... on OrderItemDeletedMetaData {
          deletedAt
          imageId
          fileId
          originalFilename
        }
        ... on FilesAddedEventMetadata {
          fileNames
          __typename
        }
        ... on OrderOperatorChangedMetaData {
          oldOperator
          newOperator
          isAutoAssigned
          __typename
        }
        ... on OrderDetailsChangedMetaData {
          fieldName
          oldValue
          newValue
          __typename
        }
      }
      user {
        id
        name
      }
      order {
        id
        __typename
      }
      orderItem {
        id
        __typename
        machineRate {
          id
          machineTimeUnit
          materialUnit
          supportUnit
        }
        cadModel {
          id
          __typename
          original {
            __typename
            asset {
              id
              originalName
              __typename
            }
          }
        }
      }
    }
  `,
  orderUser: gql`
    fragment orderUserFields on Order {
      user {
        id
        name
        email
      }
      __typename
    }
  `,
  orderItems: gql`
    fragment orderItems on Order {
      orderItems {
        ... on Image {
          id
          small {
            id
            location
            originalName
          }
          original {
            id
            location
            originalName
          }
          dateCreated
          __typename
        }
        ... on GenericFile {
          id
          asset {
            id
            location
            originalName
          }
          dateCreated
          __typename
        }
        ... on OrderItem {
          ...orderItemFields
        }
      }
    }
    ${OrderItemFields}
  `,
};

export interface IComment extends ITypeNamed<"Comment"> {
  id: number;
  text: string;
  dateCreated: string;
  user: IUser;
}

export type PossibleEventTypes =
  | OrderItemDeletedEventMetadataTypeName
  | OrderStatusChangedEventMetadataTypeName
  | FilesAddedEventMetadataTypeName
  | OrderOperatorChangedEventMetadataTypeName
  | OrderDetailsChangedMetaDataTypeName;

export type OrderItemDeletedEventMetadataTypeName = "OrderItemDeletedMetaData";
export type OrderStatusChangedEventMetadataTypeName = "StatusChangedEventMetadata";
export type FilesAddedEventMetadataTypeName = "FilesAddedEventMetadata";
export type OrderOperatorChangedEventMetadataTypeName = "OrderOperatorChangedMetaData";
export type OrderDetailsChangedMetaDataTypeName = "OrderDetailsChangedMetaData";

export interface IOrderItemDeletedEventMetadata
  extends ITypeNamed<OrderItemDeletedEventMetadataTypeName> {
  deletedAt: Date;
  imageId?: number;
  fileId?: number;
  originalFilename?: string;
}

export interface IOrderStatusChangedEventMetadata
  extends ITypeNamed<OrderStatusChangedEventMetadataTypeName> {
  fromStatus: GRAPHQL_ORDER_STATUS | null;
  toStatus: GRAPHQL_ORDER_STATUS | null;
  fromShopStatus: number | null;
  toShopStatus: number | null;
}

export interface IFilesAddedEventMetadata extends ITypeNamed<FilesAddedEventMetadataTypeName> {
  fileNames: string[];
}

export interface IOrderOperatorChangedEventMetadata
  extends ITypeNamed<OrderOperatorChangedEventMetadataTypeName> {
  oldOperator: string;
  newOperator: string;
  isAutoAssigned: boolean;
}

export interface IOrderDetailsChangedEventMetadata
  extends ITypeNamed<OrderDetailsChangedMetaDataTypeName> {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

export interface IOrderItemDeletedEvent extends IEvent {
  metadata: IOrderItemDeletedEventMetadata;
}

export interface IOrderStatusChangedEvent extends IEvent {
  metadata: IOrderStatusChangedEventMetadata;
}

export interface IOrderFilesAddedEvent extends IEvent {
  metadata: IFilesAddedEventMetadata;
}

export interface IOrderOperatorChangedEvent extends IEvent {
  metadata: IOrderOperatorChangedEventMetadata;
}

export interface IOrderDetailsChangedEvent extends IEvent {
  metadata: IOrderDetailsChangedEventMetadata;
}

export interface IOrderItemDependencyAddedEvent extends IEvent {
  metadata: IFilesAddedEventMetadata;
}
export interface IOrderItemDependencyRemovedEvent extends IEvent {
  metadata: IOrderItemDeletedEventMetadata;
}
export interface IEvent extends ITypeNamed<"Event"> {
  id: number;
  type:
    | "OrderStatusChangedEvent"
    | "OrderItemStatusChangedEvent"
    | "OrderFilesAddedEvent"
    | "OrderItemDeletedEvent"
    | "OrderOperatorChangedEvent"
    | "OrderDetailsChangedEvent"
    | "OrderItemDetailsChangedEvent"
    | "OrderCommentEvent"
    | "OrderItemDependencyAddedEvent"
    | "OrderItemDependencyRemovedEvent";
  dateCreated: string;
  metadata:
    | IOrderStatusChangedEventMetadata
    | IOrderItemDeletedEventMetadata
    | IFilesAddedEventMetadata
    | IOrderOperatorChangedEventMetadata
    | IOrderDetailsChangedEventMetadata;
  user: IUser;
  order?: Partial<IOrder>;
  orderItem?: Partial<IOrderItem>;
}

export type IListOrder = Omit<IOrder, "comments" | "notificationSubscribed">;

export interface IOrder extends ISubject, ITypeNamed<"Order"> {
  id: number;
  name: string;
  status?: GRAPHQL_ORDER_STATUS;
  shopStatus: number;
  shop?: IShop;
  user: Partial<IUser>;
  orderItems: IOrderItem[];
  projectCode?: string; // AKA order code
  needByDate?: string;
  dateCreated: string;
  dueDate?: string;
  price?: number;
  totalPartsQuantity?: number;
  comments: IComment[];
  events: IEvent[];
  orderItemsCount: number;
  shopTechnologyIds: number[];
  shopMaterialIds: number[];
  operatorId?: number;
  operator?: Partial<IUser>;
  notificationSubscribed: boolean;
}

export type OrderItemTypeName = "OrderItem";

export interface IOrderItem extends ISubject, ITypeNamed<OrderItemTypeName> {
  id: number;
  status?: GRAPHQL_ORDER_STATUS;
  shopStatus: number;
  units?: GRAPHQL_MODEL_UNITS;
  cadModel?: Partial<ICadModel>;
  quantity: number;
  shopMaterialId: number | null;
  shopMaterialColorId: number | null;
  shopTechnologyId: number | null;
  price: number | null;
  dateCreated: Date;
  machineRateId: number | null;
  machineRate: IMachineRate | null;
  materialRateId: number | null;
  materialRate: IMaterialRate | null;
  estimatedMaterialCubicMm?: number;
  estimatedSupportCubicMm?: number;
  estimatedMaterialGrams?: number;
  estimatedSupportGrams?: number;
  estimatedMaterialSquareMm?: number;
  estimatedMaterialMm?: number;
  estimatedMinutes?: number;
  estimatedUserId?: number;
  estimationFailure?: number;
  estimatedByService?: boolean;
  markupPrice?: number | null;
  extraCost?: number | null;
  overridePrice?: number | null;
  order?: Partial<IOrder>;
  children?: IOrderItem[];
  parents?: IOrderItem[];
  childImages?: IImage[];
  childFiles?: IGenericFile[];
  jobId: number | null;
  autoEstimations?: AutoEstimation[];
  autoEstimationId?: number | null;
  orientation?: ORIENTATION;
}

export type OrderItemOrImageOrFile = IOrderItem | IImage | IGenericFile;

// e.g., GenericFileTypeName | OrderItemTypeName | ImageTypeName;
export type OrderItemOrImageOrFileType = OrderItemOrImageOrFile["__typename"];
