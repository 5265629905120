import { ShopMachinesForm } from "@/components/Shop/Machines/Form";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { SHOP } from "@/shopConstants";
import { MachineDetails } from "./MachineDetails";

interface IScreensShopMachinesProps extends RouteComponentProps<{ subSection: string }> {
  isSetup: boolean;
}

export const ScreensShopMachines = withRouter(({ match, isSetup }: IScreensShopMachinesProps) => {
  const { subSection } = match.params;
  return subSection === SHOP.SECTIONS.materials ? (
    <MachineDetails />
  ) : (
    <ShopMachinesForm isSetup={isSetup} />
  );
});
ScreensShopMachines.displayName = "ScreensShopMachine";
