import gql from "graphql-tag";
import { ShopMachineFragments } from "@/graphql/Fragments";

export const CREATE_SHOP_MACHINE = gql`
  mutation createShopMachineMutation($input: ShopMachineInput!) {
    createShopMachine(input: $input) {
      ...shopMachineFields
    }
  }
  ${ShopMachineFragments.shopMachine}
`;

export const DELETE_SHOP_MACHINE = gql`
  mutation deleteShopMachineMutation($id: ID!) {
    deleteShopMachine(id: $id) {
      id
      dateDeleted
    }
  }
`;
