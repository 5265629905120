import React, { useContext } from "react";
import styled, { Header } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { AddPartsToJobForm } from "./JobForm";

const JobsList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-row {
    flex: 0 0 auto;
    margin-bottom: 1em;
  }
`;

export const ScreensShopJobsNew = (): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <JobsList>
      <div className="header-row">
        <Header as="h2" className="page-header">
          {t("shop.jobs.create")}
        </Header>
      </div>
      <AddPartsToJobForm />
    </JobsList>
  );
};
ScreensShopJobsNew.displayName = "ScreensShopJobsNew";
