import React, { useContext } from "react";
import { SHOPS_LIST, SHOP_DETAILS } from "@/graphql/Queries";
import { Notifier } from "@/utils/Notifier";
import { withRouter, RouteComponentProps } from "react-router";
import { ROUTES } from "@/shopConstants";
import { ShopForm } from "../../Form/Form";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UPDATE_SHOP } from "@/graphql/Mutations/Shop";
import { ShopState, IShop } from "@/graphql/Fragments/Shop";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ApolloError, MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

interface IUpdateShopResponse {
  updateShop: IShop;
}

export const ShopUpdateForm = withRouter(({ history }: RouteComponentProps) => {
  const { currentShop, setCurrentShop } = useContext(ApplicationContext);

  return (
    currentShop && (
      <Mutation
        mutation={UPDATE_SHOP}
        onCompleted={({ updateShop }: IUpdateShopResponse) => {
          if (currentShop && currentShop.state !== ShopState.DRAFT) {
            setCurrentShop(updateShop);
            history.push(ROUTES.SHOP(updateShop.id).ORDERS);
          } else {
            history.push(ROUTES.SHOP(updateShop.id, true).MACHINES.SHOW({}));
          }
        }}
        onError={(error: ApolloError) => Notifier.error(error)}
        refetchQueries={[
          { query: SHOPS_LIST },
          { query: SHOP_DETAILS, variables: { id: currentShop?.id } },
        ]}
      >
        {(updateShop: MutationFunction) => (
          <UiCan passThrough do={Permission.WRITE} on={currentShop}>
            {(can: boolean) =>
              can ? (
                <ShopForm mutate={updateShop} shop={currentShop} />
              ) : (
                <ShopForm shop={currentShop} />
              )
            }
          </UiCan>
        )}
      </Mutation>
    )
  );
});
