import React, { useContext, useState, useEffect, useRef } from "react";
import styled, { Popup, Checkbox, Image, Icon, Input, Button, isDarkBG } from "grabcad-ui-elements";
import { ApplicationContext, TranslateFunction } from "@/components/ApplicationProvider";
import { statusColors } from "@/components/Order/Status/Status";
import {
  CREATE_SHOP_STATUS,
  UPDATE_SHOP_STATUS,
  SET_SHOP_STATUSES_DISPLAY_ORDER,
  DELETE_SHOP_STATUS,
} from "@/graphql/Mutations";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import visible from "../../../assets/icons/visible.svg";
import hidden from "../../../assets/icons/hidden.svg";
import classnames from "classnames";
import { PrefSectionHeader, StyledPrefTable } from "./Page";
import dragIcon from "../../../assets/icons/drag-active.svg";
import { useDrop, useDrag } from "react-dnd";
import { ApolloError, MutationFunction, useApolloClient } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Notifier } from "@/utils/Notifier";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { GRAPHQL_ORDER_STATUS } from "../Order/New/New";
import {
  activeAppStatuses,
  isStatusSet,
  getActiveStatusIds,
  getInactiveStatusIds,
} from "@/components/Order/List/FiltersPopup";
import ColorPicker from "react-simple-colorpicker";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { ControlledInput, blurOnEnter } from "../../../components/Shared/ControlledInput";
import ReactGA from "react-ga";

// https://github.com/styled-components/styled-components/issues/1449
const StyledStatusesTable = styled((props: any) => <StyledPrefTable {...props} />)`
  td.name-n-drag {
    position: relative;
    .draggable-content {
      width: 100%;
    }
    .status-name {
      padding: 0 10px;
      height: 34px;
      border-radius: 4px;
      position: absolute;
      top: 8px;
      left: 46px;
      right: 0;
      display: flex;
      align-items: center;
      .ui.input {
        margin-left: -10px;
        flex-grow: 1;
        color: inherit;
        margin-right: 5px;
        > input {
          border: none !important;
          background: none;
          color: inherit;
          &::selection {
            color: inherit;
            background: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .icon {
        position: relative;
        top: -2px;
        right: 5px;
        cursor: pointer;
        &:before {
          padding: 9px;
        }
      }
      &.dark {
        color: white;
        .ui.input > input::selection {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .delete-btn {
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.disabled {
      opacity: 0.1 !important;
    }
  }
`;

const ColorSwatch = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 4px;
  cursor: pointer;
`;

const requiredStatuses = ["SUBMITTED", "IN_PROGRESS", "COMPLETED", "CANCELLED"] as unknown as [
  GRAPHQL_ORDER_STATUS
];

const MAX_CUSTOM_STATUSES = 18;

type ShopStatusMutation = MutationFunction<
  { createShopStatus: IShopStatus },
  { input: Partial<IShopStatus> }
>;
interface IUpdateStatusInput {
  input: Partial<IShopStatus>;
  i: number;
  mutation: ShopStatusMutation;
}

export const StatusesTable = (): JSX.Element | null => {
  const { t, currentShop, getOrderListStateByShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  const client = useApolloClient();
  const [statuses, setStatuses] = useState(
    currentShop.shopStatuses
      .filter(status => !status.dateDeleted)
      .sort((a, b) => a.displayOrder - b.displayOrder)
  );

  const updateStatus = ({ input, i, mutation }: IUpdateStatusInput) => {
    const newStatuses = [...statuses];
    Object.assign(newStatuses[i], input);
    setStatuses(newStatuses);
    mutation({ variables: { input: { ...input, id: newStatuses[i].id } } });
  };

  const updateStatusesDisplayOrder = async (shopStatusIds: number[]) => {
    try {
      await client.mutate({
        mutation: SET_SHOP_STATUSES_DISPLAY_ORDER,
        variables: {
          shopStatusIds,
        },
      });

      Notifier.success(t("shop.preferences.statuses.update.success.statusOrder"));
    } catch (error) {
      if (error instanceof ApolloError) {
        Notifier.error({ graphQLErrors: error.graphQLErrors });
      } else {
        throw error;
      }
    }
  };

  const moveRow = (id: number, atIndex: number) => {
    const index = findRow(id);
    const newStatuses = [...statuses];
    newStatuses.splice(index, 0, newStatuses.splice(atIndex, 1)[0]);
    setStatuses(newStatuses);
  };
  const dropRow = () => updateStatusesDisplayOrder(statuses.map(s => s.id));
  const findRow = (id: number) => statuses.indexOf(statuses.find(s => s.id === id) as IShopStatus);

  const addStatusButtonDisabled =
    statuses.filter((s: IShopStatus) => !s.dateDeleted && !s.appStatus).length >=
    MAX_CUSTOM_STATUSES;
  const [disabledPopupVisible, setDisabledPopupVisible] = useState(false);
  const addStatusButton = (
    <Mutation<{ createShopStatus: IShopStatus }, { input: Partial<IShopStatus> }>
      mutation={CREATE_SHOP_STATUS}
      variables={{
        input: {
          shopId: currentShop.id,
          displayOrder: currentShop.shopStatuses.length,
          operatorCanSet: true,
          isActive: true,
        },
      }}
      onCompleted={(data: { createShopStatus: IShopStatus }) => {
        Notifier.success(t("shop.preferences.statuses.create.success"));

        const orderListState = getOrderListStateByShop();
        const activeStatuses = getActiveStatusIds(currentShop.shopStatuses);
        const activeFilterCached = isStatusSet(orderListState.filters.shopStatuses, activeStatuses);
        if (activeFilterCached) {
          orderListState.filters.shopStatuses.push(data.createShopStatus.id);
        }
        currentShop.shopStatuses = currentShop.shopStatuses.concat(data.createShopStatus);

        setStatuses(statuses.concat(data.createShopStatus));
      }}
      onError={(error: ApolloError) => {
        Notifier.error(error);
      }}
      // Apollo does not automatically update cache when creating entities - only when updating them.
      // Refetch in case we stitch shops, then switch back.
      refetchQueries={[{ query: SHOP_DETAILS, variables: { id: currentShop.id } }]}
    >
      {addStatus => (
        <Button
          disabled={addStatusButtonDisabled}
          onMouseOver={() => setDisabledPopupVisible(true)}
          onMouseOut={() => setDisabledPopupVisible(false)}
          className="qa-addStatus"
          onClick={() => {
            addStatus();
            ReactGA.event({
              category: "GcShop Preferences",
              action: "Added status",
              label: `Shop ${currentShop.id}`,
            });
          }}
        >
          {t("shop.preferences.statuses.create")}
        </Button>
      )}
    </Mutation>
  );

  return (
    <>
      <PrefSectionHeader className="page-header">
        <h2>{t("shop.preferences.statuses.title")}</h2>
        {addStatusButtonDisabled ? (
          <Popup
            wide
            trigger={addStatusButton}
            content={t("shop.preferences.statuses.create.disabled", { max: MAX_CUSTOM_STATUSES })}
            position="left center"
            inverted
            // This popup wasn't working on hover inexplicably. Manually managing state:
            open={disabledPopupVisible}
          />
        ) : (
          addStatusButton
        )}
      </PrefSectionHeader>
      <StyledStatusesTable className="unstackable">
        <thead>
          <tr>
            <th>{t("shop.preferences.statuses.name")}</th>
            <th className="short">{t("shop.preferences.statuses.color")}</th>
            <th className="short">
              <Popup
                trigger={<div>{t("shop.preferences.statuses.active")}</div>}
                content={t("shop.preferences.statuses.header.active")}
                position="left center"
                inverted
              />
            </th>
            {/* <th className="short">{t('shop.preferences.statuses.requestor')}</th>
            <th className="short">{t('shop.preferences.statuses.operator')}</th> */}
            <th className="short">
              <Popup
                trigger={<div>{t("shop.preferences.visible")}</div>}
                content={t("shop.preferences.statuses.header.visible")}
                position="left center"
                inverted
              />
            </th>
            <th className="short">
              <Popup
                trigger={<div>{t("shop.preferences.statuses.delete")}</div>}
                content={t("shop.preferences.statuses.header.delete")}
                position="left center"
                inverted
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((status, i) => (
            <StatusesRow
              key={status.id}
              i={i}
              status={status}
              updateStatus={updateStatus}
              moveRow={moveRow}
              dropRow={dropRow}
              findRow={findRow}
              onDelete={deletedIndex => {
                const newStatuses = [...statuses];
                newStatuses.splice(deletedIndex, 1);
                setStatuses(newStatuses);
              }}
            />
          ))}
        </tbody>
      </StyledStatusesTable>
    </>
  );
};
StatusesTable.displayName = "StatusesTable";

const STATUS_ROW = "statusRow";
const StatusesRow = ({
  i,
  status,
  updateStatus,
  moveRow,
  dropRow,
  findRow,
  onDelete,
}: {
  i: number;
  status: IShopStatus;
  updateStatus: (input: IUpdateStatusInput) => void;
  moveRow: (id: number, to: number) => void;
  dropRow: () => void;
  findRow: (id: number) => number;
  onDelete: (i: number) => void;
}) => {
  const { t, currentShop, getOrderListStateByShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const color =
    status.color ||
    (status.appStatus
      ? status.appStatus && statusColors[status.appStatus.toLowerCase()]
      : "#CCCCCC");

  // Copied largely from ShopColumnRow react-dnd inplemnentation, then simplified somewhat
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const draggedRowRef = useRef<HTMLTableRowElement>(null);
  const [, drop] = useDrop({
    accept: STATUS_ROW,
    canDrop: () => false,
    hover({ id: draggedId }: any) {
      if (draggedId !== status.id) {
        const index = findRow(status.id);
        moveRow(draggedId, index);
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: STATUS_ROW,
    item: { id: status.id, index: i },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: dropRow,
  });
  drag(dragHandleRef);
  drop(draggedRowRef);

  const visibilityDisabled = status.appStatus && requiredStatuses.includes(status.appStatus);
  const visibilityButton = (mutation?: ShopStatusMutation) => (
    <div>
      <Image
        className={classnames("visibility qa-statusRow-visibility", {
          "is-hidden": status.isHidden,
          disabled: visibilityDisabled,
        })}
        src={status.isHidden ? hidden : visible}
        onClick={() =>
          mutation && updateStatus({ mutation, i, input: { isHidden: !status.isHidden } })
        }
      />
    </div>
  );
  const disabledVisibilityButton = () => (
    <Popup
      trigger={visibilityButton()}
      content={t("shop.preferences.statuses.visibility.required")}
      position="left center"
      inverted
    />
  );

  const trashIcon = (disabled?: boolean) => (
    <div>
      <Icon
        name="trash"
        size="large"
        className="delete-btn qa-deleteBtn"
        disabled={disabled}
        onClick={() => !disabled && setShowDeleteModal(true)}
        data-testid="deleteButton"
      />
    </div>
  );

  return (
    <Mutation<{ createShopStatus: IShopStatus }, { input: Partial<IShopStatus> }>
      mutation={UPDATE_SHOP_STATUS}
      onCompleted={() => Notifier.success(t("shop.preferences.statuses.update.success"))}
      onError={(error: ApolloError) => Notifier.error(error)}
    >
      {mutation => (
        <tr
          ref={draggedRowRef}
          key={status.id}
          style={{ opacity: isDragging ? 0 : 1 }}
          className="qa-statusRow"
        >
          <td className="name-n-drag">
            <div ref={dragHandleRef} className="draggable-content">
              <div className="drag-button">
                <Image src={dragIcon} />
              </div>
            </div>
            <div
              className={classnames("status-name qa-status-name", { dark: isDarkBG(color) })}
              style={{ background: color }}
              data-testid="statusName"
            >
              {status.appStatus ? (
                t(`order.status.${status.appStatus.toLowerCase()}`)
              ) : (
                <StatusNameInput
                  text={status.name || t("shop.preferences.statuses.untitled")}
                  onSubmit={name => updateStatus({ mutation, i, input: { name } })}
                />
              )}
            </div>
          </td>
          <td>
            <Popup
              on="click"
              trigger={
                <ColorSwatch
                  style={{ background: color }}
                  className="qa-color-swatch"
                  data-testid="colorSwatch"
                />
              }
              content={
                <ControledColorPicker
                  color={color}
                  onChangeComplete={newColor =>
                    updateStatus({ mutation, i, input: { color: newColor } })
                  }
                  t={t}
                  resetColor={status.appStatus && statusColors[status.appStatus.toLowerCase()]}
                />
              }
              position="right center"
            />
          </td>
          <td className="center">
            <Checkbox
              checked={
                status.appStatus ? activeAppStatuses.includes(status.appStatus) : status.isActive
              }
              disabled={!!status.appStatus}
              className="qa-statusRow-check"
              onChange={() => {
                const orderListState = getOrderListStateByShop();
                const activeStatuses = getActiveStatusIds(currentShop.shopStatuses);
                const activeFilterCached = isStatusSet(
                  orderListState.filters.shopStatuses,
                  activeStatuses
                );
                const inactiveStatuses = getInactiveStatusIds(currentShop.shopStatuses);
                const inactiveFilterCached = isStatusSet(
                  orderListState.filters.shopStatuses,
                  inactiveStatuses
                );
                if (
                  (status.isActive && activeFilterCached) ||
                  (!status.isActive && inactiveFilterCached)
                ) {
                  // If 'Active Orders' or 'Inactive Orders' filter is selected (cached via context) and we're removing a status from that set,
                  // remove it from the cached context array so orders list is filtered appropriately without requiring a refresh.
                  const index = orderListState.filters.shopStatuses.indexOf(status.id);
                  orderListState.filters.shopStatuses.splice(index, 1);
                } else if (
                  (!status.isActive && activeFilterCached) ||
                  (status.isActive && inactiveFilterCached)
                ) {
                  // Conversely, if a status is being added to a cached 'Active' or 'Inactive' filter, add it to the cached context array.
                  // This is admittedly somewhat convoluted, but the alternative is a new bit of context-state encapsulating the 'Active' & 'Inactive' filters.
                  orderListState.filters.shopStatuses.push(status.id);
                }
                updateStatus({ mutation, i, input: { isActive: !status.isActive } });
              }}
            />
          </td>
          {/* <td>
            <Checkbox
              checked={status.requestorCanSet}
              disabled={status.appStatus === 'SUBMITTED' || status.appStatus === 'CANCELLED'}
              onChange={() => updateStatus({ mutation, i, input: { requestorCanSet: !status.requestorCanSet } })}
            />
          </td>
          <td>
            <Checkbox
              checked={status.operatorCanSet}
              disabled={status.appStatus === 'SUBMITTED' || status.appStatus === 'CANCELLED'}
              onChange={() => updateStatus({ mutation, i, input: { operatorCanSet: !status.operatorCanSet } })}
            />
          </td> */}
          <td>{visibilityDisabled ? disabledVisibilityButton() : visibilityButton(mutation)}</td>
          <td className="center">
            {!status.appStatus ? (
              trashIcon()
            ) : (
              <Popup
                trigger={trashIcon(true)}
                content={t("shop.preferences.statuses.delete.disabled")}
                position="left center"
                inverted
              />
            )}
            <Mutation<any, { id: number }>
              mutation={DELETE_SHOP_STATUS}
              onCompleted={() => {
                onDelete(i);
                Notifier.success(`${t("shop.preferences.statuses.delete.deleted")}`);
              }}
              onError={(error: ApolloError) => Notifier.error(error)}
              variables={{ id: status.id }}
            >
              {deleteShopStatus => (
                <ConfirmationModal
                  headerIcon="trash"
                  headerCopy={t("shop.preferences.statuses.delete.header")}
                  bodyTitle={status.name || t("shop.preferences.statuses.untitled")}
                  bodyCopy={t("shop.preferences.statuses.delete.warning")}
                  cancelTranslationKey="general.cancel"
                  confirmTranslationKey="shop.delete"
                  open={showDeleteModal}
                  onClose={() => setShowDeleteModal(false)}
                  submitAction={() => {
                    status.dateDeleted = new Date();
                    deleteShopStatus();
                  }}
                />
              )}
            </Mutation>
          </td>
        </tr>
      )}
    </Mutation>
  );
};

export const StatusNameInput = ({
  text,
  onSubmit,
}: {
  text: string;
  onSubmit: (text: string) => void;
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <ControlledInput
        className="qa-statusNameInput"
        ref={inputRef}
        value={text}
        onSubmit={onSubmit}
        maxLength={30}
      />
      <Icon
        className="edit"
        onClick={() => {
          inputRef.current?.focus();
          inputRef.current?.select();
        }}
      />
    </>
  );
};

const StyledColorPicker = styled.div`
  .colorpicker {
    position: relative;
    width: 13.7em;
    height: 13em;
  }

  .map {
    position: absolute;
    top: 0;
    right: 1.5em;
    bottom: 1em;
    left: 0;
    overflow: hidden;
    user-select: none;
    border-radius: 0.25em;

    &.active {
      cursor: none;
    }

    &.dark .pointer {
      border-color: #fff;
    }

    &.light .pointer {
      border-color: #000;
    }

    .pointer {
      position: absolute;
      width: 10px;
      height: 10px;
      margin-left: -5px;
      margin-bottom: -5px;
      border-radius: 100%;
      border: 1px solid #000;
      will-change: left, bottom;
    }

    .background {
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .background:before,
    .background:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .background:after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }

    .background:before {
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .slider {
    position: absolute;
    user-select: none;

    &.vertical {
      top: 0;
      bottom: 0;
      left: 50%;
      width: 10px;
      cursor: ns-resize;

      .track {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 10px;
        margin-left: -5px;
      }
    }

    &.horizontal {
      left: 0;
      right: 0;
      top: 50%;
      height: 10px;
      cursor: ew-resize;

      .track {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        height: 8px;
        margin-top: -4px;
      }
    }

    .track {
      border-radius: 3px;
      background: #888;
    }

    .pointer {
      position: absolute;
      bottom: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin-left: -8px;
      margin-bottom: -8px;
      border-radius: 16px;
      background: #fff;
      box-shadow: inset 0 0 0 1px #ccc, 0 1px 2px #ccc;
      will-change: left, bottom;
    }
  }

  .hue-slider {
    position: absolute;
    top: 0;
    bottom: 1em;
    right: 0.7em;

    .track {
      background: linear-gradient(
        to bottom,
        #ff0000 0%,
        #ff0099 10%,
        #cd00ff 20%,
        #3200ff 30%,
        #0066ff 40%,
        #00fffd 50%,
        #00ff66 60%,
        #35ff00 70%,
        #cdff00 80%,
        #ff9900 90%,
        #ff0000 100%
      );
    }
  }

  .ui.input > input {
    width: 87px !important;
  }
  .input-row {
    display: flex;
    .ui.button {
      height: 32px;
      line-height: 11px;
      margin-left: 10px;
    }
  }
`;

const ControledColorPicker = ({
  color,
  onChangeComplete,
  t,
  resetColor,
}: {
  color: string;
  onChangeComplete: (color: string) => void;
  t: TranslateFunction;
  resetColor?: string;
}) => {
  const [colorState, setColorState] = useState(color);
  const [inputState, setInputState] = useState(color);
  const handleMouseUp = () => {
    // When shifting hue with a greyscale color selected, mutation is a no-op
    color !== colorState && onChangeComplete(colorState);
  };
  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, [color, colorState]);

  const rgbToHex = (rgba: string): string => {
    const [r, g, b] = rgba.slice(5, -3).split(","); // 'rgba(r,g,b,a)'
    const componentToHex = (c: string): string => {
      const hex = parseInt(c, 10).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  };

  return (
    <StyledColorPicker>
      <ColorPicker
        color={colorState}
        onChange={(newColor: any) => {
          const hex = rgbToHex(newColor);
          setColorState(hex);
          setInputState(hex);
        }}
      />
      <div className="input-row" data-testid="inputRow">
        <Input
          id="qa-color-input"
          value={inputState}
          onChange={event => {
            setInputState(event.currentTarget.value);
          }}
          onBlur={() => {
            if (/^#[0-9A-F]{6}$/i.test(inputState)) {
              onChangeComplete(inputState);
              setColorState(inputState);
            } else {
              setInputState(colorState);
            }
          }}
          onKeyDown={blurOnEnter}
          maxLength="7"
        />
        {resetColor && (
          <Button
            id="qa-color-reset"
            disabled={color === resetColor}
            content={t("shop.preferences.statuses.color.reset")}
            primary
            size="small"
            onClick={() => {
              setInputState(resetColor);
              setColorState(resetColor);
              onChangeComplete(resetColor);
            }}
          />
        )}
      </div>
    </StyledColorPicker>
  );
};
