import Cube from "@/assets/icons/cube.svg";
import { ApplicationContext } from "@/components/ApplicationProvider";
import {
  SubscriptionResults,
  ISubscriptionContainer,
} from "@/components/Order/Form/CadFile/SubscriptionResults";
import { UploadedCadFile } from "@/components/Order/Form/CadFile/UploadedCadFile";
import styled from "grabcad-ui-elements";
import React, { useCallback, useContext, useState } from "react";
import { DropzoneRootProps } from "react-dropzone";
import { Notifier } from "@/utils/Notifier";
import { IUploadContainer } from "./CadDropZone";
import { isTypename } from "@/utils/typeUtils";
import { PossibleUploadTypes } from "@/graphql/Mutations/Upload";
import {
  UploadImageResults,
  IUploadImageResultContainer,
} from "@/components/Order/Form/CadFile/UploadImageResults";
import { InUploadProgress } from "@/components/Order/Form/CadFile/InUploadProgress";
import {
  UploadFileResults,
  IUploadFileResultContainer,
} from "@/components/Order/Form/CadFile/UploadFileResults";
import { composeDependencyUploadRows } from "@/components/Shared/OrderItemDependenciesPanel";
import { useDivDropzone } from "./DropzoneFixup";
import { MAX_UPLOAD_FILE_SIZE } from "@/shopConstants";

interface DropzoneDivRootProps extends React.HTMLAttributes<HTMLDivElement> {
  refKey?: string;
  [key: string]: any;
}

const DragAndDropAreaWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;

  h3 {
    display: inline-block;
    color: #003393;
    border-bottom: 2px solid transparent;
  }
  &:hover h3 {
    border-bottom: 2px solid #003393;
  }
  img {
    pointer-events: none;
  }
`;

const SupportedFilesWrapper = styled.div`
  max-width: 366px;
`;

const UploadDropzoneWrapper = styled.div`
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;

    > div {
      flex-shrink: 0;
    }
    .cta {
      height: auto;
      flex-grow: 1;
    }
  }

  > div:focus {
    outline: none;
  }
  .fileSizeLimit {
    margin-bottom: 10px;
  }
`;

const DragAndDropCTA = (): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <>
      <h3>{t("order.new.dragndrop")}</h3>
      <div className="fileSizeLimit">
        {t("order.new.fileSizeMessage", { MAX_SIZE: MAX_UPLOAD_FILE_SIZE })}
      </div>
      <div>{t("order.new.acceptMessage")}</div>
      <SupportedFilesWrapper>{t("order.new.supportedFileTypes")}</SupportedFilesWrapper>
    </>
  );
};

const DragAndDropArea = (): JSX.Element => (
  <DragAndDropAreaWrapper>
    <img src={Cube} width="100" />
    <DragAndDropCTA />
  </DragAndDropAreaWrapper>
);

interface IUploadDropzone {
  onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => void;
  loading: boolean;
  multiple: boolean;
  accept: string | undefined;
  openFileDialogCounter: number;
  files: IUploadContainer[];
  showCTA?: boolean;
  isOrderDependencyUpload?: boolean;
}

export const CadUploadDropzone = ({
  onDrop,
  loading,
  accept,
  multiple = true,
  openFileDialogCounter,
  files,
  showCTA,
  isOrderDependencyUpload,
}: Partial<IUploadDropzone>): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const onDropCallback = useCallback((acceptedFiles, rejectedFiles) => {
    if (onDrop) {
      if (rejectedFiles && rejectedFiles.length > 0) {
        Notifier.error({ errorKey: t("shop.upload.unsupported") });
      }
      onDrop(acceptedFiles, rejectedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDivDropzone({
    multiple,
    accept,
    onDrop: onDropCallback,
  });
  const getRootDivProps = getRootProps as (props?: DropzoneRootProps) => DropzoneDivRootProps;

  // We can't reply on toggling a boolean to keep track of dialog state because of a bug in FF:
  // https://github.com/react-dropzone/react-dropzone/issues/575
  const [_openFileDialogCounter, setOpenFileDialogCounter] = useState(1);
  if (openFileDialogCounter && openFileDialogCounter > _openFileDialogCounter) {
    open();
    setOpenFileDialogCounter(openFileDialogCounter);
  }

  let uploadedFiles: IUploadContainer[] = [];

  if (files?.length) {
    uploadedFiles = files.filter(f => !f.cancelled);
  }

  return (
    <UploadDropzoneWrapper data-testid="uploadDropdownWrapper">
      <div {...getRootDivProps({ id: "qa-dropzone-cadFiles" })}>
        <input {...getInputProps()} data-testid="dropzoneCadFiles" />
        {isDragActive && !loading ? (
          showCTA ? (
            <DragAndDropArea />
          ) : null
        ) : uploadedFiles.length > 0 ? (
          isOrderDependencyUpload ? (
            composeDependencyUploadRows(uploadedFiles, t)
          ) : (
            <>
              {uploadedFiles.map((file, index) => {
                if (!file.cadFile) {
                  return (
                    <InUploadProgress
                      key={`in-progress-${file.key}`}
                      index={index}
                      uploadContainer={file}
                    />
                  );
                }
                if (isTypename<PossibleUploadTypes>("CadModel")(file.cadFile)) {
                  return (
                    <UploadedCadFile file={file} key={`uploaded-${file.key}`}>
                      {({ uploadResponse, subscribeToMoreFiles }) => (
                        <SubscriptionResults
                          index={index}
                          uploadContainer={uploadResponse as ISubscriptionContainer}
                          subscribeToMoreData={subscribeToMoreFiles}
                        />
                      )}
                    </UploadedCadFile>
                  );
                }
                if (isTypename<PossibleUploadTypes>("Image")(file.cadFile)) {
                  return (
                    <UploadImageResults
                      key={`uploaded-image-${file.key}`}
                      index={index}
                      uploadContainer={file as IUploadImageResultContainer}
                    />
                  );
                }
                if (isTypename<PossibleUploadTypes>("GenericFile")(file.cadFile)) {
                  return (
                    <UploadFileResults
                      key={`uploaded-image-${file.key}`}
                      index={index}
                      uploadContainer={file as IUploadFileResultContainer}
                    />
                  );
                }
              })}
              {uploadedFiles.length <= 4 && showCTA ? (
                <DragAndDropAreaWrapper className="cta">
                  <DragAndDropCTA />
                </DragAndDropAreaWrapper>
              ) : null}
            </>
          )
        ) : showCTA ? (
          <DragAndDropArea />
        ) : null}
      </div>
    </UploadDropzoneWrapper>
  );
};
