import React, { useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker, DayPickerRangeController, FocusedInputShape } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment, { Moment } from "moment";
import styled, { Container } from "grabcad-ui-elements";
import { applicationLocale } from "@/i18n";

// These imports are necessary for moment to work properly with Typescript
// and dates be formatted correctly according to the region
// https://momentjs.com/docs/#/use-it/typescript/
import "moment/locale/en-au";
import "moment/locale/en-ca";
import "moment/locale/en-gb";
import "moment/locale/en-ie";
import "moment/locale/en-il";
import "moment/locale/en-nz";
import "moment/locale/en-sg";

export interface IDatePickerProps {
  value?: string;
  onDateChange: (date: Moment | null) => void;
  openDirection?: "up" | "down";
  anchorDirection?: "left" | "right";
  id?: string;
  disabled?: boolean;
}
// TODO: Share with company-server via grabcad-ui-components
const CalendarStyle = styled(Container)`
  .DayPicker {
    margin: 0 auto;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    position: relative;
    .SingleDatePickerInput_calendarIcon {
      position: absolute;
      z-index: 1;
      padding: 6px 10px 8px 10px;
      margin: 0;
    }
    .DateInput {
      width: 100%;
      input[type="text"].DateInput_input {
        font-weight: 400;
        padding: 0 1em 0 33px;
        height: 32px;
        border: 1px solid #ccc !important;
        max-width: 100%;
        /* needs to be set here because grabcad-ui-elements do not override the default one */
        &:focus {
          border-color: #003393 !important;
        }
        &:hover {
          border-color: #003393 !important;
        }
      }
    }
  }

  .CalendarMonth_table {
    margin-top: 10px;
  }
  .CalendarMonth .CalendarMonth_table .CalendarDay {
    border: 1px solid #e4e7e7;
    border-top: 1px solid #e4e7e7;
    padding: 0px;
  }
  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    background: #cce2ff;
    color: #484848;
    border: 1px solid #003393;
  }
  .CalendarDay__selected.CalendarDay__selected {
    background: #003393;
    color: white;
    border: 1px solid #003393;
    &:hover {
      background: #003393;
      color: white;
    }
  }
  .CalendarDay__selected_span:hover,
  .CalendarDay__hovered_span:hover {
    background: #003393;
    color: white;
  }
`;

export const FormattedDatePicker = ({
  value,
  onDateChange,
  openDirection = "down",
  anchorDirection = "left",
  id = "qa-datePicker",
  disabled,
}: IDatePickerProps): JSX.Element => {
  const [dateFocused, setDateFocused] = useState(false);

  // Initialize the locale for the datepicker
  moment.locale(applicationLocale());

  return (
    <CalendarStyle data-testid="formattedDatePicker" fluid>
      <SingleDatePicker
        id={id}
        date={(value && moment(value)) || null}
        focused={dateFocused}
        onDateChange={onDateChange}
        onFocusChange={({ focused }: { focused: boolean | null }) =>
          setDateFocused(focused || false)
        }
        placeholder=""
        showDefaultInputIcon
        numberOfMonths={1}
        openDirection={openDirection}
        anchorDirection={anchorDirection}
        hideKeyboardShortcutsPanel
        noBorder
        disabled={disabled}
      />
    </CalendarStyle>
  );
};

export interface IFormattedDateRangePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  onDatesChange: (arg: { startDate: Moment | null; endDate: Moment | null }) => void;
  onFocusChange: (focused: FocusedInputShape | null) => void;
  focusedInput: FocusedInputShape;
}

export const FormattedDateRangePicker = ({
  startDate,
  endDate,
  onDatesChange,
  focusedInput,
  onFocusChange,
}: IFormattedDateRangePickerProps): JSX.Element => {
  // Initialize the locale for the datepicker
  moment.locale(applicationLocale());

  return (
    <CalendarStyle fluid>
      <DayPickerRangeController
        startDate={startDate || null}
        endDate={endDate || null}
        onDatesChange={onDatesChange}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        noBorder
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        minimumNights={0} // This allows selecting same day as start and end, for a 24 hour range.
      />
    </CalendarStyle>
  );
};
