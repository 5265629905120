import gql from "graphql-tag";

export const UPDATE_ORDER_NOTIFICATION_PREFERENCE = gql`
  mutation updateOrderNotificationPreference($input: NotificationInput!) {
    updateOrderNotificationPreference(input: $input) {
      entityId
      isDeleted
    }
  }
`;
