import React, { useContext } from "react";
import { ShopNewForm } from "@/components/Shop/New/Form/Form";
import { Grid, GridRow, GridColumn, Header } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ShopUpdateForm } from "@/components/Shop/Update/Form/Form";

export const General = (): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);

  return (
    <Grid className="section-grid">
      <GridRow>
        <GridColumn>
          <Header as="h2" className="page-header">
            {t("shop.new.general.title")}
          </Header>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>{currentShop ? <ShopUpdateForm /> : <ShopNewForm />}</GridColumn>
      </GridRow>
    </Grid>
  );
};
