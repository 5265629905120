import React, { useContext } from "react";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { SelectionDropdown } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import classnames from "classnames";
import { ITechnologyMateralColorIds } from "@/utils/DropdownUtils";
import { getDedupedShopMaterialsForTechnology } from "./ShopMaterialPicker";
import { getDedupedColorsForMaterialByName } from "./ShopMaterialColorPicker";
import { getIcon } from "../ItemsList/CadItemDetails";
import technology from "../../../assets/icons/technology.svg";
import { useShopTechnologies } from "@/utils/queryHooks";

export const ShopTechnologyPicker = ({
  shopTechnologyId,
  onChange,
  disabled,
  styleAsRequired,
  showIcon,
  tooltipPosition,
  ...rest
}: {
  shopTechnologyId?: number;
  onChange?: (ids: ITechnologyMateralColorIds) => void;
  styleAsRequired?: boolean;
  disabled?: boolean;
  showIcon?: boolean;
  tooltipPosition?: string;
}): JSX.Element | null => {
  const context = useContext(ApplicationContext);
  const { loadingTechnologies, shopTechnologies } = useShopTechnologies();

  const technologyOptions = shopTechnologies
    .filter(tech =>
      // Include only technologies that have undeleted machines with > 0 undeleted materialsColors
      tech.shopMachines.some(
        machine =>
          !machine.dateDeleted &&
          machine.materials.some(mat => mat.shopMaterialColors.some(color => !color.dateDeleted))
      )
    )
    .map((tech: IShopTechnology) => ({
      key: `st-${tech.id}`,
      value: tech.id,
      text: tech.appTechnology.displayName,
    }));

  const handleChange = (techId: number) => {
    const newShopTechnology = shopTechnologies.find(tech => tech.id === techId);
    // Autoselect if single material available for technology, OR clear with `null`
    const materials = getDedupedShopMaterialsForTechnology(context, newShopTechnology);
    const shopMaterialId = materials.length === 1 ? materials[0].id : null;

    // Autoselect if single color available for material, OR clear with `null`
    const { dedupedColors } = getDedupedColorsForMaterialByName(
      newShopTechnology,
      shopMaterialId || undefined
    );
    const shopMaterialColorId = dedupedColors.length === 1 ? dedupedColors[0].id : null;

    onChange?.({
      shopTechnologyId: techId,
      shopMaterialId,
      shopMaterialColorId,
    });
  };

  // If only one technology is available, auto-select it.
  if (!shopTechnologyId && technologyOptions.length === 1) {
    handleChange(technologyOptions[0].value);
  }

  const shopTechnology = shopTechnologies.find(tech => tech.id === shopTechnologyId);
  const selectedTechValid = !!technologyOptions.find(tech => tech.value === shopTechnologyId);

  return (
    <div
      data-tooltip={
        !selectedTechValid && shopTechnology
          ? context.t("order.items.technology.unavailable")
          : undefined
      }
      data-position={tooltipPosition ? tooltipPosition : "left center"}
      {...rest} // This can override the tooltip above
    >
      <SelectionDropdown
        icon={showIcon && getIcon(technology)}
        loading={loadingTechnologies}
        className={classnames(
          { "no-selection": !styleAsRequired && !shopTechnologyId },
          "qa-dropdown-tech"
        )}
        value={shopTechnologyId || undefined}
        onChange={(_evt, { value }) => handleChange(value as number)}
        options={technologyOptions}
        disabled={disabled || technologyOptions.length <= 1}
        placeholder={!selectedTechValid ? shopTechnology?.appTechnology.displayName : undefined}
        data-testid="dropdown-tech"
      />
    </div>
  );
};
