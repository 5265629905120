import React, { useContext } from "react";
import { IOrderOperatorChangedEvent } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "../../ApplicationProvider";

export const HistoryOrderOperatorChangedComponent = ({
  event,
}: {
  event: IOrderOperatorChangedEvent;
}): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { metadata } = event;
  return (
    <>
      {metadata.newOperator && (
        <div>
          {`${t("order.operator.assigned.as", { name: metadata.newOperator })} `}
          <b>{t("roles.operator")}</b>
        </div>
      )}
      {!metadata.newOperator && metadata.oldOperator && (
        <div>
          {`${t("order.operator.unassigned.as", { name: metadata.oldOperator })} `}
          <b>{t("roles.operator")}</b>
        </div>
      )}
    </>
  );
};

HistoryOrderOperatorChangedComponent.displayName = "HistoryOrderOperatorChangedComponent";
