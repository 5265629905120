import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { ROUTES } from "@/shopConstants";
import { Permission } from "@/utils/Permission";
import { Button } from "grabcad-ui-elements";
import React, { useContext } from "react";
import { getStatusName } from "../Status/Status";
import { DateFilterRangeType, getActiveStatusIds, isStatusSet } from "./FiltersPopup";
import ReactGA from "react-ga";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";
import { ORDER_COUNT } from "@/graphql/Queries";

interface INoOrdersResultProps {
  statusFilter?: number[];
  techFilter?: string | number | null;
  searchTerms?: string | null;
  dateFilterRange?: DateFilterRangeType;
  history: RouteComponentProps["history"];
}

export const NoOrdersResult: React.FunctionComponent<INoOrdersResultProps> = ({
  statusFilter,
  techFilter,
  searchTerms,
  dateFilterRange,
  history,
}: INoOrdersResultProps) => {
  const {
    t,
    currentShop,
    currentUser: { userProfile: user },
    ability,
  } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  const { data: orderCountData } = useQuery<{ orderCountByShop: number }, { shopId: number }>(
    ORDER_COUNT,
    { variables: { shopId: currentShop.id } }
  );

  const activeOrdersStatuses: number[] = currentShop
    ? getActiveStatusIds(currentShop.shopStatuses)
    : [];

  const getShopStatusName = (id: number) => {
    const shopStatus = currentShop?.shopStatuses.find(status => status.id === id);
    return shopStatus ? getStatusName(shopStatus, t) : "";
  };

  if (orderCountData?.orderCountByShop === 0) {
    let prompt = "";
    if (user && ability.can(Permission.CREATE_SHOP, user)) {
      // admin
      prompt = t("order.list.placeholder.copy.admin");
    } else if (ability.can(Permission.CREATE_JOBS, currentShop)) {
      // operator
      prompt = t("order.list.placeholder.copy.operator");
    } else {
      // requester / default
      prompt = t("order.list.placeholder.copy.requester");
    }

    return (
      <>
        <>
          <h2>{t("order.list.placeholder.header")}</h2>
          <p>{prompt}</p>
        </>
        <UiCan do={Permission.CREATE_ORDER} on={currentShop}>
          <Button
            id="qa-button-newOrder"
            primary
            content={t("order.list.newOrder")}
            onClick={() => {
              history.push(ROUTES.SHOP(currentShop.id).ORDER.NEW);
              ReactGA.event({
                category: "GcShop Order",
                action: "Create New Order",
                label: `Shop ${currentShop.id}`,
              });
            }}
          />
        </UiCan>
      </>
    );
  } else {
    // Render message indicating that no orders match status/technology/search filters
    const noOrderForFiltersMessage = [];
    if (statusFilter && statusFilter.length === 1) {
      noOrderForFiltersMessage.push(
        t("order.list.placeholder.withStatus"),
        <b key="b1">{getShopStatusName(statusFilter[0])}</b>
      );
    }
    if (statusFilter && statusFilter.length > 1) {
      noOrderForFiltersMessage.push(
        t("order.list.placeholder.withStatus"),
        <b key="status">
          {isStatusSet(statusFilter, activeOrdersStatuses)
            ? t("order.status.active")
            : t("order.status.inactive")}
        </b>
      );
    }
    if (techFilter) {
      const prefix = statusFilter && statusFilter.length >= 1 ? ", " : "";
      noOrderForFiltersMessage.push(
        prefix,
        <br key="br1" />,
        t("order.list.placeholder.forTechnology"),
        <b key="b2">{techFilter}</b>
      );
    }
    if (searchTerms && searchTerms.length > 0) {
      noOrderForFiltersMessage.push(
        noOrderForFiltersMessage.length ? " " : "",
        t("order.list.placeholder.withSearch"),
        <b key="search">{searchTerms}</b>
      );
    }
    if (dateFilterRange) {
      noOrderForFiltersMessage.push(
        noOrderForFiltersMessage.length ? " " : "",
        t("order.list.placeholder.inDateRange")
      );
    }
    return (
      <p>
        {t("order.list.placeholder.noOrders")}
        {noOrderForFiltersMessage}
      </p>
    );
  }
};
