import { ApolloClient, ApolloQueryResult, gql } from "@apollo/client";
import { ORDER_ITEM_CAD_MODELS_PREVIEW } from "../Queries/Order";
import { IOrderItem } from "../Fragments/Order";

export const updateCadModelQueryCache = async (
  client: ApolloClient<any>,
  orderItemIds: number[]
): Promise<void> => {
  const OrderItemIdsWithoutCachedCadModelPreviews = orderItemIds.filter(itemId => {
    const cachedOrderItem = client.readFragment({
      id: `OrderItem:${itemId}`,
      fragment: gql`
        fragment MyOrderItem on OrderItem {
          cadModel {
            id
            preview {
              id
              small {
                id
                location
              }
              medium {
                id
                location
              }
            }
          }
        }
      `,
    });
    return !cachedOrderItem?.cadModel?.preview;
  });
  // TODO: Batching! Groups of 10? 20? Lazy-load when scrolling?
  if (OrderItemIdsWithoutCachedCadModelPreviews.length > 0) {
    const batchResult: ApolloQueryResult<{ orderItemsCadModels: Partial<IOrderItem>[] }> =
      await client.query({
        query: ORDER_ITEM_CAD_MODELS_PREVIEW,
        variables: { orderItemIds: OrderItemIdsWithoutCachedCadModelPreviews },
      });
    // Write results of batched queries to cache as if it were a bunch individual queries, to "backfill" cache-only
    // preview image URL <Query>s. In addition to batching/lazy-loading, this approach will enable multiple pages
    // to share the cached CadModel preview image, which are time-expensive to load in quantity.
    batchResult.data.orderItemsCadModels.forEach(item =>
      client.writeQuery({
        query: ORDER_ITEM_CAD_MODELS_PREVIEW,
        variables: { orderItemIds: [item.id] },
        data: { orderItemsCadModels: [item] },
      })
    );
  }
};
