import React, { useContext } from "react";
import { IOrderDetailsChangedEvent } from "@/graphql/Fragments/Order";
import { Icon } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";
import { IMachineRate } from "@/graphql/Fragments/ShopRate";
import {
  AREA_UNIT,
  DEFAULT_TIME_UNIT,
  LENGTH_UNIT,
  QUANTITY_DEFAULT_UNIT,
  VOLUME_UNIT,
  WEIGHT_UNIT,
  MATERIAL_QUANTITY,
} from "@/shopConstants";
import {
  roundToTwoDecimalsFractional,
  transformCubicMMToDisplayUnits,
  transformGramsToDisplayUnits,
  transformMinutesToDisplayUnits,
  transformMMToDisplayUnits,
  transformSquareMMToDisplayUnits,
} from "../ItemsList/shopRatesUtils";
import {
  GRAPHQL_MODEL_UNITS,
  getMaterialUnitLabel,
  getTranslatedUnits,
} from "@/utils/DropdownUtils";

interface IHistoryOrderDetailsChangedComponentProps {
  event: IOrderDetailsChangedEvent;
  singleLine?: boolean;
}

const defaultUnitsForMaterialField = (fieldName: string) => {
  return fieldName.endsWith("Grams")
    ? QUANTITY_DEFAULT_UNIT[MATERIAL_QUANTITY.WEIGHT]
    : fieldName.endsWith("CubicMm")
    ? QUANTITY_DEFAULT_UNIT[MATERIAL_QUANTITY.VOLUME]
    : fieldName.endsWith("SquareMm")
    ? QUANTITY_DEFAULT_UNIT[MATERIAL_QUANTITY.AREA]
    : QUANTITY_DEFAULT_UNIT[MATERIAL_QUANTITY.LENGTH];
};

export const HistoryOrderDetailsChangedComponent = ({
  event,
  singleLine,
}: IHistoryOrderDetailsChangedComponentProps): JSX.Element | null => {
  const { currentShop, t, formatDate, formatPrice } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const machineRate = (
    event.orderItem ? event.orderItem.machineRate || {} : {}
  ) as Partial<IMachineRate>;
  const { fieldName, newValue, oldValue } = event.metadata;
  const machineTimeUnit = machineRate.machineTimeUnit ?? DEFAULT_TIME_UNIT;
  const materialUnit = machineRate.materialUnit ?? defaultUnitsForMaterialField(fieldName);
  const supportUnit = machineRate.supportUnit ?? materialUnit;
  let fromValue: string | null = oldValue;
  let toValue: string | null = newValue;
  // eslint-disable-next-line default-case
  switch (fieldName) {
    case "needByDate":
    case "dueDate":
      fromValue = formatDate(oldValue);
      toValue = formatDate(newValue);
      break;
    case "markupPrice":
      fromValue = oldValue + "%";
      toValue = newValue + "%";
      break;
    case "extraCost":
    case "overridePrice":
    case "price":
      fromValue = formatPrice(+oldValue);
      toValue = formatPrice(+newValue);
      break;
    case "units":
      fromValue = oldValue && getTranslatedUnits(t, oldValue as GRAPHQL_MODEL_UNITS);
      toValue = newValue && getTranslatedUnits(t, newValue as GRAPHQL_MODEL_UNITS);
      break;
    case "estimatedMinutes":
      fromValue = `${transformMinutesToDisplayUnits(+oldValue, machineTimeUnit)}${t(
        `order.items.rates.units.${machineTimeUnit.toLowerCase()}`
      )}`;
      toValue = `${transformMinutesToDisplayUnits(+newValue, machineTimeUnit)}${t(
        `order.items.rates.units.${machineTimeUnit.toLowerCase()}`
      )}`;
      break;
    case "estimatedMaterialCubicMm":
      fromValue = `${roundToTwoDecimalsFractional(
        transformCubicMMToDisplayUnits(+oldValue, materialUnit as VOLUME_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformCubicMMToDisplayUnits(+newValue, materialUnit as VOLUME_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      break;
    case "estimatedSupportCubicMm":
      fromValue = `${roundToTwoDecimalsFractional(
        transformCubicMMToDisplayUnits(+oldValue, supportUnit as VOLUME_UNIT)
      )}${getMaterialUnitLabel(t, supportUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformCubicMMToDisplayUnits(+newValue, supportUnit as VOLUME_UNIT)
      )}${getMaterialUnitLabel(t, supportUnit)}`;
      break;
    case "estimatedMaterialGrams":
      fromValue = `${roundToTwoDecimalsFractional(
        transformGramsToDisplayUnits(+oldValue, materialUnit as WEIGHT_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformGramsToDisplayUnits(+newValue, materialUnit as WEIGHT_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      break;
    case "estimatedSupportGrams":
      fromValue = `${roundToTwoDecimalsFractional(
        transformGramsToDisplayUnits(+oldValue, supportUnit as WEIGHT_UNIT)
      )}${getMaterialUnitLabel(t, supportUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformGramsToDisplayUnits(+newValue, supportUnit as WEIGHT_UNIT)
      )}${getMaterialUnitLabel(t, supportUnit)}`;
      break;
    case "estimatedMaterialSquareMm":
      fromValue = `${roundToTwoDecimalsFractional(
        transformSquareMMToDisplayUnits(+oldValue, materialUnit as AREA_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformSquareMMToDisplayUnits(+newValue, materialUnit as AREA_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      break;
    case "estimatedMaterialMm":
      fromValue = `${roundToTwoDecimalsFractional(
        transformMMToDisplayUnits(+oldValue, materialUnit as LENGTH_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      toValue = `${roundToTwoDecimalsFractional(
        transformMMToDisplayUnits(+newValue, materialUnit as LENGTH_UNIT)
      )}${getMaterialUnitLabel(t, materialUnit)}`;
      break;
  }
  return (
    <>
      <b>{t(`order.details.change.${fieldName}`)}</b>
      {singleLine ? " " : <br />}
      {fromValue} <Icon name="long arrow alternate right" />
      {toValue}
    </>
  );
};

HistoryOrderDetailsChangedComponent.displayName = "HistoryOrderDetailsChangedComponent";
