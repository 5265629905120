import { Mutation } from "@apollo/client/react/components";
import {
  OrderCommentsAndHistory,
  OrderHeader,
  OrderInfo,
  OrderItemsActionsOnMultiSelect,
  OrderItems,
  NotificationSubscription,
} from "@/components/Order";
import { UiCan } from "@/components/UiCan";
import styled, { Modal, Button, Popup } from "grabcad-ui-elements";
import {
  IOrder,
  OrderItemOrImageOrFile,
  OrderItemOrImageOrFileType,
  OrderItemTypeName,
} from "@/graphql/Fragments/Order";
import { ORDER_DETAILS } from "@/graphql/Queries";
import React, { useState, useRef, useEffect, useContext, useReducer, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Notifier } from "@/utils/Notifier";
import { Permission } from "@/utils/Permission";
import { IUploadContainer, CadDropZone } from "@/components/Upload/CadDropZone";
import { ADD_FILES_TO_ORDER } from "@/graphql/Mutations/Order";
import { IOrderItemInput } from "../New/New";

import {
  isGenericFileFilter,
  isImageFileFilter,
  isCadModelFilter,
} from "@/components/Order/Form/Form";
import { IGenericFile, ICadFile, GenericFileTypeName } from "@/graphql/Mutations/Upload";
import { IImage, ImageTypeName } from "@/graphql/Fragments/Image";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { OperatorSelector } from "@/components/Order/OperatorSelector/OperatorSelector";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import { produce } from "immer";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { useApolloClient, useQuery } from "@apollo/client";
import { updateCadModelQueryCache } from "@/graphql/Utils/updateCadModelQueryCacheUtil";
import { modelCadFormatIsUnitless } from "@/utils/DropdownUtils";

interface IAddFilesToOrderInput {
  orderId: number;
  orderItems?: IOrderItemInput[];
  fileIds?: number[];
  imageIds?: number[];
}

const OrderPage = styled.div`
  height: 100%;
  margin-top: -1rem;
  display: flex;
  flex-direction: column;

  .headerRow {
    display: flex;
    padding: 1rem 0 0 0;
    height: 45px;
    &.available-space {
      flex: 1;
      min-height: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
      margin-bottom: -2rem;
    }
    .reqHeader {
      margin: 6px 200px 6px 0;
    }

    .opHeader {
      margin: 6px 4px 6px 0;
    }
  }

  .row {
    display: flex;
    padding: 1rem 0;
    &.available-space {
      flex: 1;
      min-height: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
      margin-bottom: -2rem;
    }
  }

  .orderPartOptionsRow {
    display: flex;
    align-items: center;
    .multiOptionsCol {
      flex: auto;
      position: relative;
      padding: 0 1rem 0 0; /* padding + negative margin to prevent cropping left box shadows */
      margin: 0 0 0 -1rem;
    }
    .updatesCol {
      flex: 0 0 25%;
      min-width: 260px;
      padding-left: 1rem;
    }
  }

  .orderItemsCol {
    flex: 1 1 auto;
    overflow-y: auto;
    position: relative;
    padding: 0 1rem; /* padding + negative margin to prevent cropping left box shadows */
    margin: 0 0 -1rem -1rem;

    /* Shadow visible when scrolling past first table */
    &:before {
      content: "";
      position: sticky;
      display: block;
      top: 0;
      width: calc(100% - 1px);
      height: 10px;
      margin-bottom: -10px;
      z-index: 5;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    }
    /* Hides the shadow otherwise visible behind the rounded corners */
    > div:first-child {
      position: relative;
      z-index: 10;
      background: white;
    }

    > .ui.loader {
      position: initial;
      margin: 80px auto 40px;
    }
  }

  .commentsCol {
    flex: 0 0 25%;
    min-width: 260px;
    padding-left: 1rem;
  }
`;

export type ItemsState = {
  [key in OrderItemOrImageOrFileType]: number[];
};

const initialState = produce(
  {
    OrderItem: [],
    GenericFile: [],
    Image: [],
  } as ItemsState,
  // No need to modify things
  () => {}
);

const AddFilesModal = styled(Modal)`
  &.ui.modal {
    border-radius: 3px;
    background: white;
    overflow: hidden;
    > .content {
      color: black;
      padding: 0;
      min-height: 400px;
    }
    > .actions {
      padding: 14px 14px;
      height: 66px;
      .cancel {
        float: left;
        margin: 0;
      }
      .confirm {
        float: right;
      }
    }
  }
`;

type SelectionActionPayload =
  | { /* select items */ type: "select"; toSelect: OrderItemOrImageOrFile[] }
  | { /* unselect all items */ type: "none" }
  | { /* toggle selection of single item */ type: "toggle"; item: OrderItemOrImageOrFile };

const updateSelection = (
  prevSelections: ItemsState,
  action: SelectionActionPayload
): ItemsState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "select":
      // Select all items
      return produce(prevSelections, (draft: ItemsState) => {
        // for all order items [in order], mark them as selected by putting into appropriate selection bucket
        action.toSelect.forEach((orderItem: OrderItemOrImageOrFile) => {
          const selectionsForType = draft[orderItem.__typename];
          if (!selectionsForType.includes(orderItem.id)) {
            selectionsForType.push(orderItem.id);
          }
        });
      });
    case "none":
      // Unselect all items
      return initialState;
    case "toggle": {
      // Toggle selection item
      const newSelections = produce(prevSelections, (draft: ItemsState) => {
        const type: OrderItemTypeName | ImageTypeName | GenericFileTypeName =
          action.item.__typename;
        const itemId: number = action.item.id;
        const draftSelections = draft[type];
        const toggleIdx = draftSelections.indexOf(itemId);
        if (toggleIdx >= 0) {
          // unselect => remove order item from selection array
          draftSelections.splice(toggleIdx, 1);
        } else {
          // select => add order item to selection array
          draftSelections.push(itemId);
        }
      });
      return newSelections;
    }
  }
};

export const ScreensOrderPage = withRouter(
  ({
    match: {
      params: { orderId },
    },
  }: RouteComponentProps<{ orderId: string }>) => {
    const { currentShop, t } = useContext(ApplicationContext);

    // NB: dispatchSelection() reference will *not* change between render()
    // although the function it delegates to does
    // See https://reactjs.org/docs/hooks-reference.html#usereducer
    const [selectedItems, dispatchSelection] = useReducer(updateSelection, initialState);
    const [files, setFiles] = useState<IUploadContainer[]>([]);
    const [openFileDialogCounter, setOpenFileDialogCounter] = useState(1);
    const [allUploaded, setAllUploaded] = useState<boolean>(false);
    const currentOrderItems = useRef([] as OrderItemOrImageOrFile[]);
    const client = useApolloClient();

    const [prevItemCount, setPrevItemCount] = useState(NaN);
    const selectAllOrNoneItems = useCallback(
      (isChecked: boolean) => {
        dispatchSelection(
          isChecked ? { type: "select", toSelect: currentOrderItems.current } : { type: "none" }
        );
      },
      [dispatchSelection, currentOrderItems.current]
    );

    const isOrderStatusSubmitted = (currentOrder: IOrder): boolean => {
      const orderStatus =
        currentShop &&
        (currentShop.shopStatuses.find(s => s.id === currentOrder.shopStatus) as IShopStatus);
      if (orderStatus) {
        if (orderStatus.appStatus && orderStatus.appStatus.toLowerCase() === "submitted") {
          return true;
        }
      }
      return false;
    };

    const toggleSelection = useCallback(
      (item: OrderItemOrImageOrFile) => {
        dispatchSelection({ type: "toggle", item });
      },
      [dispatchSelection]
    );

    const { data, loading, error } = useQuery<{ order?: IOrder }, { orderId: number }>(
      ORDER_DETAILS,
      { variables: { orderId: +orderId }, onError: (err: any) => Notifier.error(err) }
    );

    // Scroll to bottom when loading and adding items, but not when deleting
    const [scrollIntoView, setScrollIntoView] = useState(false);
    const [scrollToLoader, setScrollToLoader] = useState(false);
    const itemsListRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
      itemsListRef.current?.scrollIntoView({ behavior: "auto" });
    };
    const itemsCount = data?.order?.orderItems.length;
    useEffect(() => {
      if (scrollIntoView) {
        scrollToBottom();
        setScrollIntoView(false);
      }
    }, [itemsCount]);
    useEffect(() => {
      if (scrollToLoader) {
        scrollToBottom();
        setScrollToLoader(false);
      }
    }, [scrollToLoader]);

    if (error) {
      return <p>{t("global.error")}</p>;
    }
    if (loading || !data || !data.order) {
      return <p>{t("global.loading")}</p>;
    }

    const order: IOrder = {
      ...data.order,
    };
    currentOrderItems.current = order.orderItems;
    const orderItemIds = order.orderItems
      .filter(o => o.__typename === "OrderItem")
      .map(item => item.id);
    updateCadModelQueryCache(client, orderItemIds);

    const cadDropZone = (
      <CadDropZone
        shop={currentShop}
        onFileAction={cadContainer => {
          setFiles(prevFiles => {
            const index = prevFiles.findIndex(i => i.key === cadContainer.key);
            let file = prevFiles[index];
            if (file) {
              file = { ...file, ...cadContainer };
              const newState = [...prevFiles.slice(0, index), file, ...prevFiles.slice(index + 1)];
              setAllUploaded(
                newState.every(item => item.uploaded) &&
                  newState.filter(item => !item.cancelled).length > 0
              );
              return newState;
            }
            return [...prevFiles, cadContainer];
          });
          /*
                setFiles(
                  // produce(1arg) returns (prevImmutable: IUC[]) => newImmutable as IUC[]
                  // See https://immerjs.github.io/immer/docs/example-setstate
                  produce((draft: IUploadContainer[]) => {
                    const curFileIdx = draft.findIndex(container => container.key === cadContainer.key);
                    if (curFileIdx >= 0) {
                      // Updating existing file upload (e.g., cancel, complete, etc it)
                      draft[curFileIdx] = { ...draft[curFileIdx], ...cadContainer };

                      // everything is uploaded AND at least 1 item was not cancelled
                      setAllUploaded(
                        draft.every(container => container.uploaded) && draft.some(container => !container.cancelled)
                      );
                    } else {
                      // Start tracking new file upload
                      draft.push({ ...cadContainer });
                    }
                  })
                );
                */
        }}
        openFileDialogCounter={openFileDialogCounter}
        uploadedFiles={files}
      />
    );

    return (
      <UiCan passThrough do={Permission.WRITE} on={order}>
        {(isWritable: boolean) => (
          <OrderPage>
            <div className="headerRow">
              <div className="reqHeader" data-testid="reqHeader">
                <b>{t("roles.requester")}:&nbsp;</b> {order.user.name}: {order.user.email}
                <Popup
                  trigger={
                    <span className="edit-permission" data-testid="editPermission">
                      {isOrderStatusSubmitted(order)
                        ? ` (${t("order.details.canEdit")})`
                        : ` (${t("order.details.cannotEdit")})`}
                    </span>
                  }
                  content={t("order.details.reasonToEdit", {
                    requester: order.user.name ? order.user.name : order.user.email,
                  })}
                  position="bottom center"
                  inverted
                />
              </div>
              <div className="opHeader">
                <b>{t("order.list.table.operator")}</b>
              </div>
              <OperatorSelector order={order} refetchOrderEvents />
              {/* Refetch order events to get new event in case history tab is visible */}
            </div>
            <div className="row">
              <OrderHeader order={order} />
            </div>
            <div className="orderPartOptionsRow">
              <div className="multiOptionsCol">
                <OrderItemsActionsOnMultiSelect
                  selectedItems={selectedItems}
                  selectAllOrNoneItems={selectAllOrNoneItems}
                  orderItems={currentOrderItems.current}
                  order={order}
                  openFileDialogCounter={openFileDialogCounter}
                  setOpenFileDialogCounter={setOpenFileDialogCounter}
                />
              </div>
              <div className="updatesCol">
                <NotificationSubscription order={order} />
              </div>
            </div>
            <div className="row available-space">
              <div className="orderItemsCol" data-testid="orderItemsCol">
                <div>
                  <OrderInfo order={order} />
                </div>
                <OrderItems
                  isWritable={isWritable}
                  orderId={order.id}
                  items={order.orderItems}
                  prevItemCount={prevItemCount}
                  toggleSelection={toggleSelection}
                  selectedOrderItems={selectedItems.OrderItem}
                  selectedFileItems={selectedItems.GenericFile}
                  selectedImageItems={selectedItems.Image}
                />
                {scrollIntoView ? <div className="ui loader active" /> : null}
                <div ref={itemsListRef} />
              </div>
              <div className="commentsCol">
                <OrderCommentsAndHistory order={order} />
              </div>
            </div>
            {files.length ? (
              <Mutation<{ id: number }, { input: IAddFilesToOrderInput }>
                mutation={ADD_FILES_TO_ORDER}
                onError={err => Notifier.error(err)}
                refetchQueries={[{ query: ORDER_DETAILS, variables: { orderId: +orderId } }]}
                update={async cache => fetchNewOrderEvents(cache, client, +orderId)}
                onCompleted={() => {
                  Notifier.success(
                    <>
                      {files.length}{" "}
                      {files.length === 1
                        ? t("order.items.added.success.singular")
                        : t("order.items.added.success.plural")}
                    </>
                  );
                  setPrevItemCount(order.orderItems.length);
                  setFiles([]);
                  setScrollIntoView(true);
                  setScrollToLoader(true);
                }}
              >
                {addFiles => (
                  <AddFilesModal
                    basic
                    size="small"
                    open={!!files.length}
                    onClose={() => setFiles([])}
                  >
                    <Modal.Content>{cadDropZone}</Modal.Content>
                    <Modal.Actions>
                      <Button
                        id="qa-addFiles-cancel"
                        className="cancel"
                        secondary
                        onClick={() => setFiles([])}
                      >
                        {t("general.cancel")}
                      </Button>
                      <Button
                        id="qa-addFiles-confirm"
                        className="confirm"
                        disabled={!allUploaded}
                        onClick={() => {
                          const orderItems: IOrderItemInput[] = files
                            .filter(isCadModelFilter)
                            .map((container: IUploadContainer) => ({
                              units: modelCadFormatIsUnitless(container.cadFile as ICadFile)
                                ? container.unit
                                : undefined,
                              // Type assertion made "safe" by the previous filter on container.cadFile
                              cadModelId: (container.cadFile as ICadFile).id,
                              quantity: container.quantity,
                              price: 0,
                            }));
                          addFiles({
                            variables: {
                              input: {
                                orderItems,
                                orderId: +orderId,
                                fileIds: files
                                  .filter(isGenericFileFilter)
                                  .map(filtered => (filtered.cadFile as IGenericFile).id),
                                imageIds: files
                                  .filter(isImageFileFilter)
                                  .map(filtered => (filtered.cadFile as IImage).id),
                              },
                            },
                          });
                        }}
                      >
                        {t("order.details.done")}
                      </Button>
                    </Modal.Actions>
                  </AddFilesModal>
                )}
              </Mutation>
            ) : (
              // This drop-zone isn't visible to the user, but we need to render it
              // in order to trigger useDropzone's open() method
              <div style={{ display: "none" }}>{cadDropZone}</div>
            )}
          </OrderPage>
        )}
      </UiCan>
    );
  }
);

ScreensOrderPage.displayName = "ScreensOrderPage";
