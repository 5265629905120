import React from "react";
import * as _ from "lodash";

export const convertFromUpperCase = (text: string): string =>
  text
    .toLowerCase()
    .split("_")
    .map(word => word.replace(word[0], word[0].toUpperCase()))
    .join(" ");

export const highlightedText = (
  searchTerms?: string | null,
  textToSearch?: string
): string | JSX.Element | undefined => {
  if (searchTerms && textToSearch) {
    searchTerms = _.escapeRegExp(searchTerms);
    const regex = new RegExp(`(${searchTerms})`, "ig");
    const textParts: (string | JSX.Element)[] = textToSearch.split(regex);
    // Every other term would be a match group after regex split, hence incremented by 2
    for (let i = 1; i < textParts.length; i += 2) {
      textParts[i] = <mark key={i}>{textParts[i]}</mark>;
    }
    return <>{textParts}</>;
  }
  return textToSearch;
};
