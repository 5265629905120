import { IDropdownOption } from "@/components/Shop/Materials/Form/GroupDropdown";
import { TranslateFunction } from "@/components/ApplicationProvider";
import { MATERIAL_QUANTITY, MATERIAL_UNITS, TIME_UNITS } from "@/shopConstants";
import { ICadFile } from "@/graphql/Mutations/Upload";
import { roundToTwoDecimals } from "../components/Order/ItemsList/shopRatesUtils";
import { IOrderItem } from "../graphql/Fragments/Order";

export const UnitsOptions = <const>["CM", "IN", "M", "MM"];
export type GRAPHQL_MODEL_UNITS = (typeof UnitsOptions)[number];

export type UnitType = GRAPHQL_MODEL_UNITS | "FT" | "UNDEFINED";
const unitsLabelMap: Map<UnitType, string> = new Map([
  ["MM", "millimeters"],
  ["CM", "centimeters"],
  ["IN", "inches"],
  ["M", "meters"],
  ["FT", "feet"],
]);

const unitsMultiplierMap: Map<UnitType, number> = new Map([
  ["MM", 1],
  ["CM", 10],
  ["IN", 25.4],
  ["M", 1000],
  ["FT", 304.8],
]);

export const preferredUnits2GQL = (
  preferredUnits: string | undefined
): GRAPHQL_MODEL_UNITS | undefined => {
  switch (preferredUnits) {
    case "Metric":
      return "CM";
    case "Imperial":
      return "IN";
    default:
      return preferredUnits as GRAPHQL_MODEL_UNITS;
  }
};

const getExtension = (fileName: string | undefined): string | undefined => {
  return fileName?.substring(fileName?.lastIndexOf(".") + 1, fileName.length).toLowerCase();
};

export const modelCadFormatIsUnitless = (cadModel: Partial<ICadFile> | undefined): boolean => {
  const unitlessExtensions = ["stl", "vrml", "vrl", "wrl", "obj", "ply"];

  if (!cadModel) {
    return false;
  }

  // main logic: conversion OK + size exists
  if (cadModel.conversionStatus === "SUCCESS" && !!cadModel.size) {
    if (cadModel.unit) {
      return cadModel.unit === "UNDEFINED";
    } // otherwise fallback to unit by extension, for units can be just missing from HOOPS output
  }

  const ext = getExtension(cadModel.original?.asset?.originalName);
  return ext ? unitlessExtensions.includes(ext) : false;
};
export const getModelSizesString = (
  cadModel: Partial<ICadFile> | undefined,
  modelUnits: UnitType | undefined,
  preferredUnits: string | undefined,
  t: TranslateFunction
): string => {
  if (cadModel?.size?.x && cadModel.size.y && cadModel.size.z) {
    const prefdUnits = preferredUnits2GQL(preferredUnits);
    // Converter returns the size of the model in mm
    const units: UnitType = modelUnits || "MM";
    const f1 = units ? unitsMultiplierMap.get(units) : undefined;
    const f2 = prefdUnits ? unitsMultiplierMap.get(prefdUnits) : undefined;
    const factor = f1 && f2 ? f1 / f2 : 1;
    const X = roundToTwoDecimals(cadModel.size.x * factor);
    const Y = roundToTwoDecimals(cadModel.size.y * factor);
    const Z = roundToTwoDecimals(cadModel.size.z * factor);

    return `${X} x ${Y} x ${Z} ${t("materials.units." + unitsLabelMap.get(prefdUnits ?? units))}`;
  }

  return "";
};

const MATERIAL_UNIT_LABELS: { [key in MATERIAL_UNITS]: { label: string; extraLabel: string } } = {
  [MATERIAL_UNITS.KG]: { label: "materials.units.kilogram", extraLabel: "" },
  [MATERIAL_UNITS.G]: { label: "materials.units.gram", extraLabel: "" },
  [MATERIAL_UNITS.LB]: { label: "materials.units.pounds", extraLabel: "" },
  [MATERIAL_UNITS.IN3]: { label: "materials.units.inch", extraLabel: "\u00B3" },
  [MATERIAL_UNITS.CM3]: { label: "materials.units.centimeters", extraLabel: "\u00B3" },
  [MATERIAL_UNITS.IN]: { label: "materials.units.inch", extraLabel: "" },
  [MATERIAL_UNITS.CM]: { label: "materials.units.centimeters", extraLabel: "" },
  [MATERIAL_UNITS.IN2]: { label: "materials.units.inch", extraLabel: "\u00B2" },
  [MATERIAL_UNITS.CM2]: { label: "materials.units.centimeters", extraLabel: "\u00B2" },
  [MATERIAL_UNITS.OZ]: { label: "materials.units.oz", extraLabel: "" },
  [MATERIAL_UNITS.L]: { label: "materials.units.liters", extraLabel: "" },
  [MATERIAL_UNITS.ML]: { label: "materials.units.mliters", extraLabel: "" },
};

export const getMaterialUnitLabel = (t: TranslateFunction, unit: MATERIAL_UNITS): string => {
  const currentUnit = MATERIAL_UNIT_LABELS[unit];
  return `${t(currentUnit.label)}${currentUnit.extraLabel}`;
};

/**
 * Returns a map of grouped dropdown options under the weight/volume categories
 * @param t Translation function for translating the group headers
 */
export function getMaterialUnitsDropdownItemsMap(
  t: TranslateFunction,
  whichQuantities: MATERIAL_QUANTITY[]
): Map<string, IDropdownOption[]> {
  const map: [string, IDropdownOption[]][] = [];
  const makeOption = (unit: MATERIAL_UNITS) => ({
    key: `${unit}`,
    value: `${unit}`,
    text: getMaterialUnitLabel(t, unit),
  });
  if (whichQuantities.includes(MATERIAL_QUANTITY.WEIGHT)) {
    map.push([
      t("materials.units.weight"),
      [MATERIAL_UNITS.KG, MATERIAL_UNITS.G, MATERIAL_UNITS.LB].map(makeOption),
    ]);
  }
  if (whichQuantities.includes(MATERIAL_QUANTITY.VOLUME)) {
    map.push([
      t("materials.units.volume"),
      [MATERIAL_UNITS.IN3, MATERIAL_UNITS.CM3, MATERIAL_UNITS.ML].map(makeOption),
    ]);
  }
  if (whichQuantities.includes(MATERIAL_QUANTITY.LENGTH)) {
    map.push([t("materials.units.length"), [MATERIAL_UNITS.IN, MATERIAL_UNITS.CM].map(makeOption)]);
  }
  if (whichQuantities.includes(MATERIAL_QUANTITY.AREA)) {
    map.push([t("materials.units.area"), [MATERIAL_UNITS.IN2, MATERIAL_UNITS.CM2].map(makeOption)]);
  }
  return new Map(map);
}

export function getTranslatedItemUnits(t: TranslateFunction, item: IOrderItem): string | undefined {
  if (modelCadFormatIsUnitless(item.cadModel)) {
    return item.units && getTranslatedUnits(t, item.units);
  }

  if (item.cadModel?.unit && item.cadModel?.unit !== "UNDEFINED") {
    return getTranslatedUnits(t, item.cadModel?.unit);
  }

  return " ";
}

export function getTranslatedUnits(
  t: TranslateFunction,
  units: GRAPHQL_MODEL_UNITS | "FT"
): string {
  return t(`materials.units.${unitsLabelMap.get(units)}`);
}

export function getTimeRateDropdownItems(t: TranslateFunction): IDropdownOption[] {
  return [
    {
      key: `${TIME_UNITS.HOURS}`,
      value: `${TIME_UNITS.HOURS}`,
      text: t("order.items.rates.units.hours"),
    },
    {
      key: `${TIME_UNITS.MINUTES}`,
      value: `${TIME_UNITS.MINUTES}`,
      text: t("order.items.rates.units.minutes"),
    },
  ];
}

export interface ITechnologyMateralColorIds {
  shopTechnologyId?: number | null;
  shopMaterialId?: number | null;
  shopMaterialColorId?: number | null;
}
