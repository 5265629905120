import gql from "graphql-tag";
import { IAppTechnology } from "./AppTechnology";
import { IShopMachine } from "./ShopMachine";

export interface IShopTechnology {
  id: number;
  shopMachines: IShopMachine[];
  appTechnology: IAppTechnology;
  __typename?: string;
}

export const ShopTechnologyFragments = {
  shopTechnology: gql`
    fragment shopTechnologyFields on ShopTechnology {
      id
      appTechnology {
        id
        name
        displayName
      }
      __typename
    }
  `,
};
