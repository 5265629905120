import { Mutation } from "@apollo/client/react/components";
import React, { Component, FormEvent } from "react";
import { Button, Form, FormField, Input } from "grabcad-ui-elements";
import { LOGIN } from "@/graphql/Mutations/User";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Notifier } from "@/utils/Notifier";
import { Redirect } from "react-router";

interface IUserLoginFormState {
  email: string;
  password: string;
  redirectToReferrer: boolean;
}

interface ILoginResponse {
  logIn: { jwtToken: string };
}

interface IUserLoginFormProps {
  location: {
    state: {
      from: string;
    };
  };
}

export class UserLoginForm extends Component<Partial<IUserLoginFormProps>, IUserLoginFormState> {
  public static contextType = ApplicationContext;

  // Redefined "context" type to avoid using super.context "any" type.
  // See https://github.com/DefinitelyTyped/DefinitelyTyped/blob/9a213f62efef371439e6cbcd1afb1ae68b90918c/types/react/index.d.ts#L425-L427
  // non-null assert required to avoid complier error:
  //    Property 'context' has no initializer and is not definitely assigned in the constructor.
  public context!: React.ContextType<typeof ApplicationContext>;

  constructor(props: IUserLoginFormProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
    };
  }

  private handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState({ email: value });
  };

  private handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    this.setState({ password: value });
  };

  private handleLogin = (data: ILoginResponse) => {
    if (!data.logIn) {
      return;
    }

    // const { jwtToken } = data.logIn;
    // FIXME GC-55392 Drop all this tech debt
    throw new Error("Unsupported");
    this.context.currentUser.setAsLoggedIn(null as any);

    this.setState({ redirectToReferrer: true });
  };

  public render(): JSX.Element {
    const { email, password, redirectToReferrer } = this.state;
    const { from } = this.props.location?.state || {
      from: { pathname: "/" },
    };

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <Mutation<ILoginResponse>
        mutation={LOGIN}
        onCompleted={data => this.handleLogin(data)}
        onError={error => Notifier.error(error)}
      >
        {login => (
          <Form
            onSubmit={async event => {
              event.preventDefault();

              await login({
                variables: { email, password },
              });
            }}
          >
            <FormField>
              <Input placeholder="Email" value={email} onChange={this.handleEmailChange} />
            </FormField>
            <FormField>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={this.handlePasswordChange}
              />
            </FormField>
            <FormField>
              <Button type="submit" className="login-button" primary disabled={!email || !password}>
                {this.context.t("user.login.form.login")}
              </Button>
            </FormField>
          </Form>
        )}
      </Mutation>
    );
  }
}
