import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import styled, { Loader, StyleMixins } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Notifier } from "@/utils/Notifier";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { IImage } from "@/graphql/Fragments/Image";
import Edit from "../../assets/icons/edit.svg";
import { PopupMenu } from "@/components/Shop/Machines/ContextMenu";
import { useDivDropzone } from "./DropzoneFixup";

export const ImageOverlayContainer = styled.div`
  ${StyleMixins.roundAndShadow}
  position: relative;
  overflow: hidden;
  * {
    display: table-cell;
    vertical-align: middle;
  }
  .popupPos {
    position: absolute;
    &.right {
      right: 0;
      bottom: 40px;
    }
    &.bottom {
      bottom: 0;
      right: 50%;
    }
  }
  .overlay,
  .loading {
    border-radius: 2px;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.5s ease;
    position: absolute;
    text-align: center;
  }
  .overlay {
    background-color: #404040;
    opacity: 0;
    &.clicked {
      border: 2px solid #003393;
    }
    &:hover,
    &.clicked {
      opacity: 1;
      cursor: pointer;
    }
    &.transparent.clicked,
    &.transparent:hover {
      opacity: 0.8;
    }
    img {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .loading {
    background-color: white;
  }
`;

export interface IOverlayEditButtonProps {
  children: JSX.Element;
  onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => void;
  loading: boolean;
  multiple: boolean;
  imageAlreadyPresent: boolean;
  updateImagePresence: (value: boolean) => void;
  id: string | undefined;
  accept: string | undefined;
  image: Partial<IImage>;
  resetImage: () => void;
  menuPosition: string;
  dataTestId?: string;
}

export const OverlayEditButton = ({
  children,
  onDrop,
  loading,
  accept,
  id,
  imageAlreadyPresent,
  updateImagePresence,
  multiple = true,
  image,
  resetImage,
  menuPosition,
  dataTestId = "overlayEditButton",
}: IOverlayEditButtonProps): JSX.Element => {
  const [popupMenu, setPopupMenu] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const popupPosRef = useRef(null);
  const { t } = useContext(ApplicationContext);

  useEffect(() => {
    setPopupMenu(false);
  }, [image]);

  const onDropCallback = useCallback((acceptedFiles, rejectedFiles) => {
    if (onDrop) {
      if (rejectedFiles && rejectedFiles.length > 0) {
        Notifier.error({ errorKey: t("shop.upload.unsupported") });
      }
      onDrop(acceptedFiles, rejectedFiles);
    }
  }, []);

  const { getRootProps, getInputProps } = useDivDropzone({
    multiple,
    accept,
    onDrop: onDropCallback,
    noDrag: true,
    noDragEventsBubbling: true,
  });

  const menuItems = [
    <a key={"image.upload"} className={"item"} id={"qa-OverlayEditButton-upload"}>
      <input {...getInputProps()} />
      {t("image.upload")}
    </a>,
  ];
  if (imageAlreadyPresent) {
    menuItems.push(
      <a
        key={"image.remove"}
        onClick={e => {
          e.stopPropagation(); // Needed to prevent triggering onClick of parent 'getRootProps' div
          setDeleteModal(true);
        }}
        className={"item"}
        id={"qa-OverlayEditButton-remove"}
      >
        {t("image.remove")}
      </a>
    );
  }
  return (
    <ImageOverlayContainer data-testid={dataTestId}>
      <div {...getRootProps({ id })}>
        {children}
        {loading ? (
          <div className={"loading"}>
            <Loader active={loading} size={"large"} />
          </div>
        ) : (
          <div
            className={`overlay ${popupMenu ? "clicked" : ""} ${
              imageAlreadyPresent ? "transparent" : ""
            }`}
            onClick={() => setPopupMenu(true)}
          >
            <img src={Edit} width="30" />
          </div>
        )}

        <div className={`popupPos ${menuPosition}`} ref={popupPosRef} />
        <PopupMenu
          on="click"
          open={popupMenu}
          onClose={() => setPopupMenu(false)}
          className="ui vertical menu small wide"
          position={menuPosition}
          context={popupPosRef}
          content={menuItems.map((menuItem: JSX.Element, i) => (
            <React.Fragment key={i}>{menuItem}</React.Fragment>
          ))}
        />
      </div>
      <ConfirmationModal
        headerIcon="trash"
        headerCopy={t("image.remove.title")}
        bodyCopy={t("image.remove.body")}
        cancelTranslationKey="general.cancel"
        confirmTranslationKey="general.remove"
        open={!!deleteModal}
        onClose={() => setDeleteModal(false)}
        submitAction={() => {
          updateImagePresence(false);
          resetImage();
        }}
      />
    </ImageOverlayContainer>
  );
};
