import { ApolloProvider } from "@apollo/client";
import React from "react";
import "@/App.css";
import { BrowserRouter, Route } from "react-router-dom";
import { GraphQLClient } from "@/GraphQLClient";

import {
  GlobalStyles,
  GrabCADApplicationColors,
  loadTheme,
  Notifier,
  ThemeProvider,
} from "grabcad-ui-elements";
import { i18n } from "@/i18n";
import { ApplicationProvider } from "@/components/ApplicationProvider";
import { Routes } from "@/routes/Routes";
import { UserLoader } from "@/components/UserLoader";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

export const App = (): JSX.Element =>
  i18n(
    <ThemeProvider theme={loadTheme({ applicationSpecific: GrabCADApplicationColors.GC_SHOP })}>
      <Notifier />
      <GlobalStyles />
      <ApplicationProvider>
        {/* DndProvider needs to be outside BrowserRouter
          https://github.com/react-dnd/react-dnd/issues/186#issuecomment-453990887

          We're using TouchBackend to avoid this positional glitch described here:
          https://github.com/react-dnd/react-dnd/issues/2638
          This seems to remain unfixed, and is still reproducable in this demo:
          https://react-dnd.github.io/react-dnd/examples/drag-around/custom-drag-layer */}
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          <BrowserRouter>
            <ApolloProvider client={GraphQLClient}>
              <Route
                render={({ location }) => (
                  // Note: User management logic is split between <ApplicationProvider/> + <UserLoader/>
                  // because we want the getters to be as high up the React tree as possible and
                  // the setters need to be called from inside <ApolloProvider>
                  <UserLoader>
                    <Routes location={location} />
                  </UserLoader>
                )}
              />
            </ApolloProvider>
          </BrowserRouter>
        </DndProvider>
      </ApplicationProvider>
    </ThemeProvider>
  );
