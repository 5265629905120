import React, { useState, useContext } from "react";
import styled, { Popup } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";

const SEARCH_DEFAULT_GREY_COLOR = "#d6d4d4";
const StyledSearchBox = styled.div`
  /* to override !important from semantic ui */
  .searchBox.input > input {
    padding-right: 0 !important;
  }
  .searchBox {
    display: flex;
    flex-direction: row;
    border: 1px solid ${SEARCH_DEFAULT_GREY_COLOR};
    border-radius: 4px;
    margin-right: 15px;
    width: 250px;
    height: 37px;
    .searchBoxInput {
      width: 170px;
      border: none;
      border-radius: 4px 0px 0px 4px;
      outline: none;
    }
    .searchBoxInput:not(:focus) + .searchButton {
      background: #f2f2f2;
      color: ${SEARCH_DEFAULT_GREY_COLOR};
    }
    .searchButton {
      border: none;
      border-radius: 0 3px 3px 0;
      color: #003393;
      background-color: #cce2ff;
      cursor: pointer;
      width: 35px;
      .icon {
        margin: 0;
      }
    }
    .searchButton:disabled {
      background: #f2f2f2;
      color: ${SEARCH_DEFAULT_GREY_COLOR};
      cursor: auto;
    }
    .searchCancel {
      border: none;
      background: none;
      color: ${SEARCH_DEFAULT_GREY_COLOR};
      outline: none;
      cursor: pointer;
      width: 35px;
    }
    .searchCancel:disabled {
      cursor: auto;
    }
  }
`;

export interface ISearchBoxProps {
  searchTerms?: string | null;
  onTermsSearched: (terms: string | null) => void;
  loading: boolean;
}

export const SearchBox = ({
  searchTerms,
  onTermsSearched,
  loading,
}: ISearchBoxProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const [searchString, setSearchString] = useState(searchTerms ? searchTerms : "");
  const [clearButtonVisible, setClearButtonVisible] = useState(false);
  const [popupOpen, setpopupOpen] = useState(false);
  const handleSearch = () => {
    const terms = searchString.trim();
    onTermsSearched(terms);
  };

  const handleTooltipOpen = () => {
    setpopupOpen(!searchString);
  };

  const handleTooltipClose = () => {
    setpopupOpen(false);
  };

  return (
    <StyledSearchBox>
      <div className="searchBox ui right icon input">
        <Popup
          trigger={
            <input
              className="searchBoxInput"
              type="text"
              placeholder={t("order.search.placeholder")}
              value={searchString}
              disabled={loading}
              onClick={handleTooltipClose}
              onChange={event => {
                setSearchString(event.target.value);
                setClearButtonVisible(false);
              }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  handleSearch();
                  setClearButtonVisible(false);
                }
              }}
            />
          }
          content={t("order.search")}
          position="bottom left"
          inverted
          flowing
          on="hover"
          open={popupOpen && !searchString}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
        />
        {clearButtonVisible || !!searchString ? (
          <button
            type="submit"
            className="searchCancel"
            disabled={loading}
            onClick={() => {
              if (!loading) {
                setSearchString("");
                onTermsSearched(null);
                setClearButtonVisible(false);
              }
            }}
          >
            <i className="close icon" />
          </button>
        ) : null}
        <button
          type="submit"
          className="searchButton"
          disabled={loading || !searchString}
          onClick={() => {
            handleSearch();
            setClearButtonVisible(!!searchString);
          }}
        >
          <i className="search icon" />
        </button>
      </div>
    </StyledSearchBox>
  );
};
