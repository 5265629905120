import { Query } from "@apollo/client/react/components";
import React, { useContext } from "react";
import styled, { Dropdown, Header } from "grabcad-ui-elements";
import { PROFILE } from "@/graphql/Queries";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Notifier } from "@/utils/Notifier";
import { COMPANY_SERVER_AUTH_ENABLED } from "@/GraphQLClient";
import { IProfileResponse } from "@/graphql/Fragments/User";

const ProfileDropdownHolder = styled.div`
  padding: 1rem 1rem 0 1rem;

  .role-display {
    margin-top: 2px;
  }

  .ui.dropdown .menu {
    width: 100%;
    margin-top: 5px;
  }
`;

const ProfileDropdown = styled(Dropdown)`
  i.down.icon {
    padding: 0 10px;
    position: relative;
    top: 1px;
  }

  h2 {
    margin: 0 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
  }
`;
ProfileDropdown.displayName = "ProfileDropdown";

export const UserLoginMenu = (): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const {
    currentUser: { isLoggedIn },
  } = useContext(ApplicationContext);
  if (!COMPANY_SERVER_AUTH_ENABLED && !isLoggedIn) {
    return null;
  }
  return (
    <Query<IProfileResponse> query={PROFILE} onError={error => Notifier.error(error)}>
      {({ loading, data }) => {
        if (loading || !data || !data.userProfile) {
          return <p>{t("global.loading")}</p>;
        }
        const { company } = data.userProfile;
        return (
          <ProfileDropdownHolder>
            <Header as="h2">{company.name || "GrabCAD"}</Header>
          </ProfileDropdownHolder>
        );
      }}
    </Query>
  );
};
UserLoginMenu.displayName = "UserLoginMenu";
