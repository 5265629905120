import { CadUploadDropzone } from "@/components/Upload/CadUploadDropzone";
import {
  ICadFile,
  IGenericFile,
  GET_UPLOAD_URL,
  IGetUploadUrlParams,
  IGetUploadUrlResponse,
  IFileMeta,
  UPLOAD_SUCCESS,
  IUploadSuccessResponse,
} from "@/graphql/Mutations/Upload";
import React, { useContext } from "react";
import { Notifier } from "@/utils/Notifier";
import * as uuid from "uuid";
import { IImage } from "@/graphql/Fragments/Image";
import { ApolloClient, MutationFunction, ApolloConsumer } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import axios from "axios";
import { GRAPHQL_MODEL_UNITS, preferredUnits2GQL } from "@/utils/DropdownUtils";
import { IShop } from "@/graphql/Fragments/Shop";
import { ApplicationContext } from "../ApplicationProvider";
import { MAX_UPLOAD_FILE_SIZE } from "@/shopConstants";

interface ICadDropZoneProps {
  onFileAction: (cadFile: IUploadContainer) => void;
  openFileDialogCounter: number;
  uploadedFiles: IUploadContainer[];
  showCTA?: boolean;
  shop: IShop | null;
  isOrderDependencyUpload?: boolean;
}

export interface IUploadContainer {
  key: string;
  file: File;
  cadFile: ICadFile | IImage | IGenericFile | undefined;
  uploaded: boolean;
  error: boolean;
  progress: number;
  loaded: number;
  total: number;
  cancel: () => void;
  cancelled: boolean;
  quantity: number;
  unit: GRAPHQL_MODEL_UNITS | undefined;
}

const getDefaultContainer = (file: File, shop: IShop | null): IUploadContainer => ({
  file,
  key: uuid.v4(),
  cadFile: undefined,
  uploaded: false,
  error: false,
  progress: 0,
  loaded: 0,
  total: 0,
  cancel: () => undefined,
  cancelled: false,
  quantity: 1,
  unit: preferredUnits2GQL(shop?.preferredUnits),
});

export const CadDropZone = ({
  onFileAction,
  openFileDialogCounter,
  uploadedFiles,
  showCTA,
  shop,
  isOrderDependencyUpload,
}: ICadDropZoneProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const handleDrop = async (
    client: ApolloClient<any>,
    mutation: MutationFunction<IUploadSuccessResponse, { keys: IFileMeta[]; shopId?: number }>,
    files: File[]
  ) => {
    if (files && files.length > 0) {
      const promises: Promise<IUploadContainer>[] = [];
      for (const file of files) {
        if (file.size > MAX_UPLOAD_FILE_SIZE * 1024 * 1024) {
          Notifier.error(
            {
              errorKey: t("order.new.fileSizeError", {
                fileName: file.name,
                MAX_SIZE: MAX_UPLOAD_FILE_SIZE,
              }),
            },
            { autoClose: false }
          );
        } else {
          const container = getDefaultContainer(file, shop);
          onFileAction(container);
          promises.push(
            new Promise<IUploadContainer>(async (res, rej) => {
              const uploadUrlData = await getUploadUrl(client, {
                fileName: file.name,
                fileSize: file.size,
              });
              if (uploadUrlData) {
                const {
                  url,
                  fields: {
                    XAmzDate,
                    XAmzAlgorithm,
                    XAmzCredential,
                    XAmzSignature,
                    bucket,
                    key,
                    Policy,
                  },
                } = uploadUrlData;
                const form = new FormData();
                form.append("X-Amz-Date", XAmzDate);
                form.append("X-Amz-Algorithm", XAmzAlgorithm);
                form.append("X-Amz-Credential", XAmzCredential);
                form.append("X-Amz-Signature", XAmzSignature);
                form.append("bucket", bucket);
                form.append("key", key);
                form.append("acl", "private");
                form.append("Policy", Policy);
                form.append("file", file);

                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                container.cancel = () => {
                  container.cancelled = true;
                  container.uploaded = true;
                  source.cancel("Upload Cancelled");
                  onFileAction(container);
                };

                try {
                  await axios.post(url, form, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (evt: Event & { loaded: number; total: number }) => {
                      const progress = Math.round((evt.loaded * 100) / evt.total);
                      if (progress % 10 === 0) {
                        container.progress = progress;
                        container.loaded = evt.loaded;
                        container.total = evt.total;
                        onFileAction(container);
                      }
                    },
                    cancelToken: source.token,
                  });
                  const successResp = await mutation({
                    variables: {
                      shopId: shop?.id,
                      keys: [
                        {
                          key,
                          filename: file.name,
                        },
                      ],
                    },
                  });
                  if (successResp?.data) {
                    container.cadFile = successResp.data.uploadSuccess[0];
                    container.uploaded = true;
                    onFileAction(container);
                    res(container);
                  }
                } catch (e) {
                  container.error = true;
                  Notifier.error({ errorKey: "could not upload file" });
                  onFileAction(container);
                  rej(container);
                }
              }
            })
          );
        }
      }
      await Promise.all(promises);
    }
  };

  return (
    <ApolloConsumer>
      {client => (
        <Mutation<IUploadSuccessResponse, { keys: IFileMeta[]; shopId?: number }>
          mutation={UPLOAD_SUCCESS}
          onError={error => Notifier.error(error)}
        >
          {uploadedFile => (
            <CadUploadDropzone
              onDrop={(acceptedFiles: File[]) => handleDrop(client, uploadedFile, acceptedFiles)}
              multiple={true}
              openFileDialogCounter={openFileDialogCounter}
              files={uploadedFiles}
              showCTA={showCTA}
              isOrderDependencyUpload={isOrderDependencyUpload}
              data-testid="cadUploadDropdown"
            />
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
};

const getUploadUrl = async (
  client: ApolloClient<any>,
  { fileName, fileSize }: IGetUploadUrlParams
) => {
  const resp = await client.mutate<{ getUploadUrl: IGetUploadUrlResponse }, IGetUploadUrlParams>({
    mutation: GET_UPLOAD_URL,
    variables: {
      fileName,
      fileSize,
    },
  });
  return resp.data ? resp.data.getUploadUrl : null;
};
