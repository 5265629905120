import { Preference } from "@grabcad/preferences-client";
import gql from "graphql-tag";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      jwtToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logOut
  }
`;

// NB: Keep in sync with src/graphql/Fragments/User.ts : IGetLogoutUrlResult
export const GET_LOGOUT_URL = gql`
  query GetLogoutUrl {
    getLogoutUrl
  }
`;

// NB: This is only used for ORDERS_COUNT_PER_PAGE_PREF_KEY & COMPLETED_ONBOARDING
// These are inexplicbaly stored in redis, unlike other [Shop] "preferences" which are stored in the database
// Lets consider this tech debt, and not add more [User] preferences to redis!
export const UPDATE_PREFERENCE = gql`
  mutation updatePreference($key: String!, $value: [String]!) {
    updatePreference(key: $key, value: $value) {
      key
      value
    }
  }
`;

export interface UpdatePreferenceResult {
  updatePreference: Preference;
}
