import styled, { Button, Icon, Checkbox, Loader, Message } from "grabcad-ui-elements";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ShopMaterialPicker } from "@/components/Order/Form/ShopMaterialPicker";
import {
  SUPPORTLESS_TECHNOLOGIES,
  ROUTES,
  getMachineTechnologyDefaultMaterialUnit,
  getMachineTechnologyDefaultSupportUnit,
  DEFAULT_TIME_UNIT,
  TECHNOLOGY,
} from "@/shopConstants";
import { Permission } from "@/utils/Permission";
import {
  transformMinutesToDisplayUnits,
  getEstimatedMaterialValue,
  getEstimatedSupportMaterialValue,
  getEstimatedMaterialFieldName,
  transformEstimatedMaterialToDbUnits,
  timeUnitsToMinutes,
  getEstimatedSupportMaterialFieldName,
  previewOrderItemPrice,
  FRACTIONAL_UNITS,
  getEstimatedMaterialMax,
} from "@/components/Order/ItemsList/shopRatesUtils";

import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import { ShopMachinePickerByMaterial } from "@/components/Order/Form/ShopMachinePickerByMaterial";
import { GRAPHQL_MODEL_UNITS, getMaterialUnitLabel } from "@/utils/DropdownUtils";
import { Link } from "react-router-dom";
import {
  useCacheableMachineRate,
  useCacheableMaterialRate,
  useShopTechnologies,
} from "@/utils/queryHooks";
import { getCadModelName, isGcpAsset } from "@/utils/GeneralUtils";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import { isEqual } from "lodash";

export const StyledLabeledInput = styled.div`
  display: flex;
  .ui.input {
    height: 30px;
    > input {
      text-align: right;
      height: 30px;
      border-radius: 4px 0 0 4px !important;
      flex: auto 1 1;
      min-width: 0;
    }
  }
  &[data-label]:after {
    display: flex;
    content: attr(data-label);
    border: 1px solid #d6d4d4;
    color: #000;
    border-left: none;
    flex: 0 0 30px;
    text-align: center;
    border-radius: 0 4px 4px 0 !important;
    align-items: center;
    justify-content: center;
  }
`;

const StyledRatesTable = styled.div`
  .header-row,
  .row,
  .dsc,
  .estimate,
  .price {
    display: flex;
    align-items: center;
  }
  .header-row {
    .material-machine,
    .line-items {
      font-size: 1em;
    }
    padding: 0 20px;
    height: 46px;
    font-weight: 700;
    background: #f9fafb;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
  .col-group {
    display: flex;
    margin: 0 20px;
    align-items: start;
    .material-machine {
      padding-right: 20px;
      .readonly {
        margin: 15px 0;
        display: block;
      }
    }
  }
  .row {
    > * {
      flex: 1 1 50%;
    }
    label {
      font-weight: bold;
      padding: 6px 10px 6px 0;
      &[for] {
        cursor: pointer;
      }
    }
  }
  .underline {
    border-bottom: 1px solid #cccccc;
  }
  .material-machine,
  .line-items {
    flex: 1 1 45%;
    font-size: 12px;
    .row {
      height: 30px;
      margin: 10px 0;
      &.underline {
        height: 50px;
        margin: 0;
      }
    }
  }
  .line-items {
    flex: 1 1 55%;
    .dsc {
      flex: 0 0 70%;
      .estimate {
        flex: 0 0 67%;
        justify-content: space-around;
        label {
          flex: 1 0 45px;
        }
        .labeled-input {
          max-width: 121px;
          margin: 0 20px 0 10px;
          flex: 0 1 121px;
          justify-content: flex-end;
        }
        .multiply {
          flex: 0 0 auto;
        }
      }
      .rate {
        flex: 0 0 33%;
        text-align: center;
      }
    }
    .price {
      flex: 0 0 30%;
      justify-content: flex-end;
      .operator {
        flex: 0 0 20px;
        text-align: center;
        padding-right: 10px;
      }
      .value {
        flex: 1 1 100%;
        text-align: right;
      }
    }
  }
  .material-picker,
  .machine-picker {
    button {
      margin-left: 10px !important;
    }
  }
  .ui.input {
    width: auto;
    > input {
      text-align: right;
    }
  }
  .total-price {
    font-weight: bold;
    font-size: 24px;
    color: #003393;
  }
  .using-saved-rates {
    color: #a1a1a1;
  }
  .previously-quoted {
    font-weight: bold;
    color: #ccc;
    font-size: 20px;
    margin-left: 10px;
  }
  .part-name {
    font-size: 14px;
    font-weight: 700;
    margin: 16px 0;
    word-break: break-word;
  }
  .thumbnail-holder {
    width: 155px;
    padding: 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    margin-bottom: 10px;
  }
`;
export const StyledModalMessage = styled(Message)`
  &.ui.message {
    display: flex;
    color: #333333 !important;
  }
  img {
    margin-right: 10px;
  }
`;

export const getOrderItemData = (orderItem: IOrderItem, unsavedItem: IOrderItem) => {
  const { shopTechnologies, allMachines, allMaterials, allMaterialColors } = useShopTechnologies();

  const currentMaterial = allMaterials.find(material => material.id === unsavedItem.shopMaterialId);
  const currentMachine = allMachines.find(machine => machine.id === currentMaterial?.shopMachineId);
  let technologyName: TECHNOLOGY | undefined;
  let currentTechnology;
  if (isGcpAsset(orderItem.cadModel)) {
    if (orderItem.autoEstimations) {
      const techName = orderItem.autoEstimations[0]?.resultData?.printerTechnology;
      technologyName =
        techName === TECHNOLOGY.POLYJET
          ? TECHNOLOGY.POLYJET
          : (techName?.toUpperCase() as TECHNOLOGY);
    }
  } else {
    // This should be the single source of truth for selected shopTechnology, which can be
    // changed by selecting autoEstimates, and should be derived primarily from stateful
    // currentMachine, and use the item.shopTechnologyId as a fallback if no material/machine selected:
    currentTechnology =
      shopTechnologies.find(tech => tech.id === currentMachine?.shopTechnologyId) ||
      shopTechnologies.find(tech => tech.id === orderItem.shopTechnologyId);
    technologyName = currentTechnology?.appTechnology.name;
  }
  const { machineRate: currentMachineRates } = useCacheableMachineRate(
    unsavedItem.machineRateId || currentMachine?.newestMachineRateId
  );
  const { materialRate: currentMaterialRates } = useCacheableMaterialRate(
    unsavedItem.materialRateId || currentMaterial?.newestMaterialRateId
  );

  const {
    machineTimeUnit = DEFAULT_TIME_UNIT,
    materialUnit = getMachineTechnologyDefaultMaterialUnit(
      technologyName,
      currentMachine?.appMachineType.displayName
    ),
    machineTimeRate,
    machineBaseRate,
  } = currentMachineRates || {};
  const supportUnit =
    currentMachineRates?.supportUnit ||
    currentMachineRates?.materialUnit ||
    getMachineTechnologyDefaultSupportUnit(
      technologyName,
      currentMachine?.appMachineType.displayName
    );

  const estimatedMaterialFieldName = getEstimatedMaterialFieldName(materialUnit);
  const estimatedSupportMaterialFieldName = getEstimatedSupportMaterialFieldName(supportUnit);

  const itemShopMaterialColor = allMaterialColors.find(
    color => color.id === orderItem.shopMaterialColorId
  );
  const requestedColorName = itemShopMaterialColor?.appMaterialColor.name;
  const newMaterialColor = currentMaterial?.shopMaterialColors.find(
    color => color.appMaterialColor.name === requestedColorName && color.dateDeleted === null
  );

  return {
    allMaterials,
    allMachines,
    currentMaterial,
    currentMachine,
    currentTechnology,
    currentMachineRates,
    currentMaterialRates,
    machineTimeUnit,
    materialUnit,
    machineTimeRate,
    machineBaseRate,
    supportUnit,
    estimatedMaterialFieldName,
    estimatedSupportMaterialFieldName,
    itemShopMaterialColor,
    requestedColorName,
    newMaterialColor,
  };
};

export const warningBanner = (msg: string) => {
  return (
    <StyledModalMessage warning>
      <Icon color="orange" name="warning sign" />
      {msg}
    </StyledModalMessage>
  );
};

export const PriceCalculator = ({
  orderItem,
  onOrderItemUpdate,
  setMaterial,
}: {
  orderItem: IOrderItem;
  onOrderItemUpdate?: (orderItem: IOrderItem) => void;
  setMaterial: (params: {
    shopMaterialId?: number | null;
    units?: GRAPHQL_MODEL_UNITS;
    setState: Dispatch<SetStateAction<IOrderItem>>;
  }) => void;
}): JSX.Element | null => {
  const { t, currentShop, formatPrice, formatDate, ability } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  const [unsavedItem, setUnsavedItem] = useState<IOrderItem>({ ...orderItem }); // All savable local state should be stored here

  // Update local state when orderItem prop changes
  // Needed for when an in progress auto estimation completes
  useEffect(() => {
    if (!isEqual(orderItem, unsavedItem)) {
      setUnsavedItem({ ...orderItem });
    }
  }, [orderItem]);

  // A separate useEffect is needed for unsavedItem to prevent infinite loops
  // When unsavedItem changes it sends the callback to the parent
  // so the component can stay up to date between switching tab re-renders.
  useEffect(() => {
    onOrderItemUpdate?.(unsavedItem);
  }, [unsavedItem]);

  const {
    currentMaterial,
    currentMachine,
    currentTechnology,
    currentMachineRates,
    currentMaterialRates,
    machineTimeUnit,
    materialUnit,
    machineTimeRate,
    machineBaseRate,
    supportUnit,
    estimatedMaterialFieldName,
    estimatedSupportMaterialFieldName,
    itemShopMaterialColor,
    requestedColorName,
    newMaterialColor,
  } = getOrderItemData(orderItem, unsavedItem);

  // Estimated quantities from item for display & price preview
  const estimatedMaterial = getEstimatedMaterialValue(unsavedItem, materialUnit);
  const estimatedSupport = getEstimatedSupportMaterialValue(unsavedItem, supportUnit);
  const estimatedTime = unsavedItem.estimatedMinutes
    ? transformMinutesToDisplayUnits(unsavedItem.estimatedMinutes, machineTimeUnit)
    : 0;

  const { materialRate, supportRate } = currentMaterialRates || {};

  const markUp = (unsavedItem.markupPrice || 0) / FRACTIONAL_UNITS;
  const extraCost = unsavedItem.extraCost || 0;

  const newPrice =
    unsavedItem.overridePrice !== null
      ? unsavedItem.overridePrice || 0
      : previewOrderItemPrice({
          estimatedMaterial,
          estimatedSupport,
          estimatedTime,
          markUp, // parsed whole %
          extraCost, // cents
          materialRate: materialRate || undefined,
          supportRate: supportRate || undefined,
          machineTimeRate: machineTimeRate || undefined,
          machineBaseRate: machineBaseRate || undefined,
        });
  const oldPrice = orderItem.overridePrice !== null ? orderItem.overridePrice : orderItem.price;

  const showSupportRates =
    !currentTechnology ||
    (currentTechnology && !SUPPORTLESS_TECHNOLOGIES.includes(currentTechnology.appTechnology.name));

  const machineRateIsOld =
    !!currentMachineRates &&
    !!unsavedItem.machineRateId &&
    !!currentMachine?.newestMachineRateId &&
    unsavedItem.machineRateId !== currentMachine?.newestMachineRateId;

  const materialRateIsOld =
    !!currentMaterialRates &&
    !!unsavedItem.materialRateId &&
    !!currentMaterial?.newestMaterialRateId &&
    unsavedItem.materialRateId !== currentMaterial?.newestMaterialRateId;

  const partName = getCadModelName(orderItem);

  const showTimeUnitsLabel = !!currentMachine || isGcpAsset(orderItem.cadModel);

  const noRatesWarning = (content: string) => {
    const canWriteRates = ability.can(Permission.WRITE_MACHINES, currentShop);
    const machineEditLink =
      currentMaterial &&
      ROUTES.SHOP(currentShop.id).MACHINES.MACHINE(
        currentTechnology?.id,
        currentMaterial.shopMachineId
      );
    const icon = (
      <span data-tooltip={content} data-position="left center">
        <Icon name="warning sign" size="small" color="red" className="qa-no-rates-warning" />
      </span>
    );
    return machineEditLink && canWriteRates ? <Link to={machineEditLink}>{icon}</Link> : icon;
  };

  const writeable = ability.can(Permission.WRITE, orderItem, "price");
  const autoEstimation = orderItem.autoEstimations?.find(
    ae => ae.id === orderItem.autoEstimationId
  );
  const showLoaders = orderItem.autoEstimationId && !autoEstimation?.resultData;

  const isGcpItem = isGcpAsset(orderItem.cadModel);

  return (
    <StyledRatesTable>
      <div className="header-row">
        <div className="material-machine qa-material-machine">
          {t("order.items.rates.popup.header.materialMachine")}
        </div>
        <div className="line-items">
          <div className="row">
            <div className="dsc">
              <div className="estimate">{t("order.items.rates.popup.header.estimate")}</div>
              <div className="rate">{t("order.items.rates.popup.header.rate")}</div>
            </div>
            <div className="price">{t("order.items.rates.popup.header.price")}</div>
          </div>
        </div>
      </div>

      {/* Material Rates & Estimates */}

      <div className="col-group underline">
        <div className="material-machine material-picker">
          {writeable ? (
            <>
              <div className="row">
                {isGcpItem ? (
                  warningBanner(t("order.items.rates.popup.info.gcp_file"))
                ) : (
                  <ShopMaterialPicker
                    shopTechnologyId={currentTechnology?.id}
                    shopMaterialId={unsavedItem.shopMaterialId}
                    onChange={({ shopMaterialId }) =>
                      setMaterial({ shopMaterialId, setState: setUnsavedItem })
                    }
                    disabled={!!orderItem.jobId}
                    data-tooltip={!!orderItem.jobId ? t("order.items.job.tooltip.material") : null}
                    data-position="right center"
                  />
                )}
                {materialRateIsOld && !machineRateIsOld ? (
                  // Only show "Update Material Rates" Button if machine rates are up-to-date since
                  // updating machine rates will update both, and there's no need for 2 buttons.
                  <Button
                    className="mini warning qa-material-rate-old-button"
                    onClick={() => {
                      setUnsavedItem({
                        ...unsavedItem,
                        materialRateId: currentMaterial?.newestMaterialRateId || null,
                      });
                    }}
                  >
                    {t("order.items.rates.popup.updateMaterialRates")}
                  </Button>
                ) : null}
              </div>
              {materialRateIsOld && (
                <p className="using-saved-rates qa-material-rate-old-dsc">
                  {t("order.items.rates.popup.usingSavedMaterialRates", {
                    date: formatDate(currentMaterialRates?.dateCreated),
                  })}
                </p>
              )}
            </>
          ) : (
            <b className="readonly qa-orderItemRate-materialDisplay">
              {currentMaterial?.appMaterial.displayName || "N/A"}
            </b>
          )}
        </div>
        <div className="line-items">
          <div className="row material" data-testid="materialRow">
            <div className="dsc">
              <div className="estimate qa-material-estimate">
                <label>{t("order.items.rates.popup.table.material")}</label>
                {showLoaders && <Loader inline active size="tiny"></Loader>}
                <StyledLabeledInput
                  className="labeled-input"
                  data-label={getMaterialUnitLabel(t, materialUnit)}
                >
                  <LocalizedNumericInput
                    min={0}
                    max={getEstimatedMaterialMax(materialUnit)}
                    placeholder={t("order.items.rates.popup.table.material")}
                    disabled={!writeable || unsavedItem.overridePrice !== null}
                    value={estimatedMaterial}
                    onChange={value =>
                      setUnsavedItem({
                        ...unsavedItem,
                        [estimatedMaterialFieldName]: transformEstimatedMaterialToDbUnits({
                          itemEstimatedValue: value,
                          fromUnit: materialUnit,
                        }),
                        autoEstimationId: null,
                      })
                    }
                  />
                </StyledLabeledInput>
                <div className="multiply">{"✕"}</div>
              </div>
              <div className="rate qa-material-rate" data-testid="materialRate">
                {materialRate != null // catches null & undefined, but not 0
                  ? formatPrice(materialRate)
                  : noRatesWarning(t("order.items.rates.popup.material.noRates"))}
              </div>
            </div>
            <div className="price">
              <div className="operator">{"="}</div>
              <div className="value">
                {formatPrice((estimatedMaterial || 0) * (materialRate || 0))}
              </div>
            </div>
          </div>

          {showSupportRates && (
            <div className="row support qa-support-row" data-testid="supportRow">
              <div className="dsc">
                <div className="estimate qa-support-estimate">
                  <label>{t("order.items.rates.popup.table.support")}</label>
                  {showLoaders && <Loader inline active size="tiny"></Loader>}
                  <StyledLabeledInput
                    className="labeled-input"
                    data-label={getMaterialUnitLabel(t, supportUnit)}
                  >
                    <LocalizedNumericInput
                      min={0}
                      max={getEstimatedMaterialMax(supportUnit)}
                      placeholder={t("order.items.rates.popup.table.support")}
                      disabled={!writeable || unsavedItem.overridePrice !== null}
                      value={estimatedSupport}
                      onChange={value =>
                        setUnsavedItem({
                          ...unsavedItem,
                          [estimatedSupportMaterialFieldName]: transformEstimatedMaterialToDbUnits({
                            itemEstimatedValue: value,
                            fromUnit: supportUnit,
                          }),
                          autoEstimationId: null,
                        })
                      }
                    />
                  </StyledLabeledInput>
                  <div className="multiply">{"✕"}</div>
                </div>
                <div className="rate qa-material-support-rate" data-testid="materialSupportRate">
                  {supportRate != null // catches null & undefined, but not 0
                    ? formatPrice(supportRate)
                    : noRatesWarning(t("order.items.rates.popup.material.noRates"))}
                </div>
              </div>
              <div className="price">
                <div className="operator">{"="}</div>
                <div className="value">
                  {formatPrice((estimatedSupport || 0) * (supportRate || 0))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Machine Rates & Estimates */}

      <div className="col-group underline">
        <div className="material-machine machine-picker">
          {writeable ? (
            <>
              <div className="row">
                {isGcpItem ? null : (
                  <ShopMachinePickerByMaterial
                    shopTechnologyId={currentTechnology?.id}
                    shopMaterialId={unsavedItem.shopMaterialId}
                    currentMachine={currentMachine?.id}
                    onMaterialSelected={shopMaterialId =>
                      setMaterial({ shopMaterialId, setState: setUnsavedItem })
                    }
                    disabled={!!orderItem.jobId}
                    data-tooltip={!!orderItem.jobId ? t("order.items.job.tooltip.machine") : null}
                    data-position="right center"
                  />
                )}
                {machineRateIsOld && (
                  <Button
                    className="mini warning qa-machine-rate-old-button"
                    onClick={() => {
                      // Material Rates are updated whenever Machine Rates are, on the off-chance that the
                      // Machine's material/support unit has been changed. It is unlikely that a user would ever
                      // want to use a new Machine Rate, but retain an old Material Rate, so we don't support this.
                      setUnsavedItem({
                        ...unsavedItem,
                        machineRateId: currentMachine?.newestMachineRateId || null,
                        materialRateId: currentMaterial?.newestMaterialRateId || null,
                      });
                    }}
                  >
                    {t("order.items.rates.popup.updateMachineRates")}
                  </Button>
                )}
              </div>
              {machineRateIsOld && (
                <p className="using-saved-rates qa-machine-rate-old-dsc">
                  {t("order.items.rates.popup.usingSavedMachineRates", {
                    date: formatDate(currentMachineRates?.dateCreated),
                  })}
                </p>
              )}
              {itemShopMaterialColor && !newMaterialColor && (
                <p
                  style={{ margin: "10px 0" }}
                  className="qa-noColorMatch"
                  data-testid="noColorMatch"
                >
                  <Icon name="warning sign" size="small" color="red" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("order.items.rates.popup.material.noColorMatch", {
                        colorName: requestedColorName,
                      }),
                    }}
                  />
                </p>
              )}
            </>
          ) : (
            <b className="readonly qa-orderItemRate-technologyDisplay">
              {currentTechnology?.appTechnology.displayName ?? "N/A"}
            </b>
          )}
        </div>

        <div className="line-items">
          <div className="row time" data-testid="timeRow">
            <div className="dsc">
              <div className="estimate qa-time-estimate">
                <label>{t("order.items.rates.popup.table.time")}</label>
                {showLoaders && <Loader inline active size="tiny"></Loader>}
                <StyledLabeledInput
                  className="labeled-input"
                  data-label={
                    showTimeUnitsLabel &&
                    t(`order.items.rates.units.${machineTimeUnit.toLowerCase()}`)
                  }
                >
                  <LocalizedNumericInput
                    min={0}
                    max={transformMinutesToDisplayUnits(Number.MAX_SAFE_INTEGER, machineTimeUnit)}
                    placeholder={t("order.items.rates.popup.table.time")}
                    disabled={!writeable || unsavedItem.overridePrice !== null}
                    value={estimatedTime}
                    onChange={value =>
                      setUnsavedItem({
                        ...unsavedItem,
                        estimatedMinutes: timeUnitsToMinutes(value, machineTimeUnit),
                        autoEstimationId: null,
                      })
                    }
                  />
                </StyledLabeledInput>
                <div className="multiply">{"✕"}</div>
              </div>
              <div className="rate qa-machine-time-rate" data-testid="machineTimeRate">
                {machineTimeRate != null // catches null & undefined, but not 0
                  ? formatPrice(machineTimeRate)
                  : noRatesWarning(t("order.items.rates.popup.machine.noRates"))}
              </div>
            </div>
            <div className="price">
              <div className="operator">{"="}</div>
              <div className="value">{formatPrice(estimatedTime * (machineTimeRate || 0))}</div>
            </div>
          </div>
          <div className="row base-rate" data-testid="baseRateRow">
            <div className="dsc">
              <label>{t("order.items.rates.popup.baseRate")}</label>
            </div>
            <div className="price">
              <div className="operator">{"+"}</div>
              <div className="value qa-machine-base-rate">
                {machineBaseRate != null // catches null & undefined, but not 0
                  ? formatPrice(machineBaseRate)
                  : noRatesWarning(t("order.items.rates.popup.machine.noRates"))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OrderItem Extras, Override, Totals */}

      <div className="col-group">
        <div className="material-machine">
          <div className="part-name">{partName}</div>
          <div className="thumbnail-holder">
            <CadModelPreview itemId={orderItem.id} size="small" />
          </div>
        </div>

        <div className="line-items">
          <div className="row underline" data-testid="markupRow">
            <div className="dsc">
              <label>{t("order.items.rates.popup.markUp")}</label>
            </div>
            <div className="price">
              <div className="operator">{"✕"}</div>
              <div className="value qa-markup">
                {writeable ? (
                  <LocalizedNumericInput
                    postfix="%"
                    placeholder="0%"
                    min={-99.99}
                    max={9999}
                    value={markUp}
                    disabled={unsavedItem.overridePrice !== null}
                    onChange={value =>
                      setUnsavedItem({
                        ...unsavedItem,
                        markupPrice:
                          value !== undefined ? Math.round(value * FRACTIONAL_UNITS) : null,
                      })
                    }
                  />
                ) : (
                  markUp
                )}
              </div>
            </div>
          </div>
          <div className="row underline" data-testid="extraCostRow">
            <div className="dsc">
              <label> {t("order.items.rates.popup.extraCost")}</label>
            </div>
            <div className="price">
              <div className="operator">{"+"}</div>
              <div className="value qa-extra-cost">
                {writeable ? (
                  <LocalizedNumericInput
                    disabled={unsavedItem.overridePrice !== null}
                    min={Number.MIN_SAFE_INTEGER}
                    max={Number.MAX_SAFE_INTEGER}
                    currency // dollars to cents conversion happens internally
                    value={extraCost}
                    onChange={value =>
                      setUnsavedItem({
                        ...unsavedItem,
                        extraCost: value !== undefined ? value : null,
                      })
                    }
                  />
                ) : (
                  formatPrice(unsavedItem.extraCost || 0)
                )}
              </div>
            </div>
          </div>
          <div className="row underline override-price" data-testid="overridePriceRow">
            <div className="dsc">
              <label htmlFor="override_price_checkbox">
                {t("order.items.rates.popup.override")}
              </label>
              <Checkbox
                id="override_price_checkbox"
                checked={unsavedItem.overridePrice !== null}
                className="qa-orderItemRate-override"
                onClick={() => {
                  if (writeable) {
                    setUnsavedItem({
                      ...unsavedItem,
                      overridePrice: unsavedItem.overridePrice !== null ? null : 0, // overridePrice defaults to 0
                    });
                  }
                }}
                disabled={!writeable}
              />
            </div>
            <div className="price">
              <div className="operator">{"="}</div>
              <div className="value qa-override-price">
                {writeable ? (
                  <LocalizedNumericInput
                    min={0}
                    max={Number.MAX_SAFE_INTEGER}
                    disabled={unsavedItem.overridePrice === null}
                    currency // dollars to cents conversion happens internally
                    value={unsavedItem.overridePrice || 0}
                    onChange={value =>
                      setUnsavedItem({
                        ...unsavedItem,
                        overridePrice: value !== undefined ? value : 0, // cleared overridePrice resets to 0
                      })
                    }
                  />
                ) : (
                  formatPrice(unsavedItem.overridePrice || 0)
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="dsc">
              {oldPrice && oldPrice !== newPrice ? (
                <>
                  <label>{t("order.items.rates.popup.previouslyQuoted")}</label>
                  <div className="previously-quoted" data-testid="previouslyQuoted">
                    {formatPrice(oldPrice)}
                  </div>
                </>
              ) : (
                <label>{t("order.items.rates.popup.total")}</label>
              )}
            </div>
            <div className="price">
              <div className="value total-price" data-testid="totalPrice">
                {formatPrice(newPrice)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledRatesTable>
  );
};
