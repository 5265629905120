import React, { useContext } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { SelectionDropdown } from "grabcad-ui-elements";
import classnames from "classnames";
import { ITechnologyMateralColorIds } from "@/utils/DropdownUtils";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { IShopMaterialColor } from "@/components/Shop/Materials/Form/MaterialsForm";
import _ from "lodash";
import { useShopTechnologyById } from "@/utils/queryHooks";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import colorIcon from "../../../assets/icons/materialColor.svg";
import { getIcon } from "../ItemsList/CadItemDetails";

export const getDedupedColorsForMaterialByName = (
  shopTechnology?: IShopTechnology,
  shopMaterialId?: number, // This could have dateDeleted
  shopMaterialColorId?: number // This could have dateDeleted
): {
  dedupedColors: IShopMaterialColor[];
  selectedShopMaterialColor?: IShopMaterialColor;
  materialIdsByColorId: { [id: number]: number };
} => {
  // Clone & shuffle machines array so that the machine the selected color belongs to is first.
  // This prioritizes colors on the same machine prior to deduplication, ensuring material/machine is
  // only changed when necessary, thus preserving any baked rates if possible.
  const machines = shopTechnology ? [...shopTechnology.shopMachines] : [];
  const colorMachineIndex = machines.findIndex(machine =>
    machine.materials.find(material => material.id === shopMaterialId)
  );
  const colorMachine = machines.splice(colorMachineIndex, 1)[0];
  colorMachine && machines.unshift(colorMachine);

  const allMaterials: IShopMaterial[] = machines.reduce(
    (acc, machine) => acc.concat(machine.materials),
    [] as IShopMaterial[]
  );
  const selectedShopMaterial = allMaterials.find(material => material.id === shopMaterialId);
  const allMaterialsWithSameName = selectedShopMaterial
    ? allMaterials.filter(
        material => material.appMaterial.name === selectedShopMaterial.appMaterial.name
      )
    : [];
  const materialIdsByColorId: { [id: number]: number } = {};
  allMaterialsWithSameName.forEach(material =>
    material.shopMaterialColors.forEach(color => {
      materialIdsByColorId[color.id] = material.id;
    })
  );
  const allColors = allMaterialsWithSameName.reduce(
    (acc, material) => acc.concat(material.shopMaterialColors),
    [] as IShopMaterialColor[]
  );
  // This may be a deleted color or a color of a deleted material:
  // (Even if so, we need to display these color names as `placeholder` text w/ tooltip for historical items.)
  const selectedShopMaterialColor = allColors.find(color => color.id === shopMaterialColorId);

  // "available" colors = not deleted & not belonging to deleted machine
  const availableMaterials: IShopMaterial[] = machines
    .filter(machine => !machine.dateDeleted)
    .reduce((acc, machine) => acc.concat(machine.materials), [] as IShopMaterial[]);
  const availableMaterialsWithSameName = selectedShopMaterial
    ? availableMaterials.filter(
        material => material.appMaterial.name === selectedShopMaterial.appMaterial.name
      )
    : [];
  const availableColors = availableMaterialsWithSameName.reduce(
    (acc, material) => acc.concat(material.shopMaterialColors),
    [] as IShopMaterialColor[]
  );
  const availableShopMaterialColor = availableColors.find(
    color => color.id === shopMaterialColorId
  );

  let dedupedColors = [...availableColors].filter(color => !color.dateDeleted);
  if (availableShopMaterialColor) {
    // Strip out selected material color, and any material colors that share its name.
    // We will re-add it after deduplication to ensure it remains in deduped list.
    dedupedColors = dedupedColors.filter(
      color =>
        color.id !== shopMaterialColorId &&
        color.appMaterialColor.name !== availableShopMaterialColor.appMaterialColor.name
    );
  }
  dedupedColors = _.uniqBy(dedupedColors, "appMaterialColor.name"); // Dedupe remaining colors based on name
  availableShopMaterialColor && dedupedColors.push(availableShopMaterialColor); // Re-add selected

  return { dedupedColors, selectedShopMaterialColor, materialIdsByColorId };
};

export const ShopMaterialColorPicker = ({
  shopTechnologyId,
  shopMaterialId,
  shopMaterialColorId,
  onChange,
  disabled,
  styleAsRequired,
  showIcon,
  tooltipPosition,
  ...rest
}: {
  shopTechnologyId?: number;
  shopMaterialId?: number;
  shopMaterialColorId?: number;
  onChange?: (ids: ITechnologyMateralColorIds) => void;
  disabled?: boolean;
  styleAsRequired?: boolean;
  showIcon?: boolean;
  tooltipPosition?: string;
}): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const { loading, shopTechnology } = useShopTechnologyById(shopTechnologyId);
  const { dedupedColors, selectedShopMaterialColor, materialIdsByColorId } =
    getDedupedColorsForMaterialByName(shopTechnology, shopMaterialId, shopMaterialColorId);

  // Handy debug code for seeing which color options map to which machines:
  // const getMachineIdFromColorId = (colorId: number): number | undefined => {
  //   const colorMachine =
  //     shopTechnology &&
  //     shopTechnology.shopMachines.find(machine =>
  //       machine.materials.find(material => material.shopMaterialColors.find(color => color.id === colorId))
  //     );
  //   return colorMachine && colorMachine.id;
  // };
  const materialColorOptions = dedupedColors.map(materialColor => ({
    key: `sm-${materialColor.id}`,
    value: materialColor.id,
    text: materialColor.appMaterialColor.displayName,
  }));

  const handleChange = (value: number) => {
    const ids: ITechnologyMateralColorIds = { shopMaterialColorId: value };
    // Depending on color selection, upstream material (and machine) may need to change!
    const newShopMaterialId = materialIdsByColorId[value];
    if (newShopMaterialId !== shopMaterialId) {
      ids.shopMaterialId = newShopMaterialId;
    }
    onChange?.(ids);
  };
  if (!shopMaterialColorId && materialColorOptions.length === 1) {
    // Single color auto-selection is handled by the Machine picker for EditItem,
    // however the new Order form uses this:
    handleChange(materialColorOptions[0].value);
  }

  const selectedMaterialColorValid =
    !shopMaterialColorId || !!materialColorOptions.find(mat => mat.value === shopMaterialColorId);

  return (
    <div
      data-tooltip={
        !selectedMaterialColorValid && selectedShopMaterialColor
          ? t("order.items.color.unavailable")
          : undefined
      }
      data-position={tooltipPosition ? tooltipPosition : "left center"}
      {...rest} // This can override the tooltip above
    >
      <SelectionDropdown
        icon={showIcon && getIcon(colorIcon)}
        loading={loading}
        className={classnames(
          { "no-selection": !styleAsRequired && !shopMaterialColorId },
          "qa-dropdown-materialColor"
        )}
        value={shopMaterialColorId || undefined}
        onChange={(_evt, { value }) => handleChange(value as number)}
        options={materialColorOptions}
        disabled={disabled || materialColorOptions.length <= 1}
        placeholder={
          !selectedMaterialColorValid && selectedShopMaterialColor
            ? selectedShopMaterialColor.appMaterialColor.displayName
            : undefined
        }
        data-testid="dropdown-materialColor"
      />
    </div>
  );
};
