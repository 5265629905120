import { Query } from "@apollo/client/react/components";
import React, { useContext } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Breadcrumb, BreadcrumbSection, BreadcrumbDivider } from "grabcad-ui-elements";
import { IOrder } from "@/graphql/Fragments/Order";
import { Notifier } from "@/utils/Notifier";
import { ORDER_DETAILS } from "@/graphql/Queries";
import { ROUTES } from "@/shopConstants";
import { IShopJob, JOB_DETAILS } from "@/graphql/Queries/Job";
import { useShopTechnologies, useShopTechnologyById } from "@/utils/queryHooks";

export type IBreadCrumbMatch = RouteComponentProps<{
  shopId: string;
  orderId: string;
  machineId: string;
  technologyId: string;
  jobId: string;
}>["match"];
export interface IBreadCrumbSection {
  name: (t: (key: string) => string, match: IBreadCrumbMatch) => string | React.ReactElement;
  path?: (match: IBreadCrumbMatch) => string;
}
interface IBreadcrumbsProps {
  sections?: IBreadCrumbSection[];
  match: IBreadCrumbMatch;
}

const TechnologyBreadcrumb = ({ technologyId }: { technologyId: number }): JSX.Element => {
  const { shopTechnology } = useShopTechnologyById(technologyId);
  return <>{shopTechnology?.appTechnology.displayName || ""}</>;
};

const MachineBreadcrumb = ({ machineId }: { machineId: number }): JSX.Element => {
  const { allMachines } = useShopTechnologies();
  const machine = allMachines.find(m => m.id === machineId);
  return <>{machine?.appMachineType.displayName || ""}</>;
};

export const BREADCRUMBS: { [key: string]: IBreadCrumbSection } = {
  newShop: { name: t => t("shop.list.create_new_shop") },
  info: { name: t => t("layout.shopSidebar.info") },
  preferences: { name: t => t("layout.shopSidebar.preferences") },
  technology: {
    name: (t, match) => <TechnologyBreadcrumb technologyId={+match.params.technologyId} />,
    path: match => ROUTES.SHOP(match.params.shopId).MACHINES.TECHNOLOGY(+match.params.technologyId),
  },

  machines: {
    name: t => t("layout.shopSidebar.machines"),
    path: match => ROUTES.SHOP(match.params.shopId).MACHINES.INDEX,
  },
  newMachine: { name: t => t("machines.form.appMachineTypes.grid.addMachine") },
  machine: {
    name: (t, match) => <MachineBreadcrumb machineId={+match.params.machineId} />,
  },
  orders: {
    name: t => t("layout.shopSidebar.orders"),
    path: match => ROUTES.SHOP(match.params.shopId).ORDERS,
  },
  newOrder: {
    name: t => t("order.list.newOrder"),
  },
  order: {
    name: (t, match) => (
      <Query<{ order: IOrder }, { orderId: number }>
        query={ORDER_DETAILS}
        variables={{ orderId: +match.params.orderId }}
        onError={error => Notifier.error(error)}
      >
        {({ data }) => <>{data?.order?.name || ""}</>}
      </Query>
    ),
  },
  jobs: {
    name: t => t("shop.jobs"),
    path: match => ROUTES.SHOP(match.params.shopId).JOBS.LIST,
  },
  newJob: {
    name: t => t("shop.jobs.new"),
    path: match => ROUTES.SHOP(match.params.shopId).JOBS.NEW,
  },
  job: {
    name: (t, match) => (
      <Query<{ job: IShopJob }, { id: number }>
        query={JOB_DETAILS}
        variables={{ id: +match.params.jobId }}
        onError={error => Notifier.error(error)}
      >
        {({ data }) => <>{data?.job?.name || ""}</>}
      </Query>
    ),
  },
};

export const Breadcrumbs = (props: IBreadcrumbsProps): JSX.Element => {
  const { sections, match } = props;
  const { currentShop, t } = useContext(ApplicationContext);
  const shopRoot = ROUTES.SHOP(match.params.shopId).ORDERS;
  return (
    <Breadcrumb style={{ marginBottom: 10 }}>
      <BreadcrumbSection as={Link} to={"/"} data-testid="breadcrumbSection">
        {t("index.home")}
      </BreadcrumbSection>

      {currentShop?.name && (
        <>
          <BreadcrumbDivider data-testid="breadcrumbDivider" />
          <BreadcrumbSection
            as={match.url !== shopRoot && Link}
            to={shopRoot}
            data-testid="breadcrumbSection"
          >
            {currentShop.name}
          </BreadcrumbSection>
        </>
      )}

      {sections?.map((section, i) => (
        <span key={i}>
          <BreadcrumbDivider data-testid="breadcrumbDivider" />
          <BreadcrumbSection
            as={section.path && i < sections.length - 1 && Link}
            to={section.path?.(match)}
            data-testid="breadcrumbSection"
          >
            {section.name?.(t, match)}
          </BreadcrumbSection>
        </span>
      ))}
    </Breadcrumb>
  );
};
