import React, { useContext } from "react";
import { SelectionDropdown } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { IDropdownOptions } from "@/components/Shop/Form/Form";
import { GRAPHQL_MODEL_UNITS, UnitsOptions, getTranslatedUnits } from "@/utils/DropdownUtils";

export const UnitsPicker = ({
  id,
  currentUnits,
  onUnitsSelected,
  disabled,
  tooltipText,
  tooltipPosition,
}: {
  id: string;
  currentUnits: GRAPHQL_MODEL_UNITS | undefined;
  onUnitsSelected: (value: GRAPHQL_MODEL_UNITS) => void;
  disabled?: boolean;
  tooltipText?: string;
  tooltipPosition?: string;
}): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  const units: IDropdownOptions[] = UnitsOptions.map(un => ({
    key: `un-${un}`,
    value: un,
    text: getTranslatedUnits(t, un),
  }));
  return (
    <div data-tooltip={tooltipText} data-position={tooltipPosition}>
      <SelectionDropdown
        id={id}
        className="qa-dropdown-units"
        value={currentUnits}
        onChange={(_evt, { value }) => onUnitsSelected(value as GRAPHQL_MODEL_UNITS)}
        placeholder={t("order.form.units")}
        options={units}
        disabled={disabled}
        data-testid="unitsPickerDropdown"
      />
    </div>
  );
};
