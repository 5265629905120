import classnames from "classnames";
import {
  ApplicationContext,
  IApplicationContext,
  TranslateFunction,
} from "@/components/ApplicationProvider";
import { DownloadButton } from "@/components/Shared/DownloadButton";
import styled, {
  Button,
  Checkbox,
  Icon,
  Image,
  List,
  ListContent,
  ListItem,
  Loader,
  Segment,
  SegmentGroup,
  TableCell,
} from "grabcad-ui-elements";
import { IOrderItem, OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import React, { useContext, useState, useEffect } from "react";
import { Notifier } from "@/utils/Notifier";
import { OrderStatus } from "../Status/Status";
import { OrderItemsTableRow } from "../Items/Items";
import {
  UpdateOrderItemData,
  UpdateOrderItemType,
  UpdateOrderItemVariables,
  UPDATE_ORDER_ITEM,
} from "@/graphql/Mutations/OrderItem";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ApolloError, useLazyQuery, useApolloClient, useMutation } from "@apollo/client";
import calculator from "../../../assets/icons/calculator.svg";
import { IMachineRate } from "@/graphql/Fragments/ShopRate";
import {
  TIME_UNITS,
  MATERIAL_UNITS,
  ROUTES,
  DEFAULT_TIME_UNIT,
  getMachineTechnologyDefaultMaterialUnit,
  TECHNOLOGY,
} from "@/shopConstants";
import {
  transformMinutesToDisplayUnits,
  getEstimatedMaterialValue,
  timeUnitsToMinutes,
  roundToTwoDecimals,
  transformEstimatedMaterialToDbUnits,
  getEstimatedMaterialFieldName,
  getEstimatedMaterialMax,
} from "./shopRatesUtils";
import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import ReactGA from "react-ga";
import { TechnologyMaterialColorSelectors } from "./TechnologyMaterialColorSelectors";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import {
  getCadModelName,
  getTechnologyName,
  isAnyMaterial,
  isGcpAsset,
} from "@/utils/GeneralUtils";
import { useHistory } from "react-router-dom";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import {
  getMaterialUnitLabel,
  modelCadFormatIsUnitless,
  getModelSizesString,
} from "@/utils/DropdownUtils";
import {
  AUTOESTIMATIONS_POLL_FREQ,
  findAutoEstimationWithMatchingOrientationSnapshots,
  getMostUsedMaterialName,
} from "../../Shared/AutoEstimations";
import { AUTO_ESTIMATIONS_PROGRESS } from "@/graphql/Queries/AutoEstimation";
import { useShopTechnologies } from "@/utils/queryHooks";
import { UnitsPicker } from "../Form/UnitsPicker";
import { FeatureKey } from "../../../graphql/Fragments/UserLicense";
import { IShopMaterial } from "../../../graphql/Queries/Material";
import { IShopMachine } from "../../../graphql/Fragments/ShopMachine";
import { OrderItemModal, OrderItemModalTab } from "@/screens/Shop/Order/Show/OrderItemModal";
import { StyledLabeledInput } from "@/screens/Shop/Order/Show/PriceCalculator";
import { AutoEstimationResultData } from "../../../graphql/Fragments/AutoEstimation";
import { OrientationModal } from "../../../screens/Shop/Order/Show/OrientationModal";
import JobCreated from "@/assets/icons/job_created.svg";
import Orient from "@/assets/icons/orient.svg";
import { getIcon } from "../../Shared/Icons";

export interface IOrderEditItemProps {
  item: IOrderItem;
  isOrderWritable: boolean;
  index: number;
  orderId: number;
  classNames?: string;
  isSelected: boolean;
  toggleSelection: (item: OrderItemOrImageOrFile) => void;
}

const OrderItemCheckbox = styled(Checkbox)`
  top: 25%;
`;

const EstimatesLoader = styled.div`
  width: 15px;
  margin-right: 10px;
  margin-top: 1px;
  .ui.tiny.loader {
    z-index: 0;
  }
`;
const getEstimatesLoader = (t: TranslateFunction): JSX.Element => (
  <EstimatesLoader
    data-tooltip={t("auto_estimation.selectedInProgress")}
    data-position="left center"
  >
    <Loader active={true} size="tiny" />
  </EstimatesLoader>
);

const GcpEstimatesLoader = styled.div`
  width: 15px;
  margin-right: 101px;
`;

const OrientationOptionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

const machineMaterialFromGcpEstimate = (
  context: IApplicationContext,
  estimateResult: AutoEstimationResultData | undefined,
  autoestimationId: number | null | undefined
): JSX.Element => {
  const isWaiting =
    context.hasFeature(FeatureKey.AUTO_ESTIMATION) && autoestimationId && !estimateResult;
  return isWaiting ? (
    <GcpEstimatesLoader
      data-tooltip={context.t("order.items.estimate.waiting")}
      data-position="left center"
    >
      <Loader active={true} />
    </GcpEstimatesLoader>
  ) : (
    <TableCell className="details" data-testid="machineMaterialFromGcpEstimate">
      <div className="item-label">{context.t("order.items.technology")}</div>
      <ListItem className="gcp-label qa-gcp-technology">
        {getTechnologyName(estimateResult)}
      </ListItem>
      <div className="item-label">{context.t("order.form.shopMachine")}</div>
      <ListItem className="gcp-label qa-gcp-printer">{estimateResult?.printerModel}</ListItem>
      <div className="item-label">{context.t("order.items.material")}</div>
      <ListItem className="gcp-label qa-gcp-material">
        {getMostUsedMaterialName(estimateResult)}
      </ListItem>
    </TableCell>
  );
};

const showRatesWarning = (
  item: IOrderItem,
  t: TranslateFunction,
  icon: (content: string) => JSX.Element
): JSX.Element | null => {
  const isTimeRateMissing = item.machineRate?.machineTimeRate == null;
  const isMaterialRateMissing = item.materialRate?.materialRate == null;
  const isSupportRateMissing = item.materialRate?.supportRate == null;

  if (item.shopMaterialId) {
    if (isTimeRateMissing && (isMaterialRateMissing || isSupportRateMissing)) {
      return icon(t("order.items.estimate.noRates"));
    } else if (isMaterialRateMissing || isSupportRateMissing) {
      return icon(t("order.items.material.estimate.noRates"));
    } else if (isTimeRateMissing) {
      return icon(t("order.items.time.estimate.noRates"));
    }
  }
  return null;
};

export const _OrderEditItem = ({
  item,
  isOrderWritable, // TODO: Internalize
  index,
  orderId,
  isSelected,
  classNames,
  toggleSelection,
}: IOrderEditItemProps): JSX.Element | null => {
  const context = useContext(ApplicationContext);
  const { t, formatPrice, currentShop } = context;
  if (!currentShop) {
    return null;
  }
  const history = useHistory();
  const itemToUpdate: UpdateOrderItemType = {};

  const client = useApolloClient();
  const { shopTechnologies } = useShopTechnologies();
  let itemShopMachine: IShopMachine | undefined = undefined;
  let itemShopMaterial: IShopMaterial | undefined = undefined;
  // Lookup machine and material corresponding to item
  let estimateTechnologyName: TECHNOLOGY | undefined;
  for (let technology of shopTechnologies) {
    for (let shopMachine of technology.shopMachines) {
      for (let material of shopMachine.materials) {
        if (material.id === item.shopMaterialId) {
          itemShopMaterial = material;
          itemShopMachine = shopMachine;
          estimateTechnologyName = technology.appTechnology.name;
        }
      }
    }
  }
  const [itemQuantity, setItemQuantity] = useState(item.quantity);
  const [itemUnits, setItemUnits] = useState(item.units);
  const machineRate = (item.machineRate || {}) as Partial<IMachineRate>;

  // For GCP item (.cmb/.print), we want to get technology, material and units from the only auto estimation result
  const isGcpItem = isGcpAsset(item.cadModel);
  let theGcpEstimateResult: AutoEstimationResultData | undefined;

  if (isGcpItem && item.autoEstimations && item.autoEstimations.length > 0) {
    theGcpEstimateResult = item.autoEstimations[0].resultData;
    estimateTechnologyName = getTechnologyName(theGcpEstimateResult);
  }
  const materialUnit: MATERIAL_UNITS =
    !isGcpItem && machineRate.materialUnit
      ? machineRate.materialUnit
      : getMachineTechnologyDefaultMaterialUnit(estimateTechnologyName);
  const machineTimeUnit: TIME_UNITS = machineRate.machineTimeUnit ?? DEFAULT_TIME_UNIT;

  const [showRatesModal, setShowRatesModal] = useState<boolean>(false);
  const [showOrientationModal, setShowOrientationModal] = useState<boolean>(false);
  const [estimatedTime, setEstimatedTime] = useState<number | undefined>(
    item.estimatedMinutes
      ? transformMinutesToDisplayUnits(item.estimatedMinutes, machineTimeUnit)
      : 0
  );
  const [estimatedMaterial, setEstimatedMaterial] = useState<number | undefined>(
    getEstimatedMaterialValue(item, materialUnit)
  );

  useEffect(() => {
    const newEstimateMaterial = getEstimatedMaterialValue(item, materialUnit);
    if (estimatedMaterial !== newEstimateMaterial) {
      setEstimatedMaterial(newEstimateMaterial);
    }
    const newEstimateTime = item.estimatedMinutes
      ? transformMinutesToDisplayUnits(item.estimatedMinutes, machineTimeUnit)
      : 0;
    if (estimatedTime !== newEstimateTime) {
      setEstimatedTime(newEstimateTime);
    }
    const newUnits = item.units;
    if (itemUnits !== newUnits) {
      setItemUnits(newUnits);
    }
  }, [item]);

  // AutoEstimations have dedicated UI in AutoEstimations.tsx, but this component may not be visible,
  // so we need to poll at the OrderItem level in case a selected + in-progress AutoEstimation finishes
  // and updates estimated values/price.
  const inProgressAutoEstimationIds = (item.autoEstimations || [])
    // TODO also filter out ones with errors https://grabcad.atlassian.net/browse/GC-85909
    .filter(autoEstimation => !autoEstimation.resultData)
    .map(autoEstimation => autoEstimation.id);
  const selectedAutoEstimation =
    item.autoEstimationId &&
    item.autoEstimations?.find(estimation => estimation.id === item.autoEstimationId);
  const selectedAutoEstimationInProgress =
    // TODO also filter out ones with errors https://grabcad.atlassian.net/browse/GC-85909
    !!selectedAutoEstimation && !selectedAutoEstimation.resultData;

  const isAnyMaterialSelected = isAnyMaterial(itemShopMaterial);
  const machineHasAutoEstimateCapability = !!itemShopMachine?.appMachineType?.canEstimate;
  const shouldKnowAboutAutoEstimation = context.hasFeature(FeatureKey.AUTO_ESTIMATION);
  const machineHasAutoEstimateLicense =
    itemShopMachine?.appMachineType &&
    context.hasFeature(FeatureKey.AUTO_ESTIMATION, itemShopMachine.appMachineType.name);
  const [_, { startPolling, stopPolling }] = useLazyQuery(AUTO_ESTIMATIONS_PROGRESS, {
    variables: { ids: inProgressAutoEstimationIds },
  });
  useEffect(() => {
    if (inProgressAutoEstimationIds.length) {
      startPolling(AUTOESTIMATIONS_POLL_FREQ);
    } else {
      stopPolling();
    }
  }, [inProgressAutoEstimationIds.join(",")]);

  useEffect(() => {
    if (item.autoEstimationId && !selectedAutoEstimationInProgress) {
      fetchNewOrderEvents(client.cache, client, orderId);
    }
  }, [item.autoEstimationId, selectedAutoEstimationInProgress]);

  const warningIcon = (content: string) => (
    <span
      data-testid="noRatesWarning"
      data-tooltip={content}
      data-position="left center"
      style={{ cursor: "pointer" }}
      onClick={() => setShowRatesModal(true)}
    >
      <Icon
        name="warning sign"
        size="small"
        color="red"
        className="qa-no-rates-warning"
        data-testid="noRatesWarningIcon"
      />
    </span>
  );

  const getPriceDisplay = (): string | null => {
    if (item.overridePrice) {
      return formatPrice(item.overridePrice);
    } else if (item.price) {
      return formatPrice(item.price);
    } else {
      // Show '-' in place of price when price /override price is null
      return "-";
    }
  };

  const [updateOrderItem, { loading: mutationInFlight }] = useMutation<
    UpdateOrderItemData,
    UpdateOrderItemVariables
  >(UPDATE_ORDER_ITEM, {
    onCompleted: () => Notifier.success(t("order.items.updated")),
    onError: (err: ApolloError) => Notifier.error(err),
    update: async (cache: any) => fetchNewOrderEvents(cache, client, orderId),
  });

  const handleQuantityBlur = () => {
    if (item.quantity !== itemQuantity) {
      updateOrderItem({
        variables: {
          id: item.id,
          input: { quantity: itemQuantity || 1 },
        },
      });
    }
  };

  const handleTimeEstimateBlur = (timeUnit: TIME_UNITS) => {
    const newEstimatedMinutes =
      estimatedTime && timeUnitsToMinutes(roundToTwoDecimals(estimatedTime), timeUnit);
    if (item.estimatedMinutes !== newEstimatedMinutes) {
      item = { ...item, estimatedMinutes: newEstimatedMinutes };
      itemToUpdate.estimatedMinutes = newEstimatedMinutes;

      // Send Machine rate Id in mutation only when necessary.
      if (!item.machineRateId) {
        itemToUpdate.machineRateId = item.machineRate?.id;
      }

      updateOrderItem({
        variables: {
          id: item.id,
          input: itemToUpdate,
        },
      });
    }
  };

  const handleMaterialEstimateBlur = (machineMaterialUnit: MATERIAL_UNITS) => {
    const newEstimatedMaterialValue = transformEstimatedMaterialToDbUnits({
      itemEstimatedValue: estimatedMaterial,
      fromUnit: machineMaterialUnit,
    });
    const estimatedMaterialFieldName = getEstimatedMaterialFieldName(machineMaterialUnit);
    if (item[estimatedMaterialFieldName] !== newEstimatedMaterialValue) {
      item = { ...item, [estimatedMaterialFieldName]: newEstimatedMaterialValue };
      itemToUpdate[estimatedMaterialFieldName] = newEstimatedMaterialValue;

      updateOrderItem({
        variables: {
          id: item.id,
          input: itemToUpdate,
        },
      });
    }
  };
  const units = modelCadFormatIsUnitless(item.cadModel) ? item.units : undefined;
  const modelSizes = getModelSizesString(item.cadModel, units, currentShop.preferredUnits, t);

  // This is the warning icon that appears next to the material or time estimate icon
  const estimateStatusIcon = (hasValue: boolean) => {
    // Show spinner is auto estimation in progress
    if (selectedAutoEstimationInProgress) {
      return getEstimatesLoader(t);
    } else if (!hasValue && itemShopMachine) {
      if (shouldKnowAboutAutoEstimation) {
        // We show warning icons only if there is no value already (might have been manually set)
        if (!machineHasAutoEstimateCapability) {
          return warningIcon(t("order.items.material.estimate.noCapability"));
        } else if (!machineHasAutoEstimateLicense) {
          return warningIcon(
            t("order.items.material.estimate.noLicense", {
              materialName: itemShopMaterial?.appMaterial?.displayName,
            })
          );
        } else if (machineHasAutoEstimateCapability && isAnyMaterialSelected) {
          return warningIcon(t("order.items.material.estimate.noAny"));
        }
      }
    }

    return <></>;
  };

  const matchingAE = findAutoEstimationWithMatchingOrientationSnapshots(
    item,
    estimateTechnologyName === TECHNOLOGY.POLYJET ? itemShopMachine : undefined
  );

  const OrientationButton = () => (
    <Button
      data-tooltip={t("order.items.orientation.tooltip")}
      data-position={"top center"}
      data-testid="orientationOptionsButton"
      icon={getIcon(Orient, {
        fontSize: "1.4em",
        opacity: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      })}
      disabled={mutationInFlight}
      secondary
      compact
      style={{ margin: "2px", padding: "5px" }}
      className={"borderless"}
      onClick={() => setShowOrientationModal(true)}
    />
  );

  const JobsButton = () => (
    <UiCan do={Permission.VIEW_JOBS} on={currentShop}>
      <Button
        data-tooltip={t("order.items.job.view")}
        data-position={"bottom center"}
        icon={getIcon(JobCreated, {
          fontSize: "1.2em",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
        disabled={false}
        secondary
        compact
        style={{ margin: "0", padding: "5px" }}
        className={"borderless qa-orderItemRow-job"}
        onClick={async () => {
          item.jobId && history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(item.jobId));
        }}
      />
    </UiCan>
  );

  return (
    <OrderItemsTableRow
      id={`qa-orderItemRow_${item.id}`}
      className={classnames("qa-orderItemRow", classNames, { loading: mutationInFlight })}
      key={index}
      data-testid="orderItemRow"
    >
      {/* number column */}
      <TableCell className="num" verticalAlign="top" textAlign="center" data-testid="numberColumn">
        {mutationInFlight && <Loader active={true} size="small" />}
        <strong className="qa-orderItemRow-number">
          {index + 1}
          {mutationInFlight}
        </strong>
        <OrderItemCheckbox
          checked={isSelected}
          onClick={() => toggleSelection(item)}
          className={"qa-orderItemRow-checkbox"}
        />
      </TableCell>

      {/* thumbnail image column */}
      <TableCell className="img" data-testid="thumbnailImageColumn">
        <SegmentGroup>
          <Segment data-testid="thumbnailPreviewSegment" compact style={{ position: "relative" }}>
            <CadModelPreview itemId={item.id} size="small" height={90} />
            <OrientationOptionsContainer>
              {!matchingAE && selectedAutoEstimationInProgress && (
                <div
                  data-tooltip={t("order.items.orientation.loading.tooltip")}
                  data-position="top center"
                  style={{ padding: "10px", margin: "5px" }}
                >
                  <Loader active={true} size="tiny" />
                </div>
              )}
              {matchingAE && <OrientationButton />}
            </OrientationOptionsContainer>
          </Segment>
          <Segment compact style={{ display: "flex", padding: "2px" }}>
            <DownloadButton items={[item]} />
            {item.jobId && <JobsButton />}
          </Segment>
        </SegmentGroup>
      </TableCell>

      {/* name + units + quantity column */}
      <TableCell className="model-settings" data-testid="nameUnitsQuantityColumn">
        <List relaxed>
          <ListItem className="qa-orderItemRow-filename filename">{getCadModelName(item)}</ListItem>
          {modelSizes ? <ListItem>{modelSizes}</ListItem> : <br />}
          {modelCadFormatIsUnitless(item.cadModel) && (
            <ListItem>
              <div className="item-label">{t("order.form.units")}</div>
              <UiCan passThrough do={`${Permission.WRITE} units`} on={item}>
                {(isOrderItemWriteable: boolean) => (
                  <ListContent style={{ maxWidth: "70px" }}>
                    <UnitsPicker
                      id="qa-orderItem-unitsPicker"
                      currentUnits={itemUnits}
                      onUnitsSelected={value => {
                        setItemUnits(value);
                        updateOrderItem({
                          variables: {
                            id: item.id,
                            input: { units: value },
                          },
                        });
                      }}
                      disabled={
                        !!item.jobId ||
                        !isOrderWritable ||
                        !isOrderItemWriteable ||
                        mutationInFlight
                      }
                      tooltipText={!!item.jobId ? t("order.items.job.tooltip.units") : undefined}
                      tooltipPosition={"right center"}
                    />
                  </ListContent>
                )}
              </UiCan>
            </ListItem>
          )}
          <ListItem>
            <div className="item-label item-quantity">{t("order.items.quantity")}</div>
            <UiCan passThrough do={`${Permission.WRITE} quantity`} on={item}>
              {(isOrderItemWriteable: boolean) => (
                <ListContent style={{ maxWidth: "70px" }}>
                  <div
                    data-tooltip={!!item.jobId ? t("order.items.job.tooltip.quantity") : null}
                    data-position={"right center"}
                  >
                    <LocalizedNumericInput
                      className="qa-orderItemRow-quantityInput quantity-input"
                      min={0}
                      max={9999}
                      onChange={value => setItemQuantity(value || 1)}
                      onSubmit={() => {
                        handleQuantityBlur();
                        ReactGA.event({
                          category: "GcShop Order",
                          action: "Edit Order Part quantity",
                          label: `Order ${orderId}`,
                        });
                      }}
                      value={itemQuantity}
                      disabled={
                        !!item.jobId ||
                        !isOrderWritable ||
                        !isOrderItemWriteable ||
                        mutationInFlight
                      }
                    />
                  </div>
                </ListContent>
              )}
            </UiCan>
          </ListItem>
        </List>
      </TableCell>

      {/* technology + material + color column */}
      {isGcpItem ? (
        machineMaterialFromGcpEstimate(context, theGcpEstimateResult, item.autoEstimationId)
      ) : (
        <TableCell className="details" data-testid="technologyMaterialColorColumn">
          <UiCan passThrough do={`${Permission.WRITE} shopTechnologyId`} on={item}>
            {(isOrderItemWriteable: boolean) => (
              <TechnologyMaterialColorSelectors
                item={item}
                orderId={orderId}
                updateOrderItem={vars => {
                  updateOrderItem({ variables: vars });
                }}
                canEdit={isOrderWritable && isOrderItemWriteable}
              />
            )}
          </UiCan>
        </TableCell>
      )}
      {/* status column */}
      <TableCell
        verticalAlign="top"
        className="status aligned-status qa-orderPart-status"
        data-testid="statusColumn"
      >
        <OrderStatus entity={item} readOnly={!isOrderWritable} />
      </TableCell>

      {/* price + estimation column */}
      <TableCell verticalAlign="top" className="price">
        <List relaxed>
          <ListItem>
            <span
              style={{ display: "inline-flex", paddingRight: "10px" }}
              className={classnames("price-calculator-link qa-price-calculator-button", {
                disabled: mutationInFlight,
              })}
              onClick={() => {
                if (!mutationInFlight) {
                  setShowRatesModal(true);
                  ReactGA.event({
                    category: "GcShop RateTable",
                    action: "Opening Calculator Icon",
                    label: `Shop ${currentShop.id}`,
                  });
                }
              }}
              data-testid="priceCalculatorButton"
            >
              {selectedAutoEstimationInProgress && item.overridePrice == null ? (
                getEstimatesLoader(t)
              ) : (
                <span>
                  <span className="no-rates-warning">{showRatesWarning(item, t, warningIcon)}</span>
                  <b
                    style={{ paddingRight: "5px" }}
                    className="qa-orderItemRow-priceDisplay price-display"
                    data-testid="priceDisplay"
                  >
                    {getPriceDisplay()}
                  </b>
                </span>
              )}
              <span
                data-tooltip={
                  mutationInFlight
                    ? t("order.items.view.fetchingRates")
                    : item.machineRate || item.materialRate
                    ? t("order.items.view.rates")
                    : t("order.items.rates.popup.noRates")
                }
                data-position="left center"
                data-testid="ratesPopup"
              >
                <Image
                  style={{ display: "block", paddingRight: "5px" }}
                  src={calculator}
                  className="calculator"
                />
              </span>
            </span>
          </ListItem>
          <ListItem className="material-estimate" data-testid="materialEstimate">
            <div className="item-label material-estimate">{t("order.items.material.estimate")}</div>
            <div style={{ display: "flex" }}>
              <span className="no-rates-warning input-warning">
                {estimateStatusIcon(!!estimatedMaterial)}
              </span>
              <UiCan passThrough do={`${Permission.WRITE} price`} on={item}>
                {(isOrderItemWriteable: boolean) => (
                  <StyledLabeledInput data-label={getMaterialUnitLabel(t, materialUnit)}>
                    <LocalizedNumericInput
                      className="qa-orderItemRow-materialEstimateInput"
                      min={0}
                      max={getEstimatedMaterialMax(materialUnit)}
                      placeholder={t("order.items.rates.popup.table.material")}
                      value={estimatedMaterial}
                      onChange={setEstimatedMaterial}
                      onSubmit={() => handleMaterialEstimateBlur(materialUnit)}
                      disabled={!isOrderWritable || !isOrderItemWriteable || mutationInFlight}
                    />
                  </StyledLabeledInput>
                )}
              </UiCan>
            </div>
          </ListItem>
          <ListItem className="time-estimate" data-testid="timeEstimate">
            <div className="item-label">{t("order.items.time.estimate")}</div>
            <div style={{ display: "flex" }}>
              <span className="no-rates-warning input-warning">
                {estimateStatusIcon(!!estimatedTime)}
              </span>
              <UiCan passThrough do={`${Permission.WRITE} price`} on={item}>
                {(isOrderItemWriteable: boolean) => (
                  <StyledLabeledInput
                    data-label={t(`order.items.rates.units.${machineTimeUnit.toLowerCase()}`)}
                  >
                    <LocalizedNumericInput
                      className="qa-orderItemRow-timeEstimateInput"
                      min={0}
                      max={transformMinutesToDisplayUnits(Number.MAX_SAFE_INTEGER, machineTimeUnit)}
                      placeholder={t("order.items.rates.popup.table.time")}
                      value={estimatedTime}
                      onChange={setEstimatedTime}
                      onSubmit={() => handleTimeEstimateBlur(machineTimeUnit)}
                      disabled={!isOrderWritable || !isOrderItemWriteable || mutationInFlight}
                    />
                  </StyledLabeledInput>
                )}
              </UiCan>
            </div>
          </ListItem>
        </List>

        {/* This boolean operator might seem redundant w/ `open` prop, but forcing
            the modal to rerender on open helps keep its state current when material/machine has changed.
            TIL modals hidden with `open={false}` retain state. */}
        {showRatesModal && (
          <OrderItemModal
            open={showRatesModal}
            orderItem={item}
            tab={OrderItemModalTab.rates}
            refetchOrderEvents
            orderId={orderId}
            onClose={() => setShowRatesModal(false)}
          />
        )}
        {showOrientationModal && (
          <UiCan passThrough do={`${Permission.WRITE} orientation`} on={item}>
            {(isOrderItemWriteable: boolean) => (
              <OrientationModal
                open={showOrientationModal}
                orderItem={item}
                matchingAE={matchingAE}
                canEdit={!item.jobId && isOrderWritable && isOrderItemWriteable}
                updateOrderItem={updateOrderItem}
                onClose={() => {
                  setShowOrientationModal(false);
                }}
              />
            )}
          </UiCan>
        )}
      </TableCell>
    </OrderItemsTableRow>
  );
};

// Normally, child React component will re-render if the parent re-renders.
// memo() changes child React component to be a "pure component" i.e., it will
// *not* re-render if the parent changes, but only when its props/states/context changes.
export const OrderEditItem = React.memo(_OrderEditItem);
