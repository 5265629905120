import { Mutation } from "@apollo/client/react/components";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { NewOrderForm } from "@/components/Order/Form/Form";
import { CadDropZone, IUploadContainer } from "@/components/Upload/CadDropZone";
import styled, {
  Button,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Segment,
  StyleMixins,
} from "grabcad-ui-elements";
import { CREATE_ORDER } from "@/graphql/Mutations";
import { ORDER_COUNT } from "@/graphql/Queries/Order";
import React, { useContext, useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ROUTES } from "@/shopConstants";
import { Notifier } from "@/utils/Notifier";
import ReactGA from "react-ga";
import { ShopState } from "@/graphql/Fragments/Shop";
import { SHOPS_LIST } from "@/graphql/Queries";
import { GRAPHQL_MODEL_UNITS } from "@/utils/DropdownUtils";

export interface IOrderInput {
  orderItems: IOrderItemInput[];
  shopId: number;
  projectCode: string;
  name: string;
  notes: string;
  needByDate: Date;
  fileIds?: number[];
  imageIds?: number[];
}

export interface IOrderItemInput {
  units: GRAPHQL_MODEL_UNITS | undefined;
  cadModelId: number;
  shopMaterialId?: number | undefined;
  shopMaterialColorId?: number | undefined;
  quantity: number;
  shopTechnologyId?: number;
  [key: string]: string | number | undefined;
}

export type GRAPHQL_ORDER_STATUS =
  | "SUBMITTED"
  | "IN_REVIEW"
  | "APPROVED"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "HAS_ISSUES"
  | "CANCELLED";
export interface INewOrderResponse {
  createOrder: {
    id: number;
  };
}

const CadDropZoneGridColumn = styled(GridColumn)`
  flex: 1;
  &.column .ui.segment {
    max-height: 472px;
    flex: 1;
    overflow: auto;
  }
`;
const CadDropZoneWrapper = styled(Segment)`
  ${StyleMixins.roundAndShadow}
  &.ui.segment {
    padding: 0;
  }
`;

const HeaderStyledAsRequired = styled.h3`
  &:after {
    margin: -0.2em 0 0 0.2em;
    content: "*";
    color: #db2828;
  }
`;

const FileBrowseLink = styled.span`
  cursor: pointer;
  color: #003393;
  &:hover {
    border-bottom: 2px solid #003393;
  }
`;

const BuildDetailGridColumn = styled(GridColumn)`
  max-width: 388px;
`;

const BrowseFilesGridColumn = styled(GridColumn)`
  flex: 1;
`;

export const OrderNew = withRouter((props: RouteComponentProps<{ shopId: string }>) => {
  const [files, setFiles] = useState<IUploadContainer[]>([]);
  const [openFileDialogCounter, setOpenFileDialogCounter] = useState(1);
  const [allUploaded, setAllUploaded] = useState<boolean>(false);
  const [requiredSet, setRequiredSet] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submit, setSubmit] = useState(false);
  const { currentShop, t } = useContext(ApplicationContext);

  const shopId = +props.match.params.shopId;

  useEffect(() => {
    if (submit) {
      ReactGA.event({
        category: "GcShop Order",
        action: "Created Order",
        label: `Shop ${shopId}`,
      });
    }
  }, [submit]);

  const refetchQueries: { query: any; variables?: any }[] = [
    { query: ORDER_COUNT, variables: { shopId } },
  ];
  if (currentShop && currentShop.state === ShopState.SAMPLE) {
    refetchQueries.push({ query: SHOPS_LIST });
  }

  return (
    <Container fluid>
      <Grid>
        <GridRow>
          <GridColumn>
            <Header as="h2" className="page-header">
              {t("order.new.title")}
            </Header>
          </GridColumn>
        </GridRow>
        <GridRow columns={2}>
          <BrowseFilesGridColumn>
            <HeaderStyledAsRequired>
              <FileBrowseLink onClick={() => setOpenFileDialogCounter(openFileDialogCounter + 1)}>
                {t("order.new.browse")}
              </FileBrowseLink>
            </HeaderStyledAsRequired>
          </BrowseFilesGridColumn>
          <BuildDetailGridColumn>
            <Header as="h3">{t("order.new.buildDetail")}</Header>
          </BuildDetailGridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Mutation<INewOrderResponse, { input: Partial<IOrderInput> }>
              mutation={CREATE_ORDER}
              onError={error => Notifier.error(error)}
              refetchQueries={refetchQueries}
              onCompleted={() => {
                props.history.push(ROUTES.SHOP(props.match.params.shopId).ORDERS);
                setSubmit(false);
              }}
            >
              {createOrder => (
                <Grid columns={2} stretched stackable>
                  <GridRow>
                    <CadDropZoneGridColumn>
                      <CadDropZoneWrapper>
                        <CadDropZone
                          shop={currentShop}
                          onFileAction={cadContainer => {
                            setFiles(prevFiles => {
                              const index = prevFiles.findIndex(i => i.key === cadContainer.key);
                              let file = prevFiles[index];
                              if (file) {
                                file = { ...file, ...cadContainer };
                                const newState = [
                                  ...prevFiles.slice(0, index),
                                  file,
                                  ...prevFiles.slice(index + 1),
                                ];
                                setAllUploaded(
                                  newState.every(item => item.uploaded) &&
                                    newState.filter(item => !item.cancelled).length > 0
                                );
                                return newState;
                              }
                              return [...prevFiles, cadContainer];
                            });
                          }}
                          openFileDialogCounter={openFileDialogCounter}
                          uploadedFiles={files}
                          showCTA={true}
                        />
                      </CadDropZoneWrapper>
                    </CadDropZoneGridColumn>
                    <BuildDetailGridColumn>
                      <NewOrderForm
                        shopId={shopId}
                        createOrder={createOrder}
                        uploadContainers={files}
                        requiredSet={(value: boolean) => setRequiredSet(value)}
                        setSubmitting={(value: boolean) => setSubmitting(value)}
                        submit={submit}
                        setSubmit={(value: boolean) => setSubmit(value)}
                      />
                    </BuildDetailGridColumn>
                  </GridRow>
                  <GridRow textAlign="right" columns={1}>
                    <GridColumn>
                      <Grid>
                        <GridColumn>
                          <Button
                            id="qa-orderForm-submit"
                            primary
                            floated="right"
                            disabled={!allUploaded || !requiredSet || submitting}
                            onClick={() => setSubmit(true)}
                          >
                            {t("order.form.create")}
                          </Button>
                        </GridColumn>
                      </Grid>
                    </GridColumn>
                  </GridRow>
                </Grid>
              )}
            </Mutation>
          </GridColumn>
        </GridRow>
      </Grid>
    </Container>
  );
});
OrderNew.displayName = "OrderNew";
