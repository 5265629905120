import React, { useState, useEffect } from "react";

export const useForm = <T extends { [s: string]: unknown }>(
  callback?: (values: T) => void,
  initialValues: Partial<T> = {} as Partial<T>,
  required?: Array<keyof T>
) => {
  const [allRequiredSet, setAllRequiredSet] = useState<boolean>(!required);

  const [values, setValues] = useState<T>(initialValues as T);
  let manualCallback: (values: T) => void;

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (callback) {
      callback(values);
    }

    if (manualCallback) {
      manualCallback(values);
    }
  };

  useEffect(() => {
    checkAllRequiredSet();
  }, [values]);

  const checkAllRequiredSet = () => {
    if (required) {
      const isSet = required.every(requiredField => !!values[requiredField]);
      setAllRequiredSet(isSet);
    }
  };

  const manualChange = (name: string, value: string | undefined) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const setCallback = (cb: (values: T) => void) => (manualCallback = cb);

  const handleChange = (event: React.ChangeEvent<{ name: string; value: string }>) => {
    event.persist();
    setValues(prevValues => ({ ...prevValues, [event.target.name]: event.target.value }));
  };

  return {
    setCallback,
    handleChange,
    manualChange,
    handleSubmit,
    values,
    allRequiredSet,
    stateHandler: setValues,
  };
};
