import gql from "graphql-tag";
import { UserFragments } from "@/graphql/Fragments";

export const PROFILE = gql`
  query userProfile {
    userProfile {
      ...userProfileFields
    }
  }
  ${UserFragments.user}
`;
