import React, { useContext } from "react";
import { ApplicationContext } from "../ApplicationProvider";
import styled, { GrabCADApplicationColors } from "grabcad-ui-elements";
import { IShopJob } from "../../graphql/Queries/Job";
import { Snapshot } from "../Shared/Snapshot";
import { IShopMaterial } from "../../graphql/Queries/Material";
import { TIME_UNITS } from "../../shopConstants";
import { ExpandAutoEstimationParametersButton } from "../Order/Items/ExpandAutoEstimationParametersButton";
import { IAutoEstimationParametersProps } from "../Order/Items/AutoEstimationParameters";
import { AssetType } from "../../graphql/Fragments/AutoEstimation";
import { DownloadAutoEstimationAssetButton } from "../Order/Items/AutoEstimations";
import { stringifyGCPVersion } from "../Shared/AutoEstimations";

export interface IBuildReportProps {
  job: IShopJob;
  onPreviewClick: () => void;
  shopMaterial: IShopMaterial | undefined;
  estimatedMaterial: number | undefined;
  estimatedSupport: number | undefined;
  estimatedTime: number | undefined;
  materialUnitLabel: string;
  supportUnitLabel: string;
  timeUnit: TIME_UNITS;
  detailedProps: IAutoEstimationParametersProps | undefined; // for "view details" -> AutoEstimationParameters component
}

const StyledBuildReport = styled.div`
  margin: 20px 0 0 40px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 50px;
  .property-name-and-value {
    white-space: nowrap;
    margin: 10px 0px;
    span {
      font-weight: 700;
      margin-right: 5px;
    }
  }
  .auto-estimation-snapshot-error {
    padding: 200px;
  }
  div {
    img {
      max-height: 500px;
    }
  }
  .preview {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #003393;
    padding: 20px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #d3d3d3;
  }
  .snapshot {
    margin-top: 3px;
    border: 6px solid #d3d3d3;
  }
`;

const CurrentEstimate = styled.div`
  background-color: ${GrabCADApplicationColors.GC_SUCCESS_SEMANTIC};
  border-color: inherit;
  color: white;
  padding: 2px 5px 2px 5px;
  border-radius: 2px;
  margin-top: 2px;
  display: inline-block;
`;

const StyledDownloadContainer = styled.div`
  .download-button {
    justify-content: right;
    margin-right: 30px;
  }
`;

/**
 * Build report tab for the Job estimates modal
 */
export const BuildReport = (props: IBuildReportProps): JSX.Element => {
  const { t, formatDate, formatTime } = useContext(ApplicationContext);
  const estimation = props.job.autoEstimation;
  const snapshotAsset = estimation?.autoEstimationAssets?.[0];
  const projectFileAsset = estimation?.autoEstimationAssets?.find(
    aeAsset => aeAsset.asset.assetType === AssetType.AUTO_ESTIMATION
  );
  const totalParts =
    estimation?.resultData?.estimates?.[0]?.trayModels?.reduce(
      (total, modelInfo) => total + modelInfo.quantity,
      0
    ) ?? 0;
  const gcpVersion = estimation?.resultData?.gcpVersion
    ? stringifyGCPVersion(estimation.resultData.gcpVersion)
    : null;

  return (
    <StyledBuildReport>
      {/* First column: snapshot image */}
      <div style={{ width: "350px" }} className="snapshot" data-testid="snapshotPreview">
        <Snapshot asset={snapshotAsset}></Snapshot>
        <div className="preview" onClick={props.onPreviewClick}>
          {t("auto_estimation.preview")}
        </div>
      </div>
      {/* Second column: estimate details */}
      <div data-testid="job-estimate-details">
        <CurrentEstimate>
          {t("shop.job.jobEstimatesModal.buildReport.currentEstimate")}
        </CurrentEstimate>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.totalParts")}</span>
          {totalParts}
        </div>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.dateGenerated")}</span>
          {formatDate(estimation?.dateUpdated)} {formatTime(estimation?.dateUpdated)}
        </div>
        {gcpVersion && (
          <div className="property-name-and-value">
            <span>{t("shop.job.jobEstimatesModal.buildReport.gcpVersion")}</span>
            {` ${gcpVersion}`}
          </div>
        )}
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.estimatedUsing")}</span>
          {estimation?.resultData?.printerModel}
        </div>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.trayMaterial")}</span>
          {props.shopMaterial?.appMaterial.displayName || t("shop.job.material.any")}
        </div>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.materialEstimate")}:</span>
          {`${props.estimatedMaterial} ${props.materialUnitLabel}`}
        </div>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.supportEstimate")}:</span>
          {`${props.estimatedSupport} ${props.supportUnitLabel}`}
        </div>
        <div className="property-name-and-value">
          <span>{t("shop.job.jobEstimatesModal.buildReport.timeEstimate")}:</span>
          {`${props.estimatedTime} ${t(`order.items.rates.units.${props.timeUnit.toLowerCase()}`)}`}
        </div>
        {props.detailedProps && (
          <ExpandAutoEstimationParametersButton
            parameters={props.detailedProps.parameters}
            materialDisplayUnit={props.detailedProps.materialDisplayUnit}
            estimateMaterialQuantity={props.detailedProps.estimateMaterialQuantity}
            supportMaterialQuantity={props.detailedProps.supportMaterialQuantity}
            materials={props.detailedProps.materials}
          ></ExpandAutoEstimationParametersButton>
        )}
      </div>
      <StyledDownloadContainer>
        {projectFileAsset && (
          <DownloadAutoEstimationAssetButton
            id={projectFileAsset.id}
            assetType="auto_estimation"
          ></DownloadAutoEstimationAssetButton>
        )}
      </StyledDownloadContainer>
    </StyledBuildReport>
  );
};
