import React from "react";
import { StyledProgress } from "./StyledProgress";
import {
  AutoEstimation,
  AutoEstimationEstimate,
  AutoEstimationResultData,
  GCPVersion,
} from "@/graphql/Fragments/AutoEstimation";
import {
  CUBIC_MMs_PER_CUBIC_M,
  FRACTIONAL_UNITS,
  G_PER_KG,
  transformMinutesToDisplayUnits,
  transformSIUnitsToRoundedDisplayUnits,
} from "../Order/ItemsList/shopRatesUtils";
import {
  MATERIAL_QUANTITY,
  MATERIAL_QUANTITY_UNITS,
  MATERIAL_UNITS,
  TECHNOLOGY,
  TIME_UNITS,
  VOLUME_UNIT,
  WEIGHT_UNIT,
  getMachineTechnologyMaterialQuantity,
  getMachineTechnologySupportQuantity,
} from "@/shopConstants";
import { IOrderItem } from "../../graphql/Fragments/Order";
import { IShopMachine } from "../../graphql/Fragments/ShopMachine";

export const AUTOESTIMATIONS_POLL_FREQ = 5000;

export interface EstimatedUnitResults {
  estimatedMaterialCubicMm?: number;
  estimatedSupportCubicMm?: number;
  estimatedMaterialGrams?: number;
  estimatedSupportGrams?: number;
  estimatedMaterialSquareMm?: number;
  estimatedMaterialMm?: number;
  estimatedMinutes?: number;
}

export interface AutoEstimationError {
  errorName: AutoEstimationErrorType;
  data?: { quantity: number };
}

export enum AutoEstimationErrorType {
  MODEL_OUTSIDE_TRAY = "ModelOutsideTrayError",
  MODEL_QUANTITY_OVERFLOW = "ModelQuantityOverflow",
}

/**
 * For auto estimations in a job we want to highlight an order item for these three cases:
 *
 * 1. The model is too big for the printer (in which case we can get back the error sent from the plugin)
 * 2. There is no more room on the first tray for this model
 * 3. Some of the duplicates for this model do not fit on the first tray
 *
 */
export const getEstimationError = (
  resultData: AutoEstimationResultData | undefined,
  orderItem: IOrderItem | undefined
): AutoEstimationError | undefined => {
  if (resultData && orderItem) {
    // cannot be precise here - if some parts take space on (first) tray(s), can other parts fit at all?
    const firstTray = resultData.estimates?.[0];
    const hasPartsOnTray = firstTray
      ? !!firstTray.trayModels.find(model => model.quantity > 0)
      : false;
    if (!hasPartsOnTray) {
      // if first tray is empty, no parts can fit on any tray
      return { errorName: AutoEstimationErrorType.MODEL_OUTSIDE_TRAY };
    }

    let qtyEstimated = 0;
    resultData.estimates?.forEach(estimate => {
      // be prepared for multiple trays returned!
      estimate.trayModels.find(model => {
        // there can only be one on each tray!
        if (model.orderItemId === orderItem.id) {
          qtyEstimated += model.quantity;
          return true;
        }
      });
    });

    if (qtyEstimated < orderItem.quantity) {
      return {
        errorName: AutoEstimationErrorType.MODEL_QUANTITY_OVERFLOW,
        data: { quantity: orderItem.quantity - qtyEstimated },
      };
    }
  }
};

/**
 * For CMB/.print files, we want to select most used material for display
 * @param resultData auto estimation result data
 * @returns string
 */
export const getMostUsedMaterialName = (
  resultData: AutoEstimationResultData | undefined
): string | undefined => {
  let val = -1;
  let name;
  resultData?.estimates[0]?.materials.forEach(mat => {
    if (!mat.isSupport && mat.estimate > val) {
      val = mat.estimate;
      name = mat.displayName;
    }
  });
  return name;
};

export const getAutoEstimatedValues = (
  resultData?: AutoEstimationResultData
): EstimatedUnitResults => {
  // TODO GC-81780: Share extraction/conversion logic with shop-server/services/AutoEstimation
  const firstTray = resultData?.estimates[0];
  if (!firstTray) {
    return {};
  }

  const materialSI = firstTray?.materials?.reduce(
    (sum, material) => (material.isSupport ? sum : (sum || 0) + material.estimate),
    0 as number
  );
  const supportSI = firstTray?.materials.find((mat: any) => mat.isSupport)?.estimate || 0;

  let estimatedMaterialCubicMm: number | undefined;
  let estimatedMaterialGrams: number | undefined;
  let estimatedSupportCubicMm: number | undefined;
  let estimatedSupportGrams: number | undefined;

  if (resultData.printerTechnology === "PolyJet") {
    const materialGrams = materialSI * G_PER_KG;
    const supportGrams = supportSI * G_PER_KG;

    if (materialGrams) {
      estimatedMaterialGrams = Math.round(materialGrams * FRACTIONAL_UNITS);
    }
    if (supportGrams) {
      estimatedSupportGrams = Math.round(supportGrams * FRACTIONAL_UNITS);
    }
  } else {
    const materialCubicMM = materialSI * CUBIC_MMs_PER_CUBIC_M;
    const supportCubicMM = supportSI * CUBIC_MMs_PER_CUBIC_M;

    if (materialCubicMM) {
      estimatedMaterialCubicMm = Math.round(materialCubicMM * FRACTIONAL_UNITS);
    }
    if (supportCubicMM) {
      estimatedSupportCubicMm = Math.round(supportCubicMM * FRACTIONAL_UNITS);
    }
  }

  const estimatedSeconds = firstTray?.duration || 0;
  const estimatedMinutes = Math.round((estimatedSeconds / 60) * FRACTIONAL_UNITS);

  return {
    estimatedMaterialCubicMm,
    estimatedMaterialGrams,
    estimatedSupportCubicMm,
    estimatedSupportGrams,
    estimatedMinutes,
  };
};

/**
 * Returns the total material, support, and time for a single auto estimation in the
 * given units. Also returns flags indicating whether the desired units for material and
 * support are consistent with the estimate quantity - in the past we allowed users to
 * select inconsistent units for machines.
 */
export function autoEstimateToCustomUnits({
  estimate,
  technologyName,
  machineDisplayName,
  materialUnit,
  supportUnit,
  timeUnit,
  materialDecimalPlaces,
}: {
  estimate: AutoEstimationEstimate;
  technologyName: TECHNOLOGY;
  machineDisplayName: string | undefined;
  materialUnit: MATERIAL_UNITS;
  supportUnit: MATERIAL_UNITS;
  timeUnit: TIME_UNITS;
  materialDecimalPlaces?: number;
}): {
  estimatedMaterial: number;
  estimatedSupport: number;
  estimatedTime: number;
  estimateMaterialQuantity: MATERIAL_QUANTITY;
  supportMaterialQuantity: MATERIAL_QUANTITY;
  materialUnitsAreValid: boolean;
  supportUnitsAreValid: boolean;
} {
  const materialSI = estimate?.materials
    ?.map(mat => (!mat.isSupport && mat.estimate) || 0)
    .reduce((x, y) => x + y, 0);

  const supportSI = estimate.materials.find(mat => mat.isSupport)?.estimate ?? 0;

  const estimateMaterialQuantity =
    getMachineTechnologyMaterialQuantity(technologyName, machineDisplayName)?.[0] ??
    MATERIAL_QUANTITY.VOLUME;

  const materialUnitsAreValid =
    MATERIAL_QUANTITY_UNITS[estimateMaterialQuantity].includes(materialUnit);

  const estimatedMaterial =
    transformSIUnitsToRoundedDisplayUnits(
      materialSI,
      estimateMaterialQuantity,
      materialUnit as VOLUME_UNIT | WEIGHT_UNIT,
      materialDecimalPlaces
    ) ?? 0;

  const supportMaterialQuantity =
    getMachineTechnologySupportQuantity(technologyName, machineDisplayName)?.[0] ??
    MATERIAL_QUANTITY.VOLUME;

  const supportUnitsAreValid =
    MATERIAL_QUANTITY_UNITS[supportMaterialQuantity].includes(supportUnit);

  const estimatedSupport =
    transformSIUnitsToRoundedDisplayUnits(
      supportSI,
      supportMaterialQuantity,
      supportUnit as VOLUME_UNIT | WEIGHT_UNIT,
      materialDecimalPlaces
    ) ?? 0;

  const estimatedSeconds = estimate.duration;
  const estimatedMinutes = estimatedSeconds ? estimatedSeconds / 60 : 0;
  const estimatedTime = transformMinutesToDisplayUnits(
    estimatedMinutes * FRACTIONAL_UNITS,
    timeUnit
  );

  return {
    estimatedMaterial,
    estimatedSupport,
    estimatedTime,
    estimateMaterialQuantity,
    supportMaterialQuantity,
    materialUnitsAreValid,
    supportUnitsAreValid,
  };
}

export const EstimationProgressBar = ({
  inProgress,
  progress,
}: {
  inProgress: boolean;
  progress: number | null;
}): JSX.Element | null => {
  if (!inProgress) {
    return null;
  }
  return (
    <div style={{ position: "relative" }}>
      <StyledProgress
        percent={(progress || 0) * 100}
        size="tiny"
        color={"blue"}
        attached="bottom"
      />
    </div>
  );
};

export const findAutoEstimationWithMatchingOrientationSnapshots = (
  item: IOrderItem,
  itemShopMachineToMatch: IShopMachine | undefined // currently used for PJ only
): AutoEstimation | undefined => {
  if (item.autoEstimations) {
    for (const ae of item.autoEstimations) {
      const autoEstimationData = ae.resultData;
      const firstTray = autoEstimationData?.estimates[0];
      const orientations = firstTray?.orientationOptions;
      if (!orientations || orientations.length < 1) {
        continue;
      }
      if (ae.id === item.autoEstimationId) {
        return ae;
      }
      if (ae.modelUnits !== item.units) {
        continue;
      }

      if (ae.shopMaterialId === item.shopMaterialId) {
        return ae;
      }

      if (itemShopMachineToMatch) {
        //For PJ, we want to find an auto estimation done on the same machine
        for (let material of itemShopMachineToMatch.materials) {
          // so find out if one of the item machine materials is te ae one (material contains machine!)
          if (material.id === ae.shopMaterialId) {
            return ae;
          }
        }
      }
    }
  }

  return undefined;
};

export const stringifyGCPVersion = ({ major, minor, patch, meta }: GCPVersion) =>
  [major, minor, patch, meta].join(".");
