import { Query } from "@apollo/client/react/components";
import { IUploadContainer } from "@/components/Upload/CadDropZone";
import { CAD_QUERY, CAD_SUBSCRIPTION, ICadFile, ICadQueryParams } from "@/graphql/Mutations/Upload";
import React, { useContext } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { SubscribeFunction, UnsubscribeFunction } from "@/graphql/subscription";

export const UploadedCadFile = ({
  file,
  children,
}: {
  file: IUploadContainer;
  children: ({
    uploadResponse,
    subscribeToMoreFiles,
  }: {
    uploadResponse: IUploadContainer;
    subscribeToMoreFiles: SubscribeFunction;
  }) => React.ReactNode;
}): JSX.Element => {
  const { currentShop } = useContext(ApplicationContext);
  if (file?.cadFile) {
    return (
      <Query<{ cadFile: ICadFile }, ICadQueryParams>
        query={CAD_QUERY}
        variables={{
          id: file.cadFile.id,
          shopId: currentShop?.id,
        }}
      >
        {({ loading, data, subscribeToMore }) => {
          const subscribeToMoreFiles = (): UnsubscribeFunction =>
            subscribeToMore<{ cadProcessed: ICadFile }>({
              document: CAD_SUBSCRIPTION,
              variables: {
                id: (file.cadFile as ICadFile).id,
                shopId: currentShop?.id,
              },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                  return prev;
                }
                const newPreview = subscriptionData.data.cadProcessed.preview;
                return {
                  cadFile: {
                    ...prev.cadFile,
                    preview: newPreview,
                  },
                };
              },
            });
          if (loading || !data || !data.cadFile || !children) {
            return null;
          }
          file.cadFile = data.cadFile;
          return children({ subscribeToMoreFiles, uploadResponse: file }) as JSX.Element;
        }}
      </Query>
    );
  }
  return children({ subscribeToMoreFiles: () => undefined, uploadResponse: file }) as JSX.Element;
};
