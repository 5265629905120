import { ApplicationContext } from "@/components/ApplicationProvider";
import { Icon, MenuItem, Loader } from "grabcad-ui-elements";
import React, { useContext } from "react";
import { ROUTES } from "@/shopConstants";
import { ISidebarMenuProps, BackLink } from "../ShopSidebar";
import { useShopTechnologies } from "@/utils/queryHooks";

export const TechnologiesMenuItems = ({
  shopId,
  history,
  technologyId,
}: ISidebarMenuProps & { technologyId?: number }): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { loadingTechnologies, shopTechnologies } = useShopTechnologies();

  const ordersMenuItem = (
    <MenuItem onClick={() => history.push(ROUTES.SHOP(shopId).ORDERS)}>
      <BackLink>
        <Icon name="triangle left" />
        <span className="text">{t("layout.shopSidebar.orders")}</span>
      </BackLink>
    </MenuItem>
  );

  if (loadingTechnologies) {
    return (
      <>
        {ordersMenuItem}
        <MenuItem>
          <Loader active={true} size="small" />
        </MenuItem>
      </>
    );
  }

  const technologiesWithMachines = shopTechnologies.filter(tech =>
    tech.shopMachines.some(machine => !machine.dateDeleted)
  );
  return (
    <>
      {ordersMenuItem}
      {technologiesWithMachines.map(shopTechnology => (
        <MenuItem
          key={shopTechnology.id}
          name={shopTechnology.appTechnology.displayName}
          active={shopTechnology.id === technologyId}
          onClick={() => {
            history.push(`${ROUTES.SHOP(shopId).MACHINES.TECHNOLOGY(shopTechnology.id)}`);
          }}
        >
          <span className="text">{shopTechnology.appTechnology.displayName}</span>
          <Icon name="caret right" />
        </MenuItem>
      ))}
    </>
  );
};
TechnologiesMenuItems.displayName = "TechnologiesMenuItems";
