import styled from "grabcad-ui-elements";

export const CardLinkWrapper = styled.div`
  display: flex;
  a {
    max-width: 100%;
  }
  .ui.card {
    height: 100%;
    .image {
      display: flex;
      align-items: center;
      flex-grow: 1;
      background: none;
      max-height: 200px;
      > .ribbon.label {
        top: 0.5rem;
        left: calc(100% + -0.05rem + 1.2em + 1px);
      }
    }
    img {
      max-height: 200px;
      object-fit: scale-down;
    }
    .header {
      position: relative;
      padding-right: 10px;
      .name {
        color: #4183c4; /* This matches default semantic <a> style, without nesting <a>s */
      }
      .shop-name {
        color: #444;
      }
    }
    .content {
      flex-grow: 0;
      word-break: break-word;
      @supports (-ms-ime-align: auto) {
        word-break: break-all;
      }
    }
  }
`;
