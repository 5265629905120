import React, { useContext } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { MenuItem, Icon, Image } from "grabcad-ui-elements";
import { ISidebarMenuProps } from "../ShopSidebar";
import { SHOP, ROUTES } from "@/shopConstants";
import { Permission } from "@/utils/Permission";
import { UiCan } from "@/components/UiCan";
import machines from "../../../../assets/icons/machines.svg";
import info from "../../../../assets/icons/info.svg";
import orders from "../../../../assets/icons/orders.svg";
import preferences from "../../../../assets/icons/preferences.svg";
import jobs from "../../../../assets/icons/jobs.svg";

export const ShopMenuItems = ({ shopId, history, section }: ISidebarMenuProps): JSX.Element => {
  const {
    t,
    currentShop,
    currentUser: { userProfile },
  } = useContext(ApplicationContext);

  return (
    <>
      <MenuItem
        id="qa-sideMenu-orders"
        active={section === SHOP.SECTIONS.orders}
        name={SHOP.SECTIONS.orders}
        onClick={() => history.push(ROUTES.SHOP(shopId).ORDERS)}
        data-testid="menuItem"
      >
        <Image src={orders} />
        <span className="text">{t("layout.shopSidebar.orders")}</span>
      </MenuItem>
      <UiCan do={Permission.VIEW_JOBS} on={currentShop}>
        <div className="job-settings">
          <MenuItem
            id="qa-sideMenu-jobs"
            active={section === SHOP.SECTIONS.jobs}
            name={SHOP.SECTIONS.jobs}
            onClick={() => history.push(`${ROUTES.SHOP(shopId).JOBS.LIST}`)}
          >
            <Image src={jobs} />
            <span className="text">{t("shop.jobs")}</span>
          </MenuItem>
        </div>
      </UiCan>
      <UiCan do={Permission.SHOP_INFO_VIEW} on={currentShop}>
        <div className="shop-settings">
          <MenuItem
            id="qa-sideMenu-shopInfo"
            active={section === SHOP.SECTIONS.info}
            name={SHOP.SECTIONS.info}
            onClick={() => history.push(`${ROUTES.SHOP(shopId).INFO}`)}
          >
            <Image src={info} />
            <span className="text">{t("layout.shopSidebar.info")}</span>
          </MenuItem>

          <UiCan do={Permission.WRITE_MACHINES} on={currentShop}>
            <MenuItem
              id="qa-sideMenu-machines"
              active={section === SHOP.SECTIONS.machines}
              name={SHOP.SECTIONS.machines}
              onClick={() => history.push(`${ROUTES.SHOP(shopId).MACHINES.INDEX}`)}
            >
              <Image src={machines} />
              <span className="text">{t("layout.shopSidebar.machines")}</span>
              <Icon name="caret right" />
            </MenuItem>
          </UiCan>
          <UiCan do={Permission.CREATE_SHOP} on={userProfile}>
            <MenuItem
              id="qa-sideMenu-preferences"
              active={section === SHOP.SECTIONS.preferences}
              name={SHOP.SECTIONS.preferences}
              onClick={() => history.push(`${ROUTES.SHOP(shopId).PREFERENCES}`)}
            >
              <Image src={preferences} />
              <span className="text">{t("layout.shopSidebar.preferences")}</span>
            </MenuItem>
          </UiCan>
        </div>
      </UiCan>
    </>
  );
};
ShopMenuItems.displayName = "ShopMenuItems";
