import { Query } from "@apollo/client/react/components";
import React, { createContext, useState, useContext } from "react";
import { Grid, GridRow, GridColumn } from "grabcad-ui-elements";
import { SHOP } from "@/shopConstants";
import { General } from "@/screens/Shop/New/Sections";
import { RouteComponentProps, withRouter } from "react-router";
import { ShopSetupFooter } from "@/components/Shop/Setup/Footer";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { IShop } from "@/graphql/Fragments/Shop";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { isEqual } from "lodash";
import { ScreensShopMachines } from "../Machine/Machines";

interface IScreensShopNewProps {
  section: string;
  shopId: string | undefined;
}

interface IShopFormContext {
  generalCompleted: boolean;
  machinesCompleted: boolean;
  materialsCompleted: boolean;
  setGeneralCompleted: (value: boolean) => void;
  setMachinesCompleted: (value: boolean) => void;
  setMaterialsCompleted: (value: boolean) => void;
  section: string;
}

interface IShopResponse {
  shop?: IShop;
}

export const ShopFormContext = createContext({} as IShopFormContext);
ShopFormContext.displayName = "ShopFormContext";

const getSection = (section: string): JSX.Element => {
  switch (section) {
    case SHOP.SECTIONS.SETUP.general:
      return <General />;

    case SHOP.SECTIONS.machines:
      return <ScreensShopMachines isSetup={true} />;

    default:
      return <General />;
  }
};

export const ScreensShopNew = withRouter(
  ({
    match: {
      params: { shopId },
    },
    section,
  }: RouteComponentProps<IScreensShopNewProps> & { section?: string }) => {
    const selectedSectionForm = getSection(section || SHOP.SECTIONS.SETUP.general);
    const { t, currentShop, setCurrentShop } = useContext(ApplicationContext);

    const [generalCompleted, setGeneralCompleted] = useState(false);
    const [machinesCompleted, setMachinesCompleted] = useState(false);
    const [materialsCompleted, setMaterialsCompleted] = useState(false);

    return (
      <Query
        query={SHOP_DETAILS}
        variables={{ id: +shopId! }}
        skip={!shopId}
        fetchPolicy="network-only"
        onCompleted={({ shop }: IShopResponse) => {
          if (shop) {
            setGeneralCompleted(!!shop);
            setMachinesCompleted(!!shop.shopMachines && shop.shopMachines.length > 0);
            setMaterialsCompleted(
              !!shop.shopMachines &&
                shop.shopMachines.some(shopMachine => shopMachine.materials.length > 0)
            );

            if (!isEqual(shop, currentShop)) {
              setCurrentShop(shop);
            }
            // else Do *not* set shop again as that can result in infinite recursion between
            // setCurrentShop()
            // -> ApplicationContext changes
            // -> ApplicationContext.Consumer (including us) triggerred
            // -> we call setCurrentShop() again
          }
        }}
      >
        {({ loading }: { loading: boolean }) => {
          if (loading) {
            return <p>{t("global.loading")}</p>;
          }

          return (
            <ShopFormContext.Provider
              value={{
                generalCompleted,
                machinesCompleted,
                materialsCompleted,
                setGeneralCompleted,
                setMachinesCompleted,
                setMaterialsCompleted,
                section: section || SHOP.SECTIONS.SETUP.general,
              }}
            >
              <Grid>
                <GridRow>
                  <GridColumn>{selectedSectionForm}</GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn>
                    <ShopSetupFooter />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ShopFormContext.Provider>
          );
        }}
      </Query>
    );
  }
);
ScreensShopNew.displayName = "ScreensShopNew";
