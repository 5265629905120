import React, { useContext, useState } from "react";
import styled, {
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  keyframes,
  Button,
  Icon,
  TableFooter,
  Popup,
  Image,
  StyleMixins,
} from "grabcad-ui-elements";
import {
  IOrderItem,
  OrderItemOrImageOrFile,
  OrderItemOrImageOrFileType,
} from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { OrderEditItem } from "../ItemsList/EditItem";
import { isTypename } from "@/utils/typeUtils";
import { OrderImageItem } from "../ItemsList/ImageItem";
import { OrderFileItem } from "../ItemsList/FileItem";
import classnames from "classnames";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import Paperclip from "@/assets/icons/paperclip.svg";
import {
  IconButton,
  OrderItemDependenciesPanel,
} from "@/components/Shared/OrderItemDependenciesPanel";
import { AutoEstimations } from "./AutoEstimations";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEM } from "@/graphql/Mutations/OrderItem";
import { StyledProgress } from "../../Shared/StyledProgress";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { useApolloClient } from "@apollo/client";
import { OrderItemModal, OrderItemModalTab } from "../../../screens/Shop/Order/Show/OrderItemModal";

interface IOrderItemsProps {
  items: IOrderItem[];
  isWritable: boolean;
  orderId: number;
  prevItemCount?: number;
  selectedOrderItems: number[];
  toggleSelection: (item: OrderItemOrImageOrFile) => void;
  selectedFileItems: number[];
  selectedImageItems: number[];
}

const OrderItemsTable = styled(Table)`
  ${StyleMixins.roundAndShadow}
  margin-bottom: 30px;
  tfoot {
    background-color: #f3f3f3;
    th.attachFileButton,
    th.expandPanelButton {
      padding: 0;
      display: flex;
    }
    th.attachFileButton {
      justify-content: flex-start;
    }
    th.expandPanelButton {
      padding: 5px 10px 5px 0;
      justify-content: flex-end;
    }
  }
`;

const flashBlue = keyframes`
  from {
    background: #9ad4ff;
  }
  to {
    background: #ffffff;
  }
`;

export const OrderItemsTableRow = styled(TableRow)`
  display: flex;
  min-width: 715px;
  position: relative;
  &.just-added {
    animation: ${flashBlue} 1s ease-out 1;
  }
  &.loading {
    opacity: 0.6;
  }
  td > .ui.loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .num {
    flex: 0 0 30px;
    padding-right: 0 !important;
  }
  .img {
    flex: 0 1 166px;
    min-width: 130px;
    img {
      max-width: 100% !important;
      height: auto;
    }
  }
  .details {
    flex: 0 0 166px;
    max-width: 166px;
  }
  .status {
    flex: 0 1 166px;
    max-width: 166px;
    min-width: 150px;
    &.aligned-status {
      padding-top: 2.5rem;
    }
    .ui.disabled.dropdown {
      opacity: 1;
      > .dropdown.icon {
        display: none;
      }
    }
    .ui.selection.dropdown {
      .text {
        white-space: normal;
      }
    }
  }
  .price {
    flex: 0 1 150px;
    text-align: right !important;
    min-width: 90px;
    .price-calculator-link {
      padding: 10px 10px 8px 6px;
      margin-right: -12px;
      cursor: pointer;
      &.disabled {
        cursor: default;
        opacity: 50%;
      }
    }
    .calculator {
      display: inline-block;
    }
  }

  .model-settings {
    flex: 1 0 150px;
    word-break: break-word;
    @supports (-ms-ime-align: auto) {
      word-break: break-all;
    }
    .file-name {
      position: absolute;
      padding-right: 25px;
    }
  }
  .ui.list .item .item-label {
    margin-bottom: 8px;
    font-weight: normal;
    &.item-quantity {
      margin-top: 21px; /* to align it with the material picker dropdown */
    }
    &.material-estimate {
      margin-top: 34px; /* to align it with the material picker dropdown */
      margin-bottom: 10px;
    }
  }
  .input-warning {
    flex-basis: 30px;
    align-self: center;
  }
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .quantity-input.ui.input > input {
    max-width: 70px !important;
    &.disabled {
      opacity: 1;
    }
  }
  .attach-dependency-bar {
    padding: 8px 10px;
  }
  .files-attached {
    padding: 12px 5px;
    color: #003393;
  }
  .no-files-attached {
    padding: 12px 5px;
    color: grey;
  }
  .no-attachment-pin {
    padding: 12px 15px;
    color: #003393;
  }
  .gcp-label {
    border: 1px solid #e4e7e7;
    border-radius: 4px;
    padding: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 2.2em;
    /* hard-coded to behave nicely on hover */
    /* remove after hover is remove from items table GC-94482*/
    background-color: white;
  }
`;

const ExpandButtonContainer = styled(TableHeaderCell)`
  .ui.button.secondary {
    border: 0 !important;
    background-color: inherit !important;
    &:hover {
      background-color: #e0ecfc !important;
    }
  }
`;

const StyledAutoEstimationsHeader = styled.div`
  position: relative;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px 10px 2px 0px;
    h4 {
      margin: 0;
      padding: 8px;
      cursor: pointer;
    }
    > span {
      font-style: italic;
      opacity: 0.8;
    }
  }
`;

export const OrderItems = ({ items, ...rest }: IOrderItemsProps): JSX.Element => (
  <>
    {[...items].sort(sortOrderItem).map((item, index) => (
      <ItemRow item={item} index={index} {...rest} key={item.id} />
    ))}
  </>
);

const ItemRow = ({
  item,
  index,
  selectedOrderItems,
  isWritable,
  orderId,
  prevItemCount,
  toggleSelection,
  selectedFileItems,
  selectedImageItems,
}: Omit<IOrderItemsProps, "items"> & { item: IOrderItem; index: number }): JSX.Element | null => {
  const { t, formatDate } = useContext(ApplicationContext);
  const client = useApolloClient();
  const { children, childImages, childFiles } = item;

  const [updateOrderItem] = useMutation<any, any>(UPDATE_ORDER_ITEM);
  const [openFileDialogCounter, setOpenFileDialogCounter] = useState(1);
  const [dependenciesPanelOpen, setDependenciesPanelOpen] = useState(true);
  const [autoEstimationsPanelOpen, setAutoEstimationsPanelOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewId, setPreviewId] = useState(0);

  const itemChildrenCount =
    (children?.length || 0) + (childImages?.length || 0) + (childFiles?.length || 0);

  const selectedAutoEstimation = item.autoEstimations?.find(
    estimation => estimation.id === item.autoEstimationId
  );

  let autoEstimationsHeader = undefined;
  if (selectedAutoEstimation?.queuePosition) {
    autoEstimationsHeader = t("auto_estimation.header.inQueue", {
      position: selectedAutoEstimation.queuePosition,
    });
  } else if (selectedAutoEstimation && !selectedAutoEstimation.resultData) {
    autoEstimationsHeader = t("auto_estimation.header.inProgress");
  } else if (item.autoEstimations?.length) {
    autoEstimationsHeader = t("auto_estimation.header.singular");
    if (item.autoEstimations?.length > 1) {
      autoEstimationsHeader = t("auto_estimation.header.plural");
    }
  }

  function isOrderItemFilter<T extends OrderItemOrImageOrFile>(
    file: T | null | undefined
  ): file is T {
    return !!file && isTypename<OrderItemOrImageOrFileType>("OrderItem")(file);
  }
  function isImageFilter<T extends OrderItemOrImageOrFile>(file: T | null | undefined): file is T {
    return !!file && isTypename<OrderItemOrImageOrFileType>("Image")(file);
  }
  function isFileFilter<T extends OrderItemOrImageOrFile>(file: T | null | undefined): file is T {
    return !!file && isTypename<OrderItemOrImageOrFileType>("GenericFile")(file);
  }

  const composeFooter = () => (
    <TableFooter>
      <OrderItemsTableRow data-testid="orderItemsTableRow">
        <TableHeaderCell
          className="attachFileButton"
          style={{ width: itemChildrenCount > 0 ? "50%" : "100%" }}
          data-testid="tableHeaderCell"
        >
          {isWritable ? (
            <div className="attach-dependency-bar" data-testid="attachDependencyBar">
              <Popup
                trigger={
                  <IconButton
                    className={"qa-attach-orderItemFile"}
                    onClick={() => setOpenFileDialogCounter(openFileDialogCounter + 1)}
                  >
                    <Image src={Paperclip} style={{ paddingTop: "3px", paddingLeft: "6px" }} />
                  </IconButton>
                }
                content={t("order.items.attachSupportingFiles")}
                offset={[0, 5]}
                position={"bottom left"}
                inverted
              />
            </div>
          ) : null}
          <div
            className={
              !isWritable
                ? "no-attachment-pin"
                : itemChildrenCount > 0
                ? "files-attached"
                : "no-files-attached"
            }
            data-testid="attachmentStyle"
          >
            {itemChildrenCount > 1
              ? t("order.items.dependency.filesAttached", { num: itemChildrenCount })
              : itemChildrenCount === 1
              ? t("order.items.dependency.fileAttached", { num: itemChildrenCount })
              : t("order.items.dependency.noFilesAttached")}
          </div>
        </TableHeaderCell>
        {itemChildrenCount > 0 && (
          <ExpandButtonContainer className="expandPanelButton" style={{ width: "50%" }}>
            <Button
              className="qa-collapse-dependencies-panel"
              size="small"
              secondary={true}
              onClick={() => setDependenciesPanelOpen(!dependenciesPanelOpen)}
              data-testid="collapseDependenciesPanelButton"
            >
              {t(dependenciesPanelOpen ? "layout.collapse" : "layout.expand")}
              <Icon name={dependenciesPanelOpen ? "triangle up" : "triangle down"} />
            </Button>
          </ExpandButtonContainer>
        )}
      </OrderItemsTableRow>
    </TableFooter>
  );

  const composeOrderItemTableRow = (): JSX.Element => {
    const classNames = classnames({ "just-added": prevItemCount && index >= prevItemCount });
    if (isOrderItemFilter(item)) {
      return (
        <OrderEditItem
          isOrderWritable={isWritable}
          orderId={orderId}
          key={`${item.id}_${index}`}
          index={index}
          item={item}
          isSelected={selectedOrderItems.includes(item.id)}
          toggleSelection={toggleSelection}
          classNames={classNames}
        />
      );
    }
    if (isImageFilter(item)) {
      return (
        <OrderImageItem
          item={item}
          index={index}
          key={index}
          classNames={classNames}
          selectedImageItems={selectedImageItems}
          toggleSelection={toggleSelection}
        />
      );
    }
    if (isFileFilter(item)) {
      return (
        <OrderFileItem
          item={item}
          index={index}
          key={index}
          classNames={classNames}
          selectedFileItems={selectedFileItems}
          toggleSelection={toggleSelection}
        />
      );
    }
    throw Error("Unable to resolve order item type!");
  };

  const tableHeaderOrderItem = (
    <TableHeader>
      <OrderItemsTableRow>
        <TableHeaderCell className="num" />
        <TableHeaderCell className="img" />
        <TableHeaderCell className="model-settings">
          {t("order.items.modelSettings")}
        </TableHeaderCell>
        <TableHeaderCell className="details">{t("order.items.buildDetails")}</TableHeaderCell>
        <TableHeaderCell className="status">{t("order.items.partStatus")}</TableHeaderCell>
        <TableHeaderCell className="price">{t("order.items.partPrice")}</TableHeaderCell>
      </OrderItemsTableRow>
    </TableHeader>
  );

  const tableHeaderFile = (
    <TableHeader>
      <OrderItemsTableRow>
        <TableHeaderCell className="num" />
        <TableHeaderCell className="img" />
        <TableHeaderCell className="model-settings">{t("order.items.fileName")}</TableHeaderCell>
        <TableHeaderCell className="details" />
        <TableHeaderCell className="status" />
        <TableHeaderCell className="price" />
      </OrderItemsTableRow>
    </TableHeader>
  );

  return (
    <OrderItemsTable id={`tbl_${item.id}`} key={`tbl_${item.id}`} data-testid="orderItemsTable">
      {isOrderItemFilter(item) ? tableHeaderOrderItem : tableHeaderFile}
      <TableBody>
        {composeOrderItemTableRow()}
        <tr className="qa-autoEstimationsRow" data-testid="autoEstimationsRow">
          <td colSpan={5} style={{ padding: 0 }}>
            {autoEstimationsHeader && (
              <StyledAutoEstimationsHeader>
                <div>
                  <h4 onClick={() => setAutoEstimationsPanelOpen(!autoEstimationsPanelOpen)}>
                    <Icon name={autoEstimationsPanelOpen ? "triangle down" : "triangle right"} />
                    {autoEstimationsHeader}
                  </h4>
                  <span>
                    <div className="qa-estimatedWith">
                      {selectedAutoEstimation?.resultData &&
                        (selectedAutoEstimation.resultData.printerModel
                          ? t("auto_estimation.header.estimatedWith", {
                              machine: selectedAutoEstimation.resultData.printerModel,
                              date: formatDate(selectedAutoEstimation.dateUpdated.toString()),
                            })
                          : t("auto_estimation.header.estimatedOn", {
                              date: formatDate(selectedAutoEstimation.dateUpdated.toString()),
                            }))}
                    </div>
                  </span>
                </div>
                {!autoEstimationsPanelOpen &&
                  selectedAutoEstimation &&
                  !selectedAutoEstimation.queuePosition &&
                  !selectedAutoEstimation.resultData && (
                    <StyledProgress
                      percent={(selectedAutoEstimation.progress || 0) * 100}
                      size="tiny"
                      color={"blue"}
                      attached="bottom"
                      data-testid="styledProgress"
                    />
                  )}
              </StyledAutoEstimationsHeader>
            )}
            {autoEstimationsPanelOpen && (
              <div style={{ position: "relative", top: -8 }}>
                <AutoEstimations
                  orderItem={item}
                  onSelect={autoEstimation => {
                    updateOrderItem({
                      variables: {
                        id: item.id,
                        input: { autoEstimationId: autoEstimation.id },
                      },
                      update: async (cache: any) => fetchNewOrderEvents(cache, client, orderId),
                    });
                  }}
                  onPreviewClick={(autoEstimationId: number) => {
                    setPreviewId(autoEstimationId);
                    setPreviewModalOpen(true);
                  }}
                />
              </div>
            )}
            {previewModalOpen && (
              <OrderItemModal
                open={previewModalOpen}
                orderItem={item}
                tab={OrderItemModalTab.preview}
                previewId={previewId}
                refetchOrderEvents
                orderId={orderId}
                onClose={() => setPreviewModalOpen(false)}
              ></OrderItemModal>
            )}
          </td>
        </tr>
        <OrderItemDependenciesPanel
          key={`panel_${item.id}`}
          orderItem={item}
          orderId={orderId}
          openFileDialogCounter={openFileDialogCounter}
          open={dependenciesPanelOpen}
          isWritable={isWritable}
        />
      </TableBody>
      {isOrderItemFilter(item) && (
        <UiCan passThrough do={`${Permission.WRITE}`} on={item}>
          {composeFooter()}
        </UiCan>
      )}
    </OrderItemsTable>
  );
};

OrderItems.displayName = "OrderItems";

function sortOrderItem(a: IOrderItem, b: IOrderItem): number {
  // sort by date (earlier comes first)
  let cmp = new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
  if (cmp) {
    return cmp;
  }
  // then by type (OrderItem before Image before GenericFile)
  cmp = a.__typename && -a.__typename.localeCompare(b.__typename);
  if (cmp) {
    return cmp;
  }
  // then by id
  cmp = a.id - b.id;
  return cmp;
}
