import gql from "graphql-tag";
import { ShopMachineFragments } from "../Fragments";

export const UPDATE_MATERIALS_AND_COLORS = gql`
  mutation updateShopMaterialsAndColors($input: MachineMaterialsAndColorsInput!) {
    updateShopMaterialsAndColors(input: $input) {
      ...shopMachineWithImage
    }
  }
  ${ShopMachineFragments.shopMachineWithImage}
`;
