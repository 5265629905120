import { ApplicationContext } from "@/components/ApplicationProvider";
import { Button, Checkbox, Header, Input, Modal, StyledModal } from "grabcad-ui-elements";
import React, { useContext, useState } from "react";
import classnames from "classnames";
import { getStatusName, StatusDropdown } from "../Order/Status/Status";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import { LabeledCheckbox } from "@/screens/Shop/Jobs/Job";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEMS_STATUS } from "@/graphql/Mutations/OrderItem";
import { IOrderItem } from "@/graphql/Fragments/Order";

interface IAnchorWithConfirmationProps {
  defaultJobName: string;
  onClose: () => void;
  createJob: (value: string) => void;
  selectedOrderItems: IOrderItem[];
}

export const CreateJobModal = ({
  onClose,
  createJob,
  defaultJobName,
  selectedOrderItems,
}: IAnchorWithConfirmationProps): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const MAX_LENGTH = 100;
  const [inputValue, setInputValue] = useState(defaultJobName);
  const [updatePartStatusesChecked, setUpdatePartStatusesChecked] = useState(true);
  const tooLongTooltip =
    inputValue.length > MAX_LENGTH ? t("shop.jobs.new.nameTooLong") : undefined;
  const [selectedStatus, setSelectedStatus] = useState(
    currentShop.shopStatuses.find(status => status.appStatus === "IN_PROGRESS") as IShopStatus
  );
  const [updatOrderItemsStatus] = useMutation<any, any>(UPDATE_ORDER_ITEMS_STATUS);

  return (
    <StyledModal closeIcon basic={false} open={true} onClose={onClose} data-testid="createJobModal">
      <Header content={t("shop.jobs.new.name")} />
      <Modal.Content>
        <b>{t("shop.jobs.name")}</b>
        <Input
          className={classnames("qa-inputModal-input", { error: !!tooLongTooltip })}
          data-tooltip={tooLongTooltip}
          data-visible
          onChange={e => setInputValue(e.target.value)}
          value={inputValue}
        />
        <LabeledCheckbox
          onClick={() => setUpdatePartStatusesChecked(!updatePartStatusesChecked)}
          className="qa-status-update-job-creation"
        >
          <Checkbox checked={updatePartStatusesChecked} />
          <b>{t("shop.job.completeModal.updatePartStatus")}</b>
        </LabeledCheckbox>
        <StatusDropdown
          statuses={currentShop.shopStatuses.filter(
            status => !status.isHidden && !status.dateDeleted && getStatusName(status, t)
          )}
          selectedStatus={selectedStatus}
          onSelect={setSelectedStatus}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary id="qa-inputModal-cancel" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button
          id="qa-inputModal-submit"
          onClick={async () => {
            createJob(inputValue.slice(0, MAX_LENGTH));
            if (updatePartStatusesChecked) {
              const partsToUpdateStatus = selectedOrderItems.filter(
                item => item.shopStatus !== selectedStatus.id
              );
              await updatOrderItemsStatus({
                variables: {
                  ids: partsToUpdateStatus.map(part => part.id),
                  shopStatus: selectedStatus.id,
                },
              });
            }
            onClose();
          }}
          disabled={inputValue.length === 0}
        >
          {t("general.save")}
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
