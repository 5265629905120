import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { TableCell, Popup } from "grabcad-ui-elements";
import classNames from "classnames";

export interface IOverflowTextPopupProps {
  cellClassName: string;
  children: React.ReactNode;
  isStretchy?: boolean;
  dataTestId?: string;
}

/**
 * Returns a TableCell component with text wrapping class (className `truncatable`) if text exceeds it's container's width.
 * In case the TableCell contains the wrapping class, a Popup is generated containing the full text and triggered on texts' mouseover.
 */
export const OverflowTextPopup = ({
  cellClassName,
  children,
  isStretchy,
  dataTestId,
}: IOverflowTextPopupProps): JSX.Element => {
  // Define state and function to update the value
  const [showPopup, setShowPopup] = useState(false);

  // Create a Ref for the text's DIV container
  const textElementRef = useRef<HTMLDivElement>(null);

  const compareSize = () => {
    if (!textElementRef.current?.parentElement) {
      return;
    }

    const { offsetWidth: parentOffsetWidht, offsetHeight: parentOffsetHeight } =
      textElementRef.current.parentElement;
    const { scrollWidth, scrollHeight } = textElementRef.current;
    // verify if the text overflows based on the offset it has in regards to its parent
    const shouldShowPopup = scrollWidth > parentOffsetWidht || scrollHeight > parentOffsetHeight;
    setShowPopup(shouldShowPopup);
  };

  let resizeId: NodeJS.Timeout;
  // equivalent to "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", () => {
      clearTimeout(resizeId);
      resizeId = setTimeout(() => {
        compareSize();
      }, 500);
    });
  }, []);

  useLayoutEffect(() => {
    compareSize();
  }, []);

  return (
    <TableCell
      className={classNames(
        cellClassName,
        { "truncatable-cell": !isStretchy },
        { stretchy: !!isStretchy }
      )}
      data-testid={dataTestId}
    >
      {showPopup ? (
        <Popup
          trigger={
            <div
              ref={textElementRef}
              className={classNames({ truncatable: !isStretchy, "stretchy-child": !!isStretchy })}
            >
              {children}
            </div>
          }
          content={children}
          position="bottom center"
          inverted
        />
      ) : (
        <div ref={textElementRef}>{children}</div>
      )}
    </TableCell>
  );
};
