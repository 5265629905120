import gql from "graphql-tag";
import { AppTechnologyFragments } from "@/graphql/Fragments";

export const APP_TECHNOLOGIES_LIST = gql`
  query {
    appTechnologies {
      ...appTechnologyFields
    }
  }
  ${AppTechnologyFragments.appTechnology}
`;

export const APP_TECHNOLOGY = gql`
  query GetAppTechnology($id: Int!) {
    appTechnology(id: $id) {
      ...appTechnologyFields
    }
  }
  ${AppTechnologyFragments.appTechnology}
`;
