import styled, { Popup } from "grabcad-ui-elements";
import React, { useRef, useState } from "react";

const StyledContextMenu = styled.div`
  .popupPos {
    position: absolute;
    width: 10px;
    height: 3px;
    top: 31px;
    right: 0px;
  }
  .icon.ellipsis {
    position: absolute;
    top: -1px;
    right: -9px;
    font-size: 0.8em;
    padding: 5px 20px 25px 10px;
    margin-right: -5px;
    &:before {
      color: #c1c1c1;
    }
    &:hover:before {
      color: #555;
    }
  }
`;

export const PopupMenu = styled(Popup)`
  &.ui.popup {
    padding: 0;
    z-index: 900;
    max-width: 147px;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
    &:before {
      z-index: 0;
    }
    &.ui.menu .item:before {
      background: none;
    }
  }

  /* Hack to work around https://github.com/Semantic-Org/Semantic-UI/issues/2584 */
  &.ui.popup[style].right {
    top: 21px !important;
    &:before {
      top: 19px;
    }
  }
`;

interface IContextMenuProps {
  menuItems: JSX.Element[];
}

export const ContextMenu = ({ menuItems }: IContextMenuProps): JSX.Element => {
  const [popupMenu, setPopupMenu] = useState(false);
  const popupPosRef = useRef(null);

  return (
    <StyledContextMenu>
      {/* .popupPos allows for precise Popup positioning,
          to the right or left of the ellipsis icon. */}
      <div className="popupPos" ref={popupPosRef} />
      <i
        className="qa-shopCard-ellipsis ellipsis vertical icon"
        onClick={e => {
          setPopupMenu(true);
          e.preventDefault();
          e.stopPropagation();
        }}
        data-testid="shopCardEllipsis"
      />
      <PopupMenu
        on="click"
        open={popupMenu}
        onClose={() => setPopupMenu(false)}
        className="ui vertical menu small"
        position="right center"
        context={popupPosRef}
        content={menuItems.map((menuItem: JSX.Element, i) => (
          <React.Fragment key={i}>{menuItem}</React.Fragment>
        ))}
        data-testid="popupMenu"
      />
    </StyledContextMenu>
  );
};
