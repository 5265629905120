import React, { useContext } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import styled, { Image } from "grabcad-ui-elements";
import { IOrderFilters } from "./List";
import { config } from "../../../config";
import exportIcon from "../../../assets/icons/export.svg";
import classnames from "classnames";
import { OrderListColumn } from "@/graphql/Fragments/Shop";
import ReactGA from "react-ga";

const StyledExportButton = styled.a`
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  line-height: 23px;
  height: 18px;
  outline: none;
  &:hover:not(.disabled) {
    text-decoration: underline;
  }
  &.disabled {
    cursor: default;
    opacity: 0.45;
    > img {
      opacity: 0.45;
    }
  }
  img {
    margin-right: 12px;
  }
`;

export interface IExportButtonProps {
  disabled: boolean;
  shopColumns: OrderListColumn[];
  orderFilters?: IOrderFilters;
  count?: number;
  page?: number;
  dataTestId?: string;
}

export const ExportButton = ({
  disabled,
  shopColumns,
  orderFilters,
  count,
  page,
  dataTestId,
}: IExportButtonProps): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const { shopStatuses, shopTechnologyId, ...rest } = orderFilters || {};
  return (
    <StyledExportButton
      id="qa-button-export"
      className={classnames({ disabled })}
      onClick={() => {
        if (disabled) {
          return false;
        }
        const p = new URLSearchParams();
        for (const column of shopColumns) {
          p.append("columns", column);
        }
        if (currentShop) {
          p.append("shopId", currentShop.id.toString());
        }
        if (shopStatuses && shopStatuses.length > 0) {
          for (const id of shopStatuses) {
            p.append("shopStatuses", id.toString());
          }
        }
        if (shopTechnologyId) {
          p.append("shopTechnologyId", shopTechnologyId.toString());
        }
        // eslint-disable-next-line guard-for-in
        for (const key in rest) {
          // EG: searchTerms, dateCreatedStart, needByDateStart etc.
          const value = (rest as any)[key] as Date | string;
          if (value instanceof Date) {
            p.append(key, value.toISOString());
          } else if (!!value) {
            p.append(key, String(value));
          }
        }
        if (count && page) {
          p.append("count", count.toString());
          p.append("page", page.toString());
        }

        if (config?.REACT_APP_SHOP_API_URL) {
          const url = new URL(config.REACT_APP_SHOP_API_URL);
          url.pathname = "/exportOrders";
          url.search = p.toString();
          // Handy for debugging since the tab will close immediately when download begins:
          console.log(url.toString());
          window.open(url.toString());
        }
        ReactGA.event({
          category: "GcShop Export",
          action: "Export orders list",
          label: `Shop ${currentShop?.id}`,
        });
      }}
      data-testid={dataTestId}
    >
      <Image src={exportIcon} />
      {t("order.list.export")}
    </StyledExportButton>
  );
};
