import { IOrderItem } from "@/graphql/Fragments/Order";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { IAppMaterialColor } from "@/graphql/Queries/MaterialColor";
import { ANY } from "./queryHooks";
import { IAppMaterial } from "../components/Shop/Materials/Form/MaterialsForm";
import { ICadModel } from "../graphql/Fragments/CadModel";
import { AutoEstimationResultData } from "../graphql/Fragments/AutoEstimation";
import { TECHNOLOGY } from "../shopConstants";

export const getCadModelName = (orderItem?: Partial<IOrderItem>): string | undefined =>
  orderItem?.cadModel?.original?.asset?.originalName;

export const isAnyMaterial = (material?: IShopMaterial | IAppMaterial): boolean => {
  if (material && "appMaterial" in material) {
    return material.appMaterial.name.toUpperCase() === ANY;
  }
  return material?.name.toUpperCase() === ANY;
};

// This can be adapted to accept IShopMaterialColor as well if it's ever needed
export const isAnyMaterialColor = (color?: IAppMaterialColor): boolean =>
  color?.name.toUpperCase() === ANY;

export const isGcpAsset = (cadFile: Partial<ICadModel> | undefined): boolean =>
  isGcpAssetFileName(cadFile?.original?.asset.originalName);

export const isGcpAssetFileName = (fileName: string | undefined): boolean => {
  if (fileName) {
    fileName = fileName.toLowerCase();
    return (
      fileName.endsWith(".print") ||
      fileName.endsWith(".print-fdm") ||
      fileName.endsWith(".cmb") ||
      fileName.endsWith(".cmb.gz")
    );
  }
  return false;
};

export const getTechnologyName = (
  estimationResult: AutoEstimationResultData | undefined
): TECHNOLOGY | undefined => {
  return estimationResult?.printerTechnology === TECHNOLOGY.POLYJET
    ? TECHNOLOGY.POLYJET
    : (estimationResult?.printerTechnology?.toUpperCase() as TECHNOLOGY);
};
