import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { ApplicationContext, IApplicationContext } from "@/components/ApplicationProvider";
import { SelectionDropdown } from "grabcad-ui-elements";
import classnames from "classnames";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { ITechnologyMateralColorIds } from "@/utils/DropdownUtils";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { sortByName, useShopTechnologyById } from "@/utils/queryHooks";
import { getDedupedColorsForMaterialByName } from "./ShopMaterialColorPicker";
import materialIcon from "../../../assets/icons/materialIcon.svg";
import { getIcon } from "../ItemsList/CadItemDetails";
import { FeatureKey } from "../../../graphql/Fragments/UserLicense";

export const getDedupedShopMaterialsForTechnology = (
  context: IApplicationContext,
  shopTechnology?: IShopTechnology,
  selectedMaterialId?: number | null // Optionally used to ensure a selected material is not the one that's deduped
): IShopMaterial[] => {
  // Filter materials with deleted machines, or no (non-deleted) colors
  // Whilst we are doing it we find out whether the material is estimatable
  // To be estimatable, the machine must be marked as such with canEstimate in the shop db,
  // and the user must have that particular machine type enabled for auto estimations in their auto estimation license
  let materialPairs = shopTechnology
    ? shopTechnology.shopMachines
        .filter(machine => !machine.dateDeleted)
        .reduce((acc, machine) => {
          const estimatable =
            !!machine.appMachineType.canEstimate &&
            context.hasFeature(FeatureKey.AUTO_ESTIMATION, machine.appMachineType.name);
          const pairs = machine.materials.map(material => {
            return { material, estimatable };
          });
          return acc.concat(pairs);
        }, [] as { material: IShopMaterial; estimatable: boolean }[])
    : [];
  materialPairs = materialPairs.filter(pair =>
    pair.material.shopMaterialColors.some(color => !color.dateDeleted)
  ); // Filter out materials without colors

  const selectedMaterialPair =
    selectedMaterialId && materialPairs.find(pair => pair.material.id === selectedMaterialId);
  if (selectedMaterialPair) {
    // Strip out selected material, and any materials that share its name.
    // We will re-add it after deduplication to ensure it remains in deduped list.
    materialPairs = materialPairs.filter(
      pair =>
        pair.material.id !== selectedMaterialId &&
        pair.material.appMaterial.name !== selectedMaterialPair.material.appMaterial.name
    );
  }

  // Split into estimatable and non-estimatable
  const materialsPartitionedByEstimatable = _.partition(materialPairs, p => p.estimatable);

  // Dedupe estimatable materials based on name
  const deduplicatedMaterials = _.uniqBy(
    materialsPartitionedByEstimatable[0],
    "material.appMaterial.name"
  ).map(p => p.material); // start off with estimatable ones

  // concat with non-estimatable materials, avoiding name duplicates
  for (const pair of materialsPartitionedByEstimatable[1]) {
    if (!deduplicatedMaterials.some(m => m.appMaterial.name === pair.material.appMaterial.name)) {
      deduplicatedMaterials.push(pair.material);
    }
  }

  // restore selected material to array
  selectedMaterialPair && deduplicatedMaterials.push(selectedMaterialPair.material);

  // return sorted array
  return deduplicatedMaterials.sort(sortByName); // Alphabetize (and demote 'Any')
};

export const ShopMaterialPicker = ({
  shopTechnologyId,
  shopMaterialId,
  onChange,
  disabled,
  styleAsRequired,
  showIcon,
  tooltipPosition,
  ...rest
}: {
  shopTechnologyId?: number | null;
  shopMaterialId?: number | null;
  onChange?: (ids: ITechnologyMateralColorIds) => void;
  disabled?: boolean;
  styleAsRequired?: boolean;
  showIcon?: boolean;
  tooltipPosition?: string;
}): JSX.Element | null => {
  const context = useContext(ApplicationContext);
  const { loading, shopTechnology } = useShopTechnologyById(shopTechnologyId);

  const materials = useMemo(
    () => getDedupedShopMaterialsForTechnology(context, shopTechnology, shopMaterialId),
    [shopTechnology, shopMaterialId]
  );

  const materialOptions = materials.map(material => ({
    key: `sm-${material.id}`,
    value: material.id,
    text: material.appMaterial.displayName, // + ' ' + material.id,
  }));

  const handleChange = (materialId: number) => {
    // Autoselect if single color available for material, OR clear with `null`
    const { dedupedColors } = getDedupedColorsForMaterialByName(shopTechnology, materialId);
    const shopMaterialColorId = dedupedColors.length === 1 ? dedupedColors[0].id : null;

    onChange?.({
      shopMaterialId: materialId,
      shopMaterialColorId,
    });
  };

  if (!shopMaterialId && materialOptions.length === 1) {
    // Single material auto-selection is handled by Technolgy or Machine picker for EditItem,
    // however the new Order form uses this:
    handleChange(materialOptions[0].value);
  }

  const selectedMaterialValid =
    !shopMaterialId || !!materialOptions.find(mat => mat.value === shopMaterialId);
  const allMaterials = shopTechnology
    ? shopTechnology.shopMachines.reduce(
        (acc, machine) => (acc = acc.concat(machine.materials)),
        [] as IShopMaterial[]
      )
    : [];
  const selectedMaterial =
    (shopMaterialId && allMaterials.find(mat => mat.id === shopMaterialId)) || undefined;

  return (
    <div
      data-tooltip={
        !selectedMaterialValid ? context.t("order.items.material.unavailable") : undefined
      }
      data-position={tooltipPosition ? tooltipPosition : "right center"}
      {...rest} // This can override the tooltip props above
    >
      <SelectionDropdown
        icon={showIcon && getIcon(materialIcon)}
        loading={loading}
        className={classnames(
          { "no-selection": !styleAsRequired && !shopMaterialId },
          "qa-dropdown-material"
        )}
        value={shopMaterialId || undefined}
        onChange={(_evt, { value }) => handleChange(value as number)}
        options={materialOptions}
        disabled={disabled || materials.length <= 1}
        placeholder={
          !selectedMaterialValid && selectedMaterial
            ? selectedMaterial.appMaterial.displayName
            : undefined
        }
        data-testid="dropdown-material"
      />
    </div>
  );
};
