import { ITypeNamed } from "./base";

// NB: Keep in sync with shop-server IShopLicenseStatus
export interface IShopLicenseStatusResult extends ITypeNamed<"ShopLicenseStatus"> {
  shopLicenseStatus?: {
    activeLicenses: IActiveLicense[];
    expiringLicenses: IExpiringLicense[];
  };
}

export interface IActiveLicense extends ITypeNamed<"ActiveLicense"> {
  name: string;
  state: LicenseState;
  endDate: string;
  types: LicenseType[];
}

export interface IExpiringLicense extends ITypeNamed<"ExpiringLicense"> {
  types: LicenseType[];
  expiresIn: number;
}

export type LicenseType = "Commercial" | "Educational" | "Reseller" | "Trial";

export enum LicenseState {
  NOT_STARTED = "not_started",
  ACTIVE = "active",
  EXPIRING = "expiring",
  EXPIRED = "expired",
}

export enum FeatureKey {
  AUTO_ESTIMATION = "auto_estimation",
  SHOP_USER = "shop_user",
}
