import gql from "graphql-tag";
import { OrderFragments } from "@/graphql/Fragments";
import { IListOrder, IOrder, OrderItemFields } from "@/graphql/Fragments/Order";
import { CadModelFields } from "../Fragments/CadModel";

export const ORDER_DETAILS = gql`
  query OrderDetails($orderId: Int!) {
    order(id: $orderId) {
      ...orderFields
      ...orderUserFields
      ...orderItems
    }
  }
  ${OrderFragments.order}
  ${OrderFragments.orderUser}
  ${OrderFragments.orderItems}
`;

export const ORDER_COMMENTS = gql`
  query OrderComments($orderId: Int!) {
    order(id: $orderId) {
      id
      ...orderCommentsFields
      __typename
    }
  }
  ${OrderFragments.orderComments}
`;

export interface IOrderEventsReturnType {
  order: IOrder;
}

export const ORDER_EVENTS = gql`
  query OrderEvents($orderId: Int!, $beforeEventId: Int, $afterEventId: Int, $count: Int) {
    order(id: $orderId) {
      id
      events(beforeEventId: $beforeEventId, afterEventId: $afterEventId, count: $count) {
        ...orderEventsFields
      }
      __typename
    }
  }
  ${OrderFragments.orderEvents}
`;

export const ORDERS_LIST = gql`
  query OrdersByShop(
    $shopId: Int!
    $count: Int!
    $page: Int!
    $orderStatus: OrderStatus
    $statuses: [OrderStatus!]
    $shopStatuses: [Int!]
    $shopTechnologyId: Int
    $searchTerms: String
    $dateCreatedStart: DateTime
    $dateCreatedEnd: DateTime
    $needByDateStart: DateTime
    $needByDateEnd: DateTime
    $dueDateStart: DateTime
    $dueDateEnd: DateTime
  ) {
    ordersByShopPaginated(
      shopId: $shopId
      count: $count
      page: $page
      orderStatus: $orderStatus
      statuses: $statuses
      shopStatuses: $shopStatuses
      shopTechnologyId: $shopTechnologyId
      searchTerms: $searchTerms
      dateCreatedStart: $dateCreatedStart
      dateCreatedEnd: $dateCreatedEnd
      needByDateStart: $needByDateStart
      needByDateEnd: $needByDateEnd
      dueDateStart: $dueDateStart
      dueDateEnd: $dueDateEnd
    ) {
      filteredCount
      orders {
        ...orderListFields
      }
    }
  }
  ${OrderFragments.orderList}
`;

export interface IOrdersListData {
  ordersByShopPaginated: { orders: IListOrder[]; filteredCount: number };
}

export const ORDER_COUNT = gql`
  query OrderCountByShop($shopId: Int!) {
    orderCountByShop(shopId: $shopId)
  }
`;

export const ORDER_PARTS_BY_SHOP = gql`
  query loadOrderPartsByShop($input: OrderPartsByShopInput!) {
    loadOrderPartsByShop(input: $input) {
      id
      jobId
      shopStatus
      quantity
      units
      shopMaterialId
      shopMaterialColorId
      shopTechnologyId
      price
      machineRateId
      materialRateId
      permissionsV2 {
        default
        writableFields
      }
      order {
        id
        needByDate
        name
        user {
          id
          name
          email
        }
      }
      cadModel {
        id
        unit
        conversionStatus
        size {
          x
          y
          z
        }
        original {
          asset {
            id
            originalName
            location
          }
        }
      }
    }
  }
`;

export const ORDER_ITEM_CAD_MODELS_PREVIEW = gql`
  query OrderItemsCadModels($orderItemIds: [Int!]!) {
    orderItemsCadModels(orderItemIds: $orderItemIds) {
      id
      cadModel {
        ...cadModelFields
        __typename
      }
    }
  }
  ${CadModelFields}
`;

export const LOAD_ORDER_ITEM = gql`
  query LoadOrderItem($id: Int!) {
    loadOrderItemById(id: $id) {
      ...orderItemFields
    }
  }
  ${OrderItemFields}
`;
