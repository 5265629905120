import React from "react";
import styled, {
  Dropdown,
  DropdownMenu,
  DropdownHeader,
  DropdownDivider,
  DropdownItem,
} from "grabcad-ui-elements";
import classnames from "classnames";

const StyledGroupDropdown = styled(Dropdown)`
  .header {
    background-color: #f1f3f4;
    margin: 0 !important;
    padding: 10px !important;
    height: 30px;
  }
  .divider {
    border-top: 0 !important;
  }
`;

export interface IDropdownOption {
  key: string;
  text: string;
  value: string;
}

export interface IGroupDropdownProps {
  onChange: (value: string) => void;
  defaultValue?: string;
  optionGroups: Map<string, IDropdownOption[]>;
  className?: string;
}

export const GroupDropdown = ({
  defaultValue,
  optionGroups,
  onChange,
  className,
}: IGroupDropdownProps): JSX.Element => {
  // iterates the options for the matching value and returns the text
  const getDropdownSelectedText = () => {
    let text = "";
    optionGroups.forEach(group => {
      const option = group.find(opt => opt.value === defaultValue);
      option && (text = option.text);
    });
    return text;
  };

  return (
    <StyledGroupDropdown
      className={classnames("qa-group-dropdown selection", className)}
      fluid
      text={getDropdownSelectedText()}
    >
      <DropdownMenu>
        {Array.from(optionGroups.entries()).map((optionGroup, index) => (
          <React.Fragment key={index}>
            <DropdownHeader content={optionGroup[0]} />
            <DropdownDivider />
            {optionGroup[1].map(option => (
              <DropdownItem
                key={option.key}
                text={option.text}
                value={option.value}
                active={option.value === defaultValue}
                onClick={() => onChange(option.value)}
              />
            ))}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </StyledGroupDropdown>
  );
};
