import React, { ReactNode, ErrorInfo } from "react";

interface IErrorState {
  error?: Error;
  errorInfo?: ErrorInfo;
}

// https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<Record<string, unknown>, IErrorState> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {};
  }

  public static getDerivedStateFromError(): RecursivePartial<IErrorState> {
    // Update state so the next render will show the fallback UI.
    return {};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO send raygun/etc
    console.error("render failed due to error=", error, "errorInfo=", errorInfo);

    // UNAUTHENTICATED could be buried in error.extensions.exception.graphQLErrors[0].extensions.code.
    // The "must authenticate" magic string as `message` property is a much simpler test:
    if (error.message === "must authenticate") {
      // This is a bit of paranoia & extra insurance! GQL AUTH related errors & redirects should have already been
      // handled by GraphQLClient's `errorLink` switch. If a "must authenticate" error has made it all the way here,
      // something has gone wrong with error handling. The best thing we can do is a brute force redirect,
      // choosing /logout to clear potentially invalidated JWT, and avoid any possibility of a redirect loop.
      // We're hard-coding this URL because the frontend doesn't have an ENV variable for this URL - in the happy path
      // it's fetched via GET_LOGOUT_URL Query.
      window.location.replace("https://login.grabcad.com/logout");
    } else {
      this.setState({ error, errorInfo });
    }
  }

  public render(): ReactNode {
    if (!!this.state.error) {
      return (
        <div style={{ padding: 20 }}>
          <h1>Something went wrong</h1>
          <p>{this.state.error.message}</p>
          <p>{JSON.stringify(this.state.errorInfo)}</p>
          <p>
            Please refresh your browser, or contact{" "}
            <a href="mailto:support@grabcad.com">support@grabcad.com</a> if this problem persists.
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
