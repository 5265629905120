import React, { useContext, useState } from "react";
import styled, { Button, Icon } from "grabcad-ui-elements";
import {
  AutoEstimationParameters,
  IAutoEstimationParametersProps,
} from "./AutoEstimationParameters";
import { ApplicationContext } from "../../ApplicationProvider";

const ExpandParamsButton = styled(Button)`
  &.ui.button {
    font-weight: 400;
    font-size: 12px;
    .icon {
      padding-left: 4px;
      margin: 0px;
    }
  }
  &.ui.button.flex-padding {
    div {
      padding: 4px 8px;
    }
  }
  &.ui.button.flex-padding::before {
    display: none;
    margin: 5px;
  }
  &.ui.button.flex-padding::after {
    display: none;
    margin: 0px;
  }
`;

export const ExpandAutoEstimationParametersButton = (
  props: IAutoEstimationParametersProps
): JSX.Element | null => {
  const [parametersExpanded, setParametersExpanded] = useState(false);
  const { t } = useContext(ApplicationContext);
  return (
    <>
      <ExpandParamsButton
        data-testid="viewDetailsButton"
        onClick={() => setParametersExpanded(!parametersExpanded)}
      >
        {t("auto_estimation.details")}
        <Icon name={parametersExpanded ? "triangle up" : "triangle down"} />
      </ExpandParamsButton>
      {parametersExpanded && (
        <AutoEstimationParameters
          parameters={props.parameters}
          materialDisplayUnit={props.materialDisplayUnit}
          estimateMaterialQuantity={props.estimateMaterialQuantity}
          supportMaterialQuantity={props.supportMaterialQuantity}
          materials={props.materials}
        ></AutoEstimationParameters>
      )}
    </>
  );
};
