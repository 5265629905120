import classnames from "classnames";
import { ApplicationContext } from "@/components/ApplicationProvider";
import styled, { Icon, Loader, Segment, SegmentGroup, TableHeaderCell } from "grabcad-ui-elements";
import { IOrderItem } from "@/graphql/Fragments/Order";
import React, { useContext, useState } from "react";
import calculator from "../../../assets/icons/priceCalculator.svg";
import { IMachineRate } from "@/graphql/Fragments/ShopRate";
import { TIME_UNITS, MATERIAL_UNITS } from "@/shopConstants";
import {
  transformMinutesToDisplayUnits,
  getEstimatedMaterialValue,
  getEstimatedMaterialMax,
} from "./shopRatesUtils";
import { getMaterialUnitLabel } from "@/utils/DropdownUtils";
import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import { Button } from "semantic-ui-react";
import quantity from "../../../assets/icons/quantity.svg";
import materialEstimate from "../../../assets/icons/materialEstimate.svg";
import timeEstimate from "../../../assets/icons/timeEstimate.svg";
import { ShopTechnologyPicker } from "../Form/ShopTechnologyPicker";
import { ShopMachinePickerByMaterial } from "../Form/ShopMachinePickerByMaterial";
import { ShopMaterialPicker } from "../Form/ShopMaterialPicker";
import { ShopMaterialColorPicker } from "../Form/ShopMaterialColorPicker";
import { OrderItemDependenciesPanel } from "@/components/Shared/OrderItemDependenciesPanel";
import { useQuery } from "@apollo/client";
import { DownloadButton } from "@/components/Shared/DownloadButton";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import { LOAD_ORDER_ITEM } from "@/graphql/Queries/Order";
import { OrderItemModal, OrderItemModalTab } from "@/screens/Shop/Order/Show/OrderItemModal";

export interface ICadItemDetailsProps {
  itemId: number;
  classNames?: string;
  isItemWritable: boolean;
  onClose: () => void;
}

export const CadDetails = styled.div`
  width: 100%;

  .details {
    display: flex;
    flex: auto;
    min-width: 715px;
    position: relative;
  }

  .img-cell {
    padding: 20px 10px 20px 20px !important;
  }

  .cad-item-settings {
    flex: 1 1 auto;
    padding: 0.78571429em 0.78571429em;
    flex-wrap: wrap;
    .build-details,
    .estimates {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      &.material-estimate-label,
      &.time-estimate-label {
        padding-top: 10px;
      }
    }
    .build-details {
      padding: 5px 10px 0px 10px;
    }

    .price-calculator-link .button {
      margin: 0px;
      display: flex;
      width: 100%;
      height: 32px;
      padding: 5px 10px;
      justify-content: space-between;
      .price-display {
        margin: 5px;
      }
    }
  }

  .quantity-input.ui.input {
    width: 50%;
    &.disabled {
      opacity: 1;
    }
  }

  .collapse-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 18px;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    .files-attached {
      color: #003393;
    }
    .no-files-attached {
      color: grey;
    }
    .collapse {
      cursor: pointer;
    }
  }
`;

export const StyledEstimateInput = styled.div`
  display: flex;
  position: relative;
  .ui.input {
    height: 32px;
    flex: 2;
    > input {
      padding-right: 32px !important;
      text-align: right;
    }
  }
  &[data-label]:after {
    position: absolute;
    top: 7px;
    right: 0.5em;
    content: attr(data-label);
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 0px;
  .item-setting {
    min-width: 200px;
    width: 50%;
    padding: 0px 10px 10px;
  }
`;

export const StyledBuildDetailsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  .selector {
    width: 50%;
    padding: 10px;
    .custom-icon {
      top: 6px;
      left: 5px;
    }
  }
  .technology,
  .machine {
    padding-top: 2px;
  }
`;

export const ExpandButtonContainer = styled(TableHeaderCell)`
  background-color: white;
  .ui.button.secondary {
    border: 0 !important;
  }
`;

export const getIcon = (iconName: string): JSX.Element => (
  <i className="icon" style={{ top: "20%", paddingRight: "3px" }}>
    <img width={20} height={20} src={iconName} />
  </i>
);

// Component to see the item details in Jobs Form and Jobs details
export const _CadItemDetails = ({
  itemId,
  classNames,
  isItemWritable,
  onClose,
}: ICadItemDetailsProps): JSX.Element | null => {
  const { t, formatPrice, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const [showRatesModal, setShowRatesModal] = useState<boolean>(false);

  const cadItemDetailsQuery = useQuery<{ loadOrderItemById: IOrderItem }, { id: number }>(
    LOAD_ORDER_ITEM,
    {
      variables: { id: itemId },
    }
  );
  const item = cadItemDetailsQuery.data?.loadOrderItemById;
  if (!item) {
    return (
      <div>
        <Loader active={true} size="small" />
      </div>
    );
  }

  const machineRate = (item.machineRate || {}) as Partial<IMachineRate>;
  const { machineTimeUnit = TIME_UNITS.HOURS, materialUnit = MATERIAL_UNITS.KG } = machineRate;

  const noRatesWarning = (content: string, position: string) => (
    <span
      data-tooltip={content}
      data-position={position}
      style={{ cursor: "pointer", fontSize: "1.2em" }}
      onClick={() => setShowRatesModal(true)}
    >
      <Icon name="warning sign" size="small" color="red" className="qa-no-rates-warning" />
    </span>
  );

  const getPriceDisplay = (): string | null => {
    if (item.overridePrice) {
      return formatPrice(item.overridePrice);
    } else if (item.price) {
      return formatPrice(item.price);
    } else {
      // Show '-' in place of price when price /override price is null
      return "-";
    }
  };

  let itemChildrenCount = 0;
  const { children, childImages, childFiles } = item;
  itemChildrenCount += children?.length || 0;
  itemChildrenCount += childImages?.length || 0;
  itemChildrenCount += childFiles?.length || 0;

  return (
    <CadDetails
      id={`qa-orderItemRow_${item.id}`}
      className={classnames("qa-orderItemRow", classNames)}
      key={item.id}
    >
      <div className="details">
        {/* cad image */}
        <div className="img-cell">
          <SegmentGroup>
            <Segment compact>
              <CadModelPreview itemId={item.id} size={"medium"} width={250} height={200} />
            </Segment>
            <Segment compact style={{ display: "flex", padding: "2px" }}>
              <DownloadButton items={[item]} />
            </Segment>
          </SegmentGroup>
        </div>

        <div className="cad-item-settings">
          {/* quantity + price section*/}
          <StyledSection>
            <div className="item-setting">
              <LocalizedNumericInput
                icon={getIcon(quantity)}
                iconPosition="left"
                className="qa-orderItemRow-quantityInput quantity-input"
                min={0}
                max={9999}
                value={item.quantity}
                disabled={!isItemWritable}
                data-tooltip={!!item.jobId ? t("order.items.job.tooltip.quantity") : null}
                data-position={"right center"}
              />
            </div>
            <div className="item-setting">
              <div
                className={classnames("price-calculator-link qa-price-calculator-button", {})}
                data-tooltip={
                  item.machineRate || item.materialRate
                    ? t("order.items.view.rates")
                    : t("order.items.rates.popup.noRates")
                }
                data-position="left center"
                onClick={() => setShowRatesModal(true)}
              >
                <Button
                  icon={getIcon(calculator)}
                  content={
                    <b className="qa-orderItemRow-priceDisplay price-display">
                      {getPriceDisplay()}
                    </b>
                  }
                  basic
                />
              </div>
            </div>
          </StyledSection>

          <div className="build-details">{t("order.items.buildDetails")}</div>
          {/* technology + machine + material + color*/}
          <StyledBuildDetailsRow>
            <div className="selector technology">
              <ShopTechnologyPicker
                shopTechnologyId={item.shopTechnologyId || undefined}
                disabled={!isItemWritable}
                showIcon={true}
                tooltipPosition={"right center"}
              />
            </div>
            <div className="selector machine">
              <ShopMachinePickerByMaterial
                shopTechnologyId={item.shopTechnologyId}
                shopMaterialId={item.shopMaterialId || undefined}
                currentMachine={item.machineRate?.shopMachineId || undefined}
                disabled={!isItemWritable}
                showIcon={true}
                tooltipPosition={"left center"}
              />
            </div>
            <div className="selector material">
              <ShopMaterialPicker
                shopTechnologyId={item.shopTechnologyId || undefined}
                shopMaterialId={item.shopMaterialId || undefined}
                disabled={!isItemWritable}
                showIcon={true}
                tooltipPosition={"right center"}
              />
            </div>
            <div className="selector color">
              <ShopMaterialColorPicker
                shopTechnologyId={item.shopTechnologyId || undefined}
                shopMaterialId={item.shopMaterialId || undefined}
                shopMaterialColorId={item.shopMaterialColorId || undefined}
                disabled={!isItemWritable}
                showIcon={true}
                tooltipPosition={"left center"}
              />
            </div>
          </StyledBuildDetailsRow>

          {/* material estimate + time estimate section*/}
          <StyledSection>
            <div className="item-setting">
              <div className="estimates material-estimate-label">
                {t("order.details.change.estimatedMaterialSquareMm")}
                <div style={{ paddingLeft: "5px" }}>
                  {item.shopMaterialId &&
                    !item.materialRate &&
                    noRatesWarning(t("order.items.material.estimate.noRates"), "top left")}
                </div>
              </div>

              <StyledEstimateInput data-label={getMaterialUnitLabel(t, materialUnit)}>
                <LocalizedNumericInput
                  icon={getIcon(materialEstimate)}
                  iconPosition="left"
                  className="qa-orderItemRow-materialEstimateInput material-estimate"
                  min={0}
                  max={getEstimatedMaterialMax(materialUnit)}
                  placeholder={t("order.items.rates.popup.table.material")}
                  value={getEstimatedMaterialValue(item, materialUnit)}
                  disabled={!isItemWritable}
                />
              </StyledEstimateInput>
            </div>

            <div className="item-setting">
              <div className="estimates time-estimate-label">
                {t("order.details.change.estimatedMinutes")}
                <div style={{ paddingLeft: "5px" }}>
                  {item.shopMaterialId &&
                    !item.machineRate?.machineTimeRate &&
                    noRatesWarning(t("order.items.time.estimate.noRates"), "top center")}
                </div>
              </div>

              <StyledEstimateInput
                data-label={t(`order.items.rates.units.${machineTimeUnit.toLowerCase()}`)}
              >
                <LocalizedNumericInput
                  icon={getIcon(timeEstimate)}
                  iconPosition="left"
                  className="qa-orderItemRow-timeEstimateInput time-estimate"
                  min={0}
                  max={transformMinutesToDisplayUnits(Number.MAX_SAFE_INTEGER, machineTimeUnit)}
                  placeholder={t("order.items.rates.popup.table.time")}
                  value={
                    item.estimatedMinutes
                      ? transformMinutesToDisplayUnits(item.estimatedMinutes, machineTimeUnit)
                      : 0
                  }
                  disabled={!isItemWritable}
                />
              </StyledEstimateInput>
            </div>
          </StyledSection>
          {showRatesModal && (
            <OrderItemModal
              open={showRatesModal}
              orderItem={item}
              tab={OrderItemModalTab.rates}
              onClose={() => setShowRatesModal(false)}
            />
          )}
        </div>
      </div>
      {item.order?.id && (
        <OrderItemDependenciesPanel
          key={`panel_${item.id}`}
          orderItem={item}
          orderId={item.order.id}
          openFileDialogCounter={1}
          open={true}
          isWritable={isItemWritable}
        />
      )}

      <div className="collapse-panel">
        <div className={itemChildrenCount > 0 ? "files-attached" : "no-files-attached"}>
          {itemChildrenCount > 1
            ? t("order.items.dependency.filesAttached", { num: itemChildrenCount })
            : itemChildrenCount === 1
            ? t("order.items.dependency.fileAttached", { num: itemChildrenCount })
            : t("order.items.dependency.noFilesAttached")}
        </div>
        <div className="qa-collapse-orderDetails-panel collapse" onClick={() => onClose()}>
          {t("layout.collapse")}
          <Icon name={"triangle up"} />
        </div>
      </div>
    </CadDetails>
  );
};

// Normally, child React component will re-render if the parent re-renders.
// memo() changes child React component to be a "pure component" i.e., it will
// *not* re-render if the parent changes, but only when its props/states/context changes.
export const CadItemDetails = React.memo(_CadItemDetails);
