import { IUploadContainer } from "@/components/Upload/CadDropZone";
import { Icon, Image } from "grabcad-ui-elements";
import Model from "@/assets/icons/model.svg";
import React, { SyntheticEvent, useContext } from "react";
import { CadFileRow } from "./SubscriptionResults";
import { IGenericFile } from "@/graphql/Mutations/Upload";
import { ApplicationContext } from "@/components/ApplicationProvider";

export interface IUploadFileResultContainer extends IUploadContainer {
  cadFile: IGenericFile;
}

interface IUploadFileResultsProps {
  uploadContainer: IUploadFileResultContainer;
  index: number;
}

export const UploadFileResults = ({
  uploadContainer: { file, cancel },
  index,
}: IUploadFileResultsProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  return (
    <CadFileRow
      className={"qa-uploadFileRow"}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation();
      }}
    >
      <div className="num">{index + 1}</div>
      <div className="img">
        <Image src={Model} />
      </div>
      <div className="name-n-status">
        <strong>{file.name}</strong>
        <div className="status">{t("order.form.uploader.converted")}</div>
      </div>
      <Icon
        onClick={(event: SyntheticEvent) => {
          cancel();
          event.stopPropagation();
        }}
        name={"trash"}
      />
    </CadFileRow>
  );
};
