import React, { useContext, useState, useRef, useEffect } from "react";
import classnames from "classnames";
import styled, {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
  Image,
  Loader,
  JoyrideStep,
  TooltipPosition,
  StyleMixins,
  Dropdown,
} from "grabcad-ui-elements";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ROUTES } from "@/shopConstants";
import { IOrder, IListOrder } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { OrderStatus, getStatusName } from "../Status/Status";
import PlaceholderClipboards from "@/assets/placeholder_orders.svg";
import { PROFILE } from "@/graphql/Queries";
import { ExportButton } from "./ExportButton";
import { OrderListColumn, ShopState } from "@/graphql/Fragments/Shop";
import { DateFilterRangeType } from "./FiltersPopup";
import { IShopTechnology } from "@/graphql/Fragments/ShopTechnology";
import { OverflowTextPopup } from "@/components/Shared/OverflowTextPopup";
import { getCompletedOnboardingKeys, Onboarding } from "@/components/Shared/Onboarding";
import { OperatorSelector } from "../OperatorSelector/OperatorSelector";
import { PlaceHolder } from "@/components/Shared/Placeholder";
import { useShopTechnologies } from "@/utils/queryHooks";
import { Pagination, PaginationProps } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { IProfileResponse } from "@/graphql/Fragments/User";
import { highlightedText } from "@/utils/StringUtils";
import { NoOrdersResult } from "./NoOrdersResult";

// orders list, shop settings, Grabcad Control link and jobs link onboarding
export const ADMIN_ORDER_LIST_ONBOARDING = "admin-order-list-onboarding";
// shop settings and Grabcad Control link onboarding
const SHOP_SETTINGS_ONBOARDING = "shop-settings-onboarding";
// sample shop orders onboarding
const SAMPLE_SHOP_ORDERS_ONBOARDING = "sample-shop-orders-onboarding";
// Grabcad Control link onboarding before auto shop generation
export const ADMIN_CONSOLE_LINK_ONBOARDING = "admin-console-link";
// jobs sidemenu link onboarding
const JOBS_ONBOARDING = "jobs-onboarding";

const PAGE_COUNTS = [25, 50, 100, 250, 500];

const DESCENDING = "descending";
const ASCENDING = "ascending";
export type SortDirection = "descending" | "ascending";
export interface IOrderRow extends IOrder {
  requestor: string; // This allows for a flattened object for easier sorting
  technology: string;
  material: string;
  orderItemsCount: number;
  totalPartsQuantity: number;
}
type SortFunction = ((a: IOrderRow, b: IOrderRow, descending: boolean) => number) | undefined;
interface ITableHeader {
  id: keyof IOrderRow;
  label: string;
  unsortable?: boolean;
  sort?: SortFunction;
  align?: "center" | "left" | "right" | undefined;
}

const OrderTableWrapper = styled.div`
  ${StyleMixins.roundAndShadow}
  height: 100%;
  display: flex;
  flex-direction: column;

  .scrolling-wrapper {
    overflow-x: auto;
    &.has-content {
      height: calc(100% - 45px); /* account for footer height */
    }
  }

  &.has-placeholder {
    min-height: 450px;
  }

  .place-holder {
    flex: 1 1 auto;
    min-height: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
    position: relative;
  }

  .orders-footer {
    background: #f4f5f7;
    height: 45px;
    border-top: 0.5px solid #cccccc;
    border-radius: 0 0 3px 3px;
    display: flex;
    align-items: center;

    .footer-right {
      margin: 0 18px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > * {
        margin-left: 10px;
      }
    }
    .page-count {
      min-width: 75px;
    }
  }
`;

const ORDER_ROW_HEIGHT = 50;
const ORDER_ROW_STATUS_MIN_WIDTH = 150;
const ORDER_ROW_STATUS_MAX_WIDTH = 220;
const OrdersTable = styled(Table)`
  &.ui.table {
    position: relative;
    border: none;
    flex: 1 1 100%;

    thead th {
      border-left: none;
      position: sticky;
      top: 0px;
      z-index: 2;
      &.sorted,
      &:hover,
      &.sorted:hover {
        background: #f2f2f2; /* Override semantic's default translucent black to support sticky header */
      }
    }
    tbody {
      position: relative;
      tr:hover {
        cursor: pointer;
        background: #e0ecfc;
      }
    }
    td {
      /* semantic overrides */
      border-top: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      padding: 0 0.78571429em;
      height: ${ORDER_ROW_HEIGHT}px;
      &.order-status {
        width: ${ORDER_ROW_STATUS_MIN_WIDTH}px;
        max-width: ${ORDER_ROW_STATUS_MAX_WIDTH}px;
        .dropdown {
          max-width: ${ORDER_ROW_STATUS_MAX_WIDTH}px;
          > div {
            line-height: initial;
            max-width: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &.short {
        width: 1%;
      }
      &.stretchy,
      &.truncatable-cell {
        white-space: nowrap;
      }
      &.stretchy div,
      &.truncatable-cell div {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden !important;
        white-space: normal !important;
        padding-right: 10px;
      }

      &.stretchy {
        min-width: 150px;
        div:not(.stretchy-child) {
          max-width: none !important;
        }
      }

      div.stretchy-child {
        max-width: 100% !important;
      }

      &.truncatable-cell {
        max-width: 150px;
      }
      div.truncatable {
        max-width: 150px;
      }
      .popup-trigger {
        div:first-child {
          vertical-align: middle;
        }
      }
    }
    th {
      height: 46px;
    }
    mark {
      background: #b5edff;
    }
  }
  .operator-selector {
    position: relative;
    top: 1px;
  }
`;

const StyledPagination = styled(Pagination)<PaginationProps>`
  // Disable prev or next page buttons when on first and last pages
  &.last :last-child,
  &.first :first-child {
    pointer-events: none;
    opacity: 0.5;
  }
  // Prevent cursor:pointer on active page button
  a.active {
    pointer-events: none;
  }
`;
export interface IOrderFilters {
  searchTerms?: string;
  shopTechnologyId?: number;
  shopStatuses?: number[];
  dateCreatedStart?: Date;
  dateCreatedEnd?: Date;
  needByDateStart?: Date;
  needByDateEnd?: Date;
  dueDateStart?: Date;
  dueDateEnd?: Date;
}
export interface IOrdersListProps extends RouteComponentProps<{ shopId: string }> {
  technologyDisplayName?: string;
  orders?: IListOrder[];
  loading?: boolean;
  orderListColumns?: OrderListColumn[];
  shopTechnologies?: IShopTechnology[];
  dateFilterRange?: DateFilterRangeType;
  orderFilters?: IOrderFilters;
  count?: number;
  page?: number;
  updatePagination?: (pagination: { count: number; page: number }) => void;
  filteredCount?: number;
}

export const OrderList = withRouter(
  ({
    history,
    technologyDisplayName,
    orders,
    loading,
    orderListColumns,
    dateFilterRange,
    shopTechnologies,
    orderFilters,
    count,
    page,
    updatePagination,
    filteredCount,
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  IOrdersListProps) => {
    const { t, formatDate, formatPrice, currentShop, getOrderListStateByShop } =
      useContext(ApplicationContext);
    if (!currentShop) {
      return null;
    }
    const { allMaterials } = useShopTechnologies();
    const orderListState = getOrderListStateByShop();
    const [sortCol, setSortCol] = useState(orderListState.sort.sortCol);
    const [sortDir, setSortDir] = useState<SortDirection>(orderListState.sort.sortDir);
    const [scrollTop, setScrollTop] = useState(orderListState.scrollPosition);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [showOrdersOnboarding, setShowOrdersOnboarding] = useState(false);
    const orderListRef = useRef<HTMLDivElement>(null);
    const { data: profileData } = useQuery<IProfileResponse>(PROFILE);

    const completedOnboardingKeys: string[] =
      (profileData?.userProfile && getCompletedOnboardingKeys(profileData.userProfile)) || [];

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
      orderListState.scrollPosition = e.currentTarget.scrollTop;
      setScrollTop(e.currentTarget.scrollTop);
    };

    const { searchTerms, shopStatuses } = orderFilters || {};

    const steps: JoyrideStep[] =
      // don't show tooltips from first iteration of auto shop generation if they have been dismissed
      completedOnboardingKeys?.includes(ADMIN_ORDER_LIST_ONBOARDING)
        ? []
        : [
            {
              target: ".shop-settings",
              title: t("layout.shopSidebar.settings.onboarding.title"),
              content: t("layout.shopSidebar.settings.onboarding.content"),
              placement: "right-start",
            },
            // don't show Grabcad Control link onboarding if it was already dismissed
            ...(completedOnboardingKeys?.includes(ADMIN_CONSOLE_LINK_ONBOARDING)
              ? []
              : [
                  {
                    target: ".admin-console-menu-item",
                    title: t("layout.shopSidebar.admin.onboarding.title"),
                    content: (
                      <>
                        {t("layout.shopSidebar.admin.onboarding.content")}
                        <br />
                        <br />
                        {t("layout.shopSidebar.admin.onboarding.content2")}
                      </>
                    ),
                    placement: "right",
                  } as JoyrideStep,
                ]),
            // don't show jobs link onboarding if it was already dismissed
            ...(completedOnboardingKeys?.includes(JOBS_ONBOARDING)
              ? []
              : [
                  {
                    target: ".job-settings",
                    title: t("layout.shopSidebar.job.onboarding.title"),
                    content: t("layout.shopSidebar.job.onboarding.content"),
                    placement: "right-start",
                  } as JoyrideStep,
                ]),
          ];

    const ordersOnboardingSteps: JoyrideStep[] =
      // don't show tooltips from first iteration of auto shop generation if they have been dismissed
      completedOnboardingKeys?.includes(ADMIN_ORDER_LIST_ONBOARDING)
        ? []
        : [
            ...(currentShop.state === ShopState.SAMPLE
              ? [
                  {
                    target: ".sample-orders",
                    title: t("order.list.admin.onboarding.title"),
                    content: t("order.list.admin.onboarding.content"),
                    placement: "bottom",
                  } as JoyrideStep,
                ]
              : []),
          ];

    useEffect(() => {
      // Reset scroll position when filters or sorting changes
      if (orderListRef?.current) {
        orderListRef.current.scrollTop = 0;
      }
      // Store sorting state in context
      orderListState.sort.sortCol = sortCol;
      orderListState.sort.sortDir = sortDir;
    }, [sortCol, sortDir, shopStatuses, technologyDisplayName]);

    useEffect(() => {
      // Orders length check to avoid a race condition where the scroll position was set to 0
      // before the orders were completely loaded.
      if (orders?.length && orderListRef && orderListRef.current) {
        orderListRef.current.scrollTop = orderListState.scrollPosition;
      }
    }, [orders?.length]);

    const sortOn = (key: keyof IOrderRow) => (a: IOrderRow, b: IOrderRow) => {
      if (key === "status") {
        return sortValues(getShopStatusName(a.shopStatus), getShopStatusName(b.shopStatus));
      } else if (key === "operator") {
        return sortValues(a.operator ? a.operator.name : "", b.operator ? b.operator.name : "");
      } else {
        return sortValues(a[key], b[key]);
      }
    };

    const sortValues = (vA: any, vB: any) => {
      if (typeof vA === "number" && typeof vB === "number") {
        return vA - vB;
      }
      if (!vA) {
        return !vB ? 0 : -1;
      }
      if (!vB) {
        return 1;
      }
      if (typeof vA === "string" && typeof vB === "string") {
        vA = vA.toLowerCase();
        vB = vB.toLowerCase();
      }
      if (vA > vB) {
        return 1;
      }
      if (vB > vA) {
        return -1;
      }
      return 0;
    };

    const tableHeaders =
      orderListColumns?.map(columnName => {
        let tableHeader: ITableHeader;
        switch (columnName) {
          case OrderListColumn.Status:
            tableHeader = {
              id: columnName,
              label: t(`order.list.table.${columnName}`),
              align: "left",
            };
            break;
          case OrderListColumn.Price:
            tableHeader = {
              id: columnName,
              label: t(`order.list.table.${columnName}`),
              align: "right",
            };
            break;
          case OrderListColumn.NumberOfItems:
            tableHeader = {
              id: columnName,
              label: t(`order.list.table.${columnName}`),
              // There is a mismatch between the order property "orderItemsCount" and the id of the "orderItems" column
              // Since the column name lives in production DB (preferences) it should not be changed. (GC-66735)
              sort: sortOn("orderItemsCount"),
            };
            break;
          case OrderListColumn.TotalPartsQuantity:
            tableHeader = {
              id: columnName,
              label: t(`order.list.table.${columnName}`),
              sort: sortOn("totalPartsQuantity"),
            };
            break;
          default:
            tableHeader = { id: columnName, label: t(`order.list.table.${columnName}`) };
            break;
        }
        return tableHeader;
      }) || [];

    const [sortFunc, setSortFunc] = useState<SortFunction>(() => {
      const tableHeader = tableHeaders.find(header => header.id === orderListState.sort.sortCol);
      return tableHeader?.sort || sortOn(orderListState.sort.sortCol);
    });

    const handleSort = (colId: keyof IOrderRow) => () => {
      if (sortCol !== colId) {
        setSortCol(colId);
        setSortDir(ASCENDING);

        const header = tableHeaders.find(h => h.id === colId);
        setSortFunc(() => header?.sort || sortOn(colId));
        return;
      }
      setSortDir(sortDir === ASCENDING ? DESCENDING : ASCENDING);
    };

    const getTechsOrMaterialsList = (
      ids: number[],
      key: OrderListColumn.Technology | OrderListColumn.Material
    ) => {
      const list = new Set<string>();
      shopTechnologies &&
        ids.forEach(id => {
          if (key === OrderListColumn.Technology) {
            const shopTechnology = shopTechnologies.find(tech => tech.id === id);
            shopTechnology && list.add(shopTechnology.appTechnology.displayName);
          } else if (key === OrderListColumn.Material) {
            const shopMaterial = allMaterials.find(mat => mat.id === id);
            shopMaterial && list.add(shopMaterial.appMaterial.displayName);
          }
        });

      const commaSeparatedList = Array.from(list).join(", ");
      return commaSeparatedList || "";
    };

    const getShopStatusName = (id: number) => {
      const shopStatus = currentShop.shopStatuses.find(status => status.id === id);
      return shopStatus ? getStatusName(shopStatus, t) : "";
    };

    const orders2 = orders
      ? orders.map((order: IListOrder) => ({
          // For sorting purposes we need to store the values in the order object under the according
          // OrderListColumn key
          ...order,
          [OrderListColumn.Requestor]: order.user.name,
          [OrderListColumn.Material]: getTechsOrMaterialsList(
            order.shopMaterialIds,
            OrderListColumn.Material
          ),
          [OrderListColumn.Technology]: getTechsOrMaterialsList(
            order.shopTechnologyIds,
            OrderListColumn.Technology
          ),
        }))
      : [];
    orders2.sort(sortFunc as any);
    if (sortDir === DESCENDING) {
      orders2.reverse();
    }

    // FIXME - I have no idea why this is here - we create a filters variable and populate it
    // but that's it - it's not actually used anywhere !
    // eslint-disable-next-line sonarjs/no-unused-collection
    const filters = [];
    if (shopStatuses && shopStatuses.length === 1) {
      filters.push(shopStatuses[0]);
    }
    if (technologyDisplayName) {
      filters.push(technologyDisplayName);
    }

    let orderRows: JSX.Element[] = [];
    const tableHeadersCount = tableHeaders.length;
    if (orders2.length > 0) {
      setTimeout(() => {
        // show orders onboarding in SAMPLE shop
        setShowOrdersOnboarding(true);
        // show shop settings after navigating back to orders list
        if (
          !showOrdersOnboarding &&
          !showOnboarding &&
          completedOnboardingKeys &&
          completedOnboardingKeys.includes(SAMPLE_SHOP_ORDERS_ONBOARDING)
        ) {
          setShowOnboarding(true);
        }
      }, 1000);

      orderRows = orders2
        // Status can be changed in filtered list view. Hiding immediately is better UX than short delay with refetch
        .filter(order => !shopStatuses?.length || shopStatuses.includes(order.shopStatus))
        .map((order, i) => {
          const availableHeightForStatusDropdown = (i + 1) * ORDER_ROW_HEIGHT - scrollTop - 20; // 20px = some padding
          return (
            <TableRow
              id={`qa-orderRow_${order.id}`}
              className="qa-orderRow"
              key={`order-${order.id}`}
              onClick={() => history.push(ROUTES.SHOP(currentShop.id).ORDER.SHOW(order.id))}
              data-testid="orderRow"
            >
              {tableHeaders.map((header, columnindex) => {
                let tooltipPosition = "top "; // Prefer tooltip above,
                // And popups below. PopperJS seems to handle when the popup needs to open upwards automagically.
                let popupPosition = "bottom ";
                if (i === 0) {
                  tooltipPosition = "bottom ";
                }
                if (columnindex <= 1) {
                  tooltipPosition += "left"; // TODO: Add passthrough offset prop for fine adjustment?
                  popupPosition += "left";
                } else if (columnindex >= tableHeadersCount - 2) {
                  tooltipPosition += "right";
                  popupPosition += "right";
                } else {
                  // Only center tooltip & popup if column is not one of 2 leftmost or 2 rightmost.
                  // This should hopefully account for long names and skinny outer columns.
                  tooltipPosition += "center";
                  popupPosition += "center";
                }
                switch (header.id) {
                  case OrderListColumn.NeedByDate:
                  case OrderListColumn.DueDate:
                  case OrderListColumn.DateCreated:
                    return (
                      <TableCell className={`qa-orderRow-${header.id} short`} key={header.id}>
                        {formatDate(order[header.id], { ignoreTimezone: true })}
                      </TableCell>
                    );
                  case OrderListColumn.Status:
                    return (
                      <TableCell className="qa-orderRow-status order-status" key={header.id}>
                        <OrderStatus
                          entity={order}
                          availableHeight={availableHeightForStatusDropdown}
                        />
                      </TableCell>
                    );
                  case OrderListColumn.Name:
                    return (
                      <OverflowTextPopup
                        key={header.id}
                        cellClassName="qa-orderRow-name"
                        isStretchy={true}
                      >
                        {highlightedText(searchTerms, order.name)}
                      </OverflowTextPopup>
                    );
                  case OrderListColumn.Id:
                    return (
                      <TableCell className="qa-orderRow-id short" key={header.id}>
                        {highlightedText(searchTerms, `${order.id}`)}
                      </TableCell>
                    );
                  case OrderListColumn.Requestor:
                    return (
                      <OverflowTextPopup key={header.id} cellClassName="qa-orderRow-requestor">
                        {highlightedText(searchTerms, order.user.name)}
                      </OverflowTextPopup>
                    );
                  case OrderListColumn.Price:
                    return (
                      <TableCell
                        className="qa-orderRow-price short"
                        textAlign="right"
                        key={header.id}
                      >
                        {formatPrice(order.price)}
                      </TableCell>
                    );
                  case OrderListColumn.TotalPartsQuantity:
                    return (
                      <TableCell
                        className="qa-orderRow-totalPartsQuantity short"
                        textAlign="right"
                        key={header.id}
                      >
                        {order.totalPartsQuantity}
                      </TableCell>
                    );
                  case OrderListColumn.NumberOfItems:
                    return (
                      <TableCell
                        className="qa-orderRow-numberOfItems short"
                        key={header.id}
                        textAlign="right"
                      >
                        {order.orderItemsCount}
                      </TableCell>
                    );
                  case OrderListColumn.Material:
                    return (
                      <OverflowTextPopup
                        key={header.id}
                        cellClassName="qa-orderRow-orderMaterials"
                        isStretchy={true}
                        dataTestId="orderRow-orderMaterials"
                      >
                        {getTechsOrMaterialsList(order.shopMaterialIds, OrderListColumn.Material)}
                      </OverflowTextPopup>
                    );
                  case OrderListColumn.Technology:
                    return (
                      <OverflowTextPopup
                        key={header.id}
                        cellClassName="qa-orderRow-orderTechnologies"
                        dataTestId="orderRow-orderTechologies"
                      >
                        {getTechsOrMaterialsList(
                          order.shopTechnologyIds,
                          OrderListColumn.Technology
                        )}
                      </OverflowTextPopup>
                    );
                  case OrderListColumn.ProjectCode:
                    return (
                      <OverflowTextPopup key={header.id} cellClassName="qa-orderRow-projectCode">
                        {order.projectCode}
                      </OverflowTextPopup>
                    );
                  case OrderListColumn.Operator:
                    return (
                      <TableCell
                        className="qa-orderRow-operator short"
                        key={header.id}
                        textAlign="center"
                      >
                        <OperatorSelector
                          order={order}
                          tooltipPosition={tooltipPosition as TooltipPosition}
                          popupPosition={popupPosition as TooltipPosition}
                        />
                      </TableCell>
                    );
                  default:
                    return (
                      <TableCell className={`qa-orderRow-${header.id} short`} key={header.id}>
                        {header.id}
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          );
        });
    }

    return (
      <OrderTableWrapper className={classnames({ "has-placeholder": !orderRows.length })}>
        <div
          className={classnames("scrolling-wrapper", { "has-content": orderRows.length })}
          onScroll={handleScroll}
          ref={orderListRef}
        >
          <OrdersTable sortable={orderRows.length > 0}>
            <TableHeader>
              <TableRow>
                {orderRows.length > 0 ? (
                  tableHeaders.map(({ label, id, unsortable, align }) => (
                    <TableHeaderCell
                      sorted={sortCol === id ? sortDir : undefined}
                      disabled={unsortable}
                      onClick={unsortable ? undefined : handleSort(id)}
                      key={id}
                      textAlign={align}
                    >
                      {label}
                    </TableHeaderCell>
                  ))
                ) : (
                  <TableHeaderCell />
                )}
              </TableRow>
            </TableHeader>
            <TableBody
              className={classnames({ "sample-orders": currentShop.state === ShopState.SAMPLE })}
            >
              {orderRows}
            </TableBody>
          </OrdersTable>
          {showOnboarding && (
            <Onboarding
              onboardingKey={SHOP_SETTINGS_ONBOARDING}
              steps={steps}
              continuous={true}
              nextCopy={t("general.dismiss")}
            />
          )}
          {showOrdersOnboarding && (
            <Onboarding
              onboardingKey={SAMPLE_SHOP_ORDERS_ONBOARDING}
              steps={ordersOnboardingSteps}
              dismissCopy={t("general.dismiss")}
            />
          )}
        </div>

        {orderRows.length === 0 && (
          <div className="place-holder">
            {loading ? (
              <Loader active={true} size="large" data-testid="loader" />
            ) : (
              <PlaceHolder id="qa-placeHolder" data-testid="placeHolder">
                <Image src={PlaceholderClipboards} height="145" />
                <NoOrdersResult
                  statusFilter={shopStatuses}
                  techFilter={technologyDisplayName}
                  searchTerms={searchTerms}
                  dateFilterRange={dateFilterRange}
                  history={history}
                />
              </PlaceHolder>
            )}
          </div>
        )}
        <div className="orders-footer">
          <ExportButton
            disabled={orderRows.length === 0}
            shopColumns={orderListColumns || []}
            orderFilters={orderFilters}
            count={count}
            page={page}
          />

          <div className="footer-right">
            {t("order.list.page-count")}
            <Dropdown
              className="page-count"
              value={count}
              selection
              options={PAGE_COUNTS.map(pageCount => ({
                key: pageCount,
                text: pageCount.toString(),
                value: pageCount,
              }))}
              onChange={(e, { value }) => {
                updatePagination?.({ count: value as number, page: page as number });
              }}
            />

            {count && !!filteredCount && count < filteredCount && (
              <StyledPagination
                className={classnames({
                  first: page === 1,
                  last: page === Math.ceil(filteredCount / count),
                })}
                boundaryRange={0}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(filteredCount / count)}
                onPageChange={(_event: any, data: PaginationProps) =>
                  updatePagination?.({ count, page: data.activePage as number })
                }
              />
            )}
            {filteredCount !== undefined && (
              <div className="qa-orders-count" data-testid="ordersCount">
                {t("order.list.count", { total: filteredCount })}
              </div>
            )}
          </div>
        </div>
      </OrderTableWrapper>
    );
  }
);
OrderList.displayName = "OrderList";
