import { Query } from "@apollo/client/react/components";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { MenuItem, Image } from "grabcad-ui-elements";
import { IProfileResponse } from "@/graphql/Fragments/User";
import { PROFILE } from "@/graphql/Queries";
import React, { useContext } from "react";
import { Notifier } from "@/utils/Notifier";
import reports from "../../../../assets/icons/reports.svg";
import schedule from "../../../../assets/icons/schedule.svg";

const CLOUD_BASE_URL = "https://print.grabcad.com/print/";
const WORKBENCH_COMPANY_ID_OFFSET = 1000000000; // 10^9
export const ExternalLinks = (): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <Query<IProfileResponse> query={PROFILE} onError={error => Notifier.error(error)}>
      {({ loading, data }) => {
        if (loading || !data || !data.userProfile) {
          return null;
        }
        const { company } = data.userProfile;
        const wbCompanyId = +company.id + WORKBENCH_COMPANY_ID_OFFSET;
        const menuItems: JSX.Element[] = [];
        menuItems.push(
          <MenuItem
            id="qa-sideMenu-reportsLink"
            className="admin-console-menu-item"
            name="Reports"
            key="reports"
            onClick={() => {
              window.open(`${CLOUD_BASE_URL}${wbCompanyId}/reports/materials_usage`);
            }}
            data-testid="menuItem"
          >
            <Image src={reports} />
            {t("layout.shopSidebar.reports")}
            <i style={{ marginLeft: 4 }} className="external alternate icon"></i>
          </MenuItem>
        );
        menuItems.push(
          <MenuItem
            id="qa-sideMenu-scheduleLink"
            className="admin-console-menu-item"
            name="Schedule"
            key="schedule"
            onClick={() => {
              window.open(`${CLOUD_BASE_URL}${wbCompanyId}/schedule`);
            }}
            data-testid="menuItem"
          >
            <Image src={schedule} />
            {t("layout.shopSidebar.schedule")}
            <i style={{ marginLeft: 4 }} className="external alternate icon"></i>
          </MenuItem>
        );
        return <>{menuItems}</>;
      }}
    </Query>
  );
};
ExternalLinks.displayName = "ExternalLinks";
