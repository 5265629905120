import gql from "graphql-tag";

export interface ICommentInput {
  text: string;
  orderId: number;
}

export const ADD_ORDER_COMMENT = gql`
  mutation addOrderComment($input: CommentInput!) {
    addOrderComment(input: $input) {
      id
      text
      user {
        id
        name
      }
      dateCreated
      __typename
    }
  }
`;
