import React, { useContext, FunctionComponent } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Route, RouteProps } from "react-router";
import { Permission } from "@/utils/Permission";
import { ScreensPageNotFound } from "@/screens/General/PageNotFound";
import { UiCan } from "@/components/UiCan";
import { IShop } from "@/graphql/Fragments/Shop";
import { IUser } from "@/graphql/Fragments/User";

interface IPermission {
  permissionDo?: Permission;
  permissionOn?: IShop | IUser | null;
}

export const PrivateRoute: FunctionComponent<RouteProps & IPermission> = ({
  permissionDo,
  permissionOn,
  ...rest
}: RouteProps & IPermission) => {
  const { currentUser } = useContext(ApplicationContext);
  if (!currentUser.isLoggedIn) {
    return null;
  }
  if (permissionDo && permissionOn) {
    return (
      <UiCan do={permissionDo} on={permissionOn} passThrough>
        {(can: boolean) => {
          if (!can) {
            return <Route component={ScreensPageNotFound} />;
          }
          return <Route {...rest} />;
        }}
      </UiCan>
    );
  }
  return <Route {...rest} />;
};
PrivateRoute.displayName = "PrivateRoute";
