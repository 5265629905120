import {
  IShopMaterialColor,
  IAppMaterial,
} from "../../components/Shop/Materials/Form/MaterialsForm";
import gql from "graphql-tag";

export const MATERIALS_BY_MACHINE = gql`
  query loadMaterials($shopMachineId: Int!) {
    loadMaterials(shopMachineId: $shopMachineId) {
      appMaterials {
        id
        name
        displayName
        appMaterialColors {
          id
          name
          displayName
        }
      }
      shopMaterials {
        id
        newestMaterialRateId
        shopMaterialColors {
          id
          dateDeleted
          appMaterialColor {
            id
            name
            displayName
          }
        }
        appMaterial {
          id
          name
          displayName
        }
        shopMaterialRate {
          id
          materialRate
          supportRate
        }
      }
    }
  }
`;

export interface IShopMaterial {
  id: number;
  shopMaterialColors: IShopMaterialColor[];
  appMaterial: IAppMaterial;
  shopMaterialRate?: { id: number; materialRate: number; supportRate: number };
  newestMaterialRateId: number | null;
  shopMachineId?: number;
  __typename: string;
}
