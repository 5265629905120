import React from "react";
import { ScreensShopMachines } from "./Machines";
import { ShopFormContext } from "../New/New";
import { SHOP } from "@/shopConstants";
import { withRouter } from "react-router";

export const ScreensShopMachinesNew = withRouter(() => (
  <ShopFormContext.Provider
    value={{
      generalCompleted: false,
      machinesCompleted: false,
      materialsCompleted: false,
      setGeneralCompleted: () => {},
      setMachinesCompleted: () => {},
      setMaterialsCompleted: () => {},
      section: SHOP.SECTIONS.machines,
    }}
  >
    <ScreensShopMachines isSetup={false} />
  </ShopFormContext.Provider>
));
ScreensShopMachinesNew.displayName = "ScreensShopMachinesNew";
