import { Query } from "@apollo/client/react/components";
import classnames from "classnames";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ShopLogo } from "@/components/Shop/Logo";
import styled, { Popup, Loader } from "grabcad-ui-elements";
import { IShop, ShopState } from "@/graphql/Fragments/Shop";
import { SHOPS_LIST } from "@/graphql/Queries";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ROUTES, SHOP } from "@/shopConstants";
import { Notifier } from "@/utils/Notifier";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import LicenseExpiring from "@/assets/icons/license.svg";
import { IShopLicenseStatusResult, LicenseState } from "@/graphql/Fragments/UserLicense";
import { SHOP_LICENSE_STATUS_QUERY } from "@/graphql/Queries/UserLicense";

const ApplicationSidebar = styled.div`
  position: relative;
  width: 80px;
  flex-shrink: 0;
  background: #003393;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
  .divider {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    width: 42px;
    margin: 0 auto 15px;
  }
  .ui.loader {
    top: 155px;
  }
  .license {
    padding: 15px;
  }
`;
ApplicationSidebar.displayName = "ApplicationSidebar";

const HomeLink = styled(Link)`
  display: flex;
  flex-wrap: wrap;
  margin: 2em auto;
  opacity: 0.3;
  padding: 5px;
  width: 62px;
  position: relative;
  &.active {
    opacity: 1;
    &::before {
      opacity: 1;
      content: "";
      width: 8px;
      height: 47px;
      display: block;
      position: absolute;
      top: 7px;
      left: -8px;
      background: rgb(255, 255, 255);
      border-radius: 0 3px 3px 0;
    }
  }
  &:hover {
    opacity: 1;
  }
  > div {
    background: white;
    width: 13px;
    height: 13px;
    border-radius: 1px;
    margin: 2px;
  }
`;
HomeLink.displayName = "HomeLink";

const SHOP_LINK_SIZE = 56;
const SHOP_LINK_WRAPPER_SIZE = 62;
const ShopsList = styled.div`
  width: 500px; /* Needed for correct tooltip positioning */
  max-height: calc(100vh - 127px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-grow: 1;
  flex-shrink: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  .shop-link-wrapper {
    /* Needed for correct tooltip positioning */
    width: ${SHOP_LINK_WRAPPER_SIZE}px;
    height: ${SHOP_LINK_WRAPPER_SIZE}px;
  }
  .shop-link {
    margin: 12px 9px;
    padding: 3px;
    position: relative;
    opacity: 0.5;
    width: ${SHOP_LINK_WRAPPER_SIZE}px;
    height: ${SHOP_LINK_WRAPPER_SIZE}px;
    border-radius: 6px;
    &:hover:not(.active) {
      background: rgba(255, 255, 255, 0.7);
      opacity: 1;
      cursor: pointer;
    }
    &.active {
      opacity: 1;
      &::before {
        opacity: 1;
        content: "";
        width: 8px;
        height: 50px;
        display: block;
        position: absolute;
        top: 6px;
        left: -9px;
        background: rgb(255, 255, 255);
        border-radius: 0 3px 3px 0;
      }
    }
    .inner-wrapper {
      width: ${SHOP_LINK_SIZE}px;
      height: ${SHOP_LINK_SIZE}px;
      position: relative;
      border-radius: 4px;
      padding: 3px;
      background: #003393;
      .ui.image {
        object-fit: contain;
        width: calc(${SHOP_LINK_SIZE}px - 6px);
        height: calc(${SHOP_LINK_SIZE}px - 6px);
        background: white;
        display: flex;
        border-radius: 3px;
        img {
          object-fit: contain;
          max-width: calc(${SHOP_LINK_SIZE}px - 6px);
          max-height: calc(${SHOP_LINK_SIZE}px - 6px);
          margin: auto;
          border-radius: 3px;
        }
        > .ui[class*="right ribbon"].label {
          left: calc(100% + -0.05rem + 1.2em + 1px);
        }
      }
    }
  }
`;
ShopsList.displayName = "ShopsList";

interface IShowFunction {
  SHOW: () => string;
}

// Custom type guard https://www.typescriptlang.org/docs/handbook/advanced-types.html
function hasShowFunction(val: any): val is IShowFunction {
  return val?.SHOW && typeof val.SHOW === "function";
}

// FIXME Drop this hack by passing in uppercase key into ROUTES.SHOP() instead of lowercase string
/**
 * Get link URL given shop ID + section.
 *
 * sectionToLinkUrl(1, 'orders')   => '/shops/1/orders'
 * sectionToLinkUrl(1, 'machines') => '/shops/1/machines'
 */
function sectionToLinkUrl(shopId: number, section: string): string {
  const upperSection = section.toUpperCase();

  let routeOrRouteCreator: string | IShowFunction | undefined = (ROUTES.SHOP(shopId) as any)[
    upperSection
  ];

  if (!routeOrRouteCreator) {
    // Impossible but handle just in case
    routeOrRouteCreator = "/";
  } else if (hasShowFunction(routeOrRouteCreator)) {
    // e.g., ROUTES.SHOP.MACHINES & ROUTES.SHOP.ORDERS have SHOW()
    routeOrRouteCreator = routeOrRouteCreator.SHOW();
  } else if (typeof routeOrRouteCreator !== "string") {
    throw new Error();
  }
  // else e.g., for ROUTES.SHOP.GENERAL is string -> return as is
  return routeOrRouteCreator;
}

export const LayoutApplicationSidebar = (): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);

  return (
    <ApplicationSidebar>
      <Popup
        trigger={
          <div>
            <HomeLink to={ROUTES.ROOT} className={classnames({ active: !currentShop })}>
              <div />
              <div />
              <div />
              <div />
              <div className={"qa-shop-home-icons"} />
              <div />
              <div />
              <div />
              <div />
            </HomeLink>
          </div>
        }
        content={t("layout.shopSidebar.home.popup")}
        position="right center"
        inverted
        offset={[0, 2]}
      />

      <div className="divider" />

      <ShopsList>
        <Query<{ shops?: IShop[] }> query={SHOPS_LIST}>
          {({ loading, data, error }) => {
            if (error) {
              Notifier.error(error);
              return <p>{t("global.error")}</p>;
            }
            if (loading || !data || !data.shops) {
              return <Loader active={true} size="small" inverted data-testid="shoplistLoader" />;
            }

            const { shops } = data;
            return (
              <>
                {shops.map((shop: IShop) => (
                  <UiCan key={shop.id} do={Permission.LIST} on={shop}>
                    <Popup
                      trigger={
                        <div className="shop-link-wrapper" data-testid="shopLinkWrapper">
                          <Link
                            to={
                              shop.state === ShopState.DRAFT
                                ? ROUTES.SHOP(shop.id).GENERAL
                                : sectionToLinkUrl(shop.id, SHOP.SECTIONS.orders)
                            }
                          >
                            <div
                              className={classnames("shop-link", {
                                active: currentShop && currentShop.id === shop.id,
                              })}
                              data-testid="shopLink"
                            >
                              <div className={"inner-wrapper"}>
                                <ShopLogo shop={shop} size="small" />
                              </div>
                            </div>
                          </Link>
                        </div>
                      }
                      content={shop.name}
                      position="right center"
                      inverted
                      offset={[0, 19]}
                    />
                  </UiCan>
                ))}
              </>
            );
          }}
        </Query>
      </ShopsList>
      <LicenseStatusSidebarIcon />
    </ApplicationSidebar>
  );
};

LayoutApplicationSidebar.displayName = "LayoutApplicationSidebar";

const LicenseRow = styled.div`
  &:not(:first-child) {
    margin-top: 0.5em;
  }
`;
LicenseRow.displayName = "LicenseRow";

const LicenseSubHeader = styled.div`
  margin-top: 0.5em;
  font-weight: bold;
`;
LicenseSubHeader.displayName = "LicenseSubHeader";

const LicenseMessagePopup = styled(Popup)`
  &.ui.right.center.popup:before {
    top: auto;
    bottom: 33px;
  }
`;

const LicenseStatusSidebarIcon = () => {
  const {
    t,
    ability,
    currentUser: { userProfile },
  } = useContext(ApplicationContext);
  if (!userProfile) {
    return null;
  }
  const isAdmin = ability.can(Permission.CREATE_SHOP, userProfile);
  return (
    <Query<IShopLicenseStatusResult> query={SHOP_LICENSE_STATUS_QUERY} errorPolicy={"all"}>
      {({ error, loading, data }) => {
        if (!loading && !error && data && data.shopLicenseStatus) {
          const { expiringLicenses: expiring, activeLicenses: active } = data.shopLicenseStatus;
          if (expiring.length || active.length) {
            let licenseRows: JSX.Element[] = [];
            if (isAdmin) {
              licenseRows = expiring.map((expiringLicense, i) => (
                <LicenseRow
                  key={`admin_${i}`}
                  dangerouslySetInnerHTML={{
                    __html: t("license.popup.expiring.admin", {
                      days: expiringLicense.expiresIn,
                      email: '<a href="mailto:shop@grabcad.com">shop@grabcad.com</a>',
                    }),
                  }}
                />
              ));
            } else {
              licenseRows = expiring.map((expiringLicense, i) => (
                <LicenseRow key={`non-admin_${i}`}>
                  {t("license.popup.expiring.nonAdmin", { days: expiringLicense.expiresIn })}
                </LicenseRow>
              ));
            }
            if (active.length) {
              licenseRows.push(
                <LicenseSubHeader key={"active_licenses_header"}>
                  {t("license.popup.active_licenses")}
                </LicenseSubHeader>
              );
              licenseRows = licenseRows.concat(
                active.map((singleLicense, i) => (
                  <LicenseRow key={`active_${i}`}>
                    {singleLicense.name}{" "}
                    {singleLicense.state === LicenseState.ACTIVE
                      ? t("license.popup.active.label")
                      : t("license.popup.expiring.label")}{" "}
                    {singleLicense.endDate}
                  </LicenseRow>
                ))
              );
            }
            return (
              <div className={"license"}>
                <div className="divider" />
                <LicenseMessagePopup
                  trigger={<img src={LicenseExpiring} />}
                  content={licenseRows}
                  position="right center"
                  header={t("license.popup.header")}
                  hoverable
                  offset={[0, 5]}
                />
              </div>
            );
          }
        }
        return null;
      }}
    </Query>
  );
};

LicenseStatusSidebarIcon.displayName = "LicenseStatusSidebarIcon";
