import gql from "graphql-tag";

export interface QuantitiesById {
  itemId: number;
  qty: number;
}

export interface IGcpCreateDownloadUrlInput {
  orderItemIds: number[];
  imageIds: number[];
  fileIds: number[];
  shopId: number;
  orderId?: number;
  email: string;
  companyId: number;
  jobId?: number;
  quantitiesById?: QuantitiesById[];
}

export const CREATE_DOWNLOAD_URL = gql`
  mutation createDownloadUrl($data: GcpCreateDownloadUrlInput!) {
    createDownloadUrl(data: $data)
  }
`;
