import { Card, CardContent, CardHeader } from "grabcad-ui-elements";
import { IShop } from "@/graphql/Fragments/Shop";
import React, { useContext } from "react";
import { ROUTES } from "@/shopConstants";
import { ShopLogo } from "../Logo";
import { CardLinkWrapper } from "@/components/Shared/CardLink";
import { Link } from "react-router-dom";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ContextMenu } from "../Machines/ContextMenu";

interface IShopProps {
  className: string;
  shop: Partial<IShop>;
  openDeleteModal(shop: Partial<IShop> | undefined): void;
}

export const ShopCard = ({ shop, openDeleteModal, className }: IShopProps): JSX.Element => {
  const { name, id: shopId } = shop;
  const {
    currentUser: { userProfile: user },
    t,
  } = useContext(ApplicationContext);

  // Fragments not needed here, nor parentheses, since both wrap a single child
  const menuItems: [JSX.Element, JSX.Element] = [
    <Link to={ROUTES.SHOP(shopId).INFO} className="item edit" key="edit">
      {t("shop.edit")}
    </Link>,
    <a
      className="item delete"
      onClick={e => {
        e.preventDefault();
        openDeleteModal(shop);
      }}
      key="edit-link"
    >
      {t("shop.delete")}
    </a>,
  ];

  return (
    <CardLinkWrapper className={className} data-testid="shopCard">
      <Link className="qa-shopCard" to={ROUTES.SHOP(shopId).ORDERS}>
        <Card link raised>
          <ShopLogo shop={shop} size="medium" />
          <CardContent>
            <CardHeader>
              <span className="qa-shopCard-name shop-name" data-testid="shopCardName">
                {name}
              </span>
              {/* TODO GC-58446 Remove inconsistency */}
              <UiCan do={Permission.CREATE_SHOP} on={user}>
                <ContextMenu menuItems={menuItems} />
              </UiCan>
            </CardHeader>
          </CardContent>
        </Card>
      </Link>
    </CardLinkWrapper>
  );
};
