import React, { useContext, useState } from "react";
import { UserSelector, TooltipPosition } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";
import { IListOrder, IOrder } from "@/graphql/Fragments/Order";
import { UPDATE_ORDER_OPERATOR } from "@/graphql/Mutations";
import { SHOP_OPERATORS } from "@/graphql/Queries";
import { ApolloError, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Notifier } from "@/utils/Notifier";
import { IShopOperator } from "@/graphql/Fragments/Shop";
import ReactGA from "react-ga";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { IUser } from "@/graphql/Fragments/User";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";

export interface IOperatorSelectorProps {
  order: IOrder | IListOrder;
  tooltipPosition?: TooltipPosition;
  popupPosition?: TooltipPosition;
  refetchOrderEvents?: boolean;
}

export const OperatorSelector = ({
  order,
  tooltipPosition,
  popupPosition,
  refetchOrderEvents,
}: IOperatorSelectorProps): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const client = useApolloClient();
  const [statefullId, setStatefullId] = useState(order.operator?.id); // TODO: orderId
  const { data, loading, error } = useQuery<{ shop: { operators: IShopOperator[] } }>(
    SHOP_OPERATORS,
    { variables: { id: currentShop.id } }
  );

  const operators = data?.shop.operators || [];
  const operator = statefullId ? operators.find(op => op.id === statefullId) : undefined;
  const operatorRevokedInShop = !!statefullId && !operator;

  const [updateOrderOperator] = useMutation<
    { updateOrderOperator: { operator: IUser } },
    {
      id: number;
      operatorId?: number;
    }
  >(UPDATE_ORDER_OPERATOR, {
    onError: (err: ApolloError) => Notifier.error(err),
    update: async (cache: any) =>
      refetchOrderEvents && fetchNewOrderEvents(cache, client, order.id),
    awaitRefetchQueries: true,
    onCompleted: result => {
      ReactGA.event({
        category: "GcShop Order",
        action: "Order Operator Changed",
        label: `Shop ${currentShop?.id}`,
      });
      setStatefullId(result.updateOrderOperator.operator.id);
    },
  });

  if (error || loading || !data?.shop) {
    return null;
  }

  return (
    <UiCan passThrough do={Permission.ASSIGN_OPERATOR} on={order}>
      {(canAssignOperator: boolean) => {
        // NB: assignCopy is not shown if valid operator is assigned and cannot be changed
        let assignCopy = t("order.details.noOperatorAssigned");
        if (operatorRevokedInShop) {
          assignCopy = t("order.details.operatorRevoked");
        } else if (canAssignOperator) {
          assignCopy = t("order.details.assign");
        }
        return (
          <div
            className="qa-opSelector operator-selector"
            onClick={event => event.stopPropagation()}
          >
            <UserSelector
              assignCopy={assignCopy}
              onChange={email => {
                if (!email) {
                  setStatefullId(undefined);
                  updateOrderOperator({ variables: { id: order.id } });
                } else if (email !== order.operator?.email) {
                  const newOperator = operators.find(op => op.email === email);
                  setStatefullId(newOperator?.id);
                  updateOrderOperator({
                    variables: { id: order.id, operatorId: newOperator?.id },
                  });
                }
              }}
              canSet={canAssignOperator}
              placeholder={t("order.details.assign.search")}
              noResultsCopy={t("order.details.noOperators")}
              maxHeightUsers={418}
              tooltipPosition={tooltipPosition}
              popupPosition={popupPosition}
              id={operator?.email}
              // operators can be null, hence the empty array check
              users={(operators || []).map((op: any) => ({
                id: op.email,
                name: op.name,
              }))}
            />
          </div>
        );
      }}
    </UiCan>
  );
};
