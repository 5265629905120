export interface IConfig {
  REACT_APP_SHOP_API_URL?: string;
  REACT_APP_DEFAULT_LANGUAGE?: string;
  REACT_APP_SHOP_WS_URL?: string;
  REACT_APP_HSB?: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_CLOUD_API_BASE_URL?: string;
  REACT_APP_SPOOFED_COMPANY_ID?: number;
}

const windowEnv = (window as unknown as { env: IConfig }).env || {};

export function parseBoolean(s?: string | null): boolean {
  return !(s === null || s === undefined || s === "0" || s.toLowerCase() === "false");
}

export const config: IConfig = {
  REACT_APP_SHOP_API_URL:
    windowEnv.REACT_APP_SHOP_API_URL || (process.env as IConfig).REACT_APP_SHOP_API_URL,
  REACT_APP_DEFAULT_LANGUAGE:
    windowEnv.REACT_APP_DEFAULT_LANGUAGE || (process.env as IConfig).REACT_APP_DEFAULT_LANGUAGE,
  REACT_APP_SHOP_WS_URL:
    windowEnv.REACT_APP_SHOP_WS_URL || (process.env as IConfig).REACT_APP_SHOP_WS_URL,
  REACT_APP_HSB: windowEnv.REACT_APP_HSB || (process.env as IConfig).REACT_APP_HSB,
  REACT_APP_SENTRY_DSN:
    windowEnv.REACT_APP_SENTRY_DSN || (process.env as IConfig).REACT_APP_SENTRY_DSN,
  REACT_APP_CLOUD_API_BASE_URL:
    windowEnv.REACT_APP_CLOUD_API_BASE_URL || (process.env as IConfig).REACT_APP_CLOUD_API_BASE_URL,
  REACT_APP_SPOOFED_COMPANY_ID:
    windowEnv.REACT_APP_SPOOFED_COMPANY_ID || (process.env as IConfig).REACT_APP_SPOOFED_COMPANY_ID,
};
