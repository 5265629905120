import { ApplicationContext } from "@/components/ApplicationProvider";
import React, { useContext } from "react";
import { SelectionDropdown } from "grabcad-ui-elements";
import { useQuery } from "@apollo/client";
import { APP_TECHNOLOGIES_LIST } from "@/graphql/Queries";
import { sortByName, ssysTechnologiesToBeginning } from "@/utils/queryHooks";
import { IAppTechnology } from "@/graphql/Fragments/AppTechnology";

interface ITechnologiesDropdownProps {
  selectedAppTechnologyId: number | null;
  onTechnologySelect: (newTechnology: IAppTechnology) => void;
}

export const TechnologiesDropdown = ({
  selectedAppTechnologyId,
  onTechnologySelect,
}: ITechnologiesDropdownProps): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const { data } = useQuery<{ appTechnologies: IAppTechnology[] }>(APP_TECHNOLOGIES_LIST);
  if (!data) {
    return <p>{t("global.loading")}</p>;
  }

  const appTechnologies = [...data.appTechnologies].sort(sortByName);
  ssysTechnologiesToBeginning(appTechnologies);
  const appTechnologiesById: { [id: string]: IAppTechnology } = appTechnologies.reduce(
    (acc, tech) => ({ ...acc, [tech.id]: tech }),
    {}
  );

  const technologyOptions = appTechnologies.map((appTechnology: IAppTechnology) => ({
    key: appTechnology.id,
    value: appTechnology.id,
    text: appTechnology.displayName,
    active: appTechnology.id === selectedAppTechnologyId,
  }));

  return (
    <SelectionDropdown
      id="qa-machineTypeForm-techDropdown"
      placeholder={t("machines.form.appTechnologies.dropdown.placeholder")}
      options={technologyOptions}
      onChange={(_e, option) => {
        onTechnologySelect(appTechnologiesById[option.value as string]);
      }}
      selectOnNavigation={false}
      value={selectedAppTechnologyId ? selectedAppTechnologyId : undefined}
      data-testid="technologiesDropdown"
    />
  );
};
