import React, { useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Permission } from "@/utils/Permission";
import { UiCan } from "@/components/UiCan";
import { ROUTES } from "@/shopConstants";
import { MachineNameImageAndMaterials } from "./MachineDetails";
import { useShopTechnologyById } from "../../../utils/queryHooks";

export const ScreensShopMachinePage = withRouter(
  ({
    history,
    match: { params },
  }: RouteComponentProps<{ technologyId: string; machineId: string }>) => {
    const { technologyId, machineId } = params;
    const { shopTechnology, loading } = useShopTechnologyById(+technologyId);
    const { t } = useContext(ApplicationContext);

    if (loading || !shopTechnology) {
      return <p>{t("global.loading")}</p>;
    }

    const machine = shopTechnology.shopMachines.find(shopMachine => shopMachine.id === +machineId);

    return (
      <UiCan do={Permission.WRITE} on={machine} passThrough>
        {(can: boolean) => {
          if (!can) {
            history.push(ROUTES.NOT_FOUND);
            return;
          }
          return (
            <MachineNameImageAndMaterials
              machineId={machineId}
              successMessage={t("shop.materials.form.success")}
            />
          );
        }}
      </UiCan>
    );
  }
);
ScreensShopMachinePage.displayName = "ScreensShopMachinePage";
