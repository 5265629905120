import {
  TIME_UNITS,
  MATERIAL_UNITS,
  WEIGHT_UNIT,
  AREA_UNIT,
  VOLUME_UNIT,
  WEIGHT_UNITS,
  LENGTH_UNITS,
  AREA_UNITS,
  VOLUME_UNITS,
  LENGTH_UNIT,
  MATERIAL_QUANTITY,
} from "@/shopConstants";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { EstimatedUnitResults } from "../../Shared/AutoEstimations";
import { TranslateFunction } from "../../ApplicationProvider";

export const FRACTIONAL_UNITS = 100; // EG: The smallest unit of weight will be 0.01g.
const POUNDS_PER_GRAM = 0.0022046;
const SQUARE_INCH_IN_SQUARE_MM = 645.16;
const CUBIC_INCH_IN_CUBIC_MM = 16387.064;
const MM_IN_INCH = 25.4;
export const CUBIC_MMs_PER_CUBIC_M = 1000000000;
export const G_PER_KG = 1000;
export const MAX_INT32 = 2 ** 31 - 1; // = 2,147,483,647

// time transformations
export const transformMinutesToDisplayUnits = (minutes: number, timeUnit: TIME_UNITS): number => {
  let result = minutes;
  if (timeUnit === TIME_UNITS.HOURS) {
    result = minutes / 60;
  }
  return roundToTwoDecimals(result / FRACTIONAL_UNITS);
};

export const transformMinutesToDdHhMm = (minutes: number, t: TranslateFunction): string => {
  minutes = Math.round(minutes);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  let result = "";
  if (days) {
    result = `${t("auto_estimation.days", { days })}`;
    hours -= days * 24;
    minutes -= days * 24 * 60;
  }

  if (hours) {
    result += result ? " " : "";
    result += `${t("auto_estimation.hours", { hours })}`;
    minutes -= hours * 60;
  }

  if (minutes || result.length < 1) {
    result += result ? " " : "";
    result += t("auto_estimation.minutes", { minutes });
  }

  return result;
};

export const timeUnitsToMinutes = (
  units: number | undefined,
  timeUnit: TIME_UNITS
): number | undefined => {
  if (units === undefined) {
    return undefined;
  }
  let result = units;
  if (timeUnit === TIME_UNITS.HOURS) {
    result = units * 60;
  }
  return Math.round(result * FRACTIONAL_UNITS);
};

export const transformGramsToDisplayUnits = (grams: number, materialUnit: WEIGHT_UNIT): number => {
  let result = grams;
  if (materialUnit === MATERIAL_UNITS.KG) {
    result = grams / 1000;
  } else if (materialUnit === MATERIAL_UNITS.LB) {
    result = grams * POUNDS_PER_GRAM;
  }
  return result;
};

export const weightUnitsToGrams = (units: number, materialUnit: WEIGHT_UNIT): number => {
  let result = units;
  if (materialUnit === MATERIAL_UNITS.KG) {
    result = units * 1000;
  } else if (materialUnit === MATERIAL_UNITS.LB) {
    result = units / POUNDS_PER_GRAM;
  }
  return result;
};

export const transformMMToDisplayUnits = (mm: number, materialUnit: LENGTH_UNIT): number => {
  let result = mm;
  if (materialUnit === MATERIAL_UNITS.IN) {
    result = mm / MM_IN_INCH;
  }
  return result;
};

export const lengthUnitsToMM = (units: number, materialUnit: LENGTH_UNIT): number => {
  let result = units;
  if (materialUnit === MATERIAL_UNITS.IN) {
    result = units * MM_IN_INCH;
  }
  return result;
};

export const transformSquareMMToDisplayUnits = (mm2: number, materialUnit: AREA_UNIT): number => {
  let result = mm2;
  if (materialUnit === MATERIAL_UNITS.CM2) {
    result = mm2 / 100;
  } else if (materialUnit === MATERIAL_UNITS.IN2) {
    result = mm2 / SQUARE_INCH_IN_SQUARE_MM;
  }
  return result;
};

export const areaUnitsToSquareMM = (units: number, materialUnit: AREA_UNIT): number => {
  let result = units;
  if (materialUnit === MATERIAL_UNITS.CM2) {
    result = units * 100;
  } else if (materialUnit === MATERIAL_UNITS.IN2) {
    result = units * SQUARE_INCH_IN_SQUARE_MM;
  }
  return result;
};

export const transformCubicMMToDisplayUnits = (mm3: number, displayUnit: VOLUME_UNIT): number => {
  let result = mm3;
  if (displayUnit === MATERIAL_UNITS.CM3 || displayUnit === MATERIAL_UNITS.ML) {
    result = mm3 / 1000;
  } else if (displayUnit === MATERIAL_UNITS.IN3) {
    result = mm3 / CUBIC_INCH_IN_CUBIC_MM;
  }
  return result;
};

export const roundToNPlaces = (num: number, decimalPlaces = 3) => {
  // 3 decimals matches GCP's estimates display
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

/**
 * Transform the given number, which is in SI units to the user's chosen display units.
 * This transformation depends on the 'quantity' of the number, e.g. weight or volume
 * @param si value in si units
 * @param quantity weight or volume
 * @param displayUnit required units
 */
export const transformSIUnitsToRoundedDisplayUnits = (
  si: number | undefined,
  quantity: MATERIAL_QUANTITY,
  displayUnit: VOLUME_UNIT | WEIGHT_UNIT,
  decimalPlaces = 3
): number | undefined => {
  switch (quantity) {
    case MATERIAL_QUANTITY.VOLUME:
      const materialCubicMM = si !== undefined ? si * CUBIC_MMs_PER_CUBIC_M : NaN;
      return roundToNPlaces(
        transformCubicMMToDisplayUnits(materialCubicMM, displayUnit as VOLUME_UNIT),
        decimalPlaces
      );
    case MATERIAL_QUANTITY.WEIGHT:
      const materialG = si !== undefined ? si * G_PER_KG : NaN;
      return roundToNPlaces(
        transformGramsToDisplayUnits(materialG, displayUnit as WEIGHT_UNIT),
        decimalPlaces
      );
    default:
      return si;
  }
};

export const volumeUnitsToCubicMM = (units: number, materialUnit: VOLUME_UNIT): number => {
  let result = units;
  if (materialUnit === MATERIAL_UNITS.CM3 || materialUnit === MATERIAL_UNITS.ML) {
    result = units * 1000;
  } else if (materialUnit === MATERIAL_UNITS.IN3) {
    result = units * CUBIC_INCH_IN_CUBIC_MM;
  }
  return result;
};

export const transformEstimatedMaterialToDbUnits = ({
  itemEstimatedValue,
  fromUnit,
}: {
  itemEstimatedValue: number | undefined;
  fromUnit: MATERIAL_UNITS;
}): number | undefined => {
  if (itemEstimatedValue === undefined) {
    return undefined;
  }
  let result = itemEstimatedValue;
  if (WEIGHT_UNITS.includes(fromUnit)) {
    result = weightUnitsToGrams(itemEstimatedValue, fromUnit as WEIGHT_UNIT);
  } else if (LENGTH_UNITS.includes(fromUnit)) {
    result = lengthUnitsToMM(itemEstimatedValue, fromUnit as LENGTH_UNIT);
  } else if (AREA_UNITS.includes(fromUnit)) {
    result = areaUnitsToSquareMM(itemEstimatedValue, fromUnit as AREA_UNIT);
  } else if (VOLUME_UNITS.includes(fromUnit)) {
    result = volumeUnitsToCubicMM(itemEstimatedValue, fromUnit as VOLUME_UNIT);
  }
  return Math.round(result * FRACTIONAL_UNITS);
};

export const getEstimatedMaterialValue = (
  item: IOrderItem | EstimatedUnitResults,
  materialUnit: MATERIAL_UNITS
): number => {
  let result = 0;
  if (WEIGHT_UNITS.includes(materialUnit)) {
    result = item.estimatedMaterialGrams
      ? transformGramsToDisplayUnits(item.estimatedMaterialGrams, materialUnit as WEIGHT_UNIT)
      : 0;
  } else if (LENGTH_UNITS.includes(materialUnit)) {
    result = item.estimatedMaterialMm
      ? transformMMToDisplayUnits(item.estimatedMaterialMm, materialUnit as LENGTH_UNIT)
      : 0;
  } else if (AREA_UNITS.includes(materialUnit)) {
    result = item.estimatedMaterialSquareMm
      ? transformSquareMMToDisplayUnits(item.estimatedMaterialSquareMm, materialUnit as AREA_UNIT)
      : 0;
  } else if (VOLUME_UNITS.includes(materialUnit)) {
    result = item.estimatedMaterialCubicMm
      ? transformCubicMMToDisplayUnits(item.estimatedMaterialCubicMm, materialUnit as VOLUME_UNIT)
      : 0;
  }
  return roundToTwoDecimals(result / FRACTIONAL_UNITS);
};

export const getEstimatedMaterialMax = (materialUnit: MATERIAL_UNITS): number => {
  let result = 0;
  if (WEIGHT_UNITS.includes(materialUnit)) {
    result = transformGramsToDisplayUnits(Number.MAX_SAFE_INTEGER, materialUnit as WEIGHT_UNIT);
  } else if (LENGTH_UNITS.includes(materialUnit)) {
    result = transformMMToDisplayUnits(Number.MAX_SAFE_INTEGER, materialUnit as LENGTH_UNIT);
  } else if (AREA_UNITS.includes(materialUnit)) {
    result = transformSquareMMToDisplayUnits(Number.MAX_SAFE_INTEGER, materialUnit as AREA_UNIT);
  } else if (VOLUME_UNITS.includes(materialUnit)) {
    result = transformCubicMMToDisplayUnits(Number.MAX_SAFE_INTEGER, materialUnit as VOLUME_UNIT);
  }
  return Math.floor(result / FRACTIONAL_UNITS);
};
export const getEstimatedSupportMaterialValue = (
  item: IOrderItem,
  materialUnit: MATERIAL_UNITS
): number => {
  let result = 0;
  if (WEIGHT_UNITS.includes(materialUnit)) {
    result = item.estimatedSupportGrams
      ? transformGramsToDisplayUnits(item.estimatedSupportGrams, materialUnit as WEIGHT_UNIT)
      : 0;
  } else if (VOLUME_UNITS.includes(materialUnit)) {
    result = item.estimatedSupportCubicMm
      ? transformCubicMMToDisplayUnits(item.estimatedSupportCubicMm, materialUnit as VOLUME_UNIT)
      : 0;
  }
  return roundToTwoDecimals(result / FRACTIONAL_UNITS);
};

export const getEstimatedMaterialFieldName = (
  unit: MATERIAL_UNITS
):
  | "estimatedMaterialCubicMm"
  | "estimatedMaterialGrams"
  | "estimatedMaterialMm"
  | "estimatedMaterialSquareMm" => {
  let result:
    | "estimatedMaterialCubicMm"
    | "estimatedMaterialGrams"
    | "estimatedMaterialMm"
    | "estimatedMaterialSquareMm" = "estimatedMaterialCubicMm";
  if (WEIGHT_UNITS.includes(unit)) {
    result = "estimatedMaterialGrams";
  } else if (LENGTH_UNITS.includes(unit)) {
    result = "estimatedMaterialMm";
  } else if (AREA_UNITS.includes(unit)) {
    result = "estimatedMaterialSquareMm";
  }
  return result;
};

export const getEstimatedSupportMaterialFieldName = (
  unit: MATERIAL_UNITS
): "estimatedSupportCubicMm" | "estimatedSupportGrams" => {
  let result: "estimatedSupportCubicMm" | "estimatedSupportGrams";
  if (WEIGHT_UNITS.includes(unit)) {
    result = "estimatedSupportGrams";
  } else {
    result = "estimatedSupportCubicMm";
  }
  return result;
};

export const roundToTwoDecimalsFractional = (num: number): number =>
  roundToTwoDecimals(num / FRACTIONAL_UNITS);

// NB: This should never be needed or used for prices, since currencyDenominator is variable.
// All calculations should be performed on cents (or Yen), using Math.round() if needed.
// Computers can be bad at math. EG: 1.2 * 5.55 = 6.659999999999999!
export const roundToTwoDecimals = (num: number): number => Math.round(num * 100) / 100;
interface ICalculateOrderPartPrice {
  estimatedMaterial?: number;
  materialRate?: number;
  estimatedSupport?: number;
  supportRate?: number;
  estimatedTime?: number;
  machineTimeRate?: number;
  machineBaseRate?: number;
  markUp?: number;
  extraCost?: number;
}

/*
 * All prices passed in and out as rounded cents or yen.
 * Measurements should be passed as parsed display values, not db values.
 */
export const previewOrderItemPrice = ({
  estimatedMaterial = 0,
  materialRate = 0,
  estimatedSupport = 0,
  supportRate = 0,
  estimatedTime = 0,
  machineTimeRate = 0,
  machineBaseRate = 0,
  markUp = 0,
  extraCost = 0,
}: ICalculateOrderPartPrice): number =>
  Math.round(
    Math.max(
      (Math.round(estimatedMaterial * materialRate) + // each line item is rounded before adding up
        Math.round(estimatedSupport * supportRate) +
        Math.round(estimatedTime * machineTimeRate) +
        machineBaseRate) *
        (!markUp ? 1 : markUp / 100 + 1) +
        extraCost,
      0
    )
  );
