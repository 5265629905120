import React from "react";
import { Notifier as StyledNotifier } from "grabcad-ui-elements";
import { ApolloError } from "@apollo/client";
import { ToastOptions } from "react-toastify";
import { FormattedMessage } from "react-intl";

export interface IErrorObject extends ApolloError {
  errorKey: string | JSX.Element;
}
export class Notifier {
  public static notify = (
    message: string | JSX.Element,
    options: ToastOptions = {}
  ): string | number => StyledNotifier.notify(message, options);

  public static success = (
    message: string | JSX.Element,
    options: ToastOptions = {}
  ): string | number => StyledNotifier.success(message, options);

  public static error = (error: Partial<IErrorObject>, options?: ToastOptions): void => {
    const t = (id: string) => <FormattedMessage id={id} />;
    console.log(error);
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach(err => {
        if (err.extensions) {
          const localizedErrorCode = t(`server-errors.${err.extensions.code}`);
          const messageFromServer = err.message && `: ${err.message}`;
          StyledNotifier.error(
            <>
              {localizedErrorCode}
              {messageFromServer}
            </>,
            options
          );
        }
      });
    }
    if (error.errorKey) {
      StyledNotifier.error(error.errorKey, options);
    }
    if (error.networkError) {
      console.log(JSON.stringify(error.networkError));
      StyledNotifier.error(t("server-errors.networkError"), options);
      if ((error.networkError as any).result?.errors) {
        (error.networkError as any).result.errors.forEach((err: any) => {
          if (err.extensions) {
            const localizedErrorCode = t(`server-errors.${err.extensions.code}`);
            const messageFromServer = err.message && `: ${err.message}`;
            StyledNotifier.error(
              <>
                {localizedErrorCode}
                {messageFromServer}
              </>,
              { autoClose: false }
            );
          }
        });
      }
    }
  };

  public static warn = (
    message: string | JSX.Element,
    options: ToastOptions = {}
  ): string | number => StyledNotifier.warn(message, options);

  public static info = (
    message: string | JSX.Element,
    options: ToastOptions = {}
  ): string | number => StyledNotifier.info(message, options);
}
