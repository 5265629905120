import React, { useContext } from "react";
import { IOrderStatusChangedEvent } from "@/graphql/Fragments/Order";
import { Icon, isDarkBG } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";
import { getLocalizedStatus, getStatusName, getStatusColor } from "../Status/Status";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import classnames from "classnames";
import { getCadModelName } from "@/utils/GeneralUtils";

interface IHistoryOrderItemStatusChangedComponentProps {
  event: IOrderStatusChangedEvent;
}

export const HistoryOrderItemStatusChangedComponent = ({
  event,
}: IHistoryOrderItemStatusChangedComponentProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  return (
    <>
      {/* The check is necessary to show the correct content title for the event in case of
      non-grouped OrderItemStatusChangedEvent/OrderStatusChangedEvent as they
      share the same metadata type IStatusChangedEventMetadata */}
      {event.order ? t("order.history.order") : <strong>{getCadModelName(event.orderItem)}</strong>}{" "}
      <Icon name="long arrow alternate right" />
      <StatusWithColor event={event} />
    </>
  );
};

export const StatusWithColor = ({
  event,
}: IHistoryOrderItemStatusChangedComponentProps): JSX.Element | null => {
  const { currentShop, t } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  let statusName, statusColor: string;
  if (event.metadata.toStatus) {
    statusName = getLocalizedStatus(t, event.metadata.toStatus);
    const status = currentShop.shopStatuses.find(
      s => s.appStatus === event.metadata.toStatus
    ) as IShopStatus;
    statusColor = getStatusColor(status);
  } else {
    const status = currentShop.shopStatuses.find(
      s => s.id === event.metadata.toShopStatus
    ) as IShopStatus;
    statusName = getStatusName(status, t);
    statusColor = getStatusColor(status);
  }
  return (
    <span
      className={classnames("status", { dark: isDarkBG(statusColor) })}
      style={{ background: statusColor }}
    >
      {statusName}
    </span>
  );
};

HistoryOrderItemStatusChangedComponent.displayName = "HistoryOrderItemStatusChangedComponent";
