import React, { useContext, useState } from "react";
import styled, { Button, Popup, Notifier } from "grabcad-ui-elements";
import { OrderItemOrImageOrFile, OrderItemTypename } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ItemsState } from "@/screens/Shop/Order/Show/OrderPage";
import { isWindows10 } from "@/utils/isWindows10";
import customProtocolCheck from "custom-protocol-check";
import Print from "../../../assets/icons/print.svg";
import PrintActive from "../../../assets/icons/print-active.svg";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { ApolloClient, ApolloConsumer, ApolloError } from "@apollo/client";
import { CREATE_DOWNLOAD_URL } from "@/graphql/Mutations";
import { IGcpCreateDownloadUrlInput, QuantitiesById } from "@/graphql/Mutations/GcpDownload";
import ReactGA from "react-ga";
import { STRATASYS_TECHNOLOGIES } from "@/shopConstants";
import { useShopTechnologies } from "@/utils/queryHooks";
import { getCadModelName } from "@/utils/GeneralUtils";

interface IToPrintButtonProps {
  selectedItems: ItemsState;
  orderId?: number;
  orderItems: OrderItemOrImageOrFile[];
  orderItemsWithDependenciesSelected?: boolean;
  jobId?: number;
  quantitiesById?: QuantitiesById[];
}

interface IDownloadUrl {
  createDownloadUrl: string;
}

const ToPrintButtonContainer = styled.span`
  .ui.button.flex-padding {
    > div {
      padding: 0; /* Override grabcad-ui-components */
      overflow: visible;
      position: relative;
    }

    &.disabled {
      .image {
        background-image: url(${Print});
      }
      &:hover {
        .image {
          background-image: url(${Print});
        }
      }
    }
    .image {
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${PrintActive});
    }
  }
`;

export const ToPrintButton = ({
  selectedItems, // TODO: One of these first two props is redundant?
  orderItems,
  orderId,
  jobId,
  orderItemsWithDependenciesSelected,
  quantitiesById,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
IToPrintButtonProps): JSX.Element | null => {
  const { t, currentShop, currentUser } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies } = useShopTechnologies();
  const [showModal, setShowModal] = useState(false);
  const { userProfile } = currentUser;
  const [buttonDisabledOnClick, setButtonDisabledOnClick] = useState(false);

  let modalHeaderKey = "";
  let modalBodyKey = "";
  const disabledPopUpKey = "order.gcp.button.disabled";
  let popupKey = "order.gcp.popup.open";

  let isGCPButtonDisabled = false;
  let isProjectFileSelected = false;
  let isAnyOtherFileTypeSelected = false;

  let ssysParts = 0;
  let nonSsysParts = 0;
  let noTechParts = 0;
  let imageFiles = 0;
  let nonCadFiles = 0;
  let printFiles = 0;
  let cmbFiles = 0;
  let objFiles = 0;

  const selectedFileExtensions: Array<string | undefined> = [];
  const orderFileExtensions: Array<string | undefined> = [];
  const parseExt = (name: string | undefined): string =>
    name ? (name.toLowerCase().split(".").pop() as string) : "";
  const parseProjectExt = (name: string | undefined): string => {
    let extension: string | undefined = "";
    const filenameParts = name ? name.toLowerCase().split(".") : [];
    if (filenameParts.length) {
      const ext1 = filenameParts.pop();
      if (ext1 === "gz") {
        const ext2 = filenameParts.pop();
        extension = `${ext2}.${ext1}`;
      } else {
        extension = ext1;
      }
    }
    return extension ? extension : "";
  };

  orderItems.forEach(item => {
    if (item.__typename === OrderItemTypename) {
      const ext = parseExt(getCadModelName(item));
      orderFileExtensions.push(ext);
      const shopTechnology = shopTechnologies.find(tech => tech.id === item.shopTechnologyId);
      if (selectedItems.OrderItem.includes(item.id)) {
        selectedFileExtensions.push(ext);
        if (!item.shopTechnologyId) {
          noTechParts += 1;
        } else if (
          STRATASYS_TECHNOLOGIES.some(tech => tech === shopTechnology?.appTechnology.name)
        ) {
          ssysParts += 1;
        } else {
          nonSsysParts += 1;
        }
      }
    } else if (item.__typename === "Image") {
      // ImageTypeName oddly not working for this conditional
      const ext = parseExt(item.original.originalName);
      orderFileExtensions.push(ext);
      if (selectedItems.Image.includes(item.id)) {
        selectedFileExtensions.push(ext);
        imageFiles += 1;
      }
    } else {
      const ext = parseProjectExt(item.asset.originalName);
      orderFileExtensions.push(ext);
      if (selectedItems.GenericFile.includes(item.id)) {
        selectedFileExtensions.push(ext);
        if (ext === "mtl") {
          imageFiles += 1; // .mtl files are treated the same as texture images
        } else if (ext === "gcvf") {
          ssysParts += 1; // .gcvf files are ignored by converter, but we can treat them as CAD files
        } else if (ext === "print") {
          printFiles += 1;
          isProjectFileSelected = true;
        } else if (ext === "cmb" || ext === "cmb.gz") {
          cmbFiles += 1;
          isProjectFileSelected = true;
        } else if (ext === "objzf" || ext === "objtf") {
          objFiles += 1;
          isProjectFileSelected = true;
        } else {
          nonCadFiles += 1;
        }
      }
    }
  });
  const totalSelectedFiles =
    ssysParts +
    nonSsysParts +
    noTechParts +
    nonCadFiles +
    imageFiles +
    printFiles +
    objFiles +
    cmbFiles;

  if (ssysParts || nonSsysParts || noTechParts || imageFiles || nonCadFiles) {
    isAnyOtherFileTypeSelected = true;
  }

  if (totalSelectedFiles === 0) {
    popupKey = "order.gcp.popup.select.items";
    isGCPButtonDisabled = true;
  }

  if (nonSsysParts && ssysParts) {
    modalHeaderKey = "order.gcp.modal.mixed.warning.header";
    modalBodyKey = "order.gcp.modal.mixed.warning.body";
  } else if (nonSsysParts === 1) {
    modalHeaderKey = "order.gcp.modal.nonssys.single.warning.header";
    modalBodyKey = "order.gcp.modal.nonssys.single.warning.body";
  } else if (nonSsysParts > 1) {
    modalHeaderKey = "order.gcp.modal.nonssys.multiple.warning.header";
    modalBodyKey = "order.gcp.modal.nonssys.multiple.warning.body";
  }

  const externalTexturedFormats = ["wrl", "vrml", "obj"];
  const textureFileFormats = ["jpg", "jpeg", "png", "gif", "bmp", "mtl"];
  const externalTexturedCount = externalTexturedFormats.filter(ext =>
    selectedFileExtensions.includes(ext)
  ).length;
  const selectedTextureFileCount = textureFileFormats.filter(ext =>
    selectedFileExtensions.includes(ext)
  ).length;

  if (
    !isProjectFileSelected &&
    (nonCadFiles || (!externalTexturedCount && selectedTextureFileCount))
  ) {
    // Disable if non-CAD-files selected, or textures selected with no textured file formats
    if (totalSelectedFiles > 1) {
      if (nonCadFiles === totalSelectedFiles) {
        popupKey = "order.gcp.popup.noncad.items.popup";
      } else {
        popupKey = "order.gcp.popup.mixed.parts.popup";
      }
    } else {
      popupKey = "order.gcp.popup.noncad.item.popup";
    }
    isGCPButtonDisabled = true;
  }

  if (isProjectFileSelected) {
    if (
      printFiles > 1 ||
      objFiles > 1 ||
      (printFiles && (cmbFiles || objFiles)) ||
      (cmbFiles && (printFiles || objFiles)) ||
      (objFiles && (cmbFiles || printFiles)) ||
      isAnyOtherFileTypeSelected
    ) {
      popupKey = "order.gcp.popup.project.file.error";
      isGCPButtonDisabled = true;
    }
    if (objFiles === 1) {
      modalHeaderKey = "order.gcp.modal.obj.header";
      modalBodyKey = "order.gcp.modal.obj.body";
    }
  }

  if (!isWindows10()) {
    popupKey = "order.gcp.popup.open.os";
    isGCPButtonDisabled = true;
  }

  const orderTextureFileCount = textureFileFormats.filter(ext =>
    orderFileExtensions.includes(ext)
  ).length;

  // Do not show missing textures or missing assembly file warnings if order items with dependencies are selected
  // only show them for external companies with no dependency feature
  // or internal companies for order items with no dependency selected.
  if (!orderItemsWithDependenciesSelected) {
    if (externalTexturedCount && !selectedTextureFileCount && orderTextureFileCount) {
      // Possible missing texture(s)
      modalHeaderKey = "order.gcp.modal.additional_files.header";
      modalBodyKey = "order.gcp.modal.additional_files.body";
    } else {
      // Assembly file formats that we support:
      // .CATProduct (Components are .CATPart) (Currently not supported by converter - users will need to manually download)
      // .iam (Components are .ipt)
      // .sldasm (Components are .sldprt)
      // .asm (Components are .par and .prt)
      const assemblyFormats: Array<{ main: string; subs: string[] }> = [
        { main: "sldasm", subs: ["sldprt"] },
        { main: "iam", subs: ["ipt"] },
        { main: "asm", subs: ["par", "prt"] },
      ];
      for (const format of assemblyFormats) {
        if (
          selectedFileExtensions.includes(format.main) &&
          !format.subs.filter(ext => selectedFileExtensions.includes(ext)).length
        ) {
          // Possible missing assembly file(s)
          modalHeaderKey = "order.gcp.modal.assembly.header";
          modalBodyKey = "order.gcp.modal.assembly.body";
          break;
        }
      }
    }
  }

  const handleGCPButtonClick = (client: ApolloClient<any>) => {
    if (modalBodyKey && modalHeaderKey) {
      setShowModal(true);
    } else {
      openGCP(client);
      ReactGA.event({
        category: "GcShop to Print",
        action: "Open in Print",
        label: `Shop ${currentShop?.id}`,
      });
    }
  };

  const openGCP = async (client: ApolloClient<any>) => {
    if (!userProfile) {
      Notifier.error(t("order.gcp.error.user.profile"));
    } else if (!currentShop) {
      Notifier.error(t("order.gcp.error.current.shop"));
    } else {
      const inputData: IGcpCreateDownloadUrlInput = {
        orderItemIds: selectedItems.OrderItem,
        imageIds: selectedItems.Image,
        fileIds: selectedItems.GenericFile,
        shopId: currentShop.id,
        orderId,
        jobId,
        email: userProfile.email,
        companyId: userProfile.company.id,
        quantitiesById,
      };
      try {
        const { data } = await client.mutate({
          mutation: CREATE_DOWNLOAD_URL,
          variables: {
            data: inputData,
          },
        });

        const gcpLink = (data as IDownloadUrl).createDownloadUrl;
        customProtocolCheck(gcpLink, () => {
          const warningMessage = (
            <>
              {t("order.gcp.warning.download")}
              {" " + t("order.gcp.warning.download.begin")}
              <p>
                {t("order.gcp.warning.download.option1.start")}
                <a href="https://print.grabcad.com/" target="blank">
                  {t("order.gcp.warning.download.option1.link")}
                </a>
                {t("order.gcp.warning.download.option1.end")}
                <br />
                {t("order.gcp.warning.download.option2")}
              </p>
              {t("order.gcp.warning.download.end")}
            </>
          );
          Notifier.warn(warningMessage, { autoClose: 10000 });
        });
      } catch (error) {
        if (!(error instanceof ApolloError)) {
          throw error;
        }
      }
    }
  };

  return (
    <ApolloConsumer>
      {client => (
        <ToPrintButtonContainer data-testid="toPrintButtonContainer">
          <Popup
            trigger={
              <Button
                secondary
                circular
                disabled={isGCPButtonDisabled ? isGCPButtonDisabled : buttonDisabledOnClick}
                className={"icon borderless qa-toGCPrint-button"}
                onClick={() => {
                  handleGCPButtonClick(client);
                  setButtonDisabledOnClick(true);
                  setTimeout(() => {
                    setButtonDisabledOnClick(false);
                  }, 2000);
                }}
              >
                <div className="image" />
              </Button>
            }
            content={buttonDisabledOnClick ? t(disabledPopUpKey) : t(popupKey)}
            position="bottom center"
            inverted
            offset={[0, 10]}
            className="qa-toGCPrint-popup"
            popperDependencies={[buttonDisabledOnClick]}
          />
          {showModal && (
            <ConfirmationModal
              headerIcon={"exclamation circle"}
              headerCopy={t(modalHeaderKey)}
              bodyCopy={t(modalBodyKey)}
              cancelTranslationKey={"order.items.delete.cancel"}
              confirmTranslationKey={"order.gcp.button.open"}
              open={showModal}
              onClose={() => setShowModal(false)}
              submitAction={() => openGCP(client)}
            />
          )}
        </ToPrintButtonContainer>
      )}
    </ApolloConsumer>
  );
};
ToPrintButton.displayName = "ToPrintButton";
