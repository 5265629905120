import gql from "graphql-tag";
import { AutoEstimationFields } from "../Fragments/AutoEstimation";
import { MachineRateFields, MaterialRateFields } from "../Fragments/ShopRate";
import { CadModelFields } from "../Fragments/CadModel";

/**
 * This is sort of a hack. We return the orderItem object through the auto estimation query only if
 * the auto estimation is selected and completed so we can refresh the orderItem with updated data. When the auto estimation
 * is still in progress or not selected we return null for the order item objects.
 */
const orderItem_if_autoEstimationCompleteAndSelected = gql`
  fragment orderItemFields on OrderItem {
    cadModel {
      ...cadModelFields
    }
    id
    price
    estimatedMaterialCubicMm
    estimatedSupportCubicMm
    estimatedMaterialGrams
    estimatedSupportGrams
    estimatedMaterialSquareMm
    estimatedMaterialMm
    estimatedMinutes
    machineRateId
    machineRate {
      ...machineRateFields
    }
    materialRateId
    materialRate {
      ...materialRateFields
    }
    shopMaterialId
    shopMaterialColorId
    shopTechnologyId
    autoEstimationId
    order {
      id
      price
    }
  }
  ${CadModelFields}
  ${MachineRateFields}
  ${MaterialRateFields}
`;

/*
We have two similar queries. One was for auto estimation progress for order items specifically 
and this new one is to get the progress of a singular Auto Estimation for a job. We should find a way to consolidate
*/
export const AUTO_ESTIMATION_PROGRESS = gql`
  query autoEstimation($id: Int!) {
    autoEstimation(id: $id) {
      ...autoEstimationFields
    }
  }
  ${AutoEstimationFields}
`;

export const AUTO_ESTIMATIONS_PROGRESS = gql`
  query autoEstimations($ids: [Int!]!) {
    autoEstimations(ids: $ids) {
      ...autoEstimationFields
      orderItem {
        ...orderItemFields
      }
    }
  }
  ${AutoEstimationFields}
  ${orderItem_if_autoEstimationCompleteAndSelected}
`;
