import React, { useState } from "react";
import { Input } from "grabcad-ui-elements";

export const blurOnEnter = (e: KeyboardEvent): void => {
  if (e.key === "Enter") {
    (e.target as HTMLInputElement).blur();
  }
};

// TODO: Consolidate in grabcad-ui-elements with <Input>
export const ControlledInput = React.forwardRef(
  (
    {
      value,
      className,
      onSubmit,
      maxLength,
    }: {
      value: string;
      className: string;
      onSubmit: (value: string) => void;
      maxLength: number;
    },
    ref
  ) => {
    const [textState, setTextState] = useState(value);

    return (
      <Input
        className={className}
        ref={ref}
        value={textState}
        onChange={event => setTextState(event.currentTarget.value)}
        onBlur={() => value !== textState && onSubmit(textState)}
        onKeyDown={blurOnEnter}
        maxLength={maxLength}
      />
    );
  }
);
ControlledInput.displayName = "ControlledInput";
