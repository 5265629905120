import styled, { Popup } from "grabcad-ui-elements";
import React from "react";

const StyledNavBar = styled.div`
  width: fit-content;
  display: flex;
  padding: 20px 20px 0px 20px;
  user-select: none;
  font-weight: 500;
  .active {
    color: white;
    background: #003393;
    cursor: default;
    border-color: #003393;
  }
  .disabled {
    background: #ccc;
    color: gray;
  }
  div {
    border: 1px solid #adadad;
    color: #333333;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  div:first-child {
    border-right: none;
    border-radius: 4px 0px 0px 4px;
  }
  div:last-child {
    border-left: none;
    border-radius: 0px 4px 4px 0px;
  }
  div:only-child {
    border-radius: 4px 4px 4px 4px;
  }
`;

export interface NavBarItem<T> {
  type: T;
  name: string;
  disabled: boolean;
  hidden: boolean;
  hoverText?: string;
}

export function ModalNavBar<T extends React.Key>({
  navItems,
  activeTab,
  onTabSelect,
}: {
  navItems: NavBarItem<T>[];
  activeTab: T;
  onTabSelect: (type: T) => void;
}): JSX.Element {
  const renderNavItem = (item: NavBarItem<T>) => {
    return (
      !item.hidden && (
        <div
          className={`${activeTab === item.type ? "active" : ""} ${
            item.disabled ? "disabled" : ""
          }`}
          onClick={() => !item.disabled && onTabSelect(item.type)}
          data-testid="modalTabItem"
        >
          {item.name}
        </div>
      )
    );
  };

  return (
    <StyledNavBar>
      {navItems.map(item => (
        <Popup
          key={item.type}
          pinned
          inverted
          on="hover"
          disabled={!item.disabled}
          content={item.hoverText}
          trigger={renderNavItem(item)}
        />
      ))}
    </StyledNavBar>
  );
}
