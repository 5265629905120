import React, { useState } from "react";
import { Query, Mutation } from "@apollo/client/react/components";
import { PROFILE } from "@/graphql/Queries";
import { IUser } from "@/graphql/Fragments/User";
import { UpdatePreferenceResult, UPDATE_PREFERENCE } from "@/graphql/Mutations/User";
import { updatePreferencesCache, COMPLETED_ONBOARDING } from "@/utils/preferences";
import { StyledJoyride, JoyrideStep, JOYRIDE_EVENTS, JOYRIDE_STATUS } from "grabcad-ui-elements";

interface IOnboardingProps {
  onboardingKey: string;
  steps: JoyrideStep[];
  dismissCopy?: string;
  nextCopy?: string;
  continuous?: boolean;
}

export const getCompletedOnboardingKeys = (
  userProfile: IUser | null
): string[] | null | undefined => {
  const preferences = userProfile?.preferences;
  const completedOnboarding = preferences?.find(pref => pref.key === COMPLETED_ONBOARDING);
  return completedOnboarding && (completedOnboarding.value as string[]);
};

export const Onboarding = ({
  onboardingKey,
  steps,
  dismissCopy,
  nextCopy,
  continuous,
}: IOnboardingProps): JSX.Element => {
  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  return (
    <Query<{ userProfile?: IUser }> query={PROFILE}>
      {({ loading, data, error }) => {
        if (error || loading || !data || !data.userProfile) {
          return null;
        }

        const value = getCompletedOnboardingKeys(data.userProfile);
        const optionalProps = {
          nextCopy,
          ...(continuous && { run, stepIndex, continuous, hideBackButton: true }),
        };
        if (!value?.includes(onboardingKey)) {
          return (
            <Mutation<UpdatePreferenceResult, { key: string; value: string[] }>
              mutation={UPDATE_PREFERENCE}
              variables={{
                key: COMPLETED_ONBOARDING,
                value: value ? value.concat([onboardingKey]) : [onboardingKey],
              }}
              key="groups-menu-item-onboarding"
              update={updatePreferencesCache}
            >
              {update => (
                <StyledJoyride
                  {...optionalProps}
                  steps={steps}
                  dismissCopy={dismissCopy ? dismissCopy : ""}
                  callback={({
                    index,
                    status,
                    type,
                  }: {
                    status: string;
                    index: number;
                    type: string;
                  }) => {
                    if (continuous) {
                      if (
                        (
                          [JOYRIDE_EVENTS.STEP_AFTER, JOYRIDE_EVENTS.TARGET_NOT_FOUND] as string[]
                        ).includes(type)
                      ) {
                        setStepIndex(index + 1);
                      } else if (
                        ([JOYRIDE_STATUS.FINISHED, JOYRIDE_STATUS.SKIPPED] as string[]).includes(
                          status
                        )
                      ) {
                        setRun(false);
                        update();
                      }
                    } else {
                      return status === JOYRIDE_STATUS.FINISHED ? update() : null;
                    }
                  }}
                />
              )}
            </Mutation>
          );
        }
        return null;
      }}
    </Query>
  );
};
