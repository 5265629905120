import React, { useContext } from "react";
import { Icon } from "grabcad-ui-elements";
import { ApplicationContext } from "../../ApplicationProvider";
import { IOrderItemDependencyAddedEvent } from "@/graphql/Fragments/Order";

interface IHistoryItemDependencyAddedComponentProps {
  event: IOrderItemDependencyAddedEvent;
}

export const HistoryItemDependencyAddedComponent = ({
  event,
}: IHistoryItemDependencyAddedComponentProps): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  return (
    <>
      <b>{t("order.history.dependencyAdded")}</b> <Icon name="long arrow alternate right" />
      <span>{event.metadata?.fileNames.join(", ")}</span>
    </>
  );
};

HistoryItemDependencyAddedComponent.displayName = "HistoryItemDependencyRemovedComponent";
