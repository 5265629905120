import React, { useContext } from "react";
import { IShopJob } from "../../graphql/Queries/Job";
import { CadModelPreview } from "../Shared/CadModelPreview";
import { ApplicationContext } from "../ApplicationProvider";
import styled from "grabcad-ui-elements";

export interface IPartsOnTrayProps {
  job: IShopJob;
}

const StyledPartsOnTray = styled.div`
  .part-on-tray-row {
    padding: 30px 0px 30px 40px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-row-gap: 40px;
    img {
      margin-left: 0px !important;
      padding: 10px;
      border: 1px solid #d3d3d3;
    }
    .property-name-and-value {
      margin: 10px 0px;
      span {
        font-weight: 700;
        margin-right: 5px;
      }
    }
    border-bottom: 1px solid #d3d3d3;
    &:last-child {
      border-bottom: none;
    }
  }
`;

/**
 * Parts on tray tab for the job estimates modal
 */
export const PartsOnTray = (props: IPartsOnTrayProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const estimation = props.job.autoEstimation!;
  const partRows = estimation.resultData?.estimates?.[0]?.trayModels?.map(modelInfo => {
    const orderItem = props.job.orderItems.find(oi => oi.id === modelInfo.orderItemId);
    return (
      orderItem && (
        <div className="part-on-tray-row" key={orderItem.id}>
          <div>
            <CadModelPreview itemId={modelInfo.orderItemId} size={"small"} />
          </div>
          <div>
            <div className="property-name-and-value">
              <span>{t("shop.job.jobEstimatesModal.partsOnTray.partName")}</span>
              {orderItem?.cadModel?.original?.asset?.originalName}
            </div>
            <div className="property-name-and-value">
              <span>{t("shop.job.jobEstimatesModal.partsOnTray.quantity")}</span>
              {modelInfo.quantity}/{orderItem?.quantity}
            </div>
          </div>
        </div>
      )
    );
  });
  return <StyledPartsOnTray>{partRows}</StyledPartsOnTray>;
};
