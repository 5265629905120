import * as ls from "local-storage";

export const BrowserSettingsKey = "gc_shop_browser_settings";

export interface IBrowserSettings {
  userHasCollapsedSideBar: boolean;
  sidebarIsHovered: boolean;
}

export const DEFAULT_BROWSER_SETTINGS: IBrowserSettings = {
  userHasCollapsedSideBar: false,
  sidebarIsHovered: false,
};

export const getBrowserSettings = (): IBrowserSettings =>
  ls.get<IBrowserSettings>(BrowserSettingsKey) || DEFAULT_BROWSER_SETTINGS;

export const saveBrowserSettings = (settings: Partial<IBrowserSettings>): void => {
  const currentSettings = getBrowserSettings();
  const newSettings = { ...currentSettings, ...settings };
  ls.set<IBrowserSettings>(BrowserSettingsKey, newSettings);
};
