import gql from "graphql-tag";
import { IImage } from "./Image";
import { ICadFile } from "@/graphql/Mutations/Upload";

// Converter info comes in different asynchronous pieces. will we really need a status on each piece?
// like preview fail/success, size fail/success, unit fail/success?
export type ConversionStatus = "PENDING" | "CONVERTING" | "SUCCESS" | "FAILED";

export interface ICadModel extends ICadFile {
  id: number;
  preview?: IImage;
  inFormat?: ICadAsset;
}

export interface ICadAsset {
  id: number;
  url: string;
}

export const CadModelFields = gql`
  fragment cadModelFields on CadModel {
    id
    preview {
      id
      small {
        id
        location
      }
      medium {
        id
        location
      }
    }
  }
`;
