import gql from "graphql-tag";
import { IMachineRate, IMaterialRate } from "../Fragments/ShopRate";

export const CREATE_SHOP_RATE = gql`
  mutation createShopRate($input: RateInput!) {
    createShopRate(input: $input) {
      machineRate {
        id
        shopMachine {
          id
          newestMachineRateId
        }
      }
      materialRate {
        id
        shopMaterial {
          id
          newestMaterialRateId
        }
      }
    }
  }
`;

export interface ICreateShopRateResponse {
  createShopRate: {
    machineRate: null | IMachineRate[];
    materialRate: null | IMaterialRate[];
  };
}
