import { ApplicationContext } from "@/components/ApplicationProvider";
import { Button } from "grabcad-ui-elements";
import React, { useContext } from "react";
import {
  CAD_MODEL_LOCATION,
  GENERIC_FILE_LOCATION,
  IMAGE_LOCATION,
  AUTO_ESTIMATION_ASSET_LOCATION,
} from "@/graphql/Queries";
import { ApolloClient, ApolloConsumer } from "@apollo/client";
import {
  IAutoEstimationAssetLocationResponse,
  ICadModelLocationResponse,
  IGenericFileLocationResponse,
  IImageLocationResponse,
} from "@/graphql/Queries/Asset";
import ReactGA from "react-ga";
import { OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import Download from "@/assets/icons/download.svg";
import { getIcon } from "./Icons";

export type AssetType = "cad_model" | "generic_file" | "image" | "auto_estimation";

export interface IDownloadButtonProps {
  items: OrderItemOrImageOrFile[];
  attached?: boolean | "left" | "right" | "top" | "bottom";
}

export interface IUrlDownloadProps {
  id: number;
  assetType: AssetType;
}

type ILocationResponse = ICadModelLocationResponse &
  IGenericFileLocationResponse &
  IImageLocationResponse &
  IAutoEstimationAssetLocationResponse;

export const getUrlAndDownload = async (
  client: ApolloClient<any>,
  downloadProps: IUrlDownloadProps
): Promise<void> => {
  const { id, assetType } = downloadProps;
  let assetLocationQuery: any;

  switch (assetType) {
    case "cad_model":
      assetLocationQuery = CAD_MODEL_LOCATION;
      break;
    case "generic_file":
      assetLocationQuery = GENERIC_FILE_LOCATION;
      break;
    case "image":
      assetLocationQuery = IMAGE_LOCATION;
      break;
    case "auto_estimation":
      assetLocationQuery = AUTO_ESTIMATION_ASSET_LOCATION;
      break;
    default:
      throw new Error(`Asset type ${assetType} not supported`);
  }

  const { data }: { data: ILocationResponse } = await client.query({
    query: assetLocationQuery,
    variables: { id },
    fetchPolicy: "no-cache",
  });
  let location: string;
  switch (assetType) {
    case "cad_model":
      location = data.cadModel.original.asset.location;
      break;
    case "generic_file":
      location = data.genericFile.asset.location;
      break;
    case "image":
      location = data.image.original.location;
      break;
    case "auto_estimation":
      location = data.autoEstimationAsset.asset.location;
      break;
    default:
      throw new Error(`Asset type ${assetType} not supported`);
  }

  const downloadURL = (url: string) => {
    const iframe = document.createElement("iframe");
    iframe.id = `hiddenDownloader_${assetType}_${id}`;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    iframe.src = url;
  };
  downloadURL(location);
};

export const getItemsToDownload = (item: OrderItemOrImageOrFile): IUrlDownloadProps[] => {
  let itemAndDeps = [item];
  if (item.__typename === "OrderItem") {
    itemAndDeps = [
      ...itemAndDeps,
      ...(item.children || []),
      ...(item.childImages || []),
      ...(item.childFiles || []),
    ];
  }
  return itemAndDeps.map(itemOrDep => {
    if (itemOrDep.__typename === "OrderItem") {
      const cadModelId = itemOrDep.cadModel?.id;
      return { id: cadModelId, assetType: "cad_model" };
    }
    if (itemOrDep.__typename === "Image") {
      return { id: itemOrDep.id, assetType: "image" };
    }
    if (itemOrDep.__typename === "GenericFile") {
      return { id: itemOrDep.id, assetType: "generic_file" };
    }
  }) as IUrlDownloadProps[];
};

export const DownloadButton = (props: IDownloadButtonProps): JSX.Element => {
  const { currentShop, t } = useContext(ApplicationContext);
  return (
    <ApolloConsumer>
      {client => (
        <Button
          data-tooltip={t("order.items.download")}
          data-position={"bottom center"}
          icon={getIcon(Download, {
            fontSize: "1.4em",
            opacity: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
          disabled={false}
          secondary
          compact
          className={"qa-orderItemRow-download borderless"}
          style={{ margin: "0", padding: "5px", zIndex: 3 }}
          onClick={async () => {
            let itemsToDownload: IUrlDownloadProps[] = [];
            for (const item of props.items) {
              itemsToDownload = itemsToDownload.concat(getItemsToDownload(item));
            }
            for (const itemToDownload of itemsToDownload) {
              await getUrlAndDownload(client, itemToDownload);
            }
            ReactGA.event({
              category: "GcShop File Download",
              action: "OrderItem file downloaded",
              label: `Shop ${currentShop?.id}`,
            });
          }}
        />
      )}
    </ApolloConsumer>
  );
};
