import gql from "graphql-tag";
import { Permission } from "@/utils/Permission";
import { ITypeNamed } from "./base";
import { Preference } from "@grabcad/preferences-client";

// NB: Keep in sync with IUser
export const UserFragments = {
  user: gql`
    fragment userProfileFields on User {
      id
      name
      email
      features {
        featureKey
        machineEntitlements {
          serial
          technology
          model
        }
      }
      permissions
      preferences {
        key
        value
      }
      company {
        name
        id
        internal
        analyzeServerAddress
      }
      companyAdminUrl
      companyServerHost
      __typename
    }
  `,
  // permissions for <UiCan do={Permission.CREATE_SHOP} on={user}>...</UiCan> can render children
};

export interface MachineEntitlement {
  serial?: string;
  technology?: string;
  model?: string;
}

/**
 * See shop-server\src\clients\company_types.ts
 */
export interface FeatureKeyAndEntitlements {
  featureKey: string;
  machineEntitlements?: MachineEntitlement[];
}

export interface IUser extends ITypeNamed<"User"> {
  id: number;
  email: string;
  company: {
    id: number;
    internal: boolean;
    name: string;
    analyzeServerAddress?: string;
  };
  name?: string;
  permissions?: Permission[];
  preferences?: Preference[];
  features?: FeatureKeyAndEntitlements[];
  companyAdminUrl: string;
  companyServerHost: string;
}

export interface IProfileResponse {
  userProfile?: IUser;
}

// NB: Keep in sync with src/graphql/Mutations/User.ts : GET_LOGOUT_URL
export type IGetLogoutUrlResult = string;
