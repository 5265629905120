import { IShopMaterial } from "@/graphql/Queries/Material";
import gql from "graphql-tag";
import { IImage } from "./Image";
import { Permission } from "@/utils/Permission";
import { IAppMachineType, AppMachineTypeFragments } from "./AppMachineType";

export interface IShopMachine {
  id: number;
  serialNumber?: string;
  image: IImage | null;
  materials: IShopMaterial[];
  appMachineType: IAppMachineType;
  permissions: Permission[];
  state: ShopMachineState;
  dateDeleted: Date | null;
  newestMachineRateId: number | null;
  shopTechnologyId?: number;
  __typename?: string;
}

const shopMachineFragment = gql`
  fragment shopMachineFields on ShopMachine {
    id
    dateDeleted
    newestMachineRateId
    appMachineType {
      ...appMachineTypeFields
    }
    materials {
      id
      newestMaterialRateId
      appMaterial {
        id
        name
        displayName
      }
      shopMaterialColors {
        id
        dateDeleted
        appMaterialColor {
          id
          name
          displayName
        }
      }
    }
    permissions
    state
    __typename
  }
  ${AppMachineTypeFragments.appMachineType}
`;

export const ShopMachineFragments = {
  shopMachine: shopMachineFragment,
  shopMachineWithImage: gql`
    fragment shopMachineWithImage on ShopMachine {
      ...shopMachineFields
      image {
        id
        medium {
          id
          location
        }
      }
    }
    ${shopMachineFragment}
  `,
};

export enum ShopMachineState {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  SAMPLE = "SAMPLE",
}
