import { MutationFunction } from "@apollo/client";
import React, { useContext, useState } from "react";
import styled, { Form, FormField, Input, Button } from "grabcad-ui-elements";
import { ImageUpdateOrDeleteDropzone } from "@/components/Upload/ImageUpdateOrDeleteDropzone";
import { ImageType, Entity } from "@/graphql/Mutations/Upload";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { useForm } from "@/utils/useForm";
import { IShop, ShopState } from "@/graphql/Fragments/Shop";
import { ShopFormCurrenciesDropdown } from "./CurrenciesDropdown";
import { ImagePreview } from "@/components/Shared/ImagePreview";
import { ROUTES } from "@/shopConstants";
import { withRouter, RouteComponentProps } from "react-router";
import { isUndefined } from "util";
import { IImage } from "@/graphql/Fragments/Image";
import defaultShopLogo from "@/assets/default-image-500px-sq.png";
import ReactGA from "react-ga";
import classnames from "classnames";
import { UnitsPicker } from "../../Order/Form/UnitsPicker";
import { preferredUnits2GQL } from "@/utils/DropdownUtils";

const FormHolder = styled.div`
  .ui.form {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    .settings {
      flex-grow: 1;
      padding-right: 2em;
    }
    .field {
      margin-bottom: 18px;
    }
    .field > label {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 11px;
    }
    .image-preview {
      width: 110px;
      height: 110px;
    }
  }
  .buttons {
    max-width: 600px;
    margin: 4px auto 0 auto;
    text-align: right;
  }
`;

export interface IDropdownOptions {
  key: string;
  value: string;
  text: string;
}

export interface IFormProps {
  name: string;
  currencyCode: string;
  preferredUnits: string;
  logoImageId: number;
  [key: string]: string | number;
}

interface IShopFormProps extends RouteComponentProps {
  mutate?: MutationFunction;
  shop?: Partial<IShop> | null;
}

export const ShopForm = withRouter(({ history, mutate, shop }: IShopFormProps) => {
  const { t } = useContext(ApplicationContext);
  const [submitting, setSubmitting] = useState(false);
  const defaultImage = {
    medium: { location: defaultShopLogo },
    __typename: "Image",
  } as Partial<IImage>;

  const initialValues: IFormProps = {} as IFormProps;

  if (shop) {
    initialValues.name = shop.name || "";
    initialValues.currencyCode = shop.currencyCode || "";
    initialValues.preferredUnits = shop.preferredUnits || "";
    initialValues.logoImageId = shop.logoImageId || 0;
  } else {
    // add a blank value to force it to be controlled
    initialValues.name = "";
  }

  const isWritable = !!mutate;

  const { handleChange, handleSubmit, manualChange, allRequiredSet, values } = useForm<IFormProps>(
    async ({ name, currencyCode, preferredUnits, logoImageId }) => {
      setSubmitting(true);

      const optionalFields: Partial<IShop> = {};
      if (shop) {
        optionalFields.id = shop.id;
      }
      if (logoImageId) {
        optionalFields.logoImageId = +logoImageId;
      } else if (isUndefined(logoImageId)) {
        optionalFields.logoImageId = 0;
      }
      if (shop?.state) {
        optionalFields.state = shop.state;
      }
      if (!mutate) {
        throw new Error("Unexpected change submission");
      }
      await mutate({
        variables: {
          input: {
            name,
            currencyCode,
            preferredUnits,
            ...optionalFields,
          },
        },
      });

      setSubmitting(false);
    },
    initialValues,
    ["name", "currencyCode", "preferredUnits"]
  );

  const MAX_NAME_LENGTH = 100;
  const namelengthTooltip =
    values.name && values.name.length > MAX_NAME_LENGTH ? t("shop.form.nameTooLong") : undefined;

  return (
    <FormHolder>
      <Form onSubmit={handleSubmit} data-testid="shopForm">
        <div className="settings">
          <FormField required>
            <label>{t("shop.form.name")}</label>
            <Input
              className={classnames({ error: !!namelengthTooltip })}
              data-tooltip={namelengthTooltip}
              data-position="left center"
              data-visible
              id="qa-shopForm-nameInput"
              disabled={!isWritable}
              name="name"
              value={values.name}
              onChange={handleChange}
            />
          </FormField>
          <FormField required>
            <label>{t("shop.form.currencyCode")}</label>
            <ShopFormCurrenciesDropdown
              disabled={!isWritable}
              currencyCode={values.currencyCode}
              setCurrencyCode={(value: string | undefined) => manualChange("currencyCode", value)}
            />
          </FormField>
          <FormField required>
            <label>{t("shop.form.preferredUnits2")}</label>
            <UnitsPicker
              id="qa-shopForm-preferredUnitsPicker"
              currentUnits={preferredUnits2GQL(values.preferredUnits)}
              onUnitsSelected={value => manualChange("preferredUnits", value)}
            />
          </FormField>
        </div>
        <FormField>
          <label>{t("shop.image")}</label>
          {isWritable ? (
            <ImageUpdateOrDeleteDropzone
              onImageUploaded={imageId => {
                manualChange("logoImageId", imageId.toString());
              }}
              onImageReset={() => manualChange("logoImageId", undefined)}
              imageType={ImageType.LOGO}
              entity={Entity.SHOP}
              currentImage={shop ? shop.logo : undefined}
              menuPosition={"right center"}
              defaultImage={defaultImage}
              imageSize={"small"}
            />
          ) : (
            shop?.logo && <ImagePreview image={shop.logo} size={"small"} />
          )}
        </FormField>
      </Form>
      {isWritable && (
        <div className="buttons">
          <Button
            id="qa-shopForm-cancel"
            primary
            disabled={submitting}
            className={"secondary"}
            onClick={() => {
              if (shop) {
                history.push(ROUTES.SHOP(shop.id).ORDERS);
              } else {
                history.push(ROUTES.ROOT);
              }
            }}
          >
            {t("general.cancel")}
          </Button>
          <Button
            id="qa-shopForm-submit"
            primary
            disabled={submitting || !allRequiredSet}
            onClick={() => {
              handleSubmit();
              shop && shop.state !== ShopState.DRAFT
                ? ReactGA.event({
                    category: "GcShop",
                    action: "Update Shop Info",
                    label: `Shop ${shop?.id}`,
                  })
                : ReactGA.event({
                    category: "GcShop Machines",
                    action: "Added Shop Machine in new shop",
                    label: `Shop ${shop?.id}`,
                  });
            }}
          >
            {shop && shop.state !== ShopState.DRAFT
              ? t("shop.form.update")
              : t("shop.form.addMachines")}
          </Button>
        </div>
      )}
    </FormHolder>
  );
});
