import React from "react";
import { Image } from "grabcad-ui-elements";
import { AUTO_ESTIMATION_ASSET_LOCATION } from "@/graphql/Queries";
import defaultImage from "@/assets/default-image-500px-sq.png";
import { useQuery } from "@apollo/client";
import {
  IAutoEstimationAssetLocationResponse,
  ICadModelLocationResponse,
  IGenericFileLocationResponse,
  IImageLocationResponse,
} from "../../graphql/Queries/Asset";
import { AutoEstimationAsset } from "../../graphql/Fragments/AutoEstimation";

export interface ISnapshotProps {
  asset?: AutoEstimationAsset;
}

type ILocationResponse = ICadModelLocationResponse &
  IGenericFileLocationResponse &
  IImageLocationResponse &
  IAutoEstimationAssetLocationResponse;

export const Snapshot = ({ asset }: ISnapshotProps): JSX.Element => {
  let imageSrc = defaultImage;
  if (asset) {
    const { data, loading, error } = useQuery<ILocationResponse>(AUTO_ESTIMATION_ASSET_LOCATION, {
      variables: { id: asset.id },
    });

    if (loading || error) {
      imageSrc = defaultImage;
    }

    if (data?.autoEstimationAsset) {
      imageSrc = data.autoEstimationAsset.asset.location;
    }
  }
  return <Image src={imageSrc} centered fluid />;
};
