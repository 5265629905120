import { ApplicationContext } from "@/components/ApplicationProvider";
import { UserLoginMenu } from "@/components/User/Login/Menu/Menu";
import styled, { Menu } from "grabcad-ui-elements";
import { ShopState } from "@/graphql/Fragments/Shop";
import { History } from "history";
import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ROUTES, SHOP } from "@/shopConstants";
import {
  ExternalLinks,
  MachinesMenuItems,
  ShopMenuItems,
  TechnologiesMenuItems,
} from "./MenuItems";
import StratasysLogo from "@/assets/stratasys-with-logomark.svg";

const ShopSidebarWrapper = styled.div`
  width: 240px;
  padding: 15px 1rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .ui.secondary.menu {
    flex: 1 0 auto;
  }
`;
ShopSidebarWrapper.displayName = "ShopSidebarWrapper";

const ShopMenu = styled(Menu)`
  &.ui.secondary.menu {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    text-align: left;
    display: flex;
    flex-direction: column;
    .main-items {
      flex: 1 0 auto;
    }
    .item {
      height: 36px;
      .image {
        margin-right: 10px;
        width: 18px;
        height: 18px;
      }
    }
  }
  .stratasys-logo {
    display: inline-block;
    img {
      width: 99px;
      margin: 4px 9px;
    }
  }
`;
ShopMenu.displayName = "ShopMenu";

export const BackLink = styled.div`
  font-weight: bold;
`;

interface IRouteParams {
  section: string;
  shopId: string;
  technologyId?: string;
  machineId?: string;
}

export interface ISidebarMenuProps {
  shopId?: number;
  history: History;
  section?: string;
}

export const LayoutShopSidebar = withRouter(
  ({
    match: { params },
    history,
    section,
    location: { pathname },
  }: RouteComponentProps<IRouteParams> & {
    section?: string;
  }) => {
    const { currentShop } = useContext(ApplicationContext);
    const shopId = params.shopId ? +params.shopId : undefined;
    const technologyId = params.technologyId ? +params.technologyId : undefined;
    const machineId = params.machineId ? +params.machineId : undefined;

    let sidebarMenuItems = null;
    if (currentShop && currentShop.state !== ShopState.DRAFT) {
      if (
        section === SHOP.SECTIONS.machines &&
        pathname.includes(ROUTES.SHOP(shopId).MACHINES.INDEX)
      ) {
        if (technologyId && machineId) {
          sidebarMenuItems = (
            <MachinesMenuItems
              shopId={shopId}
              history={history}
              section={section}
              technologyId={technologyId}
              machineId={machineId}
            />
          );
        } else {
          sidebarMenuItems = (
            <TechnologiesMenuItems
              shopId={shopId}
              history={history}
              section={section}
              technologyId={technologyId}
            />
          );
        }
      } else {
        sidebarMenuItems = <ShopMenuItems shopId={shopId} history={history} section={section} />;
      }
    }

    return (
      <ShopSidebarWrapper data-testid="layoutShopSidebar">
        <UserLoginMenu />
        <ShopMenu vertical secondary fluid>
          <div className="main-items qa-sidebar-menu-items">
            {sidebarMenuItems}
            <ExternalLinks />
          </div>
          <div>
            <a href="https://www.stratasys.com/" className="stratasys-logo">
              <img src={StratasysLogo} alt="Stratasys" />
            </a>
          </div>
        </ShopMenu>
      </ShopSidebarWrapper>
    );
  }
);
LayoutShopSidebar.displayName = "LayoutShopSidebar";
