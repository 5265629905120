import { IUploadContainer } from "@/components/Upload/CadDropZone";
import { Icon, Image } from "grabcad-ui-elements";
import React, { SyntheticEvent, useContext } from "react";
import { CadFileRow } from "./SubscriptionResults";
import { IImage } from "@/graphql/Fragments/Image";
import { ApplicationContext } from "@/components/ApplicationProvider";

export interface IUploadImageResultContainer extends IUploadContainer {
  cadFile: IImage;
}

interface IUploadImageResultsProps {
  uploadContainer: IUploadImageResultContainer;
  index: number;
}
export const UploadImageResults = ({
  uploadContainer,
  index,
}: IUploadImageResultsProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { cadFile, file, cancel } = uploadContainer;
  const imgSrc = cadFile?.small?.location;

  return (
    <CadFileRow
      className={"qa-uploadFileRow"}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation();
      }}
    >
      <div className="num">{index + 1}</div>
      <div className="img">
        <Image src={imgSrc} />{" "}
      </div>
      <div className="name-n-status">
        <strong>{file.name}</strong>
        <div className="status">{t("order.form.uploader.converted")}</div>
      </div>
      <Icon
        onClick={(event: SyntheticEvent) => {
          cancel();
          event.stopPropagation();
        }}
        name={"trash"}
      />
    </CadFileRow>
  );
};
