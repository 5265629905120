import { createContextualCan } from "@casl/react";
import { GcAbility } from "@/GcAbility";
import { createContext } from "react";

// Default empty (can do nothing) ability used by tests only.
// Real app defines rules in IUserContext.setAsLoggedIn().
// Note: Can give GcAbilty to <Can> only because its shape can()
// matches the normal Ability.can()
const DEFAULT_ABILITY = new GcAbility();

export const AbilityContext = createContext<GcAbility>(DEFAULT_ABILITY);
AbilityContext.displayName = "AbilityContext";

export const UiCan = createContextualCan(AbilityContext.Consumer);
UiCan.displayName = "UiCan";
