import { ApplicationContext } from "@/components/ApplicationProvider";
import React, { useContext, useState } from "react";
import { Image, Button } from "grabcad-ui-elements";
import { StyledGeneralScreensPage } from "@/screens/ScreenStyleUtils/GeneralScreenStyle";
import svgStratasys from "@/assets/stratasys.svg";
import svgGrabCAD from "@/assets/grabcad.svg";
import { UPDATE_ORDER_NOTIFICATION_PREFERENCE } from "@/graphql/Mutations";
import { ApolloError, MutationFunction } from "@apollo/client";
import { Mutation, Query } from "@apollo/client/react/components";
import { Notifier } from "@/utils/Notifier";
import { NOTIFICATION_TYPE } from "@/components/Order/NotificationSubscription/NotificationSubscription";
import { RouteComponentProps, withRouter } from "react-router";
import ReactGA from "react-ga";
import { IOrder } from "@/graphql/Fragments/Order";
import { ORDER_DETAILS } from "@/graphql/Queries";
import { ROUTES } from "@/shopConstants";

export const ScreensNotificationUnsubscribe = withRouter(
  (props: RouteComponentProps<{ orderId: string }>) => {
    const { t, currentUser } = useContext(ApplicationContext);
    const userId = currentUser.userProfile?.id;
    const userEmail = currentUser.userProfile?.email;
    const orderId = +props.match.params.orderId;
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);

    const mutationCompletedFunc = () => {
      ReactGA.event({
        category: "GcShop Order",
        action: "Order Notification unsubscribed from Email",
      });
    };

    return (
      <Query<{ order?: IOrder }, { orderId: number }>
        query={ORDER_DETAILS}
        variables={{ orderId: +orderId }}
        onError={error => Notifier.error(error)}
      >
        {({ data, loading, error }) => {
          if (error) {
            return <p>{t("global.error")}</p>;
          }
          if (loading || !data || !data.order) {
            return <p>{t("global.loading")}</p>;
          }

          const order = data.order;
          if (order.notificationSubscribed && !isUnsubscribed) {
            return (
              <Mutation
                mutation={UPDATE_ORDER_NOTIFICATION_PREFERENCE}
                onError={(err: ApolloError) => Notifier.error(err)}
                onCompleted={() => {
                  mutationCompletedFunc();
                }}
              >
                {(updateNotificationPreference: MutationFunction) => (
                  <StyledGeneralScreensPage>
                    <div>
                      <h1>{t("notification.unsubscribe")}</h1>
                      <p data-testid="unsubscribeMessage1">
                        {t("notification.unsubscribe.message1")}
                        <i> {order.name}?</i>
                      </p>
                      <p data-testid="unsubscribeMessage2">
                        {t("notification.unsubscribe.message2")}
                      </p>
                      <Button
                        className="qa-unsubscribe-confirm"
                        onClick={() => {
                          updateNotificationPreference({
                            variables: {
                              input: {
                                userId: userId,
                                entityId: orderId,
                                type: NOTIFICATION_TYPE.ORDER,
                                unSubscribe: true,
                              },
                            },
                          });
                          setIsUnsubscribed(true);
                        }}
                      >
                        {t("notification.unsubscribe.button")}
                      </Button>
                      <div className="logos">
                        <a href="https://www.stratasys.com/">
                          <Image src={svgStratasys} />
                        </a>
                        <a href="https://grabcad.com/">
                          <Image src={svgGrabCAD} />
                        </a>
                      </div>
                    </div>
                  </StyledGeneralScreensPage>
                )}
              </Mutation>
            );
          } else {
            return (
              <StyledGeneralScreensPage>
                <div>
                  <h1>{t("notification.unsubscribed")}</h1>
                  <p data-testid="unsubscribedMessage1">
                    {t("notification.unsubscribed.message1", { user_email: userEmail })}{" "}
                    <i>{order.name}.</i>
                  </p>
                  <p data-testid="unsubscribedMessage2">
                    {t("notification.unsubscribed.message2")}
                  </p>
                  <Button onClick={() => props.history.push(ROUTES.ROOT)}>
                    {t("pageNotFound.cta")}
                  </Button>
                  <div className="logos">
                    <a href="https://www.stratasys.com/">
                      <Image src={svgStratasys} />
                    </a>
                    <a href="https://grabcad.com/">
                      <Image src={svgGrabCAD} />
                    </a>
                  </div>
                </div>
              </StyledGeneralScreensPage>
            );
          }
        }}
      </Query>
    );
  }
);
