import { MutationFunction, gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import React, { FunctionComponent } from "react";
import { Notifier } from "@/utils/Notifier";

/*
  This component is not end-user-facing. It's for internal use by QA & developers.
  Accessed via "secret" route: /obscure/throw-exception
*/

const THROW_EXCEPTION = gql`
  mutation throwObscureError {
    throwObscureError
  }
`;

export const ThrowException: FunctionComponent<any> = () => (
  <div style={{ margin: 40 }}>
    <button
      onClick={() => {
        throw new Error("Test error for shop-client-frontend");
      }}
    >
      Throw test client exception
    </button>
    <br />
    <br />
    <Mutation
      mutation={THROW_EXCEPTION}
      update={() => {
        Notifier.success("Exception thrown!");
      }}
    >
      {(update: MutationFunction) => (
        <button onClick={() => update()}>Throw test server exception</button>
      )}
    </Mutation>
  </div>
);
