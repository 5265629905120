import React, { ReactElement, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import styled, {
  Avatar,
  Button,
  Checkbox,
  Image,
  Loader,
  SelectionDropdown,
  StyleMixins,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { PlaceHolder } from "@/components/Shared/Placeholder";
import PlaceholderClipboards from "../../../../assets/placeholder_orders.svg";
import { ApolloClient, ApolloError, ApolloQueryResult, useApolloClient } from "@apollo/client";
import { IOrder, IOrderItem, OrderItemFields } from "@/graphql/Fragments/Order";
import { ORDER_PARTS_BY_SHOP } from "@/graphql/Queries/Order";
import { getStatusName, OrderStatus } from "@/components/Order/Status/Status";
import {
  CREATE_JOB,
  CREATE_ORDER_ITEM_JOB_ROUTE_STEP_HISTORIES,
  IJobCreateInput,
  IJobHistoriesInput,
} from "@/graphql/Mutations/Job";
import classnames from "classnames";
import { ROUTES } from "@/shopConstants";
import { CreateJobModal } from "@/components/Job/CreateJobModal";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Notifier } from "@/utils/Notifier";
import { getActiveStatusIds } from "@/components/Order/List/FiltersPopup";
import {
  SHOP_JOBS,
  JOB_COUNTS,
  IShopJob,
  JOB_DETAILS,
  IOrderItemJobRouteStepHistory,
} from "@/graphql/Queries/Job";
import { useShopTechnologies } from "@/utils/queryHooks";
import { getCadModelName, isAnyMaterial, isGcpAsset } from "@/utils/GeneralUtils";
import moment from "moment";
import { useKeyPress } from "@/utils/useKeyPress";
import technologyIcon from "@/assets/icons/technology.svg";
import machineIcon from "@/assets/icons/machine.svg";
import materialIcon from "@/assets/icons/material.svg";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { updateCadModelQueryCache } from "@/graphql/Utils/updateCadModelQueryCacheUtil";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import { getTranslatedItemUnits } from "../../../../utils/DropdownUtils";
import { JobFragments } from "../../../../graphql/Fragments";

const JobsTableWrapper = styled.div`
  ${StyleMixins.roundAndShadow}
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;
  .scrolling-wrapper {
    overflow-x: auto;
    &.has-content {
      height: calc(100% - 45px); /* account for footer height */
    }
  }
  .no-results-container {
    position: relative;
    height: calc(100% - 221px);
    min-height: 300px;
  }

  .jobs-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-top: 0.5px solid #cccccc;
    .selected-part-count {
      color: #808080;
      &.has-selection {
        color: #003393;
      }
    }
  }

  /* Semantic UI seems to have an internal conflict which makes <Loader>s in <Modal>s
     invisible due to being nested in non-interted <Dimmer>s. Reinforce non-inverted styles: */
  .ui.loader:before {
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
  .ui.loader:after {
    border-color: #767676 transparent transparent !important;
  }
`;

const PART_ROW_HEIGHT = 65;
const PartsTable = styled(Table)`
  &.ui.table {
    position: relative;
    border: none;
    flex: 1 1 100%;
    thead th {
      height: 55px;
      background-color: #f4f5f7;
      border-top: 1px solid rgba(34, 36, 38, 0.1);
      border-left: none;
      border-radius: 0 !important;
      position: sticky;
      top: 0;
      z-index: 2;
      &.sorted,
      &:hover,
      &.sorted:hover {
        background: #f2f2f2; /* Override semantic's default translucent black to support sticky header */
      }
    }
    tbody {
      position: relative;
      tr.selected {
        background: #e0ecfc; /* TODO: Share this */
      }
      tr.selected:hover,
      tr:hover {
        cursor: pointer;
        background: #ecf4ff; /* TODO: Share this */
      }
    }
    tr.disabled td {
      pointer-events: all;
      cursor: default;
    }
    td {
      /* semantic overrides */
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      border-top: none;
      padding: 6px 0.78571429em;
      height: ${PART_ROW_HEIGHT}px;
      &.filename a {
        word-break: break-word;
        &:hover {
          text-decoration: underline;
        }
      }
      &.popup-cell {
        pointer-events: all;
      }
      &.short {
        width: 1%;
      }
      &.price {
        text-align: right;
      }
      &.stretchy,
      &.truncatable-cell {
        white-space: nowrap;
      }
      &.stretchy div,
      &.truncatable-cell div {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2 !important;
        overflow: hidden !important;
        white-space: normal !important;
        padding-right: 10px;
      }

      &.stretchy {
        min-width: 150px;
        div:not([class="stretchy-child"]) {
          max-width: none !important;
        }
      }

      div.stretchy-child {
        max-width: 100% !important;
      }

      &.truncatable-cell {
        max-width: 150px;
      }
      div.truncatable {
        max-width: 150px;
      }
      .qa-popup-trigger {
        div:first-child {
          vertical-align: middle;
        }
      }
    }
    th {
      height: ${PART_ROW_HEIGHT}px;
    }
  }
  .thumbnail {
    border: 1px solid #dddddd;
    background: white;
    box-sizing: border-box;
    border-radius: 4px;
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    img {
      height: 35px;
      margin: 0 auto;
    }
  }
`;

const StyledPartsFilters = styled.div`
  display: flex;
  padding: 20px 10px;
  .filter-dropdown {
    flex: 1 1 0;
    margin: 0 10px;
    h4 {
      margin-bottom: 3px;
    }
  }
`;

export const updateCachedOrderItemsJob = (
  client: ApolloClient<any>,
  jobId: number | null,
  orderItemIds: number[]
): void => {
  // Update any cached OrderItems to include (or nullify) jobId to lock down (or unlock) their quantities, technology, material etc.
  orderItemIds.forEach(orderItemId => {
    const cachedOrderItem = client.cache.readFragment<IOrderItem>({
      id: `OrderItem:${orderItemId}`,
      fragment: OrderItemFields,
      fragmentName: "orderItemFields",
    });
    if (cachedOrderItem) {
      client.writeFragment({
        id: `OrderItem:${orderItemId}`,
        fragment: OrderItemFields,
        fragmentName: "orderItemFields",
        data: {
          ...cachedOrderItem,
          jobId,
        },
      });
    }
  });
};

export const addOrderItemsToCachedJob = (
  client: ApolloClient<any>,
  jobId: number,
  orderItemIds: number[]
): void => {
  updateCachedOrderItemsJob(client, jobId, orderItemIds);
};

/**
 * Remove items from cached job
 * @returns number of remaining order items
 */
export const removeOrderItemsFromCachedJob = (
  client: ApolloClient<any>,
  jobId: number,
  orderItemIds: number[]
): number | undefined => {
  updateCachedOrderItemsJob(client, null, orderItemIds);

  const cachedJob = client.cache.readFragment<IShopJob>({
    id: `Job:${jobId}`,
    fragment: JobFragments.job,
    fragmentName: "jobFields",
  });

  if (!cachedJob) {
    return undefined;
  }

  const remainingOrderItems = cachedJob.orderItems.filter(item => !orderItemIds.includes(item.id));
  client.writeFragment({
    id: `Job:${jobId}`,
    fragment: JobFragments.job,
    fragmentName: "jobFields",
    data: {
      ...cachedJob,
      orderItems: remainingOrderItems,
    },
  });

  return remainingOrderItems.length;
};

export interface IOrderPart extends IOrderItem {
  order: IOrder;
  filename?: string;
  material?: string;
  materialColor?: string;
  needByDate?: string;

  shopMachine?: IShopMachine;
  shopMaterial?: IShopMaterial;
}
interface IAddPartsToJobFormProps extends RouteComponentProps {
  job?: IShopJob;
  onAddPartsSuccess?: () => void;
}

export type SortDirection = "descending" | "ascending";
const DESCENDING = "descending";
const ASCENDING = "ascending";
const DEFAULT_SORT_COLUMN: keyof IOrderPart = "needByDate";

export const AddPartsToJobForm = withRouter(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ history, job, onAddPartsSuccess }: IAddPartsToJobFormProps): JSX.Element | null => {
    const { t, formatDate, formatPrice, currentShop } = useContext(ApplicationContext);
    if (!currentShop) {
      return null;
    }

    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [orderParts, setOrderParts] = useState<IOrderPart[]>([]);
    const [filteredParts, setFilteredParts] = useState<IOrderPart[]>([]);
    const [selectedPartIds, setSelectedParts] = useState<number[]>([]);
    const [showNameModal, setShowNameModal] = useState<boolean>(false);
    const [sortCol, setSortCol] = useState<keyof IOrderPart>(DEFAULT_SORT_COLUMN);
    const [sortDir, setSortDir] = useState<SortDirection>(ASCENDING);
    const getShopStatusName = (id: number) => {
      const shopStatus = currentShop?.shopStatuses.find(status => status.id === id);
      return shopStatus ? getStatusName(shopStatus, t) : "";
    };
    const sortValues = (vA: any, vB: any) => {
      const reverse = sortDir === "ascending" ? 1 : -1;
      if (!vA) {
        return !vB ? 0 : -1 * reverse;
      }
      if (!vB) {
        return 1 * reverse;
      }
      if (typeof vA === "string" && typeof vB === "string") {
        vA = vA.toLowerCase();
        vB = vB.toLowerCase();
      }
      if (vA > vB) {
        return 1 * reverse;
      }
      if (vB > vA) {
        return -1 * reverse;
      }
      return 0;
    };
    const sortedParts = filteredParts.sort((a, b) =>
      sortCol === "status"
        ? sortValues(getShopStatusName(a.shopStatus), getShopStatusName(b.shopStatus))
        : sortValues(a[sortCol], b[sortCol])
    );

    // Shifty-select logic
    const [lastSelectedId, setLastSelectedId] = useState<number | undefined>(undefined);
    const shiftPressed = useKeyPress("Shift");

    const { loadingTechnologies, shopTechnologies, allMachines, allMaterials, allMaterialColors } =
      useShopTechnologies();

    const [shopTechnologyId, setShopTechnologyId] = useState<number | undefined>(
      job?.shopTechnologyId || undefined
    );
    const [shopMachineId, setShopMachineId] = useState<number | undefined>(
      job?.shopMachineId || undefined
    );
    const [shopMaterialId, setShopMaterialId] = useState<number | undefined>(
      job?.shopMaterialId || undefined
    );

    const shopTechnology = shopTechnologies.find(tech => tech.id === shopTechnologyId);
    const shopMachine = allMachines.find(machine => machine.id === shopMachineId);
    const shopMaterial = allMaterials.find(material => material.id === shopMaterialId);

    let availableTechnologies = shopTechnologies;
    let availableMachines = allMachines;
    let availableMaterials = allMaterials;

    if (shopTechnologyId) {
      // Limit available machine and material filters by selected technology
      availableMachines = availableMachines.filter(
        machine => machine.shopTechnologyId === shopTechnologyId
      );
      const availableMachineIds = availableMachines.map(machine => machine.id);
      availableMaterials = availableMaterials.filter(
        material => material.shopMachineId && availableMachineIds.includes(material.shopMachineId)
      );
    }
    if (shopMachineId) {
      // Limit material filters by selected machine
      availableMaterials = availableMaterials.filter(
        material => material.shopMachineId === shopMachineId
      );
    }

    // Dedupe same-named materials on different machines
    const dedupedMaterials: IShopMaterial[] = [];
    availableMaterials.forEach(material => {
      if (
        !dedupedMaterials.find(
          dedupedMaterial => dedupedMaterial.appMaterial.name === material.appMaterial.name
        )
      ) {
        dedupedMaterials.push(material);
      }
    });
    availableMaterials = dedupedMaterials;

    // Don't show filters that will result in empty lists (only currently possible with client-side filtering)
    availableTechnologies = availableTechnologies.filter(
      tech =>
        orderParts.some(part => part.shopTechnologyId === tech.id) ||
        // If the Form is being used to add parts to an existing Job, the Job's Technology may have no parts available,
        // but should still be included so it can be displayed in the disabled dropdown
        (job && job.shopTechnologyId === tech.id)
    );
    const availableTechnologyIds = availableTechnologies.map(tech => tech.id);
    availableMachines = availableMachines.filter(
      machine =>
        // Include only machines that have parts assigned to them and
        // undeleted machines that parts assigned to their technologies
        orderParts.some(part => part.shopMachine && part.shopMachine.id === machine.id) ||
        (!machine.dateDeleted &&
          machine.shopTechnologyId &&
          availableTechnologyIds.includes(machine.shopTechnologyId)) ||
        // If the Form is being used to add parts to an existing Job, the Job's Machine may have no parts available,
        // but should still be included so it can be displayed in the disabled dropdown
        (job && job.shopMachineId === machine.id)
    );
    const partMaterialsNames = orderParts.map(part => part.shopMaterial?.appMaterial.name);
    availableMaterials = availableMaterials.filter(
      material =>
        partMaterialsNames.includes(material.appMaterial.name) ||
        // If the Form is being used to add parts to an existing Job, the Job's Material may have no parts available,
        // but should still be included so it can be displayed in the disabled dropdown
        (job && job.shopMaterialId === material.id)
    );

    useEffect(() => {
      // auto select technology if only one is available
      if (availableTechnologies.length === 1 && availableTechnologies[0].id !== shopTechnologyId) {
        setShopTechnologyId(availableTechnologies[0].id);
      }
      // auto select machine if only one is available
      if (availableMachines.length === 1 && availableMachines[0].id !== shopMachineId) {
        setShopMachineId(availableMachines[0].id);
      }
      // auto select material if only one is available
      if (availableMaterials.length === 1 && availableMaterials[0].id !== shopMaterialId) {
        setShopMaterialId(availableMaterials[0].id);
      }
    }, [availableTechnologies.length, shopTechnologyId, shopMachineId]);

    useEffect(() => {
      !loadingTechnologies && fetchOrderParts();
    }, [allMachines]); // shopTechnologies, shopTechnologyId, shopMaterialId, shopMachineId]);  TODO: Pagination?

    useEffect(() => {
      const parts = orderParts
        // Filter by Technology:
        .filter(part => !shopTechnologyId || shopTechnologyId === part.shopTechnologyId)

        // Filter by Machine
        .filter(
          part =>
            !shopMachine ||
            !part.shopMachine ||
            (part.shopMachine && shopMachineId === part.shopMachine.id) ||
            // Unestimated parts with "Any" material
            (!part.machineRateId && isAnyMaterial(part.shopMaterial)) ||
            // Unestimated parts with a material available on selected machine
            (!part.machineRateId &&
              part.shopMaterial &&
              shopMachine.materials
                .map(material => material.appMaterial.name)
                .includes(part.shopMaterial.appMaterial.name))
        )

        // Filter by Material
        .filter(
          part =>
            !shopMaterial ||
            !part.shopMaterialId ||
            (part.shopMaterialId && shopMaterialId === part.shopMaterialId) ||
            isAnyMaterial(part.shopMaterial) ||
            (part.shopMaterial &&
              shopMaterial &&
              part.shopMaterial.appMaterial.name === shopMaterial.appMaterial.name)
        );
      setFilteredParts(parts);
      // Unselect any parts that become hidden
      setSelectedParts(selectedPartIds.filter(id => parts.find(part => part.id === id)));
    }, [orderParts, shopTechnologyId, shopMachineId, shopMaterialId]);

    // Default name for job
    const defaultJobName =
      (shopMachine?.appMachineType.displayName || shopTechnology?.appTechnology.displayName || "") +
      " - " +
      moment(new Date(), "YYYY-M-D").format("ll");

    const fetchOrderParts = async () => {
      setLoading(true);
      setLastSelectedId(undefined);
      const { data, errors }: ApolloQueryResult<{ loadOrderPartsByShop: IOrderPart[] }> =
        await client.query({
          query: ORDER_PARTS_BY_SHOP,
          variables: {
            input: {
              shopId: currentShop.id,
              shopStatuses: getActiveStatusIds(currentShop.shopStatuses),
            },
          }, // , shopTechnologyId, shopMaterialId } },
          fetchPolicy: "network-only", // TODO: Caching + Manual 'Refresh' button?
        });
      if (!errors && data) {
        setLoading(false);
        let flattenedOrderParts = data.loadOrderPartsByShop.map((part: IOrderPart) => {
          const material = allMaterials.find(mat => mat.id === part.shopMaterialId);
          const materialColor = allMaterialColors.find(
            color => color.id === part.shopMaterialColorId
          );
          return {
            ...part,
            filename: getCadModelName(part),
            material: material?.appMaterial.name,
            materialColor: materialColor?.appMaterialColor.name,
            needByDate: part.order.needByDate,
            price: part.price,

            // For client-side filtering convenience
            shopTechnology: shopTechnologies.find(tech => tech.id === part.shopTechnologyId),
            shopMachine: allMachines.find(
              machine => material && machine.id === material.shopMachineId
            ),
            shopMaterial: material,
            // shopMaterialColor: materialColor
          };
        });

        // Filtering by active order/part statuses happens on server too. Doing it again here should be a no-op, but can't hurt.
        // (Part statuses may become editable on parts list?)
        flattenedOrderParts = flattenedOrderParts.filter(
          (part: IOrderPart) =>
            getActiveStatusIds(currentShop.shopStatuses).includes(part.shopStatus) &&
            !isGcpAsset(part.cadModel)
        );
        setOrderParts(flattenedOrderParts);
        const orderItemIds = flattenedOrderParts.map(part => part.id);
        updateCadModelQueryCache(client, orderItemIds);
      }
    };

    const createJob = async (name: string) => {
      try {
        await client.mutate<
          { createJob: { id: number; name: string; shopId: number } },
          { input: IJobCreateInput }
        >({
          refetchQueries: [
            { query: JOB_COUNTS, variables: { id: currentShop.id } },
            { query: SHOP_JOBS, variables: { id: currentShop.id, active: true } },
          ],
          mutation: CREATE_JOB,
          variables: {
            input: {
              shopId: currentShop.id,
              name,
              shopTechnologyId,
              shopMachineId,
              shopMaterialId,
              orderItemIds: selectedPartIds,
            },
          },
          update: (cache, result) => {
            if (result.data) {
              addOrderItemsToCachedJob(client, result.data.createJob.id, selectedPartIds);
              ReactGA.event({
                category: "GcShop Jobs",
                action: "Create New Job",
                label: `Shop ${currentShop.id}`,
              });
            }
          },
        });

        Notifier.success(t("shop.jobs.create.success"));
        history.push(ROUTES.SHOP(currentShop.id).JOBS.LIST);
      } catch (error) {
        if (error instanceof ApolloError) {
          Notifier.error({ graphQLErrors: error.graphQLErrors });
        } else {
          throw error;
        }
      }
    };

    const onOrderPartClick = (id: number) => {
      const clickedItemIndex = selectedPartIds.indexOf(id);
      let updatedSelectedOrderPartsArray = [...selectedPartIds];
      if (shiftPressed && lastSelectedId) {
        // Multiselect
        const lastPart = sortedParts.find(part => part.id === lastSelectedId);
        const lastIndex = lastPart && sortedParts.indexOf(lastPart);
        const currentPart = sortedParts.find(part => part.id === id);
        const currentIndex = currentPart && sortedParts.indexOf(currentPart);
        if (lastPart && currentPart) {
          const startIndex = Math.min(lastIndex as number, currentIndex as number);
          const endIndex = Math.max(lastIndex as number, currentIndex as number) + 1;
          const idsToMultiSelect = sortedParts
            .slice(startIndex, endIndex)
            .filter(part => !selectedPartIds.includes(part.id))
            .map(part => part.id);
          updatedSelectedOrderPartsArray = [...updatedSelectedOrderPartsArray, ...idsToMultiSelect];
          setLastSelectedId(id);
        }
      } else if (clickedItemIndex === -1) {
        // Select single
        updatedSelectedOrderPartsArray = [...updatedSelectedOrderPartsArray, id];
        setLastSelectedId(id);
      } else {
        // Unselect single
        setLastSelectedId(undefined);
        updatedSelectedOrderPartsArray.splice(clickedItemIndex, 1);
      }
      setSelectedParts(updatedSelectedOrderPartsArray);
    };

    const handleSort = (colId: keyof IOrderPart) => {
      let sortDirection: SortDirection = ASCENDING;
      if (sortCol !== colId) {
        setSortCol(colId);
      } else {
        sortDirection = sortDir === ASCENDING ? DESCENDING : ASCENDING;
      }
      setSortDir(sortDirection);
    };

    const headerCheckbox = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          disabled={filteredParts.length === 0} // This should never happen except while loading
          onClick={() =>
            setSelectedParts(
              selectedPartIds.length === filteredParts.length
                ? []
                : filteredParts.map(part => part.id)
            )
          }
          checked={selectedPartIds.length > 0 && selectedPartIds.length === filteredParts.length}
          className={"qa-selectAll"}
        />
      </div>
    );

    const getTableHeaders = (): {
      content?: ReactElement | string;
      className?: string;
      unsortable?: boolean;
      id: string;
    }[] => {
      const firstPart = [
        {
          content: headerCheckbox,
          className: "short",
          unsortable: true,
          id: "checkbox",
        },
        {
          className: "short",
          unsortable: true,
          id: "img",
        },
        {
          content: t("shop.jobs.new.partInfo"),
          id: "filename",
        },
        {
          content: t("shop.jobs.new.material"),
          id: "material",
        },
        {
          content: t("shop.jobs.new.materialColor"),
          id: "materialColor",
        },
        {
          content: t("shop.jobs.new.quantity"),
          id: "quantity",
        },
      ];

      const lastPart = [
        {
          content: t("shop.jobs.new.price"),
          className: "price",
          id: "price",
        },
        {
          content: t("shop.jobs.new.dueDate"),
          id: "needByDate",
        },
        {
          content: t("shop.jobs.new.partStatus"),
          id: "status",
        },
      ];
      return [
        ...firstPart,
        {
          content: t("shop.jobs.new.units"),
          id: "units",
        },
        ...lastPart,
      ];
    };

    const tableHeaders = getTableHeaders();

    let partsSelectedText = selectedPartIds.length
      ? t("shop.jobs.new.onePartSelected")
      : t("shop.jobs.new.noPartsSelected");
    if (selectedPartIds.length > 1) {
      partsSelectedText = t("shop.jobs.new.numPartsSelected", {
        num: selectedPartIds.length,
      });
    }

    const unitsCell = (item: IOrderItem) => {
      return (
        <TableCell>
          {getTranslatedItemUnits(t, item) || <i>{t("shop.job.partTable.noUnits")}</i>}
        </TableCell>
      );
    };

    return (
      <JobsTableWrapper data-testid="addPartsToJobForm">
        {showNameModal && (
          <CreateJobModal
            onClose={() => setShowNameModal(false)}
            defaultJobName={defaultJobName}
            createJob={createJob}
            selectedOrderItems={orderParts.filter(p => selectedPartIds.includes(p.id))}
          />
        )}
        <StyledPartsFilters>
          <div className="filter-dropdown">
            <h4>{t("shop.jobs.filter.technology")}</h4>
            <SelectionDropdown
              value={shopTechnologyId || ""}
              onChange={(_evt, { value }) => {
                setShopTechnologyId(value ? +value : undefined);
                setShopMachineId(undefined);
                setShopMaterialId(undefined);
              }}
              className={"qa-dropdown-technology"}
              placeholder={t("shop.jobs.filter.technology.placeholder")}
              options={availableTechnologies.map(tech => ({
                id: tech.id,
                key: `sm-${tech.id}`,
                value: tech.id,
                text: tech.appTechnology.displayName,
              }))}
              icon={<Image src={technologyIcon} style={{ position: "relative", top: -3 }} />}
              disabled={!!job || availableTechnologies.length <= 1}
              clearable={!job && availableTechnologies.length > 1}
              selectOnBlur={false}
            />
          </div>
          <div className="filter-dropdown">
            <h4>{t("shop.jobs.filter.machine")}</h4>
            <SelectionDropdown
              value={shopMachineId || ""}
              onChange={(_evt, { value }) => {
                const machine = !!value && allMachines.find(mach => mach.id === +value);
                machine && setShopTechnologyId(machine.shopTechnologyId);
                setShopMachineId(value ? +value : undefined);
                setShopMaterialId(undefined);
              }}
              className={"qa-dropdown-machine"}
              placeholder={t("shop.jobs.filter.machine.placeholder")}
              options={availableMachines.map(machine => ({
                id: machine.id,
                key: `sm-${machine.id}`,
                value: machine.id,
                text: machine.appMachineType.displayName,
              }))}
              icon={<Image src={machineIcon} />}
              disabled={!!job || availableMachines.length <= 1}
              clearable={!job && availableMachines.length > 1}
              selectOnBlur={false}
            />
          </div>
          <div className="filter-dropdown">
            <h4>{t("shop.jobs.filter.material")}</h4>
            <SelectionDropdown
              value={shopMaterialId || ""}
              onChange={(_evt, { value }) => {
                const machine =
                  !!value &&
                  allMachines.find(mach => mach.materials.find(material => material.id === +value));
                machine && setShopMachineId(machine.id);
                machine && setShopTechnologyId(machine.shopTechnologyId);
                setShopMaterialId(value ? +value : undefined);
              }}
              className={"qa-dropdown-material"}
              placeholder={t("shop.jobs.filter.material.placeholder")}
              options={availableMaterials.map(sM => ({
                id: sM.id,
                key: `sm-${sM.id}`,
                value: sM.id,
                text: sM.appMaterial.displayName,
              }))}
              icon={<Image src={materialIcon} />}
              disabled={!!job || availableMaterials.length <= 1}
              clearable={!job && availableMaterials.length > 1}
              selectOnBlur={false}
            />
          </div>
        </StyledPartsFilters>
        <div className={classnames("scrolling-wrapper", { "has-content": orderParts.length })}>
          <PartsTable sortable={orderParts.length > 0}>
            <TableHeader>
              <TableRow>
                {tableHeaders.map(({ content, id, unsortable, className }) => (
                  <TableHeaderCell
                    className={className}
                    key={id}
                    sorted={sortCol === id ? sortDir : undefined}
                    onClick={unsortable ? undefined : () => handleSort(id as keyof IOrderPart)}
                    disabled={unsortable}
                  >
                    {content}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedParts.map(item => {
                const checkbox = (
                  <Checkbox
                    checked={selectedPartIds.includes(item.id)}
                    className={"qa-selectPart select-part"}
                  />
                );
                return (
                  <TableRow
                    key={item.id}
                    onClick={() => onOrderPartClick(item.id)}
                    className={classnames("qa-order-part order-part", {
                      selected: selectedPartIds.includes(item.id),
                      unselectable: shiftPressed,
                    })}
                  >
                    <TableCell className="short popup-cell">{checkbox}</TableCell>
                    <TableCell className="short">
                      <div className="thumbnail">
                        <CadModelPreview itemId={item.id} size={"small"} />
                      </div>
                    </TableCell>
                    <TableCell className="filename">
                      <div>
                        {/* TODO: Highlight and auto-scroll to linked orderItem */}
                        <Link to={ROUTES.SHOP(currentShop.id).ORDER.SHOW(item.order?.id)}>
                          {item.filename}
                        </Link>
                        <Avatar fullName name={item.order?.user.name} id={item.order?.user.email} />
                      </div>
                    </TableCell>
                    <TableCell className="qa-material">{item.material}</TableCell>
                    <TableCell className="qa-materialColor">{item.materialColor}</TableCell>
                    <TableCell className="qa-quantity">{item.quantity}</TableCell>
                    {unitsCell(item)}
                    <TableCell className="qa-price price">
                      {item.price ? formatPrice(item.price) : "-"}
                    </TableCell>
                    <TableCell className="qa-needByDate">
                      {formatDate(item.needByDate, { ignoreTimezone: true })}
                    </TableCell>
                    <TableCell>
                      <OrderStatus entity={item} readOnly={true} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </PartsTable>
        </div>

        {filteredParts.length === 0 && (
          <div className="no-results-container">
            {loading || loadingTechnologies ? (
              <Loader active />
            ) : (
              <PlaceHolder id="qa-placeHolder">
                <Image src={PlaceholderClipboards} height="145" />
                <p>{t("shop.job.noCompatableParts")}</p>
              </PlaceHolder>
            )}
          </div>
        )}

        <div className="jobs-footer" data-testid="jobsFooter">
          <div
            className={classnames("qa-selected-part-count", "selected-part-count", {
              "has-selection": selectedPartIds.length,
            })}
          >
            {partsSelectedText}
          </div>
          <div
            data-tooltip={
              selectedPartIds.length === 0 || (!job && !shopTechnologyId && !shopMachineId)
                ? job
                  ? t("shop.job.addParts.disabled")
                  : t("shop.jobs.save.disabled")
                : undefined
            }
            data-position="left center"
            style={{ float: "right" }}
          >
            <Button
              id="qa-jobsForm-submit"
              primary
              floated="right"
              disabled={
                selectedPartIds.length === 0 || (!job && !shopTechnologyId && !shopMachineId)
              }
              onClick={async () => {
                if (job) {
                  try {
                    const { data } = await client.mutate<
                      {
                        createOrderItemJobRouteStepHistories: IOrderItemJobRouteStepHistory[];
                      },
                      { input: IJobHistoriesInput }
                    >({
                      mutation: CREATE_ORDER_ITEM_JOB_ROUTE_STEP_HISTORIES,
                      variables: {
                        input: {
                          jobId: job.id,
                          itemQuantitiesToMove: selectedPartIds.map(id => ({ id })),
                        },
                      },
                      update: (cache, result) => {
                        if (!result.data) {
                          return;
                        }
                        const cachedJobQuery = cache.readQuery<{ job: IShopJob }>({
                          query: JOB_DETAILS,
                          variables: { id: job.id },
                        });
                        if (cachedJobQuery) {
                          const cachedJob = { ...cachedJobQuery.job };

                          // To avoid having to refetch the JOB_DETAILS query we can manually add the orderItems to the cache,
                          // along with the new history events:
                          cachedJob.orderItemJobRouteStepHistories = [
                            ...cachedJob.orderItemJobRouteStepHistories,
                            ...result.data.createOrderItemJobRouteStepHistories,
                          ];
                          cachedJob.orderItems = [
                            ...cachedJob.orderItems,
                            ...result.data.createOrderItemJobRouteStepHistories
                              // ^^ We could use selectedPartIds.map(), but a part might be rejected by the server for some reason
                              .filter(
                                event =>
                                  !job.orderItems.map(item => item.id).includes(event.orderItemId)
                              )
                              // ^^ The filter is to exclude any duplicate OrderItems that may aleady be in the cached Job:
                              // EG: If an item was added, removed, and then re-added.
                              // (We currently load removed OrderItems and filter them on the client if all their history events are deleted.
                              // This is to enable future UI showing removed OrderItems and their history.)
                              .map(
                                newEvent =>
                                  orderParts.find(
                                    item => item.id === newEvent.orderItemId
                                  ) as IOrderItem
                              ),
                          ];
                          cache.writeQuery({
                            data: { job: cachedJob },
                            query: JOB_DETAILS,
                            variables: { id: job.id },
                          });
                        }

                        addOrderItemsToCachedJob(
                          client,
                          job.id,
                          result.data.createOrderItemJobRouteStepHistories.map(
                            event => event.orderItemId
                          )
                        );
                      },
                    });

                    Notifier.success(
                      t("shop.job.step.numPartsAdded", {
                        num: data?.createOrderItemJobRouteStepHistories.length,
                      })
                    );
                    onAddPartsSuccess?.();
                  } catch (error) {
                    if (error instanceof ApolloError) {
                      Notifier.error({ graphQLErrors: error.graphQLErrors });
                    } else {
                      throw error;
                    }
                  }
                } else {
                  setShowNameModal(true);
                }
              }}
            >
              {job ? t("shop.job.addParts") : t("shop.jobs.save")}
            </Button>
          </div>
        </div>
      </JobsTableWrapper>
    );
  }
);
AddPartsToJobForm.displayName = "AddPartsToJobForm";
