import { graphql } from "@apollo/client/react/hoc";
import { Mutation } from "@apollo/client/react/components";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { LOGOUT, GET_LOGOUT_URL } from "@/graphql/Mutations/User";
import { COMPANY_SERVER_AUTH_ENABLED } from "@/GraphQLClient";
import React, { ComponentType, useContext } from "react";
import { Button } from "grabcad-ui-elements";
import { Notifier } from "@/utils/Notifier";

const handleLogout = (setAsLoggedOut: () => void) => {
  const { t } = useContext(ApplicationContext);
  setAsLoggedOut();
  Notifier.success(t("user.logout.success"));
};

const DirectLogoutButton = () => {
  const {
    t,
    currentUser: { setAsLoggedOut },
  } = useContext(ApplicationContext);

  return (
    <Mutation<boolean>
      mutation={LOGOUT}
      onCompleted={() => handleLogout(setAsLoggedOut)}
      onError={() => null}
    >
      {(logout, { error }) => {
        if (error) {
          return <div />;
        }
        return (
          <Button
            onClick={event => {
              event.preventDefault();
              logout();
            }}
            secondary
          >
            {t("license.expired.signout")}
          </Button>
        );
      }}
    </Mutation>
  );
};
DirectLogoutButton.displayName = "DirectLogoutButton";

const CompanyLogoutButton = graphql<
  Record<string, never>,
  { getLogoutUrl: string },
  Record<string, never>
>(GET_LOGOUT_URL)(({ data }): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  if (!data || data.loading) {
    return null;
  }
  if (data.error) {
    Notifier.error(data.error);
    return <p>Error: ${data.error.toString()}</p>;
  }
  return (
    <Button
      onClick={event => {
        event.preventDefault();
        Notifier.success(t("user.logout.success"));
        window.location.replace((data.getLogoutUrl as string) + window.location.origin);
      }}
      secondary
    >
      {t("license.expired.signout")}
    </Button>
  );
});
CompanyLogoutButton.displayName = "CompanyLogoutButton";

export const LogOutButton: ComponentType = COMPANY_SERVER_AUTH_ENABLED
  ? CompanyLogoutButton
  : DirectLogoutButton;
