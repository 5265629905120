import React from "react";
import { Image } from "grabcad-ui-elements";
import { ORDER_ITEM_CAD_MODELS_PREVIEW } from "@/graphql/Queries/Order";
import Model from "../../assets/icons/model.svg";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { useQuery } from "@apollo/client";

export interface ICadModelPreviewProps {
  itemId: number;
  size: "small" | "medium";
  width?: number;
  height?: number;
}

export interface IOrderItemCadModelsVariables {
  orderItemIds: number[];
}

export const CadModelPreview = ({
  itemId,
  size,
  width,
  height,
}: ICadModelPreviewProps): JSX.Element => {
  let imageSrc = Model;
  const { data } = useQuery<{ orderItemsCadModels: Partial<IOrderItem>[] }>(
    ORDER_ITEM_CAD_MODELS_PREVIEW,
    {
      variables: { orderItemIds: [itemId] },
      fetchPolicy: "cache-only",
    }
  );

  if (data && data.orderItemsCadModels.length > 0) {
    const item = data.orderItemsCadModels[0];
    const imagePreview = item.cadModel?.preview;
    const preview = null;
    if (imagePreview && size === "small") {
      imageSrc = imagePreview.small?.location;
    } else if (imagePreview && size === "medium") {
      imageSrc = imagePreview.medium?.location;
    }
    if (preview) {
      return (
        <Image
          className="qa-orderItemRow-img"
          src={preview}
          centered
          width={width}
          height={height}
        />
      );
    }
  }

  return <Image src={imageSrc} centered width={width} height={height} />;
};
