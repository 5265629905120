import React from "react";
import styled from "grabcad-ui-elements";
import { IImage } from "@/graphql/Fragments/Image";
import { ShopImage } from "../Shared/ShopImage";
import defaultShopLogo from "@/assets/default-image-500px-sq.png";

export interface IImagePreviewProps {
  image: Partial<IImage>;
  size: "small" | "medium";
}

const ImagePreviewContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const defaultImage = {
  small: { location: defaultShopLogo },
  medium: { location: defaultShopLogo },
  original: { location: defaultShopLogo },
  __typename: "Image",
} as Partial<IImage>;

export const ImagePreview = ({ image, size }: IImagePreviewProps): JSX.Element =>
  image && (
    <ImagePreviewContainer className="image-preview" data-testid="imagePreview">
      <ShopImage image={image} defaultImage={defaultImage} size={size} centered />
    </ImagePreviewContainer>
  );
