import { MutationFunction } from "@apollo/client";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { IUploadContainer } from "@/components/Upload/CadDropZone";
import { Form, FormField, Grid, GridColumn, GridRow, Input, TextArea } from "grabcad-ui-elements";
import { ICadFile, PossibleUploadTypes, IGenericFile } from "@/graphql/Mutations/Upload";
import moment from "moment";
import { default as React, FormEvent, useContext, useEffect, useRef } from "react";
import { INewOrderResponse, IOrderInput, IOrderItemInput } from "@/screens/Shop/Order/New/New";
import { useForm } from "@/utils/useForm";
import { FormattedDatePicker } from "../DatePicker/FormattedDatePicker";
import { ShopMaterialColorPicker } from "./ShopMaterialColorPicker";
import { ShopMaterialPicker } from "./ShopMaterialPicker";
import { ShopTechnologyPicker } from "./ShopTechnologyPicker";
import { isTypename } from "@/utils/typeUtils";
import { IImage } from "@/graphql/Fragments/Image";
import classnames from "classnames";

interface INewOrderProps {
  shopId: number;
  createOrder: MutationFunction<INewOrderResponse, { input: Partial<IOrderInput> }>;
  requiredSet: (value: boolean) => void;
  setSubmitting: (value: boolean) => void;
  uploadContainers: IUploadContainer[];
  submit: boolean;
  setSubmit: (value: boolean) => void;
}

interface IFormProps {
  projectName: string;
  projectCode: string;
  needByDate: string;
  notes: string;
  shopTechnologyId: number;
  shopMaterialId: number | undefined;
  shopMaterialColorId: number | undefined;
  [key: string]: string | number | undefined;
}

export function isCadModelFilter<T extends IUploadContainer>(
  container: T | null | undefined
): container is T {
  return (
    container !== null &&
    container !== undefined &&
    !container.cancelled &&
    !!container.cadFile &&
    isTypename<PossibleUploadTypes>("CadModel")(container.cadFile)
  );
}

export function isGenericFileFilter<T extends IUploadContainer>(
  container: T | null | undefined
): container is T {
  return (
    container !== null &&
    container !== undefined &&
    !container.cancelled &&
    !!container.cadFile &&
    isTypename<PossibleUploadTypes>("GenericFile")(container.cadFile)
  );
}
export function isImageFileFilter<T extends IUploadContainer>(
  container: T | null | undefined
): container is T {
  return (
    container !== null &&
    container !== undefined &&
    !container.cancelled &&
    !!container.cadFile &&
    isTypename<PossibleUploadTypes>("Image")(container.cadFile)
  );
}

export const NewOrderForm = ({
  createOrder,
  shopId,
  requiredSet,
  uploadContainers,
  setSubmitting,
  submit,
  setSubmit,
}: INewOrderProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  const { handleChange, handleSubmit, manualChange, values, allRequiredSet } = useForm<IFormProps>(
    async ({
      projectName,
      projectCode,
      needByDate,
      notes,
      shopTechnologyId,
      shopMaterialId,
      shopMaterialColorId,
    }) => {
      setSubmitting(true);
      const orderItems: IOrderItemInput[] = uploadContainers
        .filter(isCadModelFilter)
        .map((container: IUploadContainer) => ({
          shopMaterialId: +(shopMaterialId || 0),
          shopTechnologyId: +shopTechnologyId,
          shopMaterialColorId: +(shopMaterialColorId || 0),
          units: container.unit,
          // Type assertion made "safe" by the previous filter on container.cadFile
          cadModelId: (container.cadFile as ICadFile).id,
          quantity: container.quantity,
        }));
      await createOrder({
        variables: {
          input: {
            orderItems,
            projectCode,
            shopId,
            notes,
            name: projectName,
            needByDate: moment(needByDate).toDate(),
            fileIds: uploadContainers
              .filter(isGenericFileFilter)
              .map(filtered => (filtered.cadFile as IGenericFile).id),
            imageIds: uploadContainers
              .filter(isImageFileFilter)
              .map(filtered => (filtered.cadFile as IImage).id),
          },
        },
      });

      setSubmitting(false);
    },
    {},
    ["projectName", "shopMaterialColorId", "shopMaterialId", "shopTechnologyId", "needByDate"]
  );

  const requiredFieldsSet = useRef(allRequiredSet);

  useEffect(() => {
    if (!requiredFieldsSet.current && allRequiredSet) {
      requiredSet(true);
    }

    if (!requiredFieldsSet.current && !allRequiredSet) {
      requiredSet(false);
    }

    if (submit) {
      setSubmit(false);
      handleSubmit();
    }
  });

  const MAX_NAME_LENGTH = 100;
  const namelengthTooltip =
    values.projectName && values.projectName.length > MAX_NAME_LENGTH
      ? t("order.new.nameTooLong")
      : undefined;

  const MAX_NOTE_LENGTH = 1000;
  const notelengthTooltip =
    values.notes && values.notes.length > MAX_NOTE_LENGTH ? t("order.new.noteTooLong") : undefined;

  const MAX_CODE_LENGTH = 100;
  const codelengthTooltip =
    values.projectCode && values.projectCode.length > MAX_CODE_LENGTH
      ? t("order.details.codeTooLong")
      : undefined;

  return (
    <Form autoComplete="off" name="lastpass-disable-search" data-testid="newOrderForm">
      <FormField required>
        <label>{t("order.form.name")}</label>
        <Input
          className={classnames({ error: !!namelengthTooltip })}
          data-tooltip={namelengthTooltip}
          data-position="left center"
          data-visible
          id="qa-orderForm-projectName"
          name="projectName"
          value={values.projectName || ""}
          onChange={handleChange}
          data-testid="input-projectName"
        />
      </FormField>

      <FormField required>
        <label>{t("order.form.shopTechnology")}</label>
        <ShopTechnologyPicker
          styleAsRequired={true}
          shopTechnologyId={+values.shopTechnologyId}
          onChange={({ shopTechnologyId }) => {
            manualChange(
              "shopTechnologyId",
              shopTechnologyId ? shopTechnologyId.toString() : undefined
            );
            manualChange("shopMaterialId", undefined);
          }}
        />
      </FormField>

      <FormField required>
        <label>{t("order.form.shopMaterial")}</label>
        <ShopMaterialPicker
          styleAsRequired={true}
          shopTechnologyId={+values.shopTechnologyId}
          shopMaterialId={values.shopMaterialId && +values.shopMaterialId}
          onChange={({ shopMaterialId }) => {
            manualChange("shopMaterialId", shopMaterialId ? shopMaterialId.toString() : undefined);
            manualChange("shopMaterialColorId", undefined);
          }}
        />
      </FormField>

      <FormField required>
        <label>{t("order.form.shopMaterialColor")}</label>
        <ShopMaterialColorPicker
          styleAsRequired={true}
          shopTechnologyId={+values.shopTechnologyId}
          shopMaterialId={values.shopMaterialId && +values.shopMaterialId}
          shopMaterialColorId={values.shopMaterialColorId && +values.shopMaterialColorId}
          onChange={({ shopMaterialColorId, shopMaterialId }) => {
            manualChange(
              "shopMaterialColorId",
              shopMaterialColorId ? shopMaterialColorId.toString() : undefined
            );
            shopMaterialId &&
              manualChange(
                "shopMaterialId",
                shopMaterialId ? shopMaterialId.toString() : undefined
              );
          }}
        />
      </FormField>

      <FormField>
        <label>{t("order.form.note")}</label>
        <span data-tooltip={notelengthTooltip} data-position="left center" data-visible>
          <TextArea
            className={classnames({ error: !!notelengthTooltip })}
            id="qa-orderForm-projectNote"
            name="notes"
            value={values.notes || ""}
            onChange={(evt: FormEvent, { value }: { value?: string | number | undefined }) =>
              manualChange("notes", (value || "").toString())
            }
            data-testid="textArea-projectNote"
          />
        </span>
      </FormField>
      <Grid>
        <GridRow columns="2">
          <GridColumn>
            <FormField>
              <label>{t("order.form.projectCode")}</label>
              <Input
                className={classnames({ error: !!codelengthTooltip })}
                data-tooltip={codelengthTooltip}
                data-position="left center"
                data-visible
                id="qa-orderForm-projectCode"
                name="projectCode"
                value={values.projectCode || ""}
                onChange={handleChange}
                data-testid="input-projectCode"
              />
            </FormField>
          </GridColumn>
          <GridColumn>
            <FormField required>
              <label>{t("order.form.needByDate")}</label>
              <FormattedDatePicker
                value={values.needByDate || undefined}
                onDateChange={date => {
                  manualChange(
                    "needByDate",
                    date ? date.toISOString() : values.needByDate || new Date().toUTCString()
                  );
                }}
                openDirection="up"
                anchorDirection="right"
              />
            </FormField>
          </GridColumn>
        </GridRow>
      </Grid>
    </Form>
  );
};
