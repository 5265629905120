import React, { useContext } from "react";
import styled, { Header } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { LogOutButton } from "@/components/User/Login/Button/LogoutButton";
import { LicenseRole } from "@/graphql/Queries/UserLicense";

export const StyledLicenseExpiredWrapper = styled.div`
  background: #f4f5f7;
  height: 100vh;
  width: 100vw;
  .license-box {
    display: flex;
    flex-flow: column;
    width: 400px;
    height: 500px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    h1 {
      margin-top: 20px;
      color: #062872;
    }
    .body {
      font-size: 14px;
      line-height: 22px;
      padding: 20px;
      height: auto;
      flex: 1 1 auto;
      overflow-y: auto;
    }
    .footer {
      flex: 0 1 40px;
      button {
        width: 100%;
      }
    }
  }
`;

export const ScreensLicenseExpired = (): JSX.Element => {
  const { t, licenseError } = useContext(ApplicationContext);
  let licenseErrorMessage: string | JSX.Element = t("license.expired.nonAdmin");

  if (licenseError && licenseError.role === LicenseRole.MixedAdmin) {
    licenseErrorMessage = (
      <span
        dangerouslySetInnerHTML={{
          __html: t("license.expired.admin", {
            email: '<a href="mailto:shop@grabcad.com">shop@grabcad.com</a>',
          }),
        }}
      />
    );
  }
  return (
    <StyledLicenseExpiredWrapper>
      <div className={"license-box"}>
        <Header as={"h1"}>{t("license.expired.header")}</Header>
        <div className={"body"} data-testid="licenseError">
          {licenseErrorMessage}
        </div>
        <div className={"footer"}>
          <LogOutButton />
        </div>
      </div>
    </StyledLicenseExpiredWrapper>
  );
};
