import { ApplicationContext } from "@/components/ApplicationProvider";
import { Icon, MenuItem, Loader } from "grabcad-ui-elements";
import React, { useContext } from "react";
import { ROUTES } from "@/shopConstants";
import { BackLink, ISidebarMenuProps } from "../ShopSidebar";
import { useShopTechnologyById } from "@/utils/queryHooks";

export const MachinesMenuItems = ({
  shopId,
  history,
  machineId,
  technologyId,
}: ISidebarMenuProps & {
  machineId: number;
  technologyId: number;
}): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { shopTechnology, loading } = useShopTechnologyById(+technologyId);
  const techMenuItem = (
    <MenuItem onClick={() => history.push(`${ROUTES.SHOP(shopId).MACHINES.INDEX}`)}>
      <BackLink>
        <Icon name="triangle left" />
        {t("layout.shopSidebar.machines")}
      </BackLink>
    </MenuItem>
  );

  if (loading || !shopTechnology) {
    return (
      <>
        {techMenuItem}
        <MenuItem>
          <Loader active={true} size="small" />
        </MenuItem>
      </>
    );
  }

  return (
    <>
      {techMenuItem}
      {shopTechnology.shopMachines
        .filter(machine => !machine.dateDeleted)
        .map(shopMachine => (
          <MenuItem
            key={shopMachine.id}
            name={shopMachine.appMachineType.displayName}
            active={shopMachine.id === machineId}
            onClick={() =>
              history.push(`${ROUTES.SHOP(shopId).MACHINES.MACHINE(technologyId, shopMachine.id)}`)
            }
          >
            {shopMachine.appMachineType.displayName}
          </MenuItem>
        ))}
    </>
  );
};
MachinesMenuItems.displayName = "MachinesMenuItems";
