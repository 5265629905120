import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "grabcad-ui-elements";
import { Input as SemanticInput } from "semantic-ui-react";
import { ApplicationContext } from "../ApplicationProvider";

export const blurOnEnter = (e: KeyboardEvent): void => {
  if (e.key === "Enter") {
    (e.target as HTMLInputElement).blur();
  }
};

const KEYCODE = {
  BACKSPACE: 8,
  ENTER: 13,
  ESCAPE: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
};

// TODO: Move to grabcad-ui-elements and share styles with non-numeric input
const StyledInput = styled(SemanticInput)`
  &.ui.input {
    > input {
      width: 100% !important;
      border-radius: 4px !important;
      height: 32px;
      &:hover {
        border-color: #003393;
      }
    }
    &.error > input {
      border: 1px solid ${({ theme }: { theme: any }) => theme.input.errorBorderColor} !important;
    }
  }
`;

export interface ILocalizedNumericInputProps {
  value?: number;
  placeholder?: string;
  onChange?: (value: number | undefined) => void;
  onSubmit?: (value: number | undefined) => void;
  className?: string;
  prefix?: string;
  postfix?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
  currency?: boolean;
  autoFocus?: boolean;
  icon?: JSX.Element;
  iconPosition?: string;
}

export const LocalizedNumericInput = ({
  value,
  placeholder,
  onChange,
  onSubmit,
  className,
  prefix = "",
  postfix = "",
  disabled,
  max,
  min,
  currency,
  autoFocus,
  icon,
  iconPosition,
  ...rest
}: ILocalizedNumericInputProps): JSX.Element => {
  const { currentShop, formatPrice } = useContext(ApplicationContext);
  const toDisplayValue = (number?: number | null) => {
    if (number === undefined || number === null) {
      return "";
    }
    return currency && currentShop
      ? (number / currentShop.currencyDenominator).toString()
      : number.toString();
  };

  const [stringValue, setStringValue] = useState(toDisplayValue(value));
  useEffect(() => {
    setStringValue(toDisplayValue(value));
  }, [value]);

  const [cursorPos, setCursorPos] = useState<number | undefined>(undefined);
  const [resetCursorPos, setResetCursorPos] = useState(true);
  useEffect(() => {
    const innerRef = inputRef.current.inputRef.current;
    innerRef.setSelectionRange(cursorPos, cursorPos);
  }, [resetCursorPos]);

  const inputRef = useRef<any>(null);

  let currencyPrefix = "";
  let currencyPostfix = "";
  if (currency) {
    const formattedZeroPrice = formatPrice(0) as string; // Arabic numerials?
    currencyPrefix = formattedZeroPrice.slice(0, formattedZeroPrice.indexOf("0")); // EG: $0.00
    currencyPostfix = formattedZeroPrice.slice(formattedZeroPrice.lastIndexOf("0") + 1); // EG: 0.00$ or 0Kr
  }

  const addPreAndPostFix = (string: string) =>
    string && `${prefix}${currencyPrefix}${string}${currencyPostfix}${postfix}`;

  const onKeyUp = (event: KeyboardEvent) => {
    if (
      (event.keyCode === KEYCODE.RIGHT || event.keyCode === KEYCODE.LEFT) &&
      (prefix || postfix || currency)
    ) {
      clampSelection();
    }
  };

  const clampSelection = () => {
    // This clamps both selected character range, and cursor position to exclude postfixes, prefixes & currency symbols.
    if ((prefix || postfix || currency) && inputRef && inputRef.current) {
      const inputValue = inputRef.current.props.value;
      if (inputValue) {
        const innerRef = inputRef.current.inputRef.current; // TODO - use naitive <input> and share styles

        const selectionStart = innerRef.selectionStart;
        if (
          (prefix || currencyPrefix) &&
          selectionStart !== null &&
          selectionStart < prefix.length + currencyPrefix.length
        ) {
          innerRef.selectionStart = prefix.length + currencyPrefix.length;
        }

        const selectionEnd = innerRef.selectionEnd;
        if (
          (postfix || currencyPostfix) &&
          selectionEnd !== null &&
          selectionEnd > inputValue.length - (postfix.length + currencyPostfix.length)
        ) {
          innerRef.selectionEnd = inputValue.length - (postfix.length + currencyPostfix.length);
        }
      }
    }
  };

  const fromDisplayValue = (str: string): number | undefined => {
    if (str === "") {
      return undefined;
    }
    let num: number = Number(str);
    if (currency && currentShop) {
      // JS is bad a math. EG: 0.55 * 100 = 55.00000000000001
      // We need to round to ensure no fractional cents:
      num = num && Math.round(num * currentShop.currencyDenominator);
    }
    return !isNaN(num) ? clamp(num) : undefined;
  };

  const clamp = (num: number): number => {
    let clamped = num;
    if (min !== undefined) {
      clamped = Math.max(clamped, min);
    }
    if (max !== undefined) {
      clamped = Math.min(clamped, max);
    }
    return clamped;
  };

  return (
    <StyledInput
      icon={icon}
      iconPosition={iconPosition}
      className={className}
      ref={inputRef}
      value={addPreAndPostFix(stringValue)}
      placeholder={placeholder}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const prohibited =
          min !== undefined && min >= 0 ? new RegExp("[^0-9\\.]") : new RegExp("[^0-9\\-\\.]");
        let sanitized = event.currentTarget.value;
        while (prohibited.test(sanitized)) {
          sanitized = sanitized.replace(prohibited, "");
        }
        sanitized = sanitized.replace(new RegExp("(?!^)-"), ""); // Removes all but leading "-"

        const numericalChange = fromDisplayValue(sanitized) !== value;
        if (numericalChange) {
          if (sanitized === "") {
            onChange?.(undefined);
          } else {
            onChange?.(fromDisplayValue(sanitized));
          }
        } else if (stringValue === sanitized) {
          const cursorPosition = (event.currentTarget as any).selectionEnd;
          setCursorPos(cursorPosition - 1);
          setResetCursorPos(!resetCursorPos);
        }
        setStringValue(sanitized);
      }}
      onBlur={() => {
        const num = fromDisplayValue(stringValue);
        // num is now clamped
        onSubmit?.(num);
        // and display correct applied value to the user
        setStringValue(toDisplayValue(num));
      }}
      onKeyDown={blurOnEnter}
      onKeyUp={onKeyUp}
      onSelect={() => clampSelection()}
      disabled={disabled}
      autoFocus={autoFocus}
      {...rest}
      data-testid="localizedNumericInput"
    />
  );
};
