import { Query } from "@apollo/client/react/components";
import React, { useContext, useRef, useEffect, useState } from "react";
import _ from "lodash";
import styled, { Comment, CommentGroup, Icon, StyleMixins } from "grabcad-ui-elements";
import {
  IOrder,
  PossibleEventTypes,
  IOrderStatusChangedEvent,
  IOrderItemDeletedEvent,
  IOrderFilesAddedEvent,
  IOrderOperatorChangedEvent,
  IEvent,
  IOrderDetailsChangedEvent,
  IOrderDetailsChangedEventMetadata,
  IOrderItemDependencyRemovedEvent,
  IOrderItemDependencyAddedEvent,
} from "@/graphql/Fragments/Order";
import { CommentsForm } from "./CommentForm";
import { ApplicationContext, TranslateFunction } from "@/components/ApplicationProvider";
import { ORDER_COMMENTS, ORDER_EVENTS } from "@/graphql/Queries/Order";
import { Notifier } from "@/utils/Notifier";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import classnames from "classnames";
import { IStatusColors, statusColors } from "../Status/Status";
import { isTypename } from "@/utils/typeUtils";
import {
  HistoryOrderItemStatusChangedComponent,
  StatusWithColor,
} from "./HistoryOrderItemStatusChangedComponent";
import { HistoryOrderItemDeletedComponent } from "./HistoryOrderItemDeletedComponent";
import { HistoryOrderOperatorChangedComponent } from "./HistoryOrderOperatorChangedComponent";
import { HistoryEventTree, IParentNode } from "./HistoryEventTree";
import { HistoryOrderDetailsChangedComponent } from "./HistoryOrderDetailsChangedComponent";
import { HistoryItemDependencyRemovedComponent } from "./HistoryItemDependencyRemovedComponent";
import { HistoryItemDependencyAddedComponent } from "./HistoryItemDependencyAddedComponent";
import { getCadModelName } from "@/utils/GeneralUtils";

const CommentsAndHistory = styled.div<{ colors: IStatusColors }>`
  ${StyleMixins.roundAndShadow}

  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 176px;
  max-height: 100%;

  .tabs {
    display: flex;
    .tab {
      width: 50%;
      text-align: center;

      padding: 0.92857143em 0.78571429em;
      vertical-align: inherit;
      font-style: none;
      font-weight: 700;
      background: #f9fafb;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);

      &:first-child {
        border-radius: 0.28571429rem 0 0 0;
      }
      &:last-child {
        border-radius: 0 0.28571429rem 0 0;
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(34, 36, 38, 0.1);
      }
      &.active {
        background: white;
        border-bottom: none;
      }
      &:hover:not(.active) {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .ui.loader {
    top: 109px;
  }
  .no-comments-message {
    font-style: italic;
    padding: 0.78571429em;
    margin-top: 0.78571429em;
  }

  .scrollable-content {
    flex: 1 1 auto;
    padding: 0.78571429em;
    padding-bottom: 5px;
    overflow-y: auto;
    .comments {
      margin-bottom: 0;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    scrollbar-width: thin;
  }

  .history {
    margin-bottom: 3px;
    > div {
      padding-bottom: 1em;
    }
  }

  .ui.comments .comment {
    .metadata {
      display: block;
      margin: 3px 0 0 0;
    }
    .text {
      margin: 0.2em 0 0.5em;
      line-height: 1.5em;
      i.icon.arrow {
        font-size: 0.9em;
      }
    }
  }

  .status {
    display: inline-block;
    padding: 0 5px;
    border-radius: 3px;
    &.dark {
      color: white;
    }
  }

  .form-padding {
    margin: 0.78571429em;
  }
`;

export interface IOrderQueryVariables {
  orderId: number;
}

enum Tabs {
  COMMENTS = "COMMENTS",
  HISTORY = "HISTORY",
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OrderCommentsAndHistory = ({ order }: { order: IOrder }): JSX.Element => {
  const { t, formatDate, formatTime } = useContext(ApplicationContext);
  const commentsListRef = useRef<HTMLDivElement>(null);
  const [currentTab, setTabs] = useState(Tabs.COMMENTS);

  useEffect(() => {
    const { current } = commentsListRef;

    if (current?.scrollIntoView) {
      current.scrollIntoView({ behavior: "auto" });
    }
  });

  return (
    <CommentsAndHistory colors={statusColors} data-testid="orderCommentsAndHistory">
      <div className="tabs">
        <div
          className={classnames("tab comments-tab qa-comments-tab", {
            active: currentTab === Tabs.COMMENTS,
          })}
          onClick={() => setTabs(Tabs.COMMENTS)}
        >
          {t("order.comments")}
        </div>
        <div
          className={classnames("tab history-tab qa-history-tab", {
            active: currentTab === Tabs.HISTORY,
          })}
          onClick={() => setTabs(Tabs.HISTORY)}
          data-testid="historyTab"
        >
          {t("order.history")}
        </div>
      </div>

      {currentTab === Tabs.COMMENTS ? (
        <Query<{ order?: IOrder }, IOrderQueryVariables>
          query={ORDER_COMMENTS}
          variables={{ orderId: order.id }}
          onError={error => Notifier.error(error)}
        >
          {({ loading, data, refetch }) => {
            if (loading || !data || !data.order) {
              return <div className="ui loader active" />;
            }
            const { comments = [] } = data.order;
            return (
              <>
                {comments.length > 0 ? (
                  <div className="scrollable-content">
                    <CommentGroup>
                      {comments.map(comment => (
                        <Comment
                          key={`comment-${comment.id}`}
                          className="qa-comment"
                          data-testid="comment"
                        >
                          <Comment.Content>
                            <Comment.Author data-testid="commentAuthor">
                              {comment.user.name}
                            </Comment.Author>
                            <Comment.Metadata data-testid="commentMetadata">
                              {formatDate(comment.dateCreated)} {formatTime(comment.dateCreated)}
                            </Comment.Metadata>
                            <Comment.Text data-testid="commentText">{comment.text}</Comment.Text>
                          </Comment.Content>
                        </Comment>
                      ))}
                    </CommentGroup>
                    <div ref={commentsListRef} />
                  </div>
                ) : (
                  <div className="no-comments-message">{t("order.comments.noComments")}</div>
                )}
                <UiCan passThrough do={Permission.COMMENT} on={order}>
                  {(canWriteComments: boolean) =>
                    canWriteComments && (
                      <div className="form-padding">
                        <CommentsForm order={order} refetch={refetch} />
                      </div>
                    )
                  }
                </UiCan>
              </>
            );
          }}
        </Query>
      ) : (
        <Query<{ order?: { events: IEvent[] } }, IOrderQueryVariables>
          query={ORDER_EVENTS}
          variables={{ orderId: order.id }}
          onError={error => Notifier.error(error)}
        >
          {({ loading, data }) => {
            if (loading || !data || !data.order) {
              return <div className="ui loader active" />;
            }
            let { events = [] } = data.order;
            const ONE_MINUTE = 1000 * 60;
            const isAutoAssigned = (event: IEvent) =>
              isTypename<PossibleEventTypes>("OrderOperatorChangedMetaData")(event.metadata) &&
              (event as IOrderOperatorChangedEvent).metadata.isAutoAssigned;

            // Filter out comment events if any
            events = events.filter(event => event.type !== "OrderCommentEvent");

            // Filter errant rate-updated events. These are no longer populated/emailed, but some rows remain in the DB.
            // This is lazier and safer than a migration:
            events = events.filter(
              event =>
                event.type !== "OrderItemDetailsChangedEvent" ||
                ((event as IOrderDetailsChangedEvent).metadata?.fieldName !== "machineRateId" &&
                  (event as IOrderDetailsChangedEvent).metadata?.fieldName !== "materialRateId")
            );

            // Filter out events where an item property is nulled
            const nonNullingEvents = events.filter(event => {
              const metadata = event.metadata as IOrderDetailsChangedEventMetadata;
              return !metadata.fieldName || !!metadata.newValue;
            });

            // Split events by user
            const eventsByUser: { [id: number]: IEvent[] } = {};
            nonNullingEvents.forEach(event => {
              if (isAutoAssigned(event)) {
                // autoAssigned events have a user id, but we show the user as "System",
                // so we'll just pretend that "System" has a userId of 0
                if (eventsByUser[0]) {
                  // Just in case we have more than one autoAssigned event one day
                  eventsByUser[0].push(event);
                } else {
                  eventsByUser[0] = [event];
                }
              } else if (eventsByUser[event.user.id]) {
                eventsByUser[event.user.id].push(event);
              } else {
                eventsByUser[event.user.id] = [event];
              }
            });

            // IEvent['type'][]?
            const UNGROUPABLE_EVENT_TYPES: any = ["OrderFilesAddedEvent", "OrderItemDeletedEvent"];

            // Group groupable events chronolocially
            const eventsAndEventGroups: (IEvent | IEvent[])[] = [];
            // eslint-disable-next-line guard-for-in
            for (const usertId in eventsByUser) {
              const userEvents = eventsByUser[usertId].sort(
                // Sorting here should not be necessary, as the server returns events sorted
                // chronologically, but it doesn't hurt.
                (a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime()
              );
              for (let i = 0; i < userEvents.length; i++) {
                const event = userEvents[i];
                if (
                  i > 0 &&
                  !UNGROUPABLE_EVENT_TYPES.includes(event.type) &&
                  !UNGROUPABLE_EVENT_TYPES.includes(userEvents[i - 1].type)
                ) {
                  const timeElapsedSinceLastEvent =
                    new Date(event.dateCreated).getTime() -
                    new Date(userEvents[i - 1].dateCreated).getTime();
                  if (timeElapsedSinceLastEvent < ONE_MINUTE) {
                    const currentEventOrGroup =
                      eventsAndEventGroups[eventsAndEventGroups.length - 1];
                    if (Array.isArray(currentEventOrGroup) && currentEventOrGroup.length > 0) {
                      currentEventOrGroup.push(event);
                    } else {
                      eventsAndEventGroups[eventsAndEventGroups.length - 1] = [
                        userEvents[i - 1],
                        event,
                      ];
                    }
                  } else {
                    eventsAndEventGroups.push(event);
                  }
                } else {
                  eventsAndEventGroups.push(event);
                }
              }
            }

            // Group events by order or orderItem
            type OrderAndOrderItemEvents = {
              compositeId: string;
              dateCreated: string;
              orderEvents: IEvent[];
              orderItemEvents: { [fileName: string]: IEvent[] };
            };
            const eventsAndEventGroupsSubdividedByOrderOrOrderItems: (
              | IEvent
              | OrderAndOrderItemEvents
            )[] = eventsAndEventGroups.map(eventOrEventGroup => {
              if ((eventOrEventGroup as IEvent[]).length) {
                const eventGroup = eventOrEventGroup as IEvent[];
                const orderEvents = eventGroup.filter(event => !!event.order);
                const orderAndOrderItemEvents: OrderAndOrderItemEvents = {
                  compositeId: orderEvents.reduce((id, event) => id + event.id, ""),
                  dateCreated: new Date(0).toISOString(), // Time travel to dates earlier than 1970 not supported!
                  orderEvents,
                  orderItemEvents: {},
                };
                if (orderEvents.length) {
                  orderAndOrderItemEvents.dateCreated =
                    orderEvents[orderEvents.length - 1].dateCreated;
                }
                eventGroup.forEach(event => {
                  if (event.orderItem) {
                    orderAndOrderItemEvents.compositeId += event.id;
                    const fileName = getCadModelName(event.orderItem) || "";
                    if (orderAndOrderItemEvents.orderItemEvents[fileName]) {
                      orderAndOrderItemEvents.orderItemEvents[fileName].push(event);
                    } else {
                      orderAndOrderItemEvents.orderItemEvents[fileName] = [event];
                    }
                  }

                  // Set the group's date as that of the last order or orderItem event
                  orderAndOrderItemEvents.dateCreated = new Date(
                    Math.max(
                      new Date(orderAndOrderItemEvents.dateCreated).getTime(),
                      new Date(event.dateCreated).getTime()
                    )
                  ).toISOString();
                });
                return orderAndOrderItemEvents;
              } else {
                return eventOrEventGroup as IEvent;
              }
            });

            // Condense changes to order item details w/ same fieldName
            const eventsAndCondencedEventGroups: (IEvent | OrderAndOrderItemEvents)[] =
              eventsAndEventGroupsSubdividedByOrderOrOrderItems
                .map(eventOrEventGroup => {
                  if (
                    !(eventOrEventGroup as OrderAndOrderItemEvents).orderItemEvents ||
                    _.isEmpty((eventOrEventGroup as OrderAndOrderItemEvents).orderItemEvents)
                  ) {
                    return eventOrEventGroup;
                  } else {
                    const eventGroup = eventOrEventGroup as OrderAndOrderItemEvents;
                    let totalItemEvents = 0;
                    // status, technology, dependency added and dependency removed events are not condensed
                    const NON_CONDENSING_EVENT = "nonCondensingEvent";

                    // eslint-disable-next-line guard-for-in
                    for (const itemName in eventGroup.orderItemEvents) {
                      const condensedOrderItemEvents: { [fieldName: string]: IEvent | IEvent[] } =
                        {};
                      const itemEvents = eventGroup.orderItemEvents[itemName];
                      itemEvents.forEach(event => {
                        const metadata = event.metadata as IOrderDetailsChangedEventMetadata;
                        const isStatusEvent = isTypename<PossibleEventTypes>(
                          "StatusChangedEventMetadata"
                        )(event.metadata);
                        const isTechnologyChangedEvent =
                          (event.metadata as IOrderDetailsChangedEventMetadata).fieldName ===
                          "shopTechnologyId";
                        const isDependencyAddedEvent = isTypename<PossibleEventTypes>(
                          "FilesAddedEventMetadata"
                        )(event.metadata);
                        const isDependencyRemovedEvent = isTypename<PossibleEventTypes>(
                          "OrderItemDeletedMetaData"
                        )(event.metadata);
                        if (
                          isStatusEvent ||
                          isTechnologyChangedEvent ||
                          isDependencyAddedEvent ||
                          isDependencyRemovedEvent
                        ) {
                          // Status, technology, dependency added or removed events are not condensed
                          if (condensedOrderItemEvents[NON_CONDENSING_EVENT]) {
                            (condensedOrderItemEvents[NON_CONDENSING_EVENT] as IEvent[]).push(
                              event
                            );
                          } else {
                            condensedOrderItemEvents[NON_CONDENSING_EVENT] = [event];
                          }
                        } else if (metadata.fieldName) {
                          if (condensedOrderItemEvents[metadata.fieldName]) {
                            const condensedEvent = {
                              ...condensedOrderItemEvents[metadata.fieldName],
                            } as IEvent;
                            condensedEvent.metadata = {
                              ...condensedEvent.metadata,
                            } as IOrderDetailsChangedEventMetadata;
                            condensedEvent.metadata.newValue = metadata.newValue;
                            condensedEvent.dateCreated = event.dateCreated;
                            condensedOrderItemEvents[metadata.fieldName] = condensedEvent;
                          } else {
                            condensedOrderItemEvents[metadata.fieldName] = event;
                          }
                        } else {
                          // OrderItem Events currently come in only 4 flavors:
                          // OrderItemStatusChangedEvent, OrderItemDetailsChangedEvent, OrderItemDependencyAddedEvent,
                          // OrderItemDependencyRemovedEvent
                          throw new Error("Unknown Event type: " + event.type);
                        }
                      });

                      // Convert fieldName keyed Object back into array
                      eventGroup.orderItemEvents[itemName] = [];
                      for (const fieldName in condensedOrderItemEvents) {
                        if (fieldName === NON_CONDENSING_EVENT) {
                          // Status and dependency Events are not condensed
                          eventGroup.orderItemEvents[itemName].push(
                            ...(condensedOrderItemEvents[fieldName] as IEvent[])
                          );
                          totalItemEvents =
                            totalItemEvents +
                            (condensedOrderItemEvents[fieldName] as IEvent[]).length;
                        } else {
                          const metadata = (condensedOrderItemEvents[fieldName] as IEvent)
                            .metadata as IOrderDetailsChangedEventMetadata;
                          if (metadata.oldValue !== metadata.newValue) {
                            eventGroup.orderItemEvents[itemName].push(
                              condensedOrderItemEvents[fieldName] as IEvent
                            );
                            totalItemEvents = totalItemEvents + 1;
                          }
                        }
                      }
                      if (eventGroup.orderItemEvents[itemName].length === 0) {
                        delete eventGroup.orderItemEvents[itemName];
                      }
                    }
                    if (eventGroup.orderEvents.length + totalItemEvents === 1) {
                      // After condencing we have a single order or orderItem event. Ungroup!
                      if (eventGroup.orderEvents.length === 1) {
                        return eventGroup.orderEvents[0];
                      } else {
                        // eslint-disable-next-line guard-for-in
                        for (const itemName in eventGroup.orderItemEvents) {
                          // There should be only one orderItem event
                          return eventGroup.orderItemEvents[itemName][0];
                        }
                      }
                    }
                    return eventGroup;
                  }
                })
                .filter(
                  // If an event group has been condsenced into a no-op, remove it from the list.
                  eventOrEventGroup => {
                    const group = eventOrEventGroup as OrderAndOrderItemEvents;
                    return (
                      !group.compositeId ||
                      group.orderEvents.length ||
                      !_.isEmpty(group.orderItemEvents)
                    );
                  }
                );

            const eventsAndEventTreeData: (IEvent | IParentNode[])[] = eventsAndCondencedEventGroups
              .sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime())
              .map(eventOrEventGroup => {
                if ((eventOrEventGroup as IEvent).__typename === "Event") {
                  return eventOrEventGroup as IEvent;
                } else {
                  const eventGroup = eventOrEventGroup as OrderAndOrderItemEvents;
                  const userName =
                    eventGroup.orderEvents[0]?.user.name ||
                    Object.entries(eventGroup.orderItemEvents)[0][1][0].user.name ||
                    "";
                  const children: any[] = [];

                  eventGroup.orderEvents.length &&
                    children.push({
                      name: "Order",
                      nodes: eventGroup.orderEvents.map(event => getEventTreeNode(event, t)),
                    });

                  // eslint-disable-next-line guard-for-in
                  for (const itemName in eventGroup.orderItemEvents) {
                    children.push({
                      name: itemName,
                      nodes: eventGroup.orderItemEvents[itemName].map(event =>
                        getEventTreeNode(event, t)
                      ),
                    });
                  }

                  return [
                    {
                      children,
                      title: userName,
                      subTitle: `${formatDate(eventGroup.dateCreated)} ${formatTime(
                        eventGroup.dateCreated
                      )}`,
                    },
                  ];
                }
              });

            return events.length > 0 ? (
              <>
                <div className="scrollable-content">
                  <CommentGroup>
                    {eventsAndEventTreeData.map((eventOrEventTree, index) => {
                      if ((eventOrEventTree as IEvent).__typename === "Event") {
                        const event = eventOrEventTree as IEvent;
                        return (
                          <Comment
                            key={`history-item-${event.id}`}
                            className="qa-history-event"
                            data-testid="historyEventComment"
                          >
                            <Comment.Content>
                              <Comment.Author data-testid="historyEventCommentAuthor">
                                {isAutoAssigned(event) ? t("roles.system") : event.user.name}
                                {isTypename<PossibleEventTypes>("OrderDetailsChangedMetaData")(
                                  event.metadata
                                ) &&
                                  (event.orderItem
                                    ? ` ${t("order.history.updated")} ${getCadModelName(
                                        event.orderItem
                                      )}`
                                    : ` ${t("order.history.updated")} ${t("order.history.order")}`)}
                              </Comment.Author>
                              <Comment.Metadata data-testid="historyEventCommentMetadata">
                                {formatDate(event.dateCreated)} {formatTime(event.dateCreated)}
                              </Comment.Metadata>
                              <Comment.Text data-testid="historyEventCommentText">
                                {isTypename<PossibleEventTypes>("OrderItemDeletedMetaData")(
                                  event.metadata
                                ) ? (
                                  <HistoryOrderItemDeletedComponent
                                    event={event as IOrderItemDeletedEvent}
                                  />
                                ) : null}
                                {isTypename<PossibleEventTypes>("StatusChangedEventMetadata")(
                                  event.metadata
                                ) ? (
                                  <HistoryOrderItemStatusChangedComponent
                                    event={event as IOrderStatusChangedEvent}
                                  />
                                ) : null}
                                {isTypename<PossibleEventTypes>("FilesAddedEventMetadata")(
                                  event.metadata
                                ) ? (
                                  <>
                                    {/* The check is necessary to show the correct content title for the event in case of
                                    non-grouped IOrderFilesAddedEvent/IOrderItemDependencyAddedEvent events as
                                    they share the same metadata type FilesAddedEventMetadata */}
                                    <b>
                                      {event.order
                                        ? t("order.items.added")
                                        : t("order.history.dependencyAdded")}{" "}
                                    </b>
                                    <Icon name="long arrow alternate right" />
                                    {(event as IOrderFilesAddedEvent).metadata.fileNames.join(", ")}
                                  </>
                                ) : null}
                                {isTypename<PossibleEventTypes>("OrderOperatorChangedMetaData")(
                                  event.metadata
                                ) ? (
                                  <HistoryOrderOperatorChangedComponent
                                    event={event as IOrderOperatorChangedEvent}
                                  />
                                ) : null}
                                {isTypename<PossibleEventTypes>("OrderDetailsChangedMetaData")(
                                  event.metadata
                                ) ? (
                                  <HistoryOrderDetailsChangedComponent
                                    event={event as IOrderDetailsChangedEvent}
                                  />
                                ) : null}
                              </Comment.Text>
                            </Comment.Content>
                          </Comment>
                        );
                      } else {
                        return (
                          <HistoryEventTree
                            key={
                              eventsAndEventGroupsSubdividedByOrderOrOrderItems[index].dateCreated
                            }
                            items={eventOrEventTree as IParentNode[]}
                          />
                        );
                      }
                    })}
                  </CommentGroup>
                  <div ref={commentsListRef} />
                </div>
              </>
            ) : (
              <div className="no-comments-message">{t("order.history.noHistory")}</div>
            );
          }}
        </Query>
      )}
    </CommentsAndHistory>
  );
};
OrderCommentsAndHistory.displayName = "OrderCommentsAndHistory";

const getEventTreeNode = (event: IEvent, t: TranslateFunction) => {
  if (isTypename<PossibleEventTypes>("StatusChangedEventMetadata")(event.metadata)) {
    return {
      name: t("order.eventTree.status"),
      content: (
        <>
          <b>{t("order.eventTree.status")}</b> <Icon name="long arrow alternate right" />
          <StatusWithColor event={event as IOrderStatusChangedEvent} />
        </>
      ),
    };
  } else if (isTypename<PossibleEventTypes>("OrderOperatorChangedMetaData")(event.metadata)) {
    const { metadata } = event as IOrderOperatorChangedEvent;
    return {
      name: t("roles.operator"),
      content: metadata.newOperator ? (
        <>
          <b>{t("roles.operator")}</b> <Icon name="long arrow alternate right" />
          {metadata.newOperator}
        </>
      ) : (
        <>
          <b>{t("roles.operator")}</b> <Icon name="long arrow alternate right" />
          {t("order.details.change.unassigned")}
        </>
      ),
    };
  } else if (isTypename<PossibleEventTypes>("OrderDetailsChangedMetaData")(event.metadata)) {
    return {
      name: "",
      content: (
        <HistoryOrderDetailsChangedComponent
          event={event as IOrderDetailsChangedEvent}
          singleLine
        />
      ),
    };
  } else if (isTypename<PossibleEventTypes>("OrderItemDeletedMetaData")(event.metadata)) {
    return {
      name: "",
      content: (
        <HistoryItemDependencyRemovedComponent event={event as IOrderItemDependencyRemovedEvent} />
      ),
    };
  } else if (isTypename<PossibleEventTypes>("FilesAddedEventMetadata")(event.metadata)) {
    return {
      name: "",
      content: (
        <HistoryItemDependencyAddedComponent event={event as IOrderItemDependencyAddedEvent} />
      ),
    };
  }
  return { name: "Unknown Event!", content: "..." };
};
