import React, { useContext } from "react";
import styled, { Checkbox } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UPDATE_SHOP_PREFERENCES } from "@/graphql/Mutations/Shop";
import { useMutation, useQuery } from "@apollo/client";
import { SHOP_DETAILS } from "@/graphql/Queries";
import { IShop, IShopPreferences } from "@/graphql/Fragments/Shop";
import { PrefSectionHeader } from "./Page";
import { FeatureKey } from "@/graphql/Fragments/UserLicense";
import { DeepPartial } from "utility-types";

const StyledToggleLabel = styled.label`
  display: flex;
  alignitems: center;
  cursor: pointer;
  margin-top: 10px;
  .ui.checkbox {
    margin-right: 5px;
  }
`;

export const AutoEstimationPrefs = (): JSX.Element | null => {
  const { t, currentShop, currentUser } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { data } = useQuery<{ shop: IShop }>(SHOP_DETAILS, {
    variables: { id: currentShop.id },
  });
  const [updateShopPrefs] = useMutation<
    IShop,
    { shopId: number; prefs: DeepPartial<IShopPreferences> }
  >(UPDATE_SHOP_PREFERENCES);

  const hasAutoEstimationsFeature = currentUser.userProfile?.features?.some(
    f => f.featureKey === FeatureKey.AUTO_ESTIMATION
  );
  if (!hasAutoEstimationsFeature || !data?.shop) {
    return null;
  }

  return (
    <div style={{ marginBottom: 20 }}>
      <PrefSectionHeader className="page-header">
        <h2>{t("shop.preferences.autoEstimations.title")}</h2>
      </PrefSectionHeader>
      <StyledToggleLabel>
        <Checkbox
          data-testid="autoEstimationEnabled"
          toggle
          checked={data.shop.shopPreferences?.autoEstimation.enabled}
          onChange={(_event, { checked }) =>
            updateShopPrefs({
              variables: {
                shopId: currentShop.id,
                prefs: { autoEstimation: { enabled: !!checked } },
              },
            })
          }
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.enabled")}
      </StyledToggleLabel>
      <StyledToggleLabel style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Checkbox
          data-testid="autoEstimationAutoOrient"
          toggle
          checked={data.shop.shopPreferences.autoEstimation.autoOrient}
          onChange={(_event, { checked }) =>
            updateShopPrefs({
              variables: {
                shopId: currentShop.id,
                prefs: { autoEstimation: { autoOrient: !!checked } },
              },
            })
          }
        />
        &nbsp;
        {t("shop.preferences.autoEstimations.autoOrient.label")}
      </StyledToggleLabel>
    </div>
  );
};
