import styled, { Progress } from "grabcad-ui-elements";

export const StyledProgress = styled(Progress)`
  &.ui.progress {
    bottom: -1px;
    position: absolute;
    width: 100%;
    border-radius: 0;
    &.blue {
      background: #b5c8ed;
      .bar {
        background: #003393;
      }
    }
    &.green {
      background: #cbe0d0;
    }
  }
`;
