import { Ability } from "@casl/ability";
import { Permission } from "@/utils/Permission";

// FIXME GC-55795 Completely replace CASL with custom in-house stuff.
//
// We don't use most of the CASL features
// (e.g., serialization of rules, using JS as DSL to create rules, etc)
// *and* the regular Ability.can() method is _not_ flexible enough (see below)
//
// For now, basically use 0% of @casl/ability + 100% of @casl/react

export interface ISubject {
  permissions?: Permission[];
  permissionsV2?: {
    default: string[];
    writableFields: string[];
    __typename?: string;
  };
}

export class GcAbility extends Ability {
  // Override super.can() because it cannot match based on partial match of array field in subject
  // https://github.com/stalniy/casl/blob/bc76790e9945fe8c29e8ab4ed0418d1a145a1d7e/packages/casl-ability/src/ability.js#L100
  public can<ST extends ISubject>(
    action: string,
    subject: ST,
    field?: Exclude<keyof ST, number | symbol>
  ): boolean {
    if (field) {
      return !!(
        subject?.permissionsV2?.default.includes(action) &&
        subject.permissionsV2.writableFields.includes(field)
      );
    }
    return subject?.permissions ? subject.permissions.includes(action as Permission) : false;
  }
}
