import { ApolloClient, ApolloQueryResult, DataProxy } from "@apollo/client";
import { ORDER_EVENTS, IOrderEventsReturnType } from "../Queries/Order";
import { IOrder } from "../Fragments/Order";

export const fetchNewOrderEvents = async (
  cache: DataProxy,
  client: ApolloClient<any>,
  orderId: number
): Promise<void> => {
  const cachedEventsQuery: IOrderEventsReturnType | null = cache.readQuery({
    variables: { orderId },
    query: ORDER_EVENTS,
  });
  if (cachedEventsQuery) {
    const events = cachedEventsQuery.order.events;
    // last event id from existing cache
    const lastEventId = events[events.length - 1].id;

    // Fetch all new events from the last event id in cache
    const { data: getNewEventsResult }: ApolloQueryResult<{ order: IOrder }> = await client.query({
      variables: { orderId, afterEventId: lastEventId },
      query: ORDER_EVENTS,
    });

    const concatenatedEvents = [...events, ...getNewEventsResult.order.events];

    cache.writeQuery({
      data: { order: { id: orderId, events: concatenatedEvents, __typename: "Order" } },
      query: ORDER_EVENTS,
      variables: { orderId },
    });
  }
};
