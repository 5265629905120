import styled, { TreeNode } from "grabcad-ui-elements";
import React, { ReactNode } from "react";

const StyledHistoryEventTreeWrapper = styled.div`
  position: relative;
  top: 6px;
  .tree-node {
    font-size: 14px;
    .tree-node_item {
      .title {
        display: inline-block;
        color: #999999;
        font-size: 0.875em;
        p {
          font-weight: bold;
          color: #333333;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
    .tree-node_children {
      .history {
        list-style: none;
        padding-left: 0;
        margin: 4px 0 6px 0;
        &_item {
          position: relative;
          min-height: 1em;
          padding: 0 0 3px 20px;
          &::before {
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            top: 6px;
            left: 1px;
            border: 1px solid #cccccc;
            border-radius: 50%;
          }
          &::after {
            content: "";
            position: absolute;
            top: 15px;
            bottom: -7px;
            left: 5px;
            width: 1px;
            background: #cccccc;
          }
          &:last-child {
            margin-bottom: 0;
            min-height: 0.75em;
            &::before {
              border: none;
              border-bottom: 1px solid #cccccc;
              border-radius: 0;
              top: 2px;
              height: 5px;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export interface IHistoryEventTreeProps {
  items: IParentNode[];
}

export interface IParentNode {
  title: string;
  subTitle: string;
  children: IChildNode[];
}

interface IChildNode {
  name: string;
  nodes: INode[];
}

interface INode {
  name: string;
  content: ReactNode | string;
}

export const HistoryEventTree = ({ items }: IHistoryEventTreeProps): JSX.Element => (
  <StyledHistoryEventTreeWrapper data-testid="historyEventTree">
    {items.map((item, i) => {
      const parentLabel = (
        <div className="title">
          <p>{item.title}</p>
          {item.subTitle}
        </div>
      );
      return (
        <TreeNode key={`${item.title}_${i}`} nodeLabel={parentLabel} collapsed={false}>
          {item.children.map((child, index) => {
            const childLabel = <b>{child.name}</b>;
            return (
              <TreeNode nodeLabel={childLabel} key={`${child.name}_${index}`} collapsed={false}>
                <ul className="history" data-testid="history">
                  {child.nodes.map((node, index2) => (
                    <li
                      className="history_item"
                      key={`${node.name}_${index2}`}
                      data-testid="historyItem"
                    >
                      {node.content}
                    </li>
                  ))}
                  <li className="history_item" />
                </ul>
              </TreeNode>
            );
          })}
        </TreeNode>
      );
    })}
  </StyledHistoryEventTreeWrapper>
);
HistoryEventTree.displayName = "HistoryEventTree";
