import React, { useContext } from "react";
import { Grid, GridRow, GridColumn, Header } from "grabcad-ui-elements";
import { ShopUpdateForm } from "@/components/Shop/Update/Form/Form";
import { ApplicationContext } from "@/components/ApplicationProvider";

export const ScreensShopInfoPage = (): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  return (
    <Grid>
      <GridRow>
        <GridColumn>
          <Header as="h2" className="page-header">
            {t("shop.info.title")}
          </Header>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          <ShopUpdateForm />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
ScreensShopInfoPage.displayName = "ScreensShopInfoPage";
