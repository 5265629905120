import { gql } from "@apollo/client";
import { MATERIAL_UNITS, TIME_UNITS } from "../../shopConstants";

export interface IMaterialRate {
  id: number;
  shopMaterialId: number;
  materialRate?: number;
  supportRate?: number;
  dateCreated: string;
}

export interface IMachineRate {
  id: number;
  shopMachineId: number;
  machineBaseRate?: number;
  machineTimeRate?: number;
  machineTimeUnit: TIME_UNITS;
  materialUnit: MATERIAL_UNITS;
  supportUnit?: MATERIAL_UNITS;
  dateCreated: string;
}

export const MachineRateFields = gql`
  fragment machineRateFields on MachineRateHistory {
    id
    shopMachineId
    machineBaseRate
    machineTimeRate
    machineTimeUnit
    materialUnit
    supportUnit
    dateCreated
  }
`;

export const MaterialRateFields = gql`
  fragment materialRateFields on MaterialRateHistory {
    id
    shopMaterialId
    materialRate
    supportRate
    dateCreated
  }
`;

export interface IMaterialRateResponse {
  data: { materialRateByMaterialId: IMaterialRate | null };
}

export interface IMachineRateResponse {
  data: { machineRateByMachineId: IMachineRate | null };
}
