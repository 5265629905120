import { ApplicationContext } from "@/components/ApplicationProvider";
import { IOrder } from "@/graphql/Fragments/Order";
import { DELETE_ORDER_ITEMS } from "@/graphql/Mutations/Order";
import { ORDER_DETAILS } from "@/graphql/Queries";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { ItemsState } from "@/screens/Shop/Order/Show/OrderPage";
import { Notifier } from "@/utils/Notifier";
import { ApolloError, DataProxy, MutationFunction, useApolloClient } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Button } from "grabcad-ui-elements";
import React, { SyntheticEvent, useContext, useState } from "react";
import { ConfirmationModal } from "../../Shared/ConfirmationModal";

/** Trash can icon to delete selected order items */
export const DeleteOrderItemsButton = ({
  disabled,
  selectedItems,
  order,
  modalHeaderContent,
  modalBodyContent,
  onDelete,
  tooltip,
}: {
  disabled: boolean;
  selectedItems: ItemsState;
  order: IOrder;
  modalHeaderContent: string;
  modalBodyContent: string;
  onDelete: () => void;
  tooltip?: string;
}): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const [deleteModal, setDeleteModal]: [boolean, (b: boolean) => void] = useState(false as boolean);
  const client = useApolloClient();

  return (
    <div data-tooltip={tooltip}>
      <Mutation
        mutation={DELETE_ORDER_ITEMS}
        onCompleted={() => Notifier.success(`${t("order.items.delete.success")}`)}
        refetchQueries={[{ query: ORDER_DETAILS, variables: { orderId: order.id } }]}
        update={async (cache: DataProxy) => fetchNewOrderEvents(cache, client, order.id)}
        onError={(error: ApolloError) => Notifier.error(error)}
        variables={{ ids: selectedItems, orderId: order.id }}
      >
        {(update: MutationFunction) => {
          const submitAction = () => {
            update();
            onDelete();
          };
          return (
            <ConfirmationModal
              headerIcon={"trash"}
              headerCopy={t(modalHeaderContent)}
              bodyCopy={t(modalBodyContent)}
              cancelTranslationKey={"order.items.delete.cancel"}
              confirmTranslationKey={"order.items.delete"}
              open={deleteModal}
              onClose={() => setDeleteModal(false)}
              submitAction={submitAction}
            />
          );
        }}
      </Mutation>
      <Button
        icon={"trash"}
        disabled={disabled}
        secondary
        circular
        className={"borderless qa-deleteOrderItems-button trash"}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          setDeleteModal(true);
        }}
      />
    </div>
  );
};
