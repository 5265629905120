import React, { useState, useEffect, useContext } from "react";
import { UPLOAD_IMAGE, ImageType, Entity } from "@/graphql/Mutations/Upload";
import { ImagePreview } from "@/components/Shared/ImagePreview";
import { IImage } from "@/graphql/Fragments/Image";
import { OverlayEditButton } from "@/components/Upload/OverlayEditButton";
import styled, { Notifier, StyleMixins } from "grabcad-ui-elements";
import { isNullOrUndefined } from "util";
import { ApolloError, MutationFunction, MutationResult } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { ApplicationContext } from "../ApplicationProvider";

interface IImageUpdateOrDeleteDropzoneProps {
  onImageUploaded: (imageId: number) => void;
  onUploadFailed?: (err: ApolloError) => void;
  imageType: ImageType;
  entity: Entity;
  currentImage?: Partial<IImage>;
  onImageReset: () => void;
  isImageEditable?: boolean;
  menuPosition: string;
  defaultImage: Partial<IImage>;
  imageSize: "small" | "medium";
}

export const NotEditableImageContainer = styled.div`
  ${StyleMixins.roundAndShadow}
  position: relative;
  overflow: hidden;
`;

interface IImageMutationProps {
  files: File[];
  imageType: ImageType;
  entity: Entity;
  shopId?: number;
}

interface IImageMutationResponse {
  id: number;
}

export const ImageUpdateOrDeleteDropzone = ({
  onImageUploaded,
  onUploadFailed,
  imageType,
  entity,
  currentImage,
  onImageReset,
  isImageEditable = true,
  menuPosition,
  defaultImage,
  imageSize,
}: IImageUpdateOrDeleteDropzoneProps): JSX.Element => {
  const { currentShop } = useContext(ApplicationContext);
  const [image, setImage] = useState<Partial<IImage>>(currentImage ? currentImage : defaultImage);
  const [entityHasImage, setEntityHasImage] = useState(
    !isNullOrUndefined(currentImage) && currentImage !== defaultImage
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentImage) {
      setImage(currentImage);
    } else {
      setImage(defaultImage);
    }
  }, [currentImage]);

  const handleDrop = async (
    mutation: MutationFunction<{ uploadImage: IImageMutationResponse }, IImageMutationProps>,
    files: File[]
  ) => {
    if (files && files.length > 0) {
      setLoading(true);
      try {
        await mutation({ variables: { files, imageType, entity, shopId: currentShop?.id } });
      } catch (e) {
        setEntityHasImage(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Mutation<{ uploadImage: IImageMutationResponse }, IImageMutationProps>
      mutation={UPLOAD_IMAGE}
      onCompleted={({ uploadImage }: { uploadImage: IImageMutationResponse }) => {
        if (onImageUploaded) {
          onImageUploaded(uploadImage.id);
        }
        setImage(uploadImage);
        setEntityHasImage(true);
      }}
      onError={(err: ApolloError) => {
        if (onUploadFailed) {
          onUploadFailed(err);
        }
      }}
    >
      {(uploadImage, { error }: MutationResult) => {
        if (error) {
          Notifier.error(error.message);
        }
        return isImageEditable ? (
          <OverlayEditButton
            id="qa-editable-image"
            onDrop={(acceptedFiles: File[]) => handleDrop(uploadImage, acceptedFiles)}
            loading={loading}
            multiple={false}
            imageAlreadyPresent={entityHasImage}
            updateImagePresence={setEntityHasImage}
            resetImage={() => {
              setImage(defaultImage);
              onImageReset();
            }}
            accept="image/*"
            image={image}
            menuPosition={menuPosition}
          >
            <ImagePreview image={image} size={imageSize} />
          </OverlayEditButton>
        ) : (
          <NotEditableImageContainer>
            <ImagePreview image={image} size={imageSize} />
          </NotEditableImageContainer>
        );
      }}
    </Mutation>
  );
};
