import React from "react";
import { DropzoneInputProps, DropzoneOptions, DropzoneRef, useDropzone } from "react-dropzone";

interface DropzoneDivRootProps extends React.HTMLAttributes<HTMLDivElement> {
  refKey?: string;
  [key: string]: any;
}

type DropzoneDivState = DropzoneRef & {
  isFocused: boolean;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  isFileDialogActive: boolean;
  draggedFiles: File[];
  acceptedFiles: File[];
  rejectedFiles: File[];
  rootRef: React.RefObject<HTMLElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  getRootProps(props?: DropzoneDivRootProps): DropzoneDivRootProps;
  getInputProps(props?: DropzoneInputProps): DropzoneInputProps;
};

/**
 * We are using react-dropzone inside of divs which causes issues with
 * transpilation of the div because DropzoneRootProps in the typing is based on
 * React.HTMLAttributes<HTMLElement> and div is expecting React.HTMLAttributes<HTMLDivElement>
 * this fails transpilation because (ultimately): -
 *
 * Property 'code' is missing in type 'KeyboardEvent<HTMLDivElement>' but required in type 'KeyboardEvent<HTMLElement>'. TS2322
 */
export function useDivDropzone(options?: DropzoneOptions): DropzoneDivState {
  const state = useDropzone(options);
  return state as DropzoneDivState;
}
