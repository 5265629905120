import React, { useContext } from "react";
import { SHOPS_LIST } from "@/graphql/Queries";
import { CREATE_SHOP } from "@/graphql/Mutations";
import { Notifier } from "@/utils/Notifier";
import { withRouter, RouteComponentProps } from "react-router";
import { ROUTES } from "@/shopConstants";
import { ShopFormContext } from "@/screens/Shop/New/New";
import { ShopForm } from "../../Form/Form";
import { ApolloError, MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

interface ICreateShopResponse {
  createShop: {
    id: number;
  };
}

export const ShopNewForm = withRouter(({ history }: RouteComponentProps) => {
  const { setGeneralCompleted } = useContext(ShopFormContext);

  return (
    <Mutation
      mutation={CREATE_SHOP}
      onCompleted={({ createShop }: ICreateShopResponse) => {
        setGeneralCompleted(true);

        history.push(ROUTES.SHOP(createShop.id, true).MACHINES.SHOW({}));
      }}
      onError={(error: ApolloError) => Notifier.error(error)}
      refetchQueries={[{ query: SHOPS_LIST }]}
    >
      {(createShop: MutationFunction) => <ShopForm mutate={createShop} />}
    </Mutation>
  );
});
