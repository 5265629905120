import { OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import React from "react";
import { getItemsToDownload, getUrlAndDownload, IUrlDownloadProps } from "./DownloadButton";
import { Button } from "grabcad-ui-elements";
import Download from "@/assets/icons/download.svg";
import { useApolloClient } from "@apollo/client";
import { getIcon } from "./Icons";

export interface IMultiFileDownloadButtonProps {
  items: OrderItemOrImageOrFile[];
}

export const MultiFileDownloadButton = (props: IMultiFileDownloadButtonProps): JSX.Element => {
  const client = useApolloClient();
  return (
    <Button
      icon={getIcon(Download, { fontSize: "1.5em" })}
      disabled={!props.items.length}
      secondary
      circular
      className={"borderless qa-multiple-orderItem-download"}
      onClick={async () => {
        let itemsToDownload: IUrlDownloadProps[] = [];
        for (const item of props.items) {
          itemsToDownload = itemsToDownload.concat(getItemsToDownload(item));
        }
        for (const itemToDownload of itemsToDownload) {
          await getUrlAndDownload(client, itemToDownload);
        }
      }}
    />
  );
};
