import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import ReactGA from "react-ga";
import StackGrid from "react-stack-grid";

import { ROUTES } from "@/shopConstants";
import styled, {
  Avatar,
  Button,
  Header,
  Image,
  Tab,
  Loader,
  Notifier,
  StyleMixins,
} from "grabcad-ui-elements";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { PlaceHolder } from "@/components/Shared/Placeholder";
import { ApplicationContext } from "@/components/ApplicationProvider";
import PlaceholderClipboards from "@/assets/placeholder_orders.svg";
import { IShopJob, IShopRoute, JOB_COUNTS, SHOP_JOBS, SHOP_ROUTES } from "@/graphql/Queries/Job";
import { ApolloQueryResult, useQuery, useApolloClient } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { getCadModelName } from "@/utils/GeneralUtils";
import { getItemQuantitiesByStep, getSummedQuantitiesByStep } from "./Job";
import { ContextMenu } from "@/components/Shop/Machines/ContextMenu";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { DELETE_JOB } from "@/graphql/Mutations/Job";
import { useShopTechnologies } from "@/utils/queryHooks";
import { updateCadModelQueryCache } from "@/graphql/Utils/updateCadModelQueryCacheUtil";
import { CadModelPreview } from "@/components/Shared/CadModelPreview";
import _ from "lodash";
import { updateCachedOrderItemsJob } from "./New/JobForm";

const JobsList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-row {
    flex: 0 0 auto;
    margin-bottom: 1em;
    display: flex;
    h2 {
      flex-grow: 1;
    }
    .button {
      margin-top: 14px !important;
    }
  }
  .list {
    ${StyleMixins.roundAndShadow}
    flex: 1 1 100px;
    padding: 20px;
    min-width: 400px;
    .tabs {
      position: relative;
      top: -15px;
      margin-bottom: 5px;
    }
    .job {
      cursor: pointer;
      background: #f4f5f7;
      border: 1px solid #dddddd;
      border-radius: 4px;
      &:hover {
        box-shadow: 0 0 10px rgba(34, 36, 38, 0.25) !important;
      }
      .num-parts {
        opacity: 0.7;
      }
      .header {
        border-bottom: 1px solid #dddddd;
        padding: 10px 15px;
        display: flex;
        .job-info {
          max-width: 100%;
          flex-grow: 1;
          h3 {
            font-size: 12px;
            font-weight: normal;
            margin: 0 0 2px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .icon.ellipsis {
          top: 1px;
          right: 0;
        }
      }
      .progress-bar {
        height: 6px;
        border-radius: 3px;
        background: #ffffff;
        border: 1px solid #dddddd;
        margin: 4px -0;
        > div {
          height: 6px;
          border-radius: 3px;
          background: #003393;
          position: relative;
          top: -1px;
          left: -1px;
        }
      }
      .order-items {
        max-height: calc(78px * 5 + 10px);
        overflow: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 6px;
        }
      }
      .order-item {
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 3px;
        display: flex;
        margin: 10px;
        padding: 8px;
        .details {
          min-width: 10px;
          flex: 1 1 10px;
          margin-left: 10px;
          h4 {
            font-size: 12px;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
          }
          .num-parts {
            font-size: 10px;
            margin: -2px 0 -3px;
          }
          .full-name {
            height: 15px;
            position: relative;
            left: -3px;
            > div {
              height: 15px;
              padding-top: 2.5px;
            }
          }
        }
      }
    }
  }
`;

export const StyledSmallThumbnail = styled.div`
  border: 1px solid #dddddd;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  width: 54px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 35px;
  }
`;

enum TABS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ALL = "ALL",
}

export const ScreensShopJobsList = withRouter(({ history }): JSX.Element | null => {
  const { currentShop, t } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies, allMachines } = useShopTechnologies();

  const client = useApolloClient();
  const [currentTab, setCurrentTab] = useState(TABS.ACTIVE);
  const [jobRouteIdsRefetched, setJobRouteIdsRefetched] = useState<number[]>([]);
  const jobRoutesQuery = useQuery<{ loadShopRoutes: IShopRoute[] }, { id: number }>(SHOP_ROUTES, {
    variables: { id: currentShop.id },
  });
  const [jobToDelete, setJobToDelete] = useState<IShopJob | undefined>();

  const fetchJobs =
    (
      active: boolean,
      setJobs: (jobs: IShopJob[]) => void,
      setLoading: (loading: boolean) => void
    ) =>
    async () => {
      setLoading(true);
      const { data, errors }: ApolloQueryResult<{ loadShopJobs: IShopJob[] }> = await client.query({
        // FIXME use updated cache instead of network req after Apollo upgrade
        fetchPolicy: "network-only",
        query: SHOP_JOBS,
        variables: { active, id: currentShop.id },
      });
      if (!errors && data) {
        setJobs(data.loadShopJobs);
      }
      setLoading(false);
      if (data.loadShopJobs) {
        const orderItemIds = _.flatten(data.loadShopJobs.map(job => job.orderItems)).map(
          item => item.id
        );
        updateCadModelQueryCache(client, orderItemIds);
      }
    };

  const [activeJobsLoading, setActiveJobsLoading] = useState(false);
  const [activeJobs, setActiveJobs] = useState([] as IShopJob[]);
  const fetchActiveJobs = fetchJobs(true, setActiveJobs, setActiveJobsLoading);

  const [completedJobsLoading, setCompletedJobsLoading] = useState(false);
  const [completedJobs, setCompletedJobs] = useState([] as IShopJob[]);
  const fetchCompletedJobs = fetchJobs(false, setCompletedJobs, setCompletedJobsLoading);

  const refetchJobs = () => {
    if (currentTab === TABS.ACTIVE) {
      fetchActiveJobs();
    } else if (currentTab === TABS.INACTIVE) {
      fetchCompletedJobs();
    } else if (currentTab === TABS.ALL) {
      fetchActiveJobs();
      fetchCompletedJobs();
    }
  };
  useEffect(() => refetchJobs(), [currentTab]);

  let shopJobs = [] as IShopJob[];
  if (currentTab === TABS.ACTIVE) {
    shopJobs = activeJobs;
  } else if (currentTab === TABS.INACTIVE) {
    shopJobs = completedJobs;
  } else if (currentTab === TABS.ALL) {
    shopJobs = activeJobs.concat(completedJobs);
  }

  // Number of columns is based on available width
  const MIN_COL_WIDTH = 250;
  const GUTTER_WIDTH = 20;
  const gridHolderRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(gridHolderRef.current?.offsetWidth || 1000);
  let timeoutId: NodeJS.Timeout | null = null;
  const handleResize = () => {
    timeoutId && clearTimeout(timeoutId); // Prevent execution of previous setTimeout
    // Change width from the state object after 150 milliseconds
    timeoutId = setTimeout(() => setWidth(gridHolderRef.current?.offsetWidth || 1000), 150);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useLayoutEffect(() => {
    handleResize();
  }, [activeJobsLoading]);
  const cols = Math.max(Math.floor(width / MIN_COL_WIDTH), 1);
  const colWidth = (width - cols * GUTTER_WIDTH) / cols;

  return (
    <JobsList>
      <ConfirmationModal
        headerIcon="trash"
        headerCopy={t("shop.job.delete.header")}
        bodyTitle={jobToDelete?.name}
        bodyCopy={t("shop.job.delete.warning")}
        cancelTranslationKey="general.cancel"
        confirmTranslationKey="general.delete"
        open={!!jobToDelete}
        onClose={() => setJobToDelete(undefined)}
        submitAction={async () => {
          await client.mutate({
            mutation: DELETE_JOB,
            variables: { id: jobToDelete?.id },
            update: (_cache, response) => {
              if (response.data) {
                const activeJobsAfterDelete = [
                  ...activeJobs.filter(job => job.id !== response.data.deleteJob.id),
                ];
                const completedJobsAfterDelete = [
                  ...completedJobs.filter(job => job.id !== response.data.deleteJob.id),
                ];
                const cachedJobCountsByShopQuery = _cache.readQuery<{
                  jobCountsByShop: {
                    activeJobs: number;
                    completedJobs: number;
                  };
                }>({
                  query: JOB_COUNTS,
                  variables: { id: currentShop.id },
                });
                if (cachedJobCountsByShopQuery) {
                  const cachedJobCountsByShop = { ...cachedJobCountsByShopQuery.jobCountsByShop };
                  if (activeJobsAfterDelete.length !== activeJobs.length) {
                    // if active job was deleted
                    cachedJobCountsByShop.activeJobs--;
                  } else {
                    // if completed job was deleted
                    cachedJobCountsByShop.completedJobs--;
                  }
                  _cache.writeQuery({
                    data: { jobCountsByShop: cachedJobCountsByShop },
                    query: JOB_COUNTS,
                    variables: { id: currentShop.id },
                  });

                  if (jobToDelete?.orderItems.length) {
                    updateCachedOrderItemsJob(
                      client,
                      null,
                      jobToDelete?.orderItems.map(item => item.id)
                    );
                  }
                }

                setActiveJobs(activeJobsAfterDelete);
                setCompletedJobs(completedJobsAfterDelete);
              }
            },
          });
          ReactGA.event({
            category: "GcShop Jobs",
            action: "Deleted Job",
            label: `Shop ${currentShop.id}`,
          });
        }}
      />
      <div className="header-row">
        <Header as="h2" className="page-header">
          {t("shop.jobs")}
        </Header>
        <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
          <Button
            className="qa-button-newJob"
            primary
            content={t("shop.jobs.new")}
            onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.NEW)}
          />
        </UiCan>
      </div>
      <div className="list">
        <Query<
          {
            jobCountsByShop?: {
              activeJobs: number;
              completedJobs: number;
            };
          },
          { id: number }
        >
          query={JOB_COUNTS}
          variables={{ id: currentShop.id }}
          onError={error => Notifier.error(error.message)}
        >
          {({ data }) => {
            if (data?.jobCountsByShop) {
              const activeJobsCount = data.jobCountsByShop.activeJobs;
              const completedJobsCount = data.jobCountsByShop.completedJobs;
              return (
                <Tab
                  className="tabs"
                  menu={{ secondary: true, pointing: true }}
                  activeIndex={Object.keys(TABS).indexOf(currentTab)}
                  onTabChange={(_event, { activeIndex }) => {
                    setCurrentTab(Object.values(TABS)[activeIndex as number]);
                  }}
                  panes={[
                    { menuItem: `${t("shop.jobs.active")} (${activeJobsCount})` },
                    { menuItem: `${t("shop.jobs.completed")} (${completedJobsCount})` },
                    { menuItem: `${t("shop.jobs.all")} (${activeJobsCount + completedJobsCount})` },
                  ]}
                />
              );
            }
            return null;
          }}
        </Query>

        {activeJobsLoading || completedJobsLoading ? (
          <Loader active={true} size="small" data-testid="loader" />
        ) : !shopJobs.length ? (
          <PlaceHolder id="qa-placeHolder">
            <Image src={PlaceholderClipboards} height="145" />
            <h2>
              {currentTab === TABS.ACTIVE
                ? t("shop.jobs.placeholder.header.active")
                : currentTab === TABS.INACTIVE
                ? t("shop.jobs.placeholder.header.completed")
                : t("shop.jobs.placeholder.header.all")}
            </h2>
            <p>{t("shop.jobs.placeholder.copy")}</p>
            <UiCan do={Permission.CREATE_JOBS} on={currentShop}>
              <Button
                id="qa-button-newJob"
                primary
                content={t("shop.jobs.new")}
                onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.NEW)}
              />
            </UiCan>
          </PlaceHolder>
        ) : (
          <div
            ref={gridHolderRef}
            style={{ marginLeft: -GUTTER_WIDTH / 2, marginRight: -GUTTER_WIDTH / 2 }}
          >
            <StackGrid
              columnWidth={colWidth}
              gutterWidth={GUTTER_WIDTH}
              gutterHeight={20}
              duration={0}
            >
              {shopJobs.map(job => {
                const numParts = job.orderItems.reduce((acc, cur) => acc + cur.quantity, 0);

                let percentCompleted = 0;
                const jobRoute = jobRoutesQuery.data?.loadShopRoutes.find(
                  route => route.id === job.shopRouteId
                );
                if (
                  !!jobRoutesQuery.data &&
                  !jobRoute &&
                  !jobRouteIdsRefetched.includes(job.shopRouteId)
                ) {
                  // This can happen for the first job created for a Technology. The new Route is created as needed server side.
                  jobRoutesQuery.refetch();
                  // This is to avoid any potential for looping network requests if something goes wrong:
                  setJobRouteIdsRefetched([job.shopRouteId, ...jobRouteIdsRefetched]);
                }
                if (jobRoute) {
                  const orderItemQuantityByStep = getItemQuantitiesByStep(job, jobRoute);
                  const summedQuantitiesByStep = getSummedQuantitiesByStep(orderItemQuantityByStep);
                  const totalQuantity = summedQuantitiesByStep.reduce((acc, cur) => acc + cur, 0);
                  const quantityInLastStep =
                    summedQuantitiesByStep[summedQuantitiesByStep.length - 1];
                  percentCompleted = (quantityInLastStep / totalQuantity) * 100;
                }

                const shopTechnology = shopTechnologies.find(
                  tech => tech.id === job.shopTechnologyId
                );
                const shopMachine = allMachines.find(machine => machine.id === job.shopMachineId);

                return (
                  <div
                    className="job"
                    key={job.id}
                    onClick={() => history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(job.id))}
                    data-testid="job"
                  >
                    <div className="header">
                      <div className="job-info">
                        <h3>{job.name}</h3>
                        <div className="num-parts" data-testid="numParts">{`${numParts} ${t(
                          "shop.jobs.parts"
                        )}`}</div>
                        <div className="progress-bar">
                          <div style={{ width: `${percentCompleted}%` }}></div>
                        </div>
                        <h3>
                          {shopMachine?.appMachineType.displayName ||
                            shopTechnology?.appTechnology.displayName}
                        </h3>
                      </div>
                      <ContextMenu
                        menuItems={[
                          <a
                            className="item"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push(ROUTES.SHOP(currentShop.id).JOBS.SHOW(job.id));
                            }}
                            key="edit-link"
                          >
                            {t("shop.edit")}
                          </a>,
                          <a
                            className="item delete"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setJobToDelete(job);
                            }}
                            key="delete-link"
                          >
                            {t("shop.delete")}
                          </a>,
                        ]}
                      />
                    </div>
                    <div className="order-items">
                      {job.orderItems.map(item => (
                        <div className="order-item" key={item.id} data-testid="orderItem">
                          <StyledSmallThumbnail>
                            <CadModelPreview itemId={item.id} size={"small"} />
                          </StyledSmallThumbnail>
                          <div className="details">
                            <h4>{getCadModelName(item)}</h4>
                            <div className="num-parts" data-testid="numParts">{`${
                              item.quantity
                            } ${t("shop.jobs.parts")}`}</div>
                            <Avatar
                              fullName
                              name={item.order?.user?.name}
                              id={item.order?.user?.email}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </StackGrid>
          </div>
        )}
      </div>
    </JobsList>
  );
});
ScreensShopJobsList.displayName = "ScreensShopJobsList";
