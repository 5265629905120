import gql from "graphql-tag";

export interface ICadModelLocationResponse {
  cadModel: { original: { asset: { location: string } } };
}

export interface IGenericFileLocationResponse {
  genericFile: { asset: { location: string } };
}

export interface IImageLocationResponse {
  image: { original: { location: string } };
}

export interface IAutoEstimationAssetLocationResponse {
  autoEstimationAsset: { asset: { location: string } };
}

export const CAD_MODEL_LOCATION = gql`
  query GetCadModelLocation($id: Int!) {
    cadModel(id: $id) {
      original {
        asset {
          location
        }
      }
      __typename
    }
  }
`;

export const GENERIC_FILE_LOCATION = gql`
  query GetGenericFileLocation($id: Int!) {
    genericFile(id: $id) {
      asset {
        location
      }
      __typename
    }
  }
`;

export const IMAGE_LOCATION = gql`
  query GetImageLocation($id: Int!) {
    image(id: $id) {
      original {
        location
      }
      __typename
    }
  }
`;

export const AUTO_ESTIMATION_ASSET_LOCATION = gql`
  query GetAutoEstimationAssetLocation($id: Int!) {
    autoEstimationAsset(id: $id) {
      asset {
        location
        assetType
      }
      __typename
    }
  }
`;
