const parseHeaders = (rawHeaders: string) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

interface IOpts {
  status: number;
  statusText: string;
  headers: Headers;
  url: string | undefined | null;
  method: string;
  onProgress: (evt: ProgressEvent) => void;
  onAbortPossible: (callback: () => void) => void;
  body: string;
  credentials?: string;
}

export const uploadFetch = (url: string, options: IOpts): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const { credentials } = options;
    if (credentials) {
      switch (credentials) {
        case "include":
          xhr.withCredentials = true;
          break;
        default:
          throw new Error(`Unsupported credentials "${credentials}"`);
      }
    }
    xhr.onload = () => {
      const { onProgress, onAbortPossible, method, url: optsUrl, body: optsBody } = options;
      const opts: IOpts = {
        onProgress,
        onAbortPossible,
        method,
        url: optsUrl,
        body: optsBody,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
      };
      opts.url = "responseURL" in xhr ? xhr.responseURL : opts.headers.get("X-Request-URL");
      const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach(key => {
      xhr.setRequestHeader(key, (options.headers as any)[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

export const customFetch = (uri: any, options: any): Promise<unknown> => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  if (options.onAbortPossible) {
    throw new Error("onAbortPossible() is not supported for useUpload=false");
  }
  if (options.onProgress) {
    throw new Error("onProgress() is not supported for useUpload=false");
  }
  return fetch(uri, options);
};
