import { ApplicationContext } from "@/components/ApplicationProvider";
import * as currencyFunctions from "currency-codes";
import React, { memo, useContext } from "react";
import { SelectionDropdown } from "grabcad-ui-elements";
import { IDropdownOptions } from "./Form";

const currencies: IDropdownOptions[] = currencyFunctions
  .codes()
  .reduce((currencyCodes: IDropdownOptions[], currencyCode) => {
    if (currencyCode.charAt(0) !== "X") {
      const currencyInfo = currencyFunctions.code(currencyCode);

      currencyInfo &&
        currencyCodes.push({
          key: currencyInfo.code,
          value: currencyInfo.code,
          text: `${currencyInfo.code}: ${currencyInfo.currency}`,
        });
    }

    return currencyCodes;
  }, []);

export const ShopFormCurrenciesDropdown = memo(
  (props: {
    currencyCode: string | undefined;
    setCurrencyCode: (value: string) => void;
    disabled?: boolean;
  }) => {
    const { t } = useContext(ApplicationContext);
    const { currencyCode, setCurrencyCode, ...passThru } = props;

    return (
      <SelectionDropdown
        id="qa-shopForm-currencyDropdown"
        {...passThru}
        name="currencyCode"
        value={currencyCode}
        onChange={(_evt, { value }) => setCurrencyCode(value as string)}
        placeholder={t("shop.form.currencyCode")}
        options={currencies}
        data-testid="currenciesDropdown"
      />
    );
  }
);
ShopFormCurrenciesDropdown.displayName = "ShopFormCurrenciesDropdown";
