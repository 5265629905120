import gql from "graphql-tag";
import { ShopStatusFragments } from "@/graphql/Fragments";

export const CREATE_SHOP_STATUS = gql`
  mutation createShopStatus($input: ShopStatusInput!) {
    createShopStatus(input: $input) {
      ...shopStatusFields
    }
  }
  ${ShopStatusFragments.status}
`;

export const UPDATE_SHOP_STATUS = gql`
  mutation updateShopStatus($input: ShopStatusInput!) {
    updateShopStatus(input: $input) {
      ...shopStatusFields
    }
  }
  ${ShopStatusFragments.status}
`;

export const SET_SHOP_STATUSES_DISPLAY_ORDER = gql`
  mutation setShopStatusesDisplayOrder($shopStatusIds: [Int]!) {
    setShopStatusesDisplayOrder(shopStatusIds: $shopStatusIds)
  }
`;

export const DELETE_SHOP_STATUS = gql`
  mutation deleteShopStatus($id: ID!) {
    deleteShopStatus(id: $id) {
      ...shopStatusFields
    }
  }
  ${ShopStatusFragments.status}
`;
