import { IUploadContainer } from "@/components/Upload/CadDropZone";
import styled, { Image, Input } from "grabcad-ui-elements";
import Model from "@/assets/icons/model.svg";
import React, { SyntheticEvent, useEffect, useState, useContext } from "react";
import { ORDER } from "@/shopConstants";
import { Icon } from "semantic-ui-react";
import { ICadFile } from "@/graphql/Mutations/Upload";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { SubscribeFunction } from "@/graphql/subscription";
import { StyledProgress } from "../../../Shared/StyledProgress";
import { UnitsPicker } from "../UnitsPicker";
import { modelCadFormatIsUnitless, getModelSizesString } from "@/utils/DropdownUtils";
import { isGcpAssetFileName } from "../../../../utils/GeneralUtils";

export const CadFileRow = styled.div`
  background: #f4f5f7;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 70px;
  padding: 10px 0;
  .num {
    flex: 27px 0 0;
    text-align: right;
    margin-right: 10px;
  }
  .img {
    flex: 60px 0 0;
    height: 49px;
    padding: 3px;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .name-n-status {
    flex: auto 1 1;
    overflow: hidden;
    strong,
    div {
      word-break: break-word;
      @supports (-ms-ime-align: auto) {
        word-break: break-all;
      }
    }
  }
  .unit-label {
    margin-right: 5px;
  }
  .quantity-label {
    margin-left: 15px;
  }
  .quantity {
    flex: 70px 0 0;
    margin-left: 5px;
  }
  .icon {
    flex: 30px 0 0;
    margin: 0 5px;
    padding: 6px 0;
    height: 30px;
    cursor: pointer;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
`;

export interface ISubscriptionContainer extends IUploadContainer {
  cadFile: ICadFile;
}

interface ISubscriptionResultsProps {
  uploadContainer: ISubscriptionContainer;
  subscribeToMoreData: SubscribeFunction;
  index: number;
}

export const SubscriptionResults = ({
  uploadContainer,
  subscribeToMoreData,
  index,
}: ISubscriptionResultsProps): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const [quantity, setQuantity] = useState<number | "">(uploadContainer.quantity);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    let newQuantity = +event.target.value;
    newQuantity = Math.min(Math.max(0, newQuantity), ORDER.ITEM.MAX_QUANTITY); // clamp
    setQuantity(newQuantity || ""); // convert 0 to empty string to show placeholder "1" when last digit deleted
    // "" allows component to remain "controlled" unlike undefined or null
    uploadContainer.quantity = newQuantity || 1; // convert 0 to 1 to allow submitting with placeholder "1"
  };

  const { cadFile, progress, file, cancel } = uploadContainer;
  const hasImage = cadFile?.preview?.small;
  const hasSizes = cadFile?.size;

  useEffect(() => {
    if (!hasImage || !hasSizes) {
      // Subscribe for updates if thumbnail image OR sizes does not yet exist
      // cadFile.unit may be null for stl
      return subscribeToMoreData();
    }
  }, [hasImage, hasSizes]);

  const hasError = cadFile?.conversionStatus === "FAILED";
  const imgSrc = cadFile?.preview?.small?.location;
  const units = modelCadFormatIsUnitless(cadFile) ? uploadContainer.unit : undefined;
  const modelSizes = getModelSizesString(cadFile, units, currentShop?.preferredUnits, t);
  const isGcpAsset = isGcpAssetFileName(cadFile?.original?.asset?.originalName);

  return (
    <CadFileRow
      className={"qa-uploadFileRow"}
      id={`qa-uploadFileRow_${uploadContainer.key}`}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation();
      }}
    >
      <div className="qa-uploadFileRow-number num">{index + 1}</div>
      <div className="qa-uploadFileRow-img img">
        {hasImage ? <Image src={imgSrc} /> : <Image src={Model} width="20" />}
      </div>
      <div className="name-n-status">
        <strong className="qa-uploadFileRow-name">{file.name}</strong>
        <div className="qa-uploadFileRow-status status" data-testid="status">
          {isGcpAsset
            ? t("order.form.uploader.converted")
            : hasError
            ? t("order.form.uploader.error")
            : !hasImage
            ? t("order.form.uploader.converting")
            : t("order.form.uploader.converted")}
        </div>
        {modelSizes && <div>{modelSizes}</div>}
      </div>
      {modelCadFormatIsUnitless(cadFile) && (
        <>
          <span className="qa-uploadFileRow-unit unit-label">{t("order.form.units")}</span>
          <UnitsPicker
            id="qa-uploadFile-unitsPicker"
            currentUnits={uploadContainer.unit}
            onUnitsSelected={value => {
              uploadContainer.unit = value;
            }}
          />
        </>
      )}
      <>
        <span className="qa-uploadFileRow-quantity quantity-label">
          {t("order.form.uploader.quantity")}
        </span>
        <Input
          className="qa-uploadFileRow-quantityInput quantity"
          type="number"
          name="quantity"
          value={quantity}
          placeholder={1}
          onChange={handleQuantityChange}
        />
      </>
      <Icon
        className="qa-uploadFileRow-cancel"
        onClick={(event: SyntheticEvent) => {
          cancel();
          event.stopPropagation();
        }}
        name={"trash"}
      />
      {!hasImage && !hasError ? (
        <StyledProgress
          percent={progress}
          size="tiny"
          color={cadFile ? "green" : "blue"}
          attached="bottom"
          data-testid="progress"
        />
      ) : null}
    </CadFileRow>
  );
};
