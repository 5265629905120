import React from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import locale_de from "react-intl/locale-data/de";
import locale_en from "react-intl/locale-data/en";
import locale_es from "react-intl/locale-data/es";
import locale_fr from "react-intl/locale-data/fr";
import locale_it from "react-intl/locale-data/it";
import locale_ja from "react-intl/locale-data/ja";
import locale_ko from "react-intl/locale-data/ko";
import locale_ru from "react-intl/locale-data/ru";
import locale_zh from "react-intl/locale-data/zh";
import { isLanguageSupported, translations } from "./Translations";

// When adding a new language in data.json you need also to:
// 1) import it in this file: e.g. "import locale_it from 'react-intl/locale-data/it';"
// 2) add to ReactIntl locales array: e.g. addLocaleData([...locale_en, ...locale_it]);
// 3) import the locale for moment in "FormattedDatePicker.tsx"

addLocaleData([
  ...locale_de,
  ...locale_en,
  ...locale_es,
  ...locale_en,
  ...locale_fr,
  ...locale_it,
  ...locale_ja,
  ...locale_ko,
  ...locale_ru,
  ...locale_zh,
]);

const extractIso6391LanguageCode = (locale: string) => locale.toLowerCase().split(/[_-]+/)[0];

// A locale, aka IETF language tag, is a combination of language and region: e.g. en-US
let appLocale: string | undefined;

export const browser = {
  // For automated tests only
  userPreferredLocales: (): readonly string[] =>
    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
    navigator.languages,
};

export const clearApplicationLocale = (): void => {
  // For automated tests only
  appLocale = undefined;
};

let isUserLanguageSupported = false;

export const applicationLocale = (): string => {
  if (!appLocale) {
    // Only USA and some other small islands use the month before day format:
    // https://en.wikipedia.org/wiki/Date_format_by_country
    // So for all other countries we default to English (United Kingdom)
    // to have the dates formatted properly to dd/mm/yyyy.
    appLocale = "en-gb";
    const locales = browser.userPreferredLocales() || [];
    for (const navLocale of locales) {
      const languageCode = extractIso6391LanguageCode(navLocale);
      if (isLanguageSupported(languageCode)) {
        appLocale = navLocale;
        isUserLanguageSupported = true;
        break;
      }
    }
  }
  return appLocale;
};

export const DNT_LIST: { [key: string]: string } = {
  auto_grabcad_print: "GrabCAD Print",
  auto_shop: "Shop",
  auto_shops: "Shops",
  auto_admin_console: "GrabCAD Control",
  auto_GC_shop: "GrabCAD Shop",
  auto_streamline_pro: "Streamline Pro",
};

export const i18n = (node: JSX.Element | null): JSX.Element => {
  const locale = applicationLocale();
  let languageCode = extractIso6391LanguageCode(locale);
  const messagesForEnglishLanguage = translations.en;
  let messages = messagesForEnglishLanguage;
  const traditionalChineseTags = ["zh-TW", "zh-Hant-HK", "zh-Hant-MO", "zh-Hant-TW", "zh-Hant"];
  if (traditionalChineseTags.includes(locale)) {
    languageCode = "zh-TW"; // This is traditional Chinese.
    // All other Chinese lang codes can remain 'zh', which maps to simplified: 'zh-CH'
  }

  if (isUserLanguageSupported) {
    const messagesForSupportedLanguage = translations[languageCode];
    // merging keys from en translation json to handle non-translated keys
    messages = { ...messagesForEnglishLanguage, ...messagesForSupportedLanguage };
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      {node}
    </IntlProvider>
  );
};
