import React, { useContext, useState } from "react";
import classnames from "classnames";
import styled, { Dropdown, DropdownMenu, DropdownItem, isDarkBG } from "grabcad-ui-elements";
import { ApplicationContext, TranslateFunction } from "@/components/ApplicationProvider";
import { Notifier } from "@/utils/Notifier";
import { UPDATE_ORDER_STATUS } from "@/graphql/Mutations";
import { IOrderItem, IListOrder, IOrder } from "@/graphql/Fragments/Order";
import { UPDATE_ORDER_ITEM_STATUS } from "@/graphql/Mutations/OrderItem";
import { isTypename } from "@/utils/typeUtils";
import { ApolloError, MutationFunction, DataProxy, useApolloClient } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { Permission } from "@/utils/Permission";
import { IShopStatus } from "@/graphql/Fragments/ShopStatus";
import ReactGA from "react-ga";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { ORDER_DETAILS } from "@/graphql/Queries";

interface IOrderResponse {
  updateOrderShopStatus: {
    id: number;
    shopStatus: number;
  };
}

interface IOrderItemResponse {
  updateOrderItemShopStatus: {
    id: number;
    shopStatus: number;
  };
}

export const getLocalizedStatus = (t: TranslateFunction, key: string): string =>
  key ? t(`order.status.${key.toLowerCase()}`) : "";

export interface IStatusColors {
  submitted: string;
  in_review: string;
  approved: string;
  in_progress: string;
  completed: string;
  has_issues: string;
  cancelled: string;
  [key: string]: string;
}

export const statusColors: IStatusColors = {
  submitted: "#c7c7c7",
  in_review: "#c7abda",
  approved: "#8fd0ec",
  in_progress: "#c7abda",
  completed: "#a2d8a2",
  has_issues: "#ffbe73",
  cancelled: "#ff988f",
};

const MENU_ITEM_HEIGHT = 37;
export const StyledStatusDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    min-width: 0;
    &.dark {
      color: white;
    }
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .menu {
      max-height: none;
      > .item {
        /* semantic overrides */
        padding-right: 10px !important;
        height: ${MENU_ITEM_HEIGHT}px !important;
        display: flex;
        align-items: center;
      }
    }
    &.ui.disabled.dropdown {
      opacity: 1;
      > .dropdown.icon {
        display: none;
      }
    }
    .item {
      border-left: 5px solid white;
    }
  }
  &.ui.selection.visible.dropdown > .text:not(.default) {
    color: inherit;
  }
`;
const DEFAULT_STATUS_COLOR = "#CCCCCC";
export const getStatusColor = (status?: IShopStatus): string =>
  status?.color || // TODO: Add default shopStatus fixture to default context?
  (status?.appStatus
    ? status.appStatus && statusColors[status.appStatus.toLowerCase()]
    : DEFAULT_STATUS_COLOR);
export const getStatusName = (status: IShopStatus | undefined, t: TranslateFunction): string =>
  (status?.appStatus && getLocalizedStatus(t, status.appStatus)) || status?.name || "";

export const OrderStatus = ({
  entity,
  upward,
  availableHeight,
  readOnly,
}: {
  entity: IOrderItem | IListOrder;
  upward?: boolean;
  availableHeight?: number;
  readOnly?: boolean;
}): JSX.Element | null => {
  const { currentShop, t, ability } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const client = useApolloClient();

  const onChangeFunc = async (id: number, mutation: MutationFunction) => {
    await mutation({
      variables: { id: entity.id, shopStatus: id },
    });
  };
  const isOrderItem = isTypename<"Order" | "OrderItem">("OrderItem")(entity);
  const order = (isOrderItem ? (entity as IOrderItem).order : entity) as IOrder;
  const orderId = order.id;
  const isWritable =
    ability.can(`${Permission.WRITE}`, entity, "status") ||
    ability.can(`${Permission.WRITE}`, entity, "shopStatus");
  // ^^ 'status' keyed check can be removed in favor of 'shopStatus' after https://github.com/GrabCAD/shop-server/pull/393 is merged

  const getStatusById = (id: number) => currentShop.shopStatuses.find(s => s.id === id);
  const selectedShopStatus = getStatusById(entity.shopStatus);

  const handleOrderSuccess = (response: IOrderResponse, i18n: (key: string) => string) => {
    Notifier.success(
      i18n("order.list.status.updated") +
        getStatusName(getStatusById(response.updateOrderShopStatus.shopStatus), t)
    );
    ReactGA.event({
      category: "GcShop Order",
      action: "Order Status Changed",
      label: `Shop ${currentShop?.id}`,
    });
  };
  const handleOrderItemSuccess = (response: IOrderItemResponse, i18n: (key: string) => string) => {
    Notifier.success(
      i18n("order.list.status.updated") +
        getStatusName(getStatusById(response.updateOrderItemShopStatus.shopStatus), t)
    );
    ReactGA.event({
      category: "GcShop Order",
      action: "Order Part Status Changed",
      label: `Shop ${currentShop?.id}`,
    });
  };
  const mutationCompletedFunc = isOrderItem ? handleOrderItemSuccess : handleOrderSuccess;

  const canSetStatus =
    ability.can(Permission.SET_STATUS_WHEN_NOT_SUBMITTED, currentShop) ||
    !selectedShopStatus || // This should never happen in production, but it lets us be a little looser with test fixtures
    (selectedShopStatus.appStatus && selectedShopStatus.appStatus === "SUBMITTED");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  // 32px = height of closed dropdown:
  // https://github.com/GrabCAD/grabcad-ui-elements/blob/bc8d6586bcca748861da5c3cfd0ceb727ae2bf63/src/ui/General/Dropdown.tsx#L14
  const availableStatuses = currentShop.shopStatuses
    .filter(status => !status.isHidden && !status.dateDeleted && getStatusName(status, t))
    .filter(
      status =>
        (status.requestorCanSet && ability.can(Permission.SET_REQUESTER_STATUSES, currentShop)) ||
        ability.can(Permission.SET_OPERATOR_STATUSES, currentShop)
    )
    .sort((a, b) => a.displayOrder - b.displayOrder);

  return (
    <Mutation
      mutation={isOrderItem ? UPDATE_ORDER_ITEM_STATUS : UPDATE_ORDER_STATUS}
      onError={(err: ApolloError) => Notifier.error(err)}
      onCompleted={(response: IOrderResponse & IOrderItemResponse) =>
        mutationCompletedFunc(response, t)
      }
      update={async (cache: DataProxy) => fetchNewOrderEvents(cache, client, orderId)}
      refetchQueries={[{ query: ORDER_DETAILS, variables: { orderId } }]}
    >
      {(updateStatus: MutationFunction) => (
        <>
          <StatusDropdown
            statuses={availableStatuses}
            selectedStatus={selectedShopStatus}
            onSelect={status =>
              status.appStatus === "CANCELLED" &&
              !ability.can(Permission.SET_STATUS_WHEN_NOT_SUBMITTED, currentShop)
                ? setCancelModalOpen(true) // Inform requester that Cancelling is not undoable
                : onChangeFunc(status.id, updateStatus)
            }
            disabled={readOnly || !isWritable || !canSetStatus}
            upward={upward}
            availableHeight={availableHeight}
            classNames={
              entity.__typename === "Order"
                ? `qa-order-status-${orderId}`
                : `qa-orderPart-status-${entity.id}`
            }
          />
          {/* To prevent the execution of onclick event handler in the parent component OrderList */}
          <div onClick={event => event.stopPropagation()}>
            <ConfirmationModal
              headerIcon="trash"
              headerCopy={t("order.cancel.title")}
              bodyCopy={t(isOrderItem ? "orderItem.cancel.body" : "order.cancel.body")}
              cancelTranslationKey="order.cancel.withdraw"
              confirmTranslationKey="order.cancel.confirm"
              open={cancelModalOpen}
              onClose={() => setCancelModalOpen(false)}
              submitAction={() => {
                const status = currentShop?.shopStatuses.find(s => s.appStatus === "CANCELLED");
                if (!status) {
                  return;
                }
                onChangeFunc(status.id, updateStatus);
              }}
            />
          </div>
        </>
      )}
    </Mutation>
  );
};

export const StatusDropdown = ({
  statuses,
  selectedStatus,
  onSelect,
  disabled,
  upward,
  availableHeight,
  classNames,
}: {
  statuses: IShopStatus[];
  selectedStatus?: IShopStatus;
  onSelect: (status: IShopStatus) => void;
  disabled?: boolean;
  upward?: boolean;
  availableHeight?: number;
  classNames?: string;
}): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const dropdownHeight = 32 + statuses.length * MENU_ITEM_HEIGHT;
  statuses = statuses.sort((a, b) => a.displayOrder - b.displayOrder);
  return (
    <StyledStatusDropdown
      // Only allow opening upwards if sufficent available height, see GC-57614
      upward={
        upward || (!!availableHeight && (availableHeight > dropdownHeight ? undefined : false))
      } // NB: undefined is default/"auto"
      colors={statusColors}
      disabled={disabled}
      fluid
      text={getStatusName(selectedStatus, t)}
      className={classnames("selection", classNames, {
        dark: isDarkBG(getStatusColor(selectedStatus)),
      })}
      style={{ background: getStatusColor(selectedStatus) }}
      data-testid="statusDropdown"
    >
      <DropdownMenu>
        {statuses.map(status => (
          <DropdownItem
            key={status.id}
            value={status.id} // Only used for tests
            text={getStatusName(status, t)}
            onClick={() => onSelect(status)}
            selected={selectedStatus && selectedStatus.id === status.id}
            style={{ borderLeftColor: getStatusColor(status) }}
          />
        ))}
      </DropdownMenu>
    </StyledStatusDropdown>
  );
};
