import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import styled, {
  Grid,
  GridRow,
  GridColumn,
  Header,
  Dropdown,
  Checkbox,
  Loader,
} from "grabcad-ui-elements";
import { ShopMaterialsForm } from "@/components/Shop/Materials/Form/MaterialsForm";
import { RouteComponentProps, withRouter } from "react-router";
import { Notifier } from "@/utils/Notifier";
import { MATERIALS_BY_MACHINE, SHOP_DETAILS } from "@/graphql/Queries";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UPDATE_SHOP } from "@/graphql/Mutations/Shop";
import {
  ALL_MATERIAL_QUANTITIES,
  DEFAULT_TIME_UNIT,
  MATERIAL_UNITS,
  QUANTITY_DEFAULT_UNIT,
  ROUTES,
  SUPPORTLESS_TECHNOLOGIES,
  TIME_UNITS,
  getMachineTechnologyDefaultSupportUnit,
  getMachineTechnologyMaterialQuantity,
  getMachineTechnologySupportQuantity,
} from "@/shopConstants";
import { ShopState } from "@/graphql/Fragments/Shop";
import { ImageUpdateOrDeleteDropzone } from "@/components/Upload/ImageUpdateOrDeleteDropzone";
import { Entity, ImageType } from "@/graphql/Mutations/Upload";
import { IImage } from "@/graphql/Fragments/Image";
import {
  MachinesDefaultImages,
  DefaultGenericMachineImage,
  IMachineDefaultImage,
} from "@/components/Shop/Machines/DefaultImages";
import { ToastOptions } from "react-toastify";
import { ApolloError, MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { getTimeRateDropdownItems, getMaterialUnitsDropdownItemsMap } from "@/utils/DropdownUtils";
import { IMachineRate } from "@/graphql/Fragments/ShopRate";
import { CREATE_SHOP_RATE } from "@/graphql/Mutations/ShopRate";
import { GroupDropdown } from "@/components/Shop/Materials/Form/GroupDropdown";
import { LocalizedNumericInput } from "@/components/Shared/LocalizedNumericInput";
import ReactGA from "react-ga";
import { Permission } from "@/utils/Permission";
import { useCacheableMachineRate, useShopTechnologies } from "@/utils/queryHooks";
import { MAX_INT32 } from "../../../components/Order/ItemsList/shopRatesUtils";
import { ImageOverlayContainer } from "@/components/Upload/OverlayEditButton";

export const MachineDetails = withRouter(
  ({
    match: { params },
    history,
  }: RouteComponentProps<{ shopId: string; machineId: string }>): JSX.Element => {
    const shopId = +params.shopId;
    const { t, currentShop } = useContext(ApplicationContext);
    return (
      <Mutation<any, { input: { id: number; state: ShopState } }>
        mutation={UPDATE_SHOP}
        variables={{ input: { id: shopId, state: ShopState.PUBLISHED } }}
        onCompleted={() => {
          currentShop && currentShop.state === ShopState.DRAFT
            ? history.push(ROUTES.ROOT)
            : history.push(ROUTES.SHOP(shopId).MACHINES.INDEX);
        }}
        onError={(error: ApolloError) => Notifier.error(error)}
        refetchQueries={[{ query: SHOP_DETAILS, variables: { id: shopId } }]}
      >
        {finishShopSetup => (
          <MachineNameImageAndMaterials
            machineId={params.machineId}
            successMessage={
              currentShop && currentShop.state === ShopState.DRAFT ? (
                <>{t("shop.create.success")}</>
              ) : (
                t("shop.machines.new.success")
              )
            }
            onComplete={() => {
              finishShopSetup();
              currentShop && currentShop.state === ShopState.DRAFT
                ? ReactGA.event({
                    category: "GcShop",
                    action: "Created Shop",
                    label: `Shop ${shopId}`,
                  })
                : ReactGA.event({
                    category: "GcShop Machines",
                    action: "Added Machine in existing shop",
                    label: `Shop ${shopId}`,
                  });
            }}
            notifierOptions={
              currentShop && currentShop.state === ShopState.DRAFT ? { autoClose: 5000 } : {}
            }
          />
        )}
      </Mutation>
    );
  }
);
MachineDetails.displayName = "MachineDetails";

const RIGHT_INPUT_WIDTH = "86px";
const MachineImageColumn = styled(GridColumn)`
  flex: 200px 0 0;
  max-width: 200px;
  .image-preview {
    width: 175px;
    height: 175px;
  }
  h4,
  .flex-row {
    margin: 2rem 0 5px 0;
  }
  .flex-row {
    display: flex;
    align-items: center;
    h4 {
      flex: 1 1 auto;
      margin: 0 !important;
      padding-right: 5px;
    }
    .ui.input,
    .ui.dropdown {
      flex: 0 0 ${RIGHT_INPUT_WIDTH};
    }
  }
  .input-with-dropdown {
    display: flex;
    .ui.dropdown {
      flex: 0 0 ${RIGHT_INPUT_WIDTH};
      min-width: ${RIGHT_INPUT_WIDTH};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ui.input > input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none !important;
    }
  }
  .ui.dropdown {
    width: ${RIGHT_INPUT_WIDTH};
    border-color: #ccc; /* TODO: make this consistent within grabcad-ui-elements */
  }
  .ui.input > input {
    text-align: right;
  }

  .disabled-form {
    opacity: 0.4;
    pointer-events: none;
  }
`;
const MachineMaterialsColumn = styled(GridColumn)`
  flex: auto 1 1;
  max-width: 855px;
  padding-bottom: 70px;
`;

export const getDefaultMachineImage = (displayName: string): IMachineDefaultImage => {
  const defaultImageKey = displayName.replace(/ /g, "_");
  return MachinesDefaultImages[defaultImageKey] || DefaultGenericMachineImage;
};

interface IMachineImageAndMaterialsProps {
  machineId: string;
  successMessage: string | JSX.Element;
  onComplete?: () => void;
  notifierOptions?: ToastOptions;
}
export const MachineNameImageAndMaterials = ({
  machineId,
  successMessage,
  onComplete,
  notifierOptions,
}: IMachineImageAndMaterialsProps): JSX.Element | null => {
  const { t, currentShop, ability, formatPrice } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }
  const { shopTechnologies, allMachines, loadingMachineImages } = useShopTechnologies();
  const machine = allMachines.find(m => m.id === +machineId);
  const { machineRate } = useCacheableMachineRate(machine?.newestMachineRateId);
  const [imageId, setImageId] = useState<number | undefined>(undefined);
  const [machineRateOG, setMachineRateOG] = useState<IMachineRate | null>(null);
  const [machineBaseRate, setMachineBaseRate] = useState<number | undefined>(undefined);
  const [machineTimeRate, setMachineTimeRate] = useState<number | undefined>(undefined);
  const [machineTimeUnit, setMachineTimeUnit] = useState<TIME_UNITS>(DEFAULT_TIME_UNIT);
  const [materialUnit, setMaterialUnit] = useState<MATERIAL_UNITS | undefined>(undefined);
  const [supportUnit, setSupportUnit] = useState<MATERIAL_UNITS | undefined>(undefined);
  const [useSameUnit, setUseSameUnit] = useState<boolean>(); // default value depends on machine technology!

  useEffect(() => {
    machineRate && setCurrentMachineRate(machineRate);
  }, [machineRate?.id]);

  let currentImage = DefaultGenericMachineImage as Partial<IImage>;

  const setCurrentMachineRate = (rate: IMachineRate | null) => {
    if (rate) {
      setMachineBaseRate(rate.machineBaseRate !== null ? rate.machineBaseRate : undefined);
      setMachineTimeRate(rate.machineTimeRate !== null ? rate.machineTimeRate : undefined);
      setMachineTimeUnit(rate.machineTimeUnit);
      setMaterialUnit(rate.materialUnit);
      setSupportUnit(rate.supportUnit);
      setUseSameUnit(!rate.supportUnit);
    }

    setMachineRateOG({
      ...rate,
      // Nullable values come as null from GQL, but we use undefined for local state. Convert nulls to undefined:
      // TODO: More generic solution to swap undefined for null?
      machineBaseRate: rate?.machineBaseRate !== null ? rate?.machineBaseRate : undefined,
      machineTimeRate: rate?.machineTimeRate !== null ? rate?.machineTimeRate : undefined,
      materialUnit: rate?.materialUnit || undefined,
    } as IMachineRate);
  };

  const isMachineRateChanged = (): boolean =>
    (!machineRateOG &&
      (machineBaseRate != null ||
        machineTimeUnit !== DEFAULT_TIME_UNIT ||
        machineTimeRate != null)) ||
    (!!machineRateOG &&
      (machineBaseRate !== machineRateOG.machineBaseRate ||
        machineTimeUnit !== machineRateOG.machineTimeUnit ||
        machineTimeRate !== machineRateOG.machineTimeRate ||
        materialUnit !== machineRateOG.materialUnit ||
        supportUnit !== machineRateOG.supportUnit));

  const canSetRates = ability.can(Permission.WRITE_SHOP_RATES, currentShop);

  if (!machine) {
    return null;
  }
  currentImage =
    machine.image || (getDefaultMachineImage(machine.appMachineType.name) as Partial<IImage>);

  let supportUnitInput: JSX.Element | null = null;
  const shopTechnology = shopTechnologies.find(tech =>
    tech.shopMachines.find(m => m.id === +machineId)
  );
  const technologyName = shopTechnology?.appTechnology.name;

  // Quantity (volume / weight etc.) depends on technology
  const materialQuantities =
    getMachineTechnologyMaterialQuantity(technologyName, machine.appMachineType.name) ??
    ALL_MATERIAL_QUANTITIES;
  const supportQuantities =
    getMachineTechnologySupportQuantity(technologyName, machine.appMachineType.name) ??
    ALL_MATERIAL_QUANTITIES;
  // choose default unit based on default quantity for the technology
  const defaultMaterialUnit = QUANTITY_DEFAULT_UNIT[materialQuantities[0]];
  const defaultSupportUnit = getMachineTechnologyDefaultSupportUnit(
    technologyName,
    machine.appMachineType.name
  );

  const showSupportRates = !(technologyName && SUPPORTLESS_TECHNOLOGIES.includes(technologyName));
  if (showSupportRates) {
    if (useSameUnit === undefined || useSameUnit === null) {
      if (materialQuantities !== supportQuantities) {
        setUseSameUnit(false);
        setSupportUnit(supportUnit ?? defaultSupportUnit);
      } else {
        setUseSameUnit(true);
      }
    }
    supportUnitInput = (
      <>
        <div className="flex-row" style={{ marginTop: 10 }}>
          <Checkbox
            id="same-unit-checkbot"
            className="qa-sameUnitCheckbox"
            checked={useSameUnit}
            onChange={() => {
              if (!useSameUnit) {
                setSupportUnit(undefined);
              }
              setUseSameUnit(!useSameUnit);
            }}
          />
          <label htmlFor="same-unit-checkbot" style={{ fontSize: 12, marginLeft: 10 }}>
            {t("materials.form.sameUnit")}
          </label>
        </div>
        {!useSameUnit && (
          <div className="flex-row">
            <h4>{t("materials.form.supportUnit")}</h4>
            <GroupDropdown
              className="qa-supportUnitDropdown"
              onChange={value => setSupportUnit(value as MATERIAL_UNITS)}
              defaultValue={supportUnit || defaultSupportUnit}
              optionGroups={getMaterialUnitsDropdownItemsMap(t, supportQuantities)}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <Grid stackable>
      <GridRow>
        <GridColumn>
          <Header as="h2" className="page-header qa-machine-name-header">
            {machine.appMachineType.displayName}
          </Header>
        </GridColumn>
      </GridRow>
      <GridRow columns={2}>
        <MachineImageColumn>
          {loadingMachineImages ? (
            <ImageOverlayContainer className="image-preview" data-testid="imagePreview">
              <div className="loading">
                <Loader active={loadingMachineImages} size="large" />
              </div>
            </ImageOverlayContainer>
          ) : (
            <ImageUpdateOrDeleteDropzone
              onImageUploaded={uploadedImageId => {
                setImageId(uploadedImageId);
              }}
              onImageReset={() => {
                setImageId(0);
              }}
              imageType={ImageType.MACHINE_IMAGE}
              entity={Entity.MACHINE}
              currentImage={currentImage}
              menuPosition={"bottom center"}
              defaultImage={getDefaultMachineImage(machine.appMachineType.name) as Partial<IImage>}
              imageSize={"medium"}
            />
          )}
          <div className={classnames({ "disabled-form": !canSetRates })}>
            <h4>
              <span>{t("materials.form.timeRate")}</span>
            </h4>
            <div className="input-with-dropdown">
              <LocalizedNumericInput
                min={0}
                max={MAX_INT32} // limited by db/gql capability, amounts to $21474836.47
                className="qa-timeRateInput"
                placeholder={formatPrice(0) as string}
                currency
                onChange={setMachineTimeRate}
                value={machineTimeRate}
              />
              <Dropdown
                className="qa-timeUnitDropdown"
                selection
                value={machineTimeUnit}
                onChange={(_evt, { value }) => setMachineTimeUnit(value as TIME_UNITS)}
                options={getTimeRateDropdownItems(t)}
              />
            </div>
            <div className="flex-row">
              <h4>{t("materials.form.baseRate")}</h4>
              <LocalizedNumericInput
                min={0}
                max={MAX_INT32} // limited by db/gql capability, amounts to $21474836.47
                className="qa-baseRateInput"
                placeholder={formatPrice(0) as string}
                currency
                onChange={setMachineBaseRate}
                value={machineBaseRate}
              />
            </div>
            <div className="flex-row">
              <h4>{t("materials.form.materialUnit")}</h4>
              <GroupDropdown
                className="qa-materialUnitDropdown"
                onChange={value => setMaterialUnit(value as MATERIAL_UNITS)}
                defaultValue={materialUnit || defaultMaterialUnit}
                optionGroups={getMaterialUnitsDropdownItemsMap(t, materialQuantities)}
              />
            </div>
            {supportUnitInput}
          </div>
          {!canSetRates && (
            <p style={{ fontSize: 12, marginTop: 23 }}>
              {t("materials.form.onlyAdminsCanSetRates")}
            </p>
          )}
        </MachineImageColumn>
        <MachineMaterialsColumn>
          <Mutation
            mutation={CREATE_SHOP_RATE}
            onError={(err: ApolloError) => Notifier.error(err)}
            onCompleted={() => {
              Notifier.success(t("shop.materials.form.rates.success"), notifierOptions);
            }}
            refetchQueries={[
              { query: MATERIALS_BY_MACHINE, variables: { shopMachineId: +machineId } },
            ]}
          >
            {(createShopRates: MutationFunction) => (
              // TODO GC-88455: This should prob all be a single component instead of 2 components sharing a
              // "Finish" button and passing the createShopRates mutation + state as props.
              // Or perhaps the mutations should live in the outer component, and ShopMaterialsForm can become stateless?
              // We also have 0 unit tests exercising CREATE_SHOP_RATE!
              <ShopMaterialsForm
                successMessage={successMessage}
                onComplete={onComplete}
                imageId={imageId}
                notifierOptions={notifierOptions}
                createShopRates={canSetRates ? createShopRates : undefined}
                machineRateInput={
                  isMachineRateChanged() || // User has changed material rate fields
                  !machineRate // If no user input or existing rate, we need to save one with default material/support units
                    ? {
                        machineBaseRate: machineBaseRate,
                        machineTimeRate: machineTimeRate,
                        machineTimeUnit,
                        materialUnit: materialUnit || defaultMaterialUnit,
                        shopMachineId: +machineId,
                        supportUnit: useSameUnit ? undefined : supportUnit || defaultSupportUnit,
                      }
                    : undefined
                }
                materialUnit={materialUnit || defaultMaterialUnit}
                supportUnit={supportUnit || materialUnit || defaultMaterialUnit}
                showSupportRates={showSupportRates}
                disableRateInputs={!canSetRates}
              />
            )}
          </Mutation>
        </MachineMaterialsColumn>
      </GridRow>
    </Grid>
  );
};
MachineNameImageAndMaterials.displayName = "MachineNameImageAndMaterials";
