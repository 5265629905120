import { ApplicationContext } from "@/components/ApplicationProvider";
import { GET_LOGOUT_URL } from "@/graphql/Mutations/User";
import { Notifier } from "@/utils/Notifier";
import { isWindows10 } from "@/utils/isWindows10";
import { useQuery } from "@apollo/client";
import styled, { Beacon, Navbar } from "grabcad-ui-elements";
import { PlatformConstants } from "grabcad-ui-elements/dist/Theme";
import { GCApplication } from "grabcad-ui-elements/dist/ui/General/ApplicationLogo";
import React, { useContext } from "react";

// region CONSTANTS
export const NAVBAR_HEIGHT_PX = `${PlatformConstants}px`;
const OPEN_GCP_LINK = "gcprint://open/?skipDownload=true";
const GET_GCP_LINK = "https://www.grabcad.com/print";
const UPGRADE_LICENSE_URL = `https://hub.stratasys.com/s/category/store/
0ZG07000000wlFH?language=en_US&filter=%7B%22categoryId%22%3A%220ZG07000000wvveGAA%22%2C%22refinements%22%3A%5B%5D%7D`;
export const GETTING_STARTED_URL_OPERATOR = "https://grabcad-1.wistia.com/medias/wv4pxummbl";
export const GETTING_STARTED_URL_REQUESTER = "https://grabcad-1.wistia.com/medias/o3nzpp2wnd";
export const GETTING_STARTED_URL_DEFAULT = "https://grabcad-1.wistia.com/medias/d27e5fx73z";
// endregion

export function fullAnalyzeUrl(companyServerUrl: string, baseUrl: string) {
  const ANALYZE_ROUTE = "/login/analyze/";
  const analyzeUrl = new URL(ANALYZE_ROUTE, baseUrl);
  return new URL(`/login?return_to=${analyzeUrl}&expose_auth`, companyServerUrl).toString();
}

export const TopNavbarWrapper = ({ role }: ITopNavbarWrapperProps): JSX.Element => {
  const { t, currentShop, currentUser, allEnabledFeatureKeys } = useContext(ApplicationContext);
  const { userProfile, setAsLoggedOut } = currentUser;

  if (!userProfile) {
    return <></>;
  }
  const { data: logoutUrl } = useQuery<{ getLogoutUrl: string }>(GET_LOGOUT_URL);
  const adminUrl = userProfile.companyAdminUrl;
  const analyzeServerAddress = userProfile.company.analyzeServerAddress;
  const companyServerHost = userProfile.companyServerHost;
  const changePasswordUrl = `${companyServerHost}/update_password?return_to=${encodeURI(
    window.location.href
  )}`;
  const beaconProps = {
    userName: userProfile.name,
    userEmail: userProfile.email,
    companyName: userProfile.company,
    shopName: currentShop?.name ?? "",
    userRole: role ?? "",
    text: {
      contactSupport: t("feedback.contact_support"),
    },
  };

  const doLogout = () => {
    setAsLoggedOut();
    Notifier.success(t("user.logout.success"));
    window.location.replace((logoutUrl?.getLogoutUrl as string) + window.location.origin);
  };

  const openGcp = () => {
    if (isWindows10()) {
      const text = t("order.gcp.warning.open.gcp").replace(
        "{auto_GC_print}",
        `
                    <a href="${GET_GCP_LINK}" target="_blank">
                            GrabCAD Print
                    </a>
                `
      );
      const message = <div dangerouslySetInnerHTML={{ __html: text }}></div>;
      Notifier.warn(message);
      window.open(OPEN_GCP_LINK, "_self");
    } else {
      window.open(GET_GCP_LINK, "_blank");
    }
  };

  const controlApplication = {
    application: "GC_CONTROL" as GCApplication,
    name: "Control",
    logoBoxClassName: "admin-console-menu-item",
    onClick: () => {
      window.location.assign(adminUrl);
    },
  };
  const shopApplication = {
    application: "GC_SHOP" as GCApplication,
    name: "Shop",
    active: true,
    onClick: () => {},
  };
  const printApplication = {
    application: "GC_PRINT" as GCApplication,
    name: "Print",
    onClick: openGcp,
  };
  const sdkApplication = {
    application: "GC_DEVELOP" as GCApplication,
    name: "Develop",
    onClick: () => {
      window.location.assign(`${adminUrl}/sdk`);
    },
  };
  const analyzeApplication = {
    application: "GC_ANALYZE" as GCApplication,
    name: "Analyze",
    onClick: () => {
      analyzeServerAddress &&
        window.location.assign(`${fullAnalyzeUrl(companyServerHost, analyzeServerAddress)}`);
    },
  };

  const applications = [
    ...(role?.includes("Admin")
      ? [controlApplication, shopApplication, printApplication, sdkApplication]
      : [shopApplication, printApplication]),
    ...(analyzeServerAddress ? [analyzeApplication] : []),
  ];

  let gettingStartedURL = GETTING_STARTED_URL_DEFAULT;
  // role is untyped but I think it comes from userRoleFromShop() in Screen.tsx
  // The user might have multiple roles. The below is a deliberate hierarchy
  if (role?.match(".*Admin.*")) {
    gettingStartedURL = GETTING_STARTED_URL_DEFAULT;
  } else if (role?.match(".*Operator.*")) {
    gettingStartedURL = GETTING_STARTED_URL_OPERATOR;
  } else if (role?.match(".*Requester.*")) {
    gettingStartedURL = GETTING_STARTED_URL_REQUESTER;
  }

  const data = {
    helpDropdown: {
      pointing: true,
      options: [
        {
          key: 1,
          value: 1,
          text: t("feedback.help_link"),
          active: false,
          selected: false,
          icon: "help",
          onClick: () => window.open("https://help.grabcad.com/category/256-getting-started"),
        },
        {
          key: 2,
          value: 2,
          text: t("feedback.whats_new"),
          active: false,
          selected: false,
          icon: "gift",
          onClick: () => window.open("https://help.grabcad.com/article/263-grabcad-shop-updates"),
        },
        {
          key: 3,
          value: 3,
          active: false,
          selected: false,
          children: (
            <>
              <i aria-hidden="true" className="list icon"></i>
              <a
                href={`${process.env.PUBLIC_URL}/acknowledgements.txt`}
                download={"acknowledgements"}
              >
                {t("feedback.acknowledgements")}
              </a>
            </>
          ),
        },
        {
          key: 4,
          value: 4,
          text: t("feedback.getting_started"),
          active: false,
          selected: false,
          icon: "play",
          onClick: () => window.open(gettingStartedURL),
        },
      ],
    },
    userDropdown: {
      pointing: true,
      fullName: userProfile?.name ?? "",
      options: [
        {
          key: 1,
          text: t("top.navbar.update.password"),
          value: 1,
          active: false,
          selected: false,
          onClick: () => window.open(changePasswordUrl),
          icon: "lock",
        },
        {
          key: 2,
          text: t("top.navbar.logout"),
          value: 2,
          active: false,
          selected: false,
          onClick: doLogout,
          icon: "sign out alternate",
        },
      ],
    },
    mainDropdown: {
      pointing: true,
      text: t("top.navbar.contact.us"),
      options: [
        {
          key: 1,
          value: 1,
          selected: false,
          active: false,
          children: <Beacon {...beaconProps} />,
        },
        {
          key: 2,
          text: t("top.navbar.upgrade.license"),
          value: 2,
          selected: false,
          active: false,
          onClick: () => window.location.assign(UPGRADE_LICENSE_URL),
          icon: "cart",
        },
        {
          key: 3,
          text: t("top.navbar.request.demo"),
          value: 3,
          selected: false,
          active: false,
          onClick: () =>
            window.location.assign(
              "https://outlook.office365.com/book/GrabCADStreamlineProGettingStartedTraining@stratasysinc.onmicrosoft.com/s/Lhuf1Ceyc0qG-mbLllgIOw2"
            ),
          icon: "video",
        },
      ],
    },
    applications,
    featureKeys: allEnabledFeatureKeys(),
  };

  return (
    <StyledTopNavbarWrapper>
      <Navbar {...data} />
    </StyledTopNavbarWrapper>
  );
};

interface ITopNavbarWrapperProps {
  role?: string;
}

const StyledTopNavbarWrapper = styled.div`
  flex: 0 0 auto;
`;
