import { ApplicationContext } from "@/components/ApplicationProvider";
import { List, ListItem } from "grabcad-ui-elements";
import { IOrderItem } from "@/graphql/Fragments/Order";
import React, { useContext, useEffect, useState } from "react";
import { UpdateOrderItemType, UpdateOrderItemVariables } from "@/graphql/Mutations/OrderItem";
import ReactGA from "react-ga";
import { ITechnologyMateralColorIds } from "@/utils/DropdownUtils";
import { ShopMaterialPicker } from "../Form/ShopMaterialPicker";
import { ShopTechnologyPicker } from "../Form/ShopTechnologyPicker";
import { ShopMaterialColorPicker } from "../Form/ShopMaterialColorPicker";
import { ConfirmationModal } from "@/components/Shared/ConfirmationModal";
import { useShopTechnologies } from "@/utils/queryHooks";
import { getCadModelName } from "@/utils/GeneralUtils";

interface ITechnologyMaterialColorSelectorsProps {
  orderId: number;
  item: IOrderItem;
  canEdit: boolean;
  updateOrderItem: (vars: UpdateOrderItemVariables) => void;
}

export const TechnologyMaterialColorSelectors = ({
  item,
  orderId,
  canEdit,
  updateOrderItem,
}: ITechnologyMaterialColorSelectorsProps): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const { allMachines, allMaterials } = useShopTechnologies();
  const getMaterialMachine = (shopMaterialId: number | null) =>
    allMachines.find(mach => mach.materials.some(mat => mat.id === shopMaterialId));
  const itemShopMachine = getMaterialMachine(item.shopMaterialId);
  const itemShopMaterial = allMaterials.find(material => material.id === item.shopMaterialId);
  const [itemShopTechnologyId, setItemShopTechnologyId] = useState(
    item.shopTechnologyId || undefined
  );
  const [itemShopMaterialId, setItemShopMaterialId] = useState(item.shopMaterialId || undefined);
  const [itemShopMaterialColorId, setItemShopMaterialColorId] = useState(
    item.shopMaterialColorId || undefined
  );

  // This disables dropdowns when Order query is being refetched.
  // This is necessary to allow external updates and avoid multiple in-flight queries, which can cause a race condition.
  const [queryInFlight, setQueryInFlight] = useState(false);

  const [idsForConfirmationModal, setIdsForConfirmationModal] = useState<
    ITechnologyMateralColorIds | undefined
  >();

  useEffect(() => {
    setQueryInFlight(false);
    // Refetched items can update local state
    setItemShopTechnologyId(item.shopTechnologyId || undefined);
    setItemShopMaterialId(item.shopMaterialId || undefined);
    setItemShopMaterialColorId(item.shopMaterialColorId || undefined);
  }, [item]);

  const onDropDownChange = (ids: ITechnologyMateralColorIds) => {
    const hasOldMaterialRate =
      !!itemShopMaterial &&
      !!item.materialRate &&
      itemShopMaterial.newestMaterialRateId !== item.materialRate.id;
    const hasOldMachineRate =
      !!itemShopMachine &&
      !!item.machineRate &&
      itemShopMachine.newestMachineRateId !== item.machineRate.id;

    if (
      (hasOldMaterialRate || hasOldMachineRate) &&
      (!!ids.shopMaterialId || !!ids.shopTechnologyId)
    ) {
      setIdsForConfirmationModal(ids);
    } else {
      handleMutation(ids);
    }
  };

  const handleMutation = (ids: ITechnologyMateralColorIds) => {
    const itemUpdates: UpdateOrderItemType = ids;

    itemUpdates.shopTechnologyId &&
      ReactGA.event({
        category: "GcShop Order",
        action: "Edit Order Part Technology",
        label: `Order ${orderId}`,
      });

    itemUpdates.shopMaterialId &&
      ReactGA.event({
        category: "GcShop Order",
        action: "Edit Order Part Material",
        label: `Order ${orderId}`,
      });

    itemUpdates.shopMaterialColorId &&
      !itemUpdates.shopMaterialId &&
      ReactGA.event({
        category: "GcShop Order",
        action: "Edit Order Part Material Color",
        label: `Order ${orderId}`,
      });

    // Null values are sent to server for resetting, local null state is stored as undefined to keep interfaces clean.
    // Bottom-up order seems important here for auto-selecting w/ single mutation. (As if state updates not batched?)
    itemUpdates.shopMaterialColorId !== undefined &&
      setItemShopMaterialColorId(itemUpdates.shopMaterialColorId || undefined);
    itemUpdates.shopMaterialId !== undefined &&
      setItemShopMaterialId(itemUpdates.shopMaterialId || undefined);
    itemUpdates.shopTechnologyId !== undefined &&
      setItemShopTechnologyId(itemUpdates.shopTechnologyId || undefined);

    updateOrderItem({
      id: item.id,
      input: itemUpdates,
    });
    setQueryInFlight(true);
  };

  return (
    <List relaxed>
      <ListItem>
        <div className="item-label">{t("order.items.technology")}</div>
        <ShopTechnologyPicker
          shopTechnologyId={itemShopTechnologyId}
          onChange={onDropDownChange}
          disabled={!!item.jobId || !canEdit || queryInFlight}
          data-tooltip={!!item.jobId ? t("order.items.job.tooltip.technology") : null}
          data-position="left center"
        />
      </ListItem>

      <ListItem>
        <div className="item-label">{t("order.items.material")}</div>
        <ShopMaterialPicker
          shopTechnologyId={itemShopTechnologyId}
          shopMaterialId={itemShopMaterialId}
          onChange={onDropDownChange}
          disabled={!!item.jobId || !canEdit || queryInFlight}
          data-tooltip={!!item.jobId ? t("order.items.job.tooltip.material") : null}
          data-position="left center"
        />
      </ListItem>
      <ListItem>
        <div className="item-label">{t("order.items.color")}</div>
        <ShopMaterialColorPicker
          shopTechnologyId={itemShopTechnologyId}
          shopMaterialId={itemShopMaterialId}
          shopMaterialColorId={itemShopMaterialColorId}
          onChange={onDropDownChange}
          disabled={!!item.jobId || !canEdit || queryInFlight}
          data-tooltip={!!item.jobId ? t("order.items.job.tooltip.color") : null}
          data-position="left center"
        />
      </ListItem>
      <ConfirmationModal
        headerIcon={"exclamation circle"}
        headerCopy={t("order.items.rates.confirmUpdate.header")}
        bodyTitle={t("order.items.rates.confirmUpdate.bodyTitle", {
          partName: getCadModelName(item),
          machineType: itemShopMachine?.appMachineType.displayName,
          material: itemShopMaterial?.appMaterial.displayName,
        })}
        bodyCopy={t("order.items.rates.confirmUpdate.body")}
        cancelTranslationKey={"general.cancel"}
        confirmTranslationKey={"order.items.rates.popup.footer.update"}
        open={!!idsForConfirmationModal}
        onClose={() => setIdsForConfirmationModal(undefined)}
        submitAction={() => {
          idsForConfirmationModal && handleMutation(idsForConfirmationModal);
        }}
      />
    </List>
  );
};
