import gql from "graphql-tag";
import { GRAPHQL_ORDER_STATUS } from "@/screens/Shop/Order/New/New";

export interface IShopStatus {
  id: number;
  shopId: number;
  appStatus?: GRAPHQL_ORDER_STATUS;
  displayOrder: number;
  name?: string;
  color?: string;
  isHidden?: boolean;
  isActive?: boolean;
  operatorCanSet?: boolean;
  requestorCanSet?: boolean;
  userId?: number;
  dateDeleted: Date;
}

export const ShopStatusFragments = {
  status: gql`
    fragment shopStatusFields on ShopStatus {
      id
      name
      appStatus
      displayOrder
      color
      isActive
      isHidden
      operatorCanSet
      requestorCanSet
      dateDeleted
      __typename
    }
  `,
};
