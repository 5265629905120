import React, { useContext } from "react";
import styled, { Icon, Popup, Segment } from "grabcad-ui-elements";
import {
  AssetType,
  AutoEstimation,
  AutoEstimationAsset,
} from "../../../../graphql/Fragments/AutoEstimation";
import { Snapshot } from "../../../../components/Shared/Snapshot";
import { ApplicationContext } from "../../../../components/ApplicationProvider";

export interface IPartPreviewProps {
  autoEstimationId?: number | null;
  autoEstimations?: AutoEstimation[] | null;
  snapshotAsset?: AutoEstimationAsset;
}

const StyledPreview = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 10px;
  .centered {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .auto-estimation-snapshot-error {
    padding: 200px;
  }
  div {
    img {
      max-height: 500px;
    }
  }
`;

export const PartPreview = ({
  autoEstimationId,
  autoEstimations,
  snapshotAsset,
}: IPartPreviewProps): JSX.Element => {
  const { t, messageExists } = useContext(ApplicationContext);

  const autoEstimation = autoEstimations?.find(ae => ae.id === autoEstimationId);
  const resultData = autoEstimation?.resultData;
  let errorMessage = null;
  const error = resultData?.error ?? resultData?.estimates.find(e => e.error)?.error;
  if (error) {
    const messageKey = `auto_estimation.error.${error.name}`;
    errorMessage = messageExists(messageKey)
      ? t(messageKey)
      : t("auto_estimation.error.unexpected");
  }

  if (!snapshotAsset) {
    snapshotAsset = autoEstimation?.autoEstimationAssets?.find(
      a => a.asset.assetType === AssetType.AUTO_ESTIMATION_SNAPSHOT
    );
  }

  if (!errorMessage) {
    return (
      <StyledPreview>
        <Segment compact>
          <Snapshot asset={snapshotAsset} />
        </Segment>
      </StyledPreview>
    );
  } else {
    return (
      <StyledPreview>
        <Segment compact className="auto-estimation-snapshot-error">
          <Popup
            trigger={
              <span>
                <Icon name="warning sign" color="red" size="huge" />
              </span>
            }
            content={t("auto_estimation.error.snapshot")}
            position={"right center"}
            inverted
          />
        </Segment>
      </StyledPreview>
    );
  }
};
