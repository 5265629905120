import gql from "graphql-tag";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { JobFragments } from "@/graphql/Fragments/Job";
import { IUser } from "../Fragments/User";
import { AutoEstimation } from "../Fragments/AutoEstimation";

export interface IShopRoute {
  id: number;
  shopTechnologyId: number;
  shopRouteSteps: IShopRouteStep[];
}
export interface IShopRouteStep {
  id: number;
  index: number;
  appRouteStep: {
    iconFilename: string;
    translationKey: string;
  };
}
export interface IOrderItemJobRouteStepHistory {
  id: number;
  orderItemId: number;
  quantityMoved: number;
  toShopRouteStepId: number;
  fromShopRouteStepId: number | null;
  dateCreated: string;
  dateDeleted: string | null;
  __typename?: "OrderItemJobRouteStepHistory";
}
export interface IShopJob {
  id: number;
  name: string;
  shopRouteId: number;
  dateCreated: string;
  dateCompleted: string | null;
  dateDeleted: Date | null;
  shopTechnologyId?: number | null;
  shopMachineId?: number | null;
  shopMaterialId?: number | null;
  shopMaterialColorId?: number | null;
  user: Partial<IUser>;
  orderItems: IOrderItem[];
  orderItemJobRouteStepHistories: IOrderItemJobRouteStepHistory[];
  autoEstimationId?: number | null;
  autoEstimation?: AutoEstimation | null;
  __typename?: "Job";
}

export const SHOP_JOBS = gql`
  query loadShopJobs($id: Int!, $active: Boolean!) {
    loadShopJobs(shopId: $id, active: $active) {
      ...jobFields
    }
  }
  ${JobFragments.job}
`;

export const SHOP_ROUTES = gql`
  query loadShopRoutes($id: Int!) {
    loadShopRoutes(shopId: $id) {
      id
      shopTechnologyId
      shopRouteSteps {
        id
        index
        appRouteStep {
          id
          iconFilename
          translationKey
        }
      }
    }
  }
`;

export const JOB_COUNTS = gql`
  query jobCountsByShop($id: Int!) {
    jobCountsByShop(shopId: $id) {
      activeJobs
      completedJobs
    }
  }
`;

export const JOB_DETAILS = gql`
  query job($id: Int!) {
    job(id: $id) {
      ...jobWithEstimationFields
    }
  }
  ${JobFragments.jobWithAutoEstimationFields}
`;
