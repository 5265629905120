import defaultShopLogo from "@/assets/default-image-500px-sq.png";
import { IShop, ShopState } from "@/graphql/Fragments/Shop";
import React, { useContext } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { ShopImage } from "../Shared/ShopImage";
import { IImage } from "@/graphql/Fragments/Image";

interface IShopLogoProps {
  shop: Partial<IShop>;
  size?: ShopLogoSIZES;
  rounded?: boolean;
  className?: string | undefined;
}

export type ShopLogoSIZES = "small" | "medium" | "original";

export const ShopLogo = ({
  shop: { logo, state, name },
  size,
  rounded,
  className,
}: IShopLogoProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const defaultImage = {
    small: { location: defaultShopLogo },
    medium: { location: defaultShopLogo },
    original: { location: defaultShopLogo },
    __typename: "Image",
  } as Partial<IImage>;

  let imgLabel;
  switch (state) {
    case ShopState.DRAFT:
      imgLabel = {
        color: "orange",
        content: t("shop.draft.label"),
        ribbon: "right",
        size: size === "small" ? "mini" : undefined,
      };
      break;
    case ShopState.SAMPLE:
      imgLabel = {
        color: "orange",
        content: t("shop.sample.label"),
        ribbon: "right",
        size: size === "small" ? "mini" : undefined,
      };
      break;
    default:
      break;
  }

  return (
    <ShopImage
      image={logo}
      defaultImage={defaultImage}
      style={{ height: size === "medium" ? "200px" : "" }}
      size={size}
      className={className}
      label={imgLabel}
      alt={name}
      rounded={rounded}
    />
  );
};
ShopLogo.displayName = "ShopLogo";
