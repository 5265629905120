import styled, { Button, Modal } from "grabcad-ui-elements";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "@/components/ApplicationProvider";
import ReactGA from "react-ga";

import { ModalNavBar, NavBarItem } from "../Shared/ModalNavBar";
import { BuildReport } from "./BuildReport";
import { IShopJob } from "../../graphql/Queries/Job";
import { IShopMaterial } from "../../graphql/Queries/Material";
import { TIME_UNITS } from "../../shopConstants";
import { IAutoEstimationParametersProps } from "../Order/Items/AutoEstimationParameters";
import { PartsOnTray } from "./PartsOnTray";
import { TrayPreview } from "./TrayPreview";

const StyledJobEstimatesModal = styled(Modal)`
  &.ui.modal.job-estimates-modal {
    width: 80%;
    max-width: 1200px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
    > .close {
      color: black;
      margin-right: -7px;
    }
    .header {
      color: black;
    }
    .sub-header {
      font-size: 18px;
      padding: 20px 0px 0px 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: clip;
      span {
        margin-left: 4px;
      }
    }
    > .content {
      color: black;
      padding: 0;
    }
    .inner-content {
      overflow: auto;
    }
    .actions {
      padding: 20px 20px;
      height: 78px;
      .cancel {
        float: left;
        margin: 0;
      }
      .submit {
        float: right;
      }
    }
  }
`;

export enum JobEstimatesModalTab {
  buildReport,
  trayPreview,
  partsOnTray,
}

export interface IJobEstimatesModalProps {
  isOpen: boolean;
  job: IShopJob;
  tab: JobEstimatesModalTab;
  onClose(): void;
  shopMaterial: IShopMaterial | undefined;
  // these correspond to the currently selected estimation
  // In future we will probably have to calculated them for each estimation inside the modal
  estimatedMaterial: number | undefined;
  estimatedSupport: number | undefined;
  estimatedTime: number | undefined;
  materialUnitLabel: string;
  supportUnitLabel: string;
  timeUnit: TIME_UNITS;
  detailedProps: IAutoEstimationParametersProps | undefined; // for "view details" -> AutoEstimationParameters component
}

/**
 * Jobs estimate modal, containing various possible tabs
 */
export const JobEstimatesModal = (props: IJobEstimatesModalProps): JSX.Element | null => {
  const { t, currentShop } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  const [activeTab, setActiveTab] = useState<JobEstimatesModalTab>(props.tab);

  useEffect(() => {
    setActiveTab(props.tab);
  }, [props.tab]);

  const handleTabClick = (newTab: JobEstimatesModalTab) => {
    if (activeTab !== newTab) {
      setActiveTab(newTab);
    }
  };

  const navItems: NavBarItem<JobEstimatesModalTab>[] = [
    {
      type: JobEstimatesModalTab.buildReport,
      name: t("shop.job.jobEstimatesModal.nav.buildReport"),
      disabled: false,
      hidden: false,
    },
    {
      type: JobEstimatesModalTab.trayPreview,
      name: t("shop.job.jobEstimatesModal.nav.trayPreview"),
      disabled: false,
      hidden: false,
    },
    {
      type: JobEstimatesModalTab.partsOnTray,
      name: t("shop.job.jobEstimatesModal.nav.partsOnTray"),
      disabled: false,
      hidden: false,
    },
  ];

  return (
    <StyledJobEstimatesModal
      basic
      size="small"
      open={props.isOpen}
      className="job-estimates-modal"
      onClose={props.onClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <h3>{t("shop.job.jobEstimatesModal.title")}</h3>
      </Modal.Header>
      <Modal.Content>
        <div className="sub-header">
          <b>{t("shop.job.jobEstimatesModal.jobName")}</b>
          <span>{props.job.name}</span>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <ModalNavBar<JobEstimatesModalTab>
            navItems={navItems}
            activeTab={activeTab}
            onTabSelect={handleTabClick}
          />
        </div>
        <div className="inner-content">
          {activeTab === JobEstimatesModalTab.buildReport && (
            <BuildReport
              job={props.job}
              onPreviewClick={() => setActiveTab(JobEstimatesModalTab.trayPreview)}
              estimatedMaterial={props.estimatedMaterial}
              estimatedSupport={props.estimatedSupport}
              estimatedTime={props.estimatedTime}
              shopMaterial={props.shopMaterial}
              materialUnitLabel={props.materialUnitLabel}
              supportUnitLabel={props.supportUnitLabel}
              timeUnit={props.timeUnit}
              detailedProps={props.detailedProps}
            />
          )}
          {activeTab === JobEstimatesModalTab.partsOnTray && <PartsOnTray job={props.job} />}
          {activeTab === JobEstimatesModalTab.trayPreview && <TrayPreview job={props.job} />}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="qa-jobEstimatesModal-close"
          className="submit"
          onClick={() => {
            props.onClose();
            ReactGA.event({
              category: "GcShop JobEstimatesModal",
              action: "Clicking close",
              label: `Shop ${currentShop.id}`,
            });
          }}
        >
          {t("order.items.rates.popup.footer.close")}
        </Button>
      </Modal.Actions>
    </StyledJobEstimatesModal>
  );
};
