import React, { useContext } from "react";
import { Header } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { LogOutButton } from "@/components/User/Login/Button/LogoutButton";
import { StyledLicenseExpiredWrapper } from "./LicenseExpiredPage";
import { LicenseRole } from "@/graphql/Queries/UserLicense";

export const ScreensNoLicensePage = (): JSX.Element => {
  const { t, licenseError } = useContext(ApplicationContext);
  let errorMessage: string | JSX.Element = t("license.no_license_seats.nonAdmin");

  let headerMessage = t("license.no_license_seats.header");
  if (licenseError) {
    if (licenseError.role === LicenseRole.Admin) {
      headerMessage = t("license.expired.header");
      errorMessage = (
        <span
          dangerouslySetInnerHTML={{
            __html: t("license.expired.admin", {
              email: '<a href="mailto:shop@grabcad.com">shop@grabcad.com</a>',
            }),
          }}
        />
      );
    } else if (licenseError.role === LicenseRole.MixedAdmin) {
      errorMessage = (
        <span
          dangerouslySetInnerHTML={{
            __html: t("license.no_license_seats.admin", {
              adminConsole: `<a href="https://company.grabcad.com/">${t(
                "layout.shopSidebar.admin"
              )}</a>`,
              email: '<a href="mailto:shop@grabcad.com">shop@grabcad.com</a>',
            }),
          }}
        />
      );
    }
  }

  return (
    <StyledLicenseExpiredWrapper>
      <div className={"license-box"}>
        <Header as={"h1"}>{headerMessage}</Header>
        <div className={"body"} data-testid="errorMessage">
          {errorMessage}
        </div>
        <div className={"footer"}>
          <LogOutButton />
        </div>
      </div>
    </StyledLicenseExpiredWrapper>
  );
};
