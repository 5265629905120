import React, { useContext } from "react";
import { SelectionDropdown } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import classnames from "classnames";
import { IShopMachine } from "@/graphql/Fragments/ShopMachine";
import { IShopMaterial } from "@/graphql/Queries/Material";
import { useShopTechnologyById } from "@/utils/queryHooks";
import machineIcon from "../../../assets/icons/machineIcon.svg";
import { getIcon } from "../ItemsList/CadItemDetails";

// Note: This component shows a dropdown of machines that support a specific material by name.
export const ShopMachinePickerByMaterial = ({
  shopMaterialId,
  currentMachine, // TODO - remove this! It can/should be derived from shopMaterialId
  shopTechnologyId,
  onMachineSelected,
  onMaterialSelected,
  disabled,
  showIcon,
  tooltipPosition,
  ...rest
}: {
  shopMaterialId?: number | null;
  currentMachine?: number | null;
  shopTechnologyId?: number | null;
  onMachineSelected?: (value: number) => void; // TODO - remove this!
  onMaterialSelected?: (value: number) => void;
  showIcon?: boolean;
  disabled?: boolean;
  tooltipPosition?: string;
}): JSX.Element | null => {
  const { t } = useContext(ApplicationContext);
  const { loading, shopTechnology } = useShopTechnologyById(shopTechnologyId);
  const materials: IShopMaterial[] = shopTechnology
    ? shopTechnology.shopMachines.reduce(
        (acc, val) => acc.concat(val.materials),
        [] as IShopMaterial[]
      )
    : []; // This needs to include materials from deleted machines since they may have been selected

  const material = materials.find(mat => mat.id === shopMaterialId);
  const machines =
    (shopTechnology &&
      material &&
      shopTechnology.shopMachines
        .filter(machine => !machine.dateDeleted)
        .filter(machine =>
          machine.materials
            .filter(mat => mat.shopMaterialColors.length)
            .map(mat => mat.appMaterial.name)
            .includes(material.appMaterial.name)
        )) ||
    [];

  const machineOptions = machines.map((machine: IShopMachine) => ({
    key: `sm-${machine.id}`,
    value: machine.id,
    text: machine.appMachineType.displayName,
  }));

  // If only one machine is available, auto-select it.
  if (!currentMachine && machineOptions.length === 1) {
    onMachineSelected?.(machineOptions[0].value);
  }

  const selectedMachinelValid =
    !!currentMachine && machineOptions.find(option => option.value === currentMachine);
  const selectedMachine = shopTechnology?.shopMachines.find(
    machine => machine.id === currentMachine
  );

  return (
    <div
      data-tooltip={
        !selectedMachinelValid && selectedMachine ? t("order.items.machine.unavailable") : undefined
      }
      data-position={tooltipPosition ? tooltipPosition : "right center"}
      {...rest}
    >
      <SelectionDropdown
        icon={showIcon && getIcon(machineIcon)}
        loading={loading}
        className={classnames({ "no-selection": !currentMachine }, "qa-dropdown-machine")}
        value={selectedMachinelValid && currentMachine}
        onChange={(_evt, { value }) => {
          onMachineSelected?.(value as number);
          const machine: IShopMachine | undefined = machines.find(ma => ma.id === value);
          const newMaterial =
            material &&
            machine &&
            machine.materials.find(mat => mat.appMaterial.name === material.appMaterial.name);
          const materialId = newMaterial?.id;
          materialId && onMaterialSelected && onMaterialSelected(materialId);
        }}
        options={machineOptions}
        disabled={disabled || loading || machines.length <= 1}
        placeholder={
          !selectedMachinelValid && selectedMachine
            ? selectedMachine.appMachineType.displayName
            : undefined
        }
        data-testid="dropdown-machine"
      />
    </div>
  );
};
