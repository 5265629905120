import styled from "grabcad-ui-elements";

export const PlaceHolder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0 0 20px;
  img,
  p {
    margin: 0 auto;
  }
  p {
    max-width: 350px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
  }
  p,
  h2 {
    color: #666666;
  }
`;
