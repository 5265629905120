import { ApplicationContext } from "@/components/ApplicationProvider";
import styled, { Button, Header, Modal } from "grabcad-ui-elements";
import React, { useContext } from "react";

interface IAnchorWithConfirmationProps {
  headerIcon?: string;
  headerCopy: JSX.Element | string;
  bodyTitle?: JSX.Element | string;
  bodyCopy: JSX.Element | string;
  cancelTranslationKey: string;
  confirmTranslationKey: string;
  open: boolean;
  nonDestructive?: boolean;
  onClose(): void;
  submitAction(): any;
}

const StyledModal = styled(Modal)`
  /* Semantic overrides. TODO: move to grabcad-ui-components if another inverted button is needed */
  button.ui.button.inverted {
    box-shadow: none !important;
    border: 1px solid hsl(0, 0%, 80%);
    border-bottom: 2px solid hsl(0, 0%, 64%);
    background-color: #262626;
  }
`;

/**
 * List of supported icons = https://react.semantic-ui.com/elements/icon/
 */
export const ConfirmationModal = ({
  headerIcon,
  headerCopy,
  bodyTitle,
  bodyCopy,
  cancelTranslationKey,
  confirmTranslationKey,
  open,
  onClose,
  submitAction,
  nonDestructive,
}: IAnchorWithConfirmationProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  return (
    <StyledModal basic size="small" open={open} onClose={onClose} data-testid="confirmationModal">
      <Header icon={headerIcon} content={headerCopy} />
      <Modal.Content>
        <h3>{bodyTitle}</h3>
        <p>{bodyCopy}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="qa-modal-cancel"
          secondary={!nonDestructive}
          inverted={nonDestructive}
          onClick={onClose}
        >
          {t(cancelTranslationKey)}
        </Button>
        <Button
          id="qa-modal-confirm"
          negative={!nonDestructive}
          secondary={nonDestructive}
          onClick={() => {
            submitAction();
            onClose();
          }}
        >
          {t(confirmTranslationKey)}
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};
