import gql from "graphql-tag";

export const AppMachineTypeFragments = {
  appMachineType: gql`
    fragment appMachineTypeFields on AppMachineType {
      id
      name
      displayName
      canEstimate
      __typename
    }
  `,
};

export interface IAppMachineType {
  id: number;
  name: string;
  displayName: string;
  canEstimate: number;
  __typename?: string;
}
