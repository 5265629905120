import gql from "graphql-tag";

export enum LicenseRole {
  Admin = "admin",
  MixedAdmin = "mixed-admin",
  NonAdmin = "non-admin",
}

export const SHOP_LICENSE_STATUS_QUERY = gql`
  query GetShopLicenseStatus {
    shopLicenseStatus {
      activeLicenses {
        name
        endDate
        state
        types
      }
      expiringLicenses {
        types
        expiresIn
      }
    }
  }
`;
