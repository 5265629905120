import gql from "graphql-tag";
import { IImage, ImageTypeName } from "@/graphql/Fragments/Image";
import { ConversionStatus } from "@/graphql/Fragments/CadModel";
import { ITypeNamed } from "@/graphql/Fragments/base";
import { UnitType } from "@/utils/DropdownUtils";

export enum Entity {
  SHOP = "SHOP",
  MACHINE = "MACHINE",
  ORDER_ITEM = "ORDER_ITEM",
}

export enum ImageType {
  LOGO = "LOGO",
  MACHINE_IMAGE = "MACHINE_IMAGE",
}

// NB: Keep in sync with src/graphql/Fragments/Image.ts : IImageFragment
export const UPLOAD_IMAGE = gql`
  mutation uploadImage($files: [Upload!]!, $imageType: ImageType!, $entity: Entity!, $shopId: Int) {
    uploadImage(files: $files, imageType: $imageType, entity: $entity, shopId: $shopId) {
      id
      original {
        id
        location
      }
      small {
        id
        location
      }
      medium {
        id
        location
      }
    }
  }
`;

export type PossibleUploadTypes = GenericFileTypeName | CadModelTypeName | ImageTypeName;

export interface IAsset {
  id: number;
  filename: string;
  mimetype: string;
  location: string;
  originalName: string;
  assetType?: string;
  key: string;
  __typename?: string;
}

export type GenericFileTypeName = "GenericFile";

export interface IGenericFile extends ITypeNamed<GenericFileTypeName> {
  id: number;
  asset: IAsset;
}

export type CadModelTypeName = "CadModel";

export type Size = {
  x?: number;
  y?: number;
  z?: number;
};

export interface ICadFile extends ITypeNamed<CadModelTypeName> {
  id: number;
  conversionStatus: ConversionStatus;
  preview?: Partial<IImage>;
  unit?: UnitType | null;
  size?: Size;
  original: {
    asset: Partial<IAsset>;
    __typename?: string;
  };
}

export interface IUploadSuccessResponse {
  uploadSuccess: ICadFile[];
}

export const CAD_SUBSCRIPTION = gql`
  subscription onCadProcessed($id: Int!, $shopId: Int!) {
    cadProcessed(id: $id, shopId: $shopId) {
      id
      conversionStatus
      unit
      size {
        x
        y
        z
      }
      preview {
        id
        small {
          location
        }
      }
      original {
        asset {
          id
          originalName
        }
      }
    }
  }
`;

export interface ICadQueryParams {
  id: number;
  // shopId is required, but AppContext can have it nulled
  shopId?: number;
}

// Identical to query above?
export const CAD_QUERY = gql`
  query uploadedFiles($id: Int!, $shopId: Int!) {
    cadFile(id: $id, shopId: $shopId) {
      id
      conversionStatus
      unit
      size {
        x
        y
        z
      }
      preview {
        small {
          location
        }
      }
      original {
        asset {
          id
          originalName
        }
      }
    }
  }
`;

export interface IGetUploadUrlParams {
  fileName: string;
  fileSize: number;
}

export interface IGetUploadUrlResponse {
  url: string;
  fields: {
    XAmzDate: string;
    XAmzAlgorithm: string;
    XAmzCredential: string;
    XAmzSignature: string;
    bucket: string;
    key: string;
    Policy: string;
  };
}

export const GET_UPLOAD_URL = gql`
  mutation UploadUrl($fileName: String!, $fileSize: BigInt!) {
    getUploadUrl(fileName: $fileName, fileSize: $fileSize) {
      fields {
        XAmzDate
        XAmzAlgorithm
        XAmzCredential
        XAmzSignature
        bucket
        key
        Policy
      }
      url
    }
  }
`;

export interface IFileMeta {
  filename: string;
  key: string;
}

export const UPLOAD_SUCCESS = gql`
  mutation UploadSuccess($keys: [FileMeta!]!, $shopId: Int!) {
    uploadSuccess(keys: $keys, shopId: $shopId) {
      ... on CadModel {
        id
        conversionStatus
        unit
        size {
          x
          y
          z
        }
        original {
          asset {
            id
            originalName
          }
        }
      }
      ... on GenericFile {
        id
        asset {
          originalName
        }
      }
      ... on Image {
        id
        small {
          id
          originalName
          location
        }
      }
      __typename
    }
  }
`;
