import { MutationUpdaterFn, DataProxy } from "@apollo/client";
import { PROFILE } from "@/graphql/Queries";
import { Preference } from "@grabcad/preferences-client";
import { IProfileResponse } from "@/graphql/Fragments/User";
import { cloneDeep } from "lodash";
import { UpdatePreferenceResult } from "@/graphql/Mutations/User";

export const COMPLETED_ONBOARDING = "completed-onboarding";

export const updatePreferencesCache: MutationUpdaterFn<UpdatePreferenceResult> = (
  cache: DataProxy,
  { data }
) => {
  const profileQueryResult = cache.readQuery<IProfileResponse>({ query: PROFILE });
  const newData = cloneDeep(profileQueryResult);
  if (data?.updatePreference && newData?.userProfile) {
    const otherPreferences = profileQueryResult?.userProfile?.preferences?.filter(
      (pref: Preference) => pref.key !== data.updatePreference.key
    );

    newData.userProfile.preferences = [...(otherPreferences ?? []), data.updatePreference];

    cache.writeQuery<IProfileResponse>({ data: newData, query: PROFILE });
  }
};
