import { IUploadContainer } from "@/components/Upload/CadDropZone";
import { Image } from "grabcad-ui-elements";
import Model from "@/assets/icons/model.svg";
import React, { SyntheticEvent, useContext } from "react";
import { Icon } from "semantic-ui-react";
import { CadFileRow } from "./SubscriptionResults";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { StyledProgress } from "../../../Shared/StyledProgress";

interface IInUploadProgressProps {
  uploadContainer: IUploadContainer;
  index: number;
}
export const InUploadProgress = ({
  uploadContainer,
  index,
}: IInUploadProgressProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);
  const { cadFile, progress, loaded, file, total, cancel, error } = uploadContainer;
  return (
    <CadFileRow
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation();
      }}
    >
      <div className="num">{index + 1}</div>
      <div className="img">
        <Image src={Model} width="20" />
      </div>
      <div className="name-n-status">
        <strong>{file.name}</strong>
        <div className="status">
          {t("order.form.uploader.uploading")}
          <span className="kb">{` (${Math.round(loaded / 1024)} / ${Math.round(
            total / 1024
          )} KB)`}</span>
        </div>
      </div>
      <Icon
        onClick={(event: SyntheticEvent) => {
          cancel();
          event.stopPropagation();
        }}
        name={cadFile ? "trash alternate outline" : "x"}
      />
      <StyledProgress
        percent={progress}
        size="tiny"
        color={error ? "red" : cadFile ? "green" : "blue"}
        attached="bottom"
      />
    </CadFileRow>
  );
};
