import styled, { Button, Modal, Image } from "grabcad-ui-elements";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { IOrderItem } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { AutoEstimations } from "@/components/Order/Items/AutoEstimations";
import { getAutoEstimatedValues } from "@/components/Shared/AutoEstimations";
import ReactGA from "react-ga";
import {
  PriceCalculator,
  StyledModalMessage,
  getOrderItemData,
  warningBanner,
} from "./PriceCalculator";
import { ApolloError, DataProxy, useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEM, UpdateOrderItemType } from "@/graphql/Mutations/OrderItem";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { Notifier } from "@/utils/Notifier";
import { Permission } from "@/utils/Permission";
import { GRAPHQL_MODEL_UNITS } from "@/utils/DropdownUtils";
import { AutoEstimation } from "../../../../graphql/Fragments/AutoEstimation";
import information from "../../../../assets/icons/information.svg";
import check from "@/assets/icons/check.svg";
import { PartPreview } from "./PartPreview";
import { ModalNavBar, NavBarItem } from "../../../../components/Shared/ModalNavBar";

const StyledOrderItemModal = styled(Modal)`
  &.ui.modal.rates-modal {
    width: 1256px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
    > .close {
      color: black;
      margin-right: -7px;
    }
    .header {
      color: black;
    }
    .sub-header {
      padding: 20px;
      word-break: break-all;
    }
    > .content {
      color: black;
      padding: 0;
    }
    .inner-content {
      overflow: auto;
      max-height: 900px;
    }
    .actions {
      padding: 20px 20px;
      height: 78px;
      .cancel {
        float: left;
        margin: 0;
      }
      .submit {
        float: right;
      }
    }
  }
`;

export enum OrderItemModalTab {
  estimation,
  rates,
  preview,
}

export const OrderItemModal = ({
  open,
  orderItem,
  tab,
  previewId,
  refetchOrderEvents,
  orderId,
  onClose,
}: {
  open: boolean;
  orderItem: IOrderItem;
  tab: OrderItemModalTab;
  previewId?: number;
  refetchOrderEvents?: boolean;
  orderId?: number;
  onClose(): void;
}): JSX.Element | null => {
  const { t, messageExists, currentShop, ability } = useContext(ApplicationContext);
  if (!currentShop) {
    return null;
  }

  const client = useApolloClient();
  const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM, {
    update: async (cache: DataProxy) =>
      refetchOrderEvents && orderId && fetchNewOrderEvents(cache, client, orderId),
  });

  const [activeTab, setActiveTab] = useState<OrderItemModalTab>(tab);

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  const handleTabClick = (newTab: OrderItemModalTab) => {
    if (activeTab !== newTab) {
      setAutoEstimationId(orderItem.autoEstimationId);
      setAutoEstimationPreviewId(undefined);
      setActiveTab(newTab);
    }
  };

  const [unsavedItem, setUnsavedItem] = useState<IOrderItem>({ ...orderItem });
  const [autoEstimationId, setAutoEstimationId] = useState(orderItem.autoEstimationId);
  const [autoEstimationPreviewId, setAutoEstimationPreviewId] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    const unsavedSelectedAutoEstimation = unsavedItem.autoEstimations?.find(
      autoEstimation => autoEstimation.id === unsavedItem.autoEstimationId
    );
    const incomingSelectedAutoEstimation = orderItem.autoEstimations?.find(
      autoEstimation => autoEstimation.id === unsavedItem.autoEstimationId
    );
    if (
      unsavedSelectedAutoEstimation &&
      incomingSelectedAutoEstimation &&
      incomingSelectedAutoEstimation.resultData?.estimates.length &&
      !unsavedSelectedAutoEstimation.resultData?.estimates.length
    ) {
      // The unsaved item's selected autoEstimation was in progress, but "live" item's has just completed!
      // We need to update material and time estimation values (potentially discarding some unsaved manual inputs),
      // while retaining any other form dirtying values.
      setUnsavedItem({
        ...unsavedItem,
        autoEstimations: orderItem.autoEstimations,
        ...getAutoEstimatedValues(incomingSelectedAutoEstimation.resultData),
      });
    }
  }, [orderItem]);

  const {
    allMaterials,
    allMachines,
    currentMaterial,
    estimatedMaterialFieldName,
    estimatedSupportMaterialFieldName,
    newMaterialColor,
  } = getOrderItemData(orderItem, unsavedItem);

  const setMaterial = ({
    shopMaterialId,
    units,
    setState,
  }: {
    shopMaterialId?: number | null;
    units?: GRAPHQL_MODEL_UNITS | null;
    setState: Dispatch<SetStateAction<IOrderItem>>;
  }) => {
    const material = allMaterials.find(m => m.id === shopMaterialId);
    const machine = allMachines.find(m => m.id === material?.shopMachineId);
    const autoEstimation = orderItem.autoEstimations?.find(
      est => est.shopMaterialId === shopMaterialId && est.modelUnits === units
    );
    setState({
      ...unsavedItem,
      shopMaterialId: shopMaterialId || null,
      machineRateId: machine?.newestMachineRateId || null,
      materialRateId: material?.newestMaterialRateId || null,
      autoEstimationId: autoEstimation?.id,
      ...getAutoEstimatedValues(autoEstimation?.resultData),
    });
  };

  const writeable = ability.can(Permission.WRITE, orderItem, "price");

  const getItemToUpdate = (item: IOrderItem) => {
    let itemFieldsToUpdate: UpdateOrderItemType = {};
    const updatableFields: Array<keyof UpdateOrderItemType> = [
      "shopMaterialId",
      "overridePrice",
      "extraCost",
      "markupPrice",
      estimatedMaterialFieldName,
      estimatedSupportMaterialFieldName,
      "estimatedMinutes",
      "machineRateId",
      "materialRateId",
      "autoEstimationId",
    ];

    updatableFields.forEach(field => {
      // Only send changed fields (Is this the best UX in unlikely case of toe stepping?)
      if (unsavedItem[field] !== item[field] && unsavedItem[field] !== undefined) {
        itemFieldsToUpdate[field] = unsavedItem[field] as any;
      }
    });
    if (newMaterialColor && item.shopMaterialColorId !== newMaterialColor.id) {
      itemFieldsToUpdate.shopMaterialColorId = newMaterialColor.id;
    } else if (currentMaterial && !newMaterialColor && item.shopMaterialColorId) {
      itemFieldsToUpdate.shopMaterialColorId = null;
    }
    return itemFieldsToUpdate;
  };

  let itemToUpdate: UpdateOrderItemType = getItemToUpdate(orderItem);
  const isDirty: boolean = Object.keys(itemToUpdate).length > 0;
  const hasAutoEstimations = orderItem.autoEstimations && orderItem.autoEstimations?.length > 0;

  const updateUnsaveOrderItem = (item: IOrderItem) => {
    setUnsavedItem(item);
  };

  const onAutoEstimationSelect = (autoEstimation: AutoEstimation) => {
    setMaterial({
      shopMaterialId: autoEstimation.shopMaterialId,
      units: autoEstimation.modelUnits,
      setState: setUnsavedItem,
    });
  };

  const navItems: NavBarItem<OrderItemModalTab>[] = [
    {
      type: OrderItemModalTab.estimation,
      name: t("order.items.rates.popup.nav.autoEstimations"),
      disabled: false,
      hidden: !hasAutoEstimations,
      hoverText: t("order.items.auto_estimations.tooltip.none"),
    },
    {
      type: OrderItemModalTab.rates,
      name: t("order.items.rates.popup.nav.rates"),
      disabled: false,
      hidden: false,
    },
    {
      type: OrderItemModalTab.preview,
      name: t("order.items.rates.popup.nav.preview"),
      disabled:
        !orderItem.autoEstimationId &&
        !unsavedItem.autoEstimationId &&
        activeTab !== OrderItemModalTab.preview,
      hoverText: t("auto_estimation.snapshot.unavailable"),
      hidden: !hasAutoEstimations,
    },
  ];

  const InfoBanner = (msg: string) => {
    return (
      <StyledModalMessage info>
        <Image src={information} />
        {msg}
      </StyledModalMessage>
    );
  };

  const PositiveBanner = (msg: string) => {
    return (
      <StyledModalMessage positive>
        <Image src={check} />
        {msg}
      </StyledModalMessage>
    );
  };

  const resultData = orderItem.autoEstimations?.find(ae => ae.id === autoEstimationId)?.resultData;
  let errorMessage: string | null = null;
  const autoEstimationError = resultData?.error ?? resultData?.estimates.find(e => e.error)?.error;
  if (autoEstimationError) {
    const messageKey = `auto_estimation.error.${autoEstimationError.name}`;
    errorMessage = messageExists(messageKey)
      ? t(messageKey)
      : t("auto_estimation.error.unexpected");
  }

  const showBanner = () => {
    if (isDirty) {
      return warningBanner(t("order.items.rates.popup.info.unsaved"));
    }
    if (errorMessage && activeTab === OrderItemModalTab.preview) {
      return warningBanner(t("auto_estimation.error.snapshot"));
    }
    if (hasAutoEstimations) {
      if (orderItem.autoEstimationId && orderItem.overridePrice == null) {
        if (activeTab === OrderItemModalTab.rates) {
          return PositiveBanner(t("order.items.rates.popup.info.autoEstimated"));
        }
      } else {
        if (activeTab === OrderItemModalTab.rates) {
          return InfoBanner(t("order.items.rates.popup.info.manualEstimated"));
        } else if (activeTab === OrderItemModalTab.estimation) {
          return InfoBanner(t("order.items.rates.popup.info.manualEstimated.warn"));
        }
      }
    }
  };

  return (
    <StyledOrderItemModal
      basic
      size="small"
      open={open}
      className="rates-modal"
      onClose={onClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <h3>{t("order.items.rates.popup.title")}</h3>
      </Modal.Header>
      <Modal.Content>
        <ModalNavBar<OrderItemModalTab>
          navItems={navItems}
          activeTab={activeTab}
          onTabSelect={handleTabClick}
        />
        <div className="sub-header">{showBanner()}</div>
        <div className="inner-content">
          {activeTab === OrderItemModalTab.estimation && (
            <AutoEstimations
              orderItem={unsavedItem}
              onSelect={onAutoEstimationSelect}
              autoEstimations={orderItem.autoEstimations}
              onPreviewClick={(id: number) => {
                setAutoEstimationPreviewId(id);
                setActiveTab(OrderItemModalTab.preview);
              }}
            />
          )}
          {activeTab === OrderItemModalTab.rates && (
            <PriceCalculator
              orderItem={unsavedItem}
              onOrderItemUpdate={updateUnsaveOrderItem}
              setMaterial={setMaterial}
            />
          )}
          {activeTab === OrderItemModalTab.preview && (
            <PartPreview
              autoEstimationId={
                autoEstimationPreviewId ?? unsavedItem.autoEstimationId ?? autoEstimationId
              }
              autoEstimations={orderItem.autoEstimations}
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="qa-orderItemRate-close"
          className="cancel"
          secondary
          onClick={() => {
            onClose();
            ReactGA.event({
              category: "GcShop RateTable",
              action: "Clicking on Cancel button",
              label: `Shop ${currentShop.id}`,
            });
          }}
        >
          {t("order.items.rates.popup.footer.close")}
        </Button>
        {writeable && (
          <Button
            className="submit"
            id="qa-orderItemRate-update"
            disabled={!isDirty}
            onClick={async () => {
              await updateOrderItem({
                variables: { id: orderItem.id, input: itemToUpdate },
                onCompleted: () => {
                  Notifier.success(t("order.items.updated"));
                  onClose();
                },
                onError: (error: ApolloError) => Notifier.error(error),
                // "PRICE_TOO_HIGH" message now coming from server for any order Item change
              });
              ReactGA.event({
                category: "GcShop RateTable",
                action: "Clicking Update button in rate table",
                label: `Shop ${currentShop.id}`,
              });
            }}
          >
            {t("order.items.rates.popup.footer.update")}
          </Button>
        )}
      </Modal.Actions>
    </StyledOrderItemModal>
  );
};
