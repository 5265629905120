import gql from "graphql-tag";
import { AutoEstimationFields } from "./AutoEstimation";

const coreJobFragmentString = `
  id
    name
    shopRouteId
    dateCreated
    dateCompleted
    shopTechnologyId
    shopMachineId
    shopMaterialId
    shopMaterialColorId
    user {
      id
      name
      email
    }
    orderItems {
      id
      jobId
      quantity
      units
      shopMaterialId
      shopStatus
      permissionsV2 {
        default
        writableFields
      }
      order {
        id
        needByDate
        name
        user {
          id
          name
          email
        }
      }
      cadModel {
        id
        unit
        size {
          x
          y
          z
        }
        original {
          asset {
            id
            originalName
            location
          }
        }
      }
    }
    orderItemJobRouteStepHistories {
      id
      orderItemId
      quantityMoved
      toShopRouteStepId
      fromShopRouteStepId
      dateCreated
      dateDeleted
    }
    autoEstimationId
`;

// The following seemed to be the only way I could share the bulk of the job fields (above)
// but allowing for the SHOP_JOBS query not loading auto estimation objects
// and the JOB_DETAILS query actually loading them
export const JobFragments = {
  job: gql`
    fragment jobFields on Job {
      ${coreJobFragmentString}
    }
  `,
  jobWithAutoEstimationFields: gql`
    fragment jobWithEstimationFields on Job {
      ${coreJobFragmentString}
      autoEstimation {
        ...autoEstimationFields
      }
    }
    ${AutoEstimationFields}
  `,
};
