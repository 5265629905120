import React, { useContext } from "react";
import styled, { Checkbox, Button } from "grabcad-ui-elements";
import { IOrder, IOrderItem, OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { ItemsState } from "@/screens/Shop/Order/Show/OrderPage";
import { ToPrintButton } from "./ToPrintButton";
import { Onboarding } from "@/components/Shared/Onboarding";
import ReactGA from "react-ga";
import { DeleteOrderItemsButton } from "./DeleteOrderItemsButton";
import { MultiFileDownloadButton } from "@/components/Shared/MultiFileDownloadButton";
import { MAX_UPLOAD_FILE_SIZE } from "@/shopConstants";

const EDIT_ORDER_ONBOARDING = "edit-order";
interface IOrderItemsActionsOnMultiSelectProps {
  selectedItems: ItemsState;
  order: IOrder;
  orderItems: OrderItemOrImageOrFile[];
  selectAllOrNoneItems: (isSelectAll: boolean) => void;
  setOpenFileDialogCounter: (counter: number) => void;
  openFileDialogCounter: number;
}

const OrderItemActions = styled.div`
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  .ui.checkbox {
    margin: 0 0.5rem 0 1.5rem;
  }
  button.ui.button {
    &.circular {
      align-items: center;
      justify-content: space-around;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    > i.icon {
      color: #767676;
      height: auto;
      &.trash {
        font-size: 1.4em;
        vertical-align: bottom;
        flex: 1 2 auto;
      }
    }
  }
  .addFilesNote {
    margin-left: auto;
  }
`;

export const OrderItemsActionsOnMultiSelect = ({
  selectedItems,
  selectAllOrNoneItems,
  orderItems,
  order,
  openFileDialogCounter,
  setOpenFileDialogCounter,
}: IOrderItemsActionsOnMultiSelectProps): JSX.Element => {
  const { t } = useContext(ApplicationContext);

  const selectedItemsArray = [
    selectedItems.GenericFile,
    selectedItems.Image,
    selectedItems.OrderItem,
  ];
  const totalCountOfSelectedItems = selectedItemsArray.reduce((acc, it) => [...acc, ...it]);
  const areAllFilesSelected = totalCountOfSelectedItems.length === orderItems.length;

  const modalHeaderContent =
    totalCountOfSelectedItems.length === orderItems.length
      ? "order.items.delete.title.all"
      : "order.items.delete.title.multiple";
  const modalBodyContent =
    totalCountOfSelectedItems.length === orderItems.length
      ? "order.items.delete.body.all"
      : "order.items.delete.body.multiple";

  const toggleSelectAll = () => {
    selectAllOrNoneItems(!areAllFilesSelected);
  };

  const selectedOrderItems = orderItems.filter(item =>
    selectedItems.OrderItem.includes(item.id)
  ) as IOrderItem[];

  const orderItemsWithDependenciesSelected = selectedOrderItems.some(
    item => item.children?.length || item.childImages?.length || item.childFiles?.length
  );
  const selectedOrderItemsInJobs = selectedOrderItems.some(item => item.jobId);

  const selectedImageItems: OrderItemOrImageOrFile[] = orderItems.filter(image =>
    selectedItems.Image.includes(image.id)
  );
  const selectedFileItems: OrderItemOrImageOrFile[] = orderItems.filter(file =>
    selectedItems.GenericFile.includes(file.id)
  );
  const selectedParts: OrderItemOrImageOrFile[] = selectedOrderItems;
  const allSelectedItems: OrderItemOrImageOrFile[] = selectedParts.concat(
    selectedImageItems,
    selectedFileItems
  );

  return (
    <OrderItemActions data-testid="orderItemsActionsOnMultiSelect">
      <Checkbox
        checked={orderItems.length > 0 && areAllFilesSelected}
        disabled={orderItems.length === 0}
        onClick={() => toggleSelectAll()}
        className={"qa-deleteOrderItems-checkbox"}
      />
      <div
        className={"multi-select-copy qa-deleteOrderItems-text"}
        data-testid="deleteOrderItemsText"
      >
        {totalCountOfSelectedItems.length
          ? t("order.items.numSelected", {
              num: totalCountOfSelectedItems.length,
              total: orderItems.length,
            }).concat(
              orderItemsWithDependenciesSelected
                ? " ".concat(t("order.items.selectedWithDeps"))
                : ""
            )
          : t("order.items.noneSelected")}
      </div>
      <UiCan key={order.id} do={Permission.DELETE} on={order}>
        {/* FIXME: This permission action seems arbirary? ^^ */}
        <ToPrintButton
          selectedItems={selectedItems}
          orderId={order.id}
          orderItems={orderItems}
          orderItemsWithDependenciesSelected={orderItemsWithDependenciesSelected}
        />

        <MultiFileDownloadButton items={allSelectedItems} />

        <DeleteOrderItemsButton
          disabled={!totalCountOfSelectedItems.length || selectedOrderItemsInJobs}
          selectedItems={selectedItems}
          order={order}
          modalHeaderContent={modalHeaderContent}
          modalBodyContent={modalBodyContent}
          onDelete={() => {
            // Unselect everything after a deletion
            selectAllOrNoneItems(false);
          }}
          tooltip={selectedOrderItemsInJobs ? t("order.items.job.tooltip.delete") : undefined}
        />
        <div className="onboarding">
          <Onboarding
            onboardingKey={EDIT_ORDER_ONBOARDING}
            steps={[
              {
                target: ".onboarding",
                title: t("order.details.edit.onboarding.title"),
                content: t("order.details.edit.onboarding.content"),
                placement: "bottom",
                offset: -30,
              },
            ]}
            dismissCopy={t("general.dismiss")}
          />
        </div>
      </UiCan>
      <UiCan passThrough do={Permission.WRITE} on={order}>
        {(writable: boolean) =>
          writable && (
            <>
              <span className="addFilesNote">
                {t("order.new.fileSizeMessage", { MAX_SIZE: MAX_UPLOAD_FILE_SIZE })}
              </span>
              <Button
                id="qa-order-addFiles"
                secondary
                size="small"
                onClick={() => {
                  setOpenFileDialogCounter &&
                    openFileDialogCounter &&
                    setOpenFileDialogCounter(openFileDialogCounter + 1);
                  ReactGA.event({
                    category: "GcShop Order",
                    action: "Add Files to existing order",
                    label: `Order ${order.id}`,
                  });
                }}
              >
                {t("order.details.addFiles")}
              </Button>
            </>
          )
        }
      </UiCan>
    </OrderItemActions>
  );
};
OrderItemsActionsOnMultiSelect.displayName = "OrderItemsActionsOnMultiSelect";
