import React, { useContext } from "react";
import { AutoEstimationMaterial, FdmOrPjParameters } from "@/graphql/Fragments/AutoEstimation";
import {
  roundToNPlaces,
  transformMMToDisplayUnits,
  transformSIUnitsToRoundedDisplayUnits,
} from "../ItemsList/shopRatesUtils";
import { ApplicationContext } from "../../ApplicationProvider";
import {
  MATERIAL_QUANTITY,
  MATERIAL_UNITS,
  VOLUME_UNIT,
  WEIGHT_UNIT,
} from "../../../shopConstants";
import { getMaterialUnitLabel } from "@/utils/DropdownUtils";
import styled from "grabcad-ui-elements";

const StyledParameters = styled.div`
  display: flex;
  line-height: 25px;
  width: max-content;
  div {
    color: #333333;
    font-size: 12px;
    font-weight: 700;
  }
  div::after {
    content: "";
    border-left: 1px solid #858585;
    height: 50%;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
  }
  div:last-child::after {
    display: none;
  }
`;

const StyledMaterials = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #5c5c5c;
  font-weight: 400;
  div {
    white-space: nowrap;
    width: max-content;
    margin-right: 5px;
  }
  div::after {
    content: ",";
  }
  div:last-child::after {
    content: "";
  }
`;

const supportStyleDisplayMap: Map<string, string> = new Map([
  ["smart", "SMART"],
  ["sparse", "Sparse"],
  ["basic", "Basic"],
  ["surround", "Surround"],
  ["box", "Box"],
  ["noSupports", "No Supports"],
]); // these are NOT translated

export interface IAutoEstimationParametersProps {
  parameters: FdmOrPjParameters;
  materialDisplayUnit: MATERIAL_UNITS;
  estimateMaterialQuantity: MATERIAL_QUANTITY;
  supportMaterialQuantity: MATERIAL_QUANTITY;
  materials: AutoEstimationMaterial[] | undefined;
}

export const AutoEstimationParameters = (
  props: IAutoEstimationParametersProps
): JSX.Element | null => {
  const parameters = props.parameters;
  const { t } = useContext(ApplicationContext);
  const lengthUnit =
    props.materialDisplayUnit === MATERIAL_UNITS.IN3 ? MATERIAL_UNITS.IN : MATERIAL_UNITS.CM;
  const lengthUnitLbl =
    lengthUnit === MATERIAL_UNITS.IN ? '"' : ` ${getMaterialUnitLabel(t, lengthUnit)}`;
  const translateInfillStyle = (style: string) => {
    const prefix = "part_fill_style.";
    const translated = t(prefix + style);
    return translated.startsWith(prefix) ? style : translated;
  };
  const nonSupportMaterials = props.materials?.filter(m => !m.isSupport) || [];
  return (
    <div data-testid="estimateParameters">
      <StyledParameters>
        {parameters?.sliceHeight && (
          // FDM
          <>
            <div>
              {`${t("auto_estimation.sliceHeight")}  ${roundToNPlaces(
                transformMMToDisplayUnits(parameters.sliceHeight * 1000, lengthUnit),
                3
              )}`}
              {`${lengthUnitLbl}${
                parameters.isAdaptiveSlice ? ", Adaptive" /* this is not translated */ : ""
              }`}
            </div>
            {parameters.infillDensity && (
              <div>
                {`${t("auto_estimation.infillDensity")} ${roundToNPlaces(
                  parameters.infillDensity * 100,
                  2
                )}%`}
              </div>
            )}
            {parameters.infillStyle && (
              <div>
                {`${t("auto_estimation.infillStyle")} ${translateInfillStyle(
                  parameters.infillStyle
                )}`}
              </div>
            )}
            {parameters.supportStyle && (
              <div>
                {`${t("auto_estimation.supportStyle")} `}
                {
                  supportStyleDisplayMap.get(parameters.supportStyle) ??
                    parameters.supportStyle /* this is not translated */
                }
              </div>
            )}
          </>
        )}
        {parameters?.printMode && (
          <div>
            {t("auto_estimation.printMode") +
              " " +
              t(`auto_estimation.printMode.${parameters?.printMode}`)}
          </div>
        )}
        {parameters?.surfaceFinish && (
          <div>
            {t("auto_estimation.surfaceFinish") +
              " " +
              t(`auto_estimation.surfaceFinish.${parameters?.surfaceFinish}`)}
          </div>
        )}
      </StyledParameters>
      {nonSupportMaterials.length > 1 && (
        // This is here for the PJ case but more generically anything with multi material estimates
        // should display those materials here
        <StyledMaterials>
          {nonSupportMaterials.map(material => {
            const materialInDisplayUnits = transformSIUnitsToRoundedDisplayUnits(
              material.estimate,
              material.isSupport ? props.supportMaterialQuantity : props.estimateMaterialQuantity,
              props.materialDisplayUnit as VOLUME_UNIT | WEIGHT_UNIT
            );
            return (
              <div key={material.displayName}>
                {`${material.displayName}: ${materialInDisplayUnits}${getMaterialUnitLabel(
                  t,
                  props.materialDisplayUnit
                )}`}
              </div>
            );
          })}
        </StyledMaterials>
      )}
    </div>
  );
};
