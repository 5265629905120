import { Mutation } from "@apollo/client/react/components";
import React, { useContext, useState } from "react";
import styled, { Form, FormField, Input, Button } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { IOrder, IComment } from "@/graphql/Fragments/Order";
import { IOrderQueryVariables } from "./CommentsAndHistory";
import { ICommentInput, ADD_ORDER_COMMENT } from "@/graphql/Mutations/Comment";
import ReactGA from "react-ga";
import classnames from "classnames";
import { Notifier } from "@/utils/Notifier";

interface ICommentsFormProps {
  order: IOrder;
  refetch: (variables?: IOrderQueryVariables) => unknown;
}

const AddCommentForm = styled(Form)`
  .buttons {
    display: flex;
    button {
      flex: 1 1 50%;
    }
  }
`;

export const CommentsForm = ({ order, refetch }: ICommentsFormProps): JSX.Element => {
  const { t, currentShop } = useContext(ApplicationContext);
  const [commentText, setCommentText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const MAX_LENGTH = 1000;
  const tooltip = commentText.length > MAX_LENGTH ? t("order.details.commentTooLong") : undefined;

  return (
    <Mutation<IComment, { input: ICommentInput }>
      mutation={ADD_ORDER_COMMENT}
      onError={error => {
        Notifier.error(error);
      }}
      onCompleted={() => {
        refetch({ orderId: order.id });
        setSubmitting(false);
        setCommentText("");
      }}
    >
      {addComment => (
        <AddCommentForm size="mini">
          <FormField>
            <Input
              className={classnames({ error: commentText.length > MAX_LENGTH })}
              data-tooltip={tooltip}
              data-visible
              data-position="left center"
              id="qa-commentsForm-input"
              name="text"
              value={commentText}
              onChange={event => setCommentText(event.currentTarget.value)}
            />
          </FormField>
          <FormField>
            <div className="buttons">
              <Button
                id="qa-commentsForm-cancel"
                content={t("general.cancel")}
                secondary
                type="reset"
                size="small"
                onClick={() => {
                  setCommentText("");
                }}
              />
              <Button
                id="qa-commentsForm-submit"
                disabled={commentText.length < 1 || submitting}
                content={t("order.comments.comment")}
                primary
                size="small"
                onClick={async () => {
                  setSubmitting(true);
                  await addComment({
                    variables: {
                      input: {
                        text: commentText.slice(0, MAX_LENGTH),
                        orderId: order.id,
                      },
                    },
                  });
                  ReactGA.event({
                    category: "GcShop Comments",
                    action: "Added Comment",
                    label: `Shop ${currentShop?.id}`,
                  });
                }}
              />
            </div>
          </FormField>
        </AddCommentForm>
      )}
    </Mutation>
  );
};
