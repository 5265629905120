import classnames from "classnames";
import { DownloadButton } from "@/components/Shared/DownloadButton";
import styled, { Checkbox, Image, Segment, SegmentGroup, TableCell } from "grabcad-ui-elements";
import { IImage } from "@/graphql/Fragments/Image";
import { OrderItemOrImageOrFile } from "@/graphql/Fragments/Order";
import React from "react";
import { OrderItemsTableRow } from "../Items/Items";

interface IOrderImageProps {
  item: IImage;
  index: number;
  classNames?: string;
  selectedImageItems: number[];
  toggleSelection: (item: OrderItemOrImageOrFile) => void;
}

const OrderItemCheckbox = styled(Checkbox)`
  top: 25%;
`;

export const _OrderImageItem = ({
  item,
  index,
  classNames,
  selectedImageItems,
  toggleSelection,
}: IOrderImageProps): JSX.Element => {
  const { small } = item;

  return (
    <OrderItemsTableRow key={index} className={classnames("qa-orderImageItemRow", classNames)}>
      <TableCell verticalAlign="top" className="num">
        <strong>{index + 1}</strong>
        <OrderItemCheckbox
          checked={selectedImageItems.indexOf(item.id) > -1}
          onClick={() => toggleSelection(item)}
          className={"qa-orderItemRow-checkbox"}
        />
      </TableCell>
      <TableCell className="img">
        <SegmentGroup>
          <Segment compact>
            <Image src={small.location} centered width={small.location ? "auto" : 50} />
          </Segment>
          <Segment compact style={{ display: "flex", padding: "2px" }}>
            <DownloadButton items={[item]} />
          </Segment>
        </SegmentGroup>
      </TableCell>
      <TableCell className="model-settings" verticalAlign="middle">
        <strong className="file-name">{small.originalName}</strong>
      </TableCell>

      {/* Cells below are just to maintan grid alignment */}
      <TableCell className="details" />
      <TableCell className="status" />
      <TableCell className="price" />
    </OrderItemsTableRow>
  );
};

// Normally, child React component will re-render if the parent re-renders.
// memo() changes child React component to be a "pure component" i.e., it will
// *not* re-render if the parent changes, but only when its props/states/context changes.
export const OrderImageItem = React.memo(_OrderImageItem);
