import styled from "grabcad-ui-elements";

export const StyledGeneralScreensPage = styled.div`
  text-align: center;
  color: #003393;
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 690px;
  padding: 25px;
  > div {
    margin: 10px auto;
    > img {
      margin: 40px 0;
    }
  }
  h1 {
    font-size: 64px;
  }
  p {
    font-size: 22px;
    font-weight: 500;
  }
  .button {
    font-size: 20px;
    line-height: 12px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
  .logos {
    display: flex;
    align-items: center;
    margin: 40px;
    a {
      padding: 10px;
    }
    a:first-child {
      margin: 0 0 0 auto;
      position: relative;
      &:before {
        display: block;
        content: "";
        width: 1px;
        height: 25px;
        background: #003393;
        position: absolute;
        right: 0px;
      }
    }
    a:last-child {
      margin: 0 auto 0 0;
    }
  }
`;
