import React from "react";
import { IShopJob } from "../../graphql/Queries/Job";
import { Snapshot } from "../Shared/Snapshot";
import styled from "grabcad-ui-elements";

export interface ITrayPreviewProps {
  job: IShopJob;
}

const StyledTrayPreview = styled.div`
  width: 600px;
  margin: 20px auto 0 auto;
  img {
    border: 6px solid #d3d3d3;
  }
`;

/**
 * Tray Preview tab for the job estimates modal
 */
export const TrayPreview = (props: ITrayPreviewProps): JSX.Element => {
  const estimation = props.job.autoEstimation!;
  const snapshotAsset = estimation.autoEstimationAssets?.[0];
  return (
    <StyledTrayPreview data-testid="trayPreview">
      <Snapshot asset={snapshotAsset}></Snapshot>
    </StyledTrayPreview>
  );
};
