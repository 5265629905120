import { MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import React, { useContext, useState } from "react";
import styled, { Modal, Image, Button, Progress } from "grabcad-ui-elements";
import { ApplicationContext } from "@/components/ApplicationProvider";
import GeneratedSampleShopsImage from "@/assets/modal_generated_sample_shops.svg";
import { CREATE_SHOP_SAMPLE } from "@/graphql/Mutations/ShopSample";
import { SHOPS_LIST, TOTAL_SHOPS_COUNT } from "@/graphql/Queries/Shop";

const StyledModal = styled(Modal)`
  div.content {
    background: linear-gradient(299.21deg, #deedff -3.52%, rgba(255, 255, 255, 0) 102.97%), #ffffff !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    .img {
      width: 125px;
      height: 105px;
    }
    div.header,
    div.description {
      text-align: center;
      color: #003393;
    }
    div.header {
      font-weight: 900;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;
    }
    div.description {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      div.secondParagraph {
        .generatingText {
          padding-top: 20px;
        }
      }
    }
    .progress {
      color: #003393;
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      .bar {
        height: 0.5em;
      }
    }
  }
`;

export interface ISampleShopGenerationProps {
  onClose: (generationFailed: boolean) => void;
}

export const SampleShopGeneration = ({ onClose }: ISampleShopGenerationProps): JSX.Element => {
  const { t, currentUser } = useContext(ApplicationContext);
  const username = !!currentUser.userProfile ? currentUser.userProfile.name || "" : "";
  const [generationStarted, setGenerationStarted] = useState(false);
  const [generationCompleted, setGenerationCompleted] = useState(false);
  const [generationFailed, setGenerationFailed] = useState(false);
  return (
    <Mutation
      mutation={CREATE_SHOP_SAMPLE}
      onCompleted={() => {
        setGenerationCompleted(true);
      }}
      onError={() => {
        setGenerationFailed(true);
        setGenerationCompleted(true);
      }}
      refetchQueries={[{ query: TOTAL_SHOPS_COUNT }, { query: SHOPS_LIST }]}
    >
      {(generateSampleShop: MutationFunction) => (
        <StyledModal
          size="tiny"
          open
          onMount={() => {
            if (!generationStarted && !generationCompleted && !generationFailed) {
              setGenerationStarted(true);
              generateSampleShop();
            }
          }}
        >
          <Modal.Content>
            <Image src={GeneratedSampleShopsImage} style={{ margin: "0 auto 20px auto" }} />
            <Modal.Header>{t("shop.autogeneration.modal.welcomeuser", { username })}</Modal.Header>
            <Modal.Description>
              <p>{t("shop.autogeneration.modal.letsgetstarted")}</p>
              <div className="secondParagraph">
                {!generationCompleted ? (
                  <>
                    <p className="generatingText">{t("shop.autogeneration.modal.generating")}</p>
                    <Progress percent={100} size="tiny" active color="blue"></Progress>
                  </>
                ) : (
                  <>
                    <p>{t("shop.autogeneration.modal.shopsgenerated")}</p>
                    <p>
                      <Button
                        className="qa-sampleShop-explore"
                        onClick={() => {
                          onClose(generationFailed);
                        }}
                      >
                        {t("shop.autogeneration.modal.exploreShopButton")}
                      </Button>
                    </p>
                  </>
                )}
              </div>
            </Modal.Description>
          </Modal.Content>
        </StyledModal>
      )}
    </Mutation>
  );
};
