import { useContext, useEffect, useState } from "react";
import {
  CloudUnauthorizedProvider,
  FixturesProvider,
  getPrintersApi,
  IPrinter,
  PrintersApiProvider,
} from "grabcad-printers-api";
import fakePrinterData from "../testing-utils/ep_printers_reply.json";
import { ApplicationContext } from "@/components/ApplicationProvider";
import { Notifier } from "./Notifier";
import { config } from "../config";

// This hook largely copypastaed from https://github.com/GrabCAD/company-server/blob/master/frontend/src/screens/Printer/UseGetAllPrinters.tsx

/**
 * By convention with grabcad.com and eagle cloud, this number is added to
 * company server company ids to distinguish them from workbench account ids
 */
const WORKBENCH_COMPANY_ID_OFFSET = 1000000000; // 10^9
export function workbenchifyComanyId(id: number): number {
  // first `+` must be there to tell javascript these are numbers, not strings to concatenate!
  return +id + WORKBENCH_COMPANY_ID_OFFSET;
}

export const NO_AGENTS_MESSAGE = "No agents are connected to selected account.";
export const CLOUD_UNAVAILABLE_MESSAGE = "Unable to connect to your printers.";

export const useGetAllPrinters = () => {
  const [printers, setPrinters] = useState<IPrinter[]>([]);
  const [loadingPrinters, setLoadingPrinters] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // usePrinterFixtures is passed to tests via context to simplify components that use this hook in nested components:
  const { currentUser, usePrinterFixtures } = useContext(ApplicationContext);
  const companyId = currentUser.userProfile?.company.id as number;

  useEffect(() => {
    let provider: PrintersApiProvider;
    if (!usePrinterFixtures) {
      // Company IDs are communicated to cloud from cg.com alongside WorkBench Ids.
      // cg.com gets these from company-server, but increments them by 1 billion so they can be differentiated,
      // so we need to do the same:
      const workbenchifiedCompanyId = workbenchifyComanyId(
        (config.REACT_APP_SPOOFED_COMPANY_ID || companyId) as number
      );
      provider = new CloudUnauthorizedProvider(
        config.REACT_APP_CLOUD_API_BASE_URL as string,
        workbenchifiedCompanyId
      );
    } else {
      provider = new FixturesProvider(
        usePrinterFixtures === "none" ? ([] as any) : fakePrinterData
      );
    }

    const api = getPrintersApi(provider);
    const apiPrintersPromise =
      // TODO: Apply ACLs for non-admin roles?
      //   role === Role.CompanyAdmin || role === Role.GlobalAdmin
      //     ? api.getAllWithoutAcl()
      //     :
      api.getAll();

    void apiPrintersPromise
      .then(allPrinters => {
        setPrinters(allPrinters);
        setLoadingPrinters(false);
      })
      .catch(error => {
        setPrinters([]);
        setLoadingPrinters(false);
        setErrorOccurred(true);
        if (error) {
          console.log(JSON.stringify(error));
          setErrorMessage(error.message);
          if (error.message !== NO_AGENTS_MESSAGE) {
            Notifier.error(error);
          }
        }
      });
  }, [loadingPrinters]);

  return { loadingPrinters, setLoadingPrinters, printers, errorOccurred, errorMessage };
};
